import React, {useState, useRef} from "react";
import AvatarEditor from "react-avatar-editor";
import { Strings } from "../../../constants";
import Spinner from "../../Spinner";

const ProfilePicCropper = ({ imageSrc, setResizedImage, closeModal }) => {
  const [slideValue, setSlideValue] = useState(10);
  const [loading, setLoading] = useState(false);
  const cropRef = useRef(null);

  //handle save
  const handleSave = async () => {
    if (cropRef) {
      setLoading(true);
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setResizedImage(URL.createObjectURL(blob));
      setLoading(false);
      closeModal();
    }
  };

  return (
    <div id="modal">
      <div className="w-full lg:mt-0 bg-white bg-black/[0.85] fixed mx-auto inset-0 z-50 outline-none focus:outline-none flex items-center justify-center h-screen pb-14 sm:pb-0">
        <div className="relative mx-auto flex items-center h-full">
          <div
            className="lg:shadow-lg w-96 relative bg-white overflow-x-hidden overflow-y-auto px-2 flex flex-col"
          >
            <div className="flex justify-between items-center py-4 mb-8">
              <h3 className="text-xl font-semibold ml-8 md:ml-2 lg:ml-0">Add Profile Picture</h3>
              <button
                className="py-3 lg:py-2 px-4 font-bold text-sm flex items-center gap-2"
                onClick={() => {
                  closeModal()
                }}
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.09756 1.34146L1.09756 8.84146M8.90244 8.84146L0.902439 1.34146"
                    stroke="black"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
            <div className="w-60 h-60 flex flex-col justify-center items-center m-auto mt-12 mb-6">
              <AvatarEditor
                ref={cropRef}
                image={imageSrc}
                style={{ width: "100%", height: "100%" }}
                border={23}
                color={[0, 0, 0, 0.2]}
                scale={slideValue / 10}
                rotate={0}
              />
              <div className="flex flex-row w-full mb-12">
                <i className="fa fa-light fa-image fa-xs m-auto pt-2"></i>
                <div className="slider mt-4 mb-4 w-4/5 mx-2">
                  <input
                    id="slider"
                    onChange={(e) => setSlideValue(e.target.value)}
                    className="w-full h-1 cursor-pointer accent-secondary rounded"
                    type="range"
                    defaultValue={10}
                    min={10}
                    max={50}
                    step={0}
                  />
                </div>
                <i className="fa fa-light fa-image fa-xl m-auto pt-2"></i>
              </div>
            </div>
            <div className="flex flex-row w-full justify-end mb-6 gap-3">
              <button
                onClick={() => {
                  handleSave()
                }}
                disabled={loading}
                type="submit"
                className={`w-fit cursor-pointer text-white font-normal rounded text-center py-2 px-2 flex justify-center items-center text-base mt-4 mb-4 ${
                  loading
                    ? `bg-gray pointer-events-none`
                    : `bg-secondaryLight hover:bg-secondary active:bg-secondaryDark`
                }`}
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <Spinner color={"white"} loading={loading} size={15} />
                  </div>
                ) : (
                  `${Strings.upload}`
                )}
              </button>
              <button
                onClick={() => {
                  closeModal()
                }}
                type="submit"
                className="w-fit cursor-pointer text-white font-normal rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-4 bg-gray"
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <Spinner color={"white"} loading={loading} size={15} />
                  </div>
                ) : (
                  `${Strings.cancel}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default ProfilePicCropper;
