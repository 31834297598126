/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, List, Popover, Select, Tag } from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FilterAge from "../talents/talentListNew/filterComponents/filterAge";
import FilterMultiSelectBox from "../talents/talentListNew/filterComponents/filterMultiSelectBox";
import FilterWeight from "../talents/talentListNew/filterComponents/filterWeight";
import {
  ethnicityList,
  eyeColorList,
  genderList,
  hairColorList,
  inchesList,
  maxHeightFeetList,
  minHeightFeetList,
  lookupListMarkup,
  uuidMapping,
} from "../talents/talentListNew/helper";
import { squareRounded } from "../../../constants/events";
import { updateRoleCompositionTemplate } from "../../../store/actions/shifts";
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";

const RoleComposition = (props) => {
  const {
    shiftRoleCompositionTemp,
    triggerUpdateShiftComp,
    showEditIcon,
    updateRoleOnEdit
  } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [roleCompTags, setRoleCompTags] = useState([]);
  const [maxFeet, setMaxFeet] = useState("");
  const [maxInches, setMaxInches] = useState("");
  const [minFeet, setMinFeet] = useState("");
  const [minInches, setMinInches] = useState("");
  const [ageValues, setAgeValues] = useState({
    start_value: 0,
    end_value: 0,
  });
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedEthnicity, setSelectedEthnicity] = useState([]);
  const [weightValues, setWeightValues] = useState({
    start_value: 0,
    end_value: 0,
  });
  const [selectedEyeColor, setSelectedEyeColor] = useState([]);
  const [selectedHairColor, setSelectedHairColor] = useState([]);
  const [showCompositionPopover, setShowCompositionPopover] = useState(null);
  const [showCompositionParent, setShowCompositionParent] = useState(null);

  let roleCompTemp = _.cloneDeep(shiftRoleCompositionTemp);

  useEffect(() => {
    const catchResizeObserverError = (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.style.display = "none";
        }
      }
    };
  
    window.addEventListener("error", catchResizeObserverError);
  
    return () => {
      window.removeEventListener("error", catchResizeObserverError);
    };
  }, []);

  const updateRoleCompTemplate = () => {
    roleCompTemp = _.cloneDeep(shiftRoleCompositionTemp);
    let newTags = [];
    setRoleCompTags([]);
    if (roleCompTemp?.value && roleCompTemp?.value?.length) {
      roleCompTemp.value.forEach((value) => {
        const getTag = _.filter(lookupListMarkup, {
          uuid: value?.person_attribute_uuid,
        });
        let tagValues = [];
        if (value?.list) {
          tagValues = value.list;
        } else {
          tagValues = {
            start_value: value?.start_value,
            end_value: value?.end_value,
          };
        }
        if (value.person_attribute_uuid === uuidMapping.height) {
          tagValues.start_value = `${parseInt(
            value?.start_value / 12
          )}'${parseInt(value?.start_value % 12)}"`;
          tagValues.end_value = `${parseInt(value?.end_value / 12)}'${parseInt(
            value?.end_value % 12
          )}"`;
          setMinFeet(parseInt(value?.start_value / 12));
          setMinInches(parseInt(value?.start_value % 12));
          setMaxFeet(parseInt(value?.end_value / 12));
          setMaxInches(parseInt(value?.end_value % 12));
        }
        if (value.person_attribute_uuid === uuidMapping.weight) {
          setWeightValues({
            start_value: value?.start_value,
            end_value: value?.end_value,
          });
        }
        if (value.person_attribute_uuid === uuidMapping.age) {
          setAgeValues({
            start_value: value?.start_value,
            end_value: value?.end_value,
          });
        }
        if (value.person_attribute_uuid === uuidMapping.gender) {
          setSelectedGender(value.list);
        }
        if (value.person_attribute_uuid === uuidMapping.ethnicity) {
          setSelectedEthnicity(value.list);
        }
        if (value.person_attribute_uuid === uuidMapping.eyeColor) {
          setSelectedEyeColor(value.list);
        }
        if (value.person_attribute_uuid === uuidMapping.hairColor) {
          setSelectedHairColor(value.list);
        }
        const tagData = {
          uuid: getTag[0].uuid,
          data: getTag[0].tag(tagValues),
        };
        newTags.push(tagData);
      });
      setRoleCompTags(newTags);
    }
  };

  useEffect(() => {
    updateRoleCompTemplate();
  }, [shiftRoleCompositionTemp]);

  const addTagForEdit = (person_attribute_uuid, values) => {
    let existingTags = roleCompTags;
    const getTag = _.filter(lookupListMarkup, {
      uuid: person_attribute_uuid,
    });
    const existingIndex = _.findIndex(existingTags, {
      uuid: person_attribute_uuid,
    });
    const tagData = {
      uuid: getTag[0].uuid,
      data: getTag[0].tag(values),
    };
    if (existingIndex < 0) {
      existingTags.push(tagData);
    } else {
      existingTags[existingIndex] = tagData;
    }
    setRoleCompTags(existingTags);
    setShowCompositionPopover(null);
  };

  const onRoleCompositionChange = (person_attribute_uuid) => {
    let person_attribute_arr = [];
    // Restore existing values
    if (roleCompTemp?.value && roleCompTemp?.value?.length) {
      person_attribute_arr = roleCompTemp.value;
    }
    // Gender
    if (person_attribute_uuid === uuidMapping.gender && selectedGender.length) {
      const genderIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.gender,
      });
      if (selectedGender && selectedGender.length) {
        if (genderIndex < 0) {
          person_attribute_arr.push({
            person_attribute_uuid: uuidMapping.gender,
            contains: false,
            start_value: null,
            end_value: null,
            has_all_in_list: false,
            list: selectedGender,
          });
        } else {
          person_attribute_arr[genderIndex].list = selectedGender;
        }
      } else if (genderIndex >= 0) {
        person_attribute_arr.splice(genderIndex, 1);
      }
    }
    // Age
    if (
      person_attribute_uuid === uuidMapping.age &&
      ageValues.start_value &&
      ageValues.end_value
    ) {
      const { start_value, end_value } = ageValues;
      const ageIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.age,
      });
      if (
        start_value > 0 &&
        end_value > 0 &&
        parseInt(end_value) > parseInt(start_value)
      ) {
        if (ageIndex < 0) {
          person_attribute_arr.push({
            start_value: parseInt(start_value) || 0,
            end_value: parseInt(end_value) || 0,
            person_attribute_uuid: uuidMapping.age,
            contains: false,
            has_all_in_list: false,
            list: null,
          });
        } else {
          person_attribute_arr[ageIndex].start_value =
            parseInt(start_value) || 0;
          person_attribute_arr[ageIndex].end_value = parseInt(end_value) || 0;
        }
      } else if (ageIndex >= 0) {
        person_attribute_arr.splice(ageIndex, 1);
      }
    }
    // Ethinicity
    if (
      person_attribute_uuid === uuidMapping.ethnicity &&
      selectedEthnicity.length
    ) {
      const ethinicityIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.ethnicity,
      });
      if (ethinicityIndex < 0) {
        person_attribute_arr.push({
          person_attribute_uuid: uuidMapping.ethnicity,
          contains: false,
          start_value: null,
          end_value: null,
          has_all_in_list: false,
          list: selectedEthnicity,
        });
      } else {
        person_attribute_arr[ethinicityIndex].list = selectedEthnicity;
      }
    }
    // height
    if (
      person_attribute_uuid === uuidMapping.height &&
      parseInt(maxFeet) * 12 + parseInt(maxInches) >
        parseInt(minFeet) * 12 + parseInt(minInches)
    ) {
      const heightIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.height,
      });
      if (
        maxFeet > 0 &&
        maxInches > 0 &&
        minFeet > 0 &&
        minInches > 0 &&
        parseInt(maxFeet) * 12 + parseInt(maxInches) >
          parseInt(minFeet) * 12 + parseInt(minInches)
      ) {
        const calcValues = {
          start_value: parseInt(minFeet) * 12 + parseInt(minInches),
          end_value: parseInt(maxFeet) * 12 + parseInt(maxInches),
        };
        if (heightIndex < 0) {
          person_attribute_arr.push({
            start_value: calcValues.start_value,
            end_value: calcValues.end_value,
            person_attribute_uuid: uuidMapping.height,
            contains: false,
            has_all_in_list: false,
            list: null,
          });
        } else {
          person_attribute_arr[heightIndex].start_value = parseInt(
            calcValues.start_value
          );
          person_attribute_arr[heightIndex].end_value = parseInt(
            calcValues.end_value
          );
        }
      } else if (heightIndex >= 0) {
        person_attribute_arr.splice(heightIndex, 1);
      }
    }
    // weight
    if (
      person_attribute_uuid === uuidMapping.weight &&
      weightValues.start_value &&
      weightValues.end_value
    ) {
      const { start_value, end_value } = weightValues;
      const weightIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.weight,
      });
      if (
        start_value > 0 &&
        end_value > 0 &&
        parseInt(end_value) > parseInt(start_value)
      ) {
        if (weightIndex < 0) {
          person_attribute_arr.push({
            start_value: parseInt(start_value) || 0,
            end_value: parseInt(end_value) || 0,
            person_attribute_uuid: uuidMapping.weight,
            contains: false,
            has_all_in_list: false,
            list: null,
          });
        } else {
          person_attribute_arr[weightIndex].start_value =
            parseInt(start_value) || 0;
          person_attribute_arr[weightIndex].end_value =
            parseInt(end_value) || 0;
        }
      } else if (weightIndex >= 0) {
        person_attribute_arr.splice(weightIndex, 1);
      }
    }
    // Eye Color
    if (
      person_attribute_uuid === uuidMapping.eyeColor &&
      selectedEyeColor.length
    ) {
      const eyeColorIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.eyeColor,
      });
      if (selectedEyeColor && selectedEyeColor.length) {
        if (eyeColorIndex < 0) {
          person_attribute_arr.push({
            person_attribute_uuid: uuidMapping.eyeColor,
            contains: false,
            start_value: null,
            end_value: null,
            has_all_in_list: false,
            list: selectedEyeColor,
          });
        } else {
          person_attribute_arr[eyeColorIndex].list = selectedEyeColor;
        }
      } else if (eyeColorIndex >= 0) {
        person_attribute_arr.splice(eyeColorIndex, 1);
      }
    }
    // Hair color
    if (
      person_attribute_uuid === uuidMapping.hairColor &&
      selectedHairColor.length
    ) {
      const hairColorIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.hairColor,
      });
      if (selectedHairColor && selectedHairColor.length) {
        if (hairColorIndex < 0) {
          person_attribute_arr.push({
            person_attribute_uuid: uuidMapping.hairColor,
            contains: false,
            start_value: null,
            end_value: null,
            has_all_in_list: false,
            list: selectedHairColor,
          });
        } else {
          person_attribute_arr[hairColorIndex].list = selectedHairColor;
        }
      } else if (hairColorIndex >= 0) {
        person_attribute_arr.splice(hairColorIndex, 1);
      }
    }
    setShowCompositionPopover(null);
    roleCompTemp["value"] = person_attribute_arr;
    triggerUpdateShiftComp({ roleCompDetails: roleCompTemp });
  };

  const onCloseRoleCompTag = (tag) => {
    const { uuid } = tag;
    if (uuid === uuidMapping.age) {
      setAgeValues({ start_value: 0, end_value: 0 });
    }
    if (uuid === uuidMapping.weight) {
      setWeightValues({ start_value: 0, end_value: 0 });
    }
    if (uuid === uuidMapping.height) {
      setMaxFeet("");
      setMaxInches("");
      setMinFeet("");
      setMinInches("");
    }
    if (uuid === uuidMapping.gender) {
      setSelectedGender([]);
    }
    if (uuid === uuidMapping.ethnicity) {
      setSelectedEthnicity([]);
    }
    if (uuid === uuidMapping.eyeColor) {
      setSelectedEyeColor([]);
    }
    if (uuid === uuidMapping.hairColor) {
      setSelectedHairColor([]);
    }
    if (!showEditIcon) {
      let person_attribute_arr = roleCompTemp.value;
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuid,
      });
      person_attribute_arr.splice(index, 1);
      roleCompTemp["value"] = person_attribute_arr;
      triggerUpdateShiftComp({ roleCompDetails: roleCompTemp });
    } else {
      let tempTags = roleCompTags.filter((tag) => tag.uuid !== uuid);
      setRoleCompTags(tempTags);
    }
  };

  const roleCompositionData = [
    {
      title: "Age",
      description: () => {
        return (
          <FilterAge
            ageValues={ageValues}
            onMinValueChange={(value) => {
              setAgeValues({ ...ageValues, start_value: value });
            }}
            onMaxValueChange={(value) => {
              setAgeValues({ ...ageValues, end_value: value });
            }}
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.age, {
                  start_value: ageValues.start_value,
                  end_value: ageValues.end_value,
                });
              } else {
                onRoleCompositionChange(uuidMapping.age);
              }
            }}
            btnContent="Select"
          />
        );
      },
      person_attribute_uuid: uuidMapping.age,
    },
    {
      title: "Gender",
      description: () => {
        return (
          <FilterMultiSelectBox
            onChange={(value) => {
              setSelectedGender(value);
            }}
            title="GENDER (Select one or several)"
            label="Select gender or search"
            list={genderList}
            selectedValue={selectedGender}
            type="gender"
            btnContent="Select"
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.gender, selectedGender);
              } else {
                onRoleCompositionChange(uuidMapping.gender, selectedGender);
              }
            }}
          />
        );
      },
      person_attribute_uuid: uuidMapping.gender,
    },
    {
      title: "Ethnicity",
      description: () => {
        return (
          <FilterMultiSelectBox
            onChange={(value) => {
              setSelectedEthnicity(value);
            }}
            title="ETHNICITY (Select one or several)"
            label="Select ethnicity or search"
            list={ethnicityList}
            selectedValue={selectedEthnicity}
            type="ethnicity"
            btnContent="Select"
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.ethnicity, selectedEthnicity);
              } else {
                onRoleCompositionChange(
                  uuidMapping.ethnicity,
                  selectedEthnicity
                );
              }
            }}
          />
        );
      },
      person_attribute_uuid: uuidMapping.ethnicity,
    },
    {
      title: "Height",
      description: () => (
        <div className="p-[12px]">
          <div>Select height range</div>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select mb-2">
            <div>MIN</div>
            <Select
              placeholder="Feet"
              style={{
                width: 120,
              }}
              value={minFeet}
              options={minHeightFeetList}
              onChange={(value) => {
                setMinFeet(value);
              }}
            />
            <Select
              placeholder="Inches"
              style={{
                width: 120,
              }}
              value={minInches}
              options={inchesList}
              onChange={(value) => {
                setMinInches(value);
              }}
            />
          </div>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select">
            <div>MAX</div>
            <Select
              placeholder="Feet"
              style={{
                width: 120,
              }}
              value={maxFeet}
              options={maxHeightFeetList}
              onChange={(value) => {
                setMaxFeet(value);
              }}
            />
            <Select
              placeholder="Inches"
              style={{
                width: 120,
              }}
              value={maxInches}
              options={inchesList}
              onChange={(value) => {
                setMaxInches(value);
              }}
            />
          </div>
          <div className="text-right">
            <Button
              disabled={
                parseInt(maxFeet) * 12 + parseInt(maxInches) <=
                  parseInt(minFeet) * 12 + parseInt(minInches) ||
                !minFeet ||
                !maxFeet ||
                !minInches ||
                !maxInches
              }
              className="mt-[10px]"
              onClick={() => {
                if (showEditIcon) {
                  addTagForEdit(uuidMapping.height, {
                    start_value: `${minFeet}'${minInches}"`,
                    end_value: `${maxFeet}'${maxInches}`,
                  });
                } else {
                  onRoleCompositionChange(uuidMapping.height, {
                    minFeet,
                    minInches,
                    maxFeet,
                    maxInches,
                  });
                }
              }}
              icon={<PlusOutlined className="align-baseline" />}
            >
              Select
            </Button>
          </div>
        </div>
      ),
      person_attribute_uuid: uuidMapping.height,
    },
    {
      title: "Weight",
      description: () => {
        return (
          <FilterWeight
            weightValues={weightValues}
            onMinValueChange={(value) => {
              setWeightValues({ ...weightValues, start_value: value });
            }}
            onMaxValueChange={(value) => {
              setWeightValues({ ...weightValues, end_value: value });
            }}
            btnContent="Select"
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.weight, {
                  start_value: weightValues.start_value,
                  end_value: weightValues.end_value,
                });
              } else {
                onRoleCompositionChange(uuidMapping.weight, {
                  start_value: weightValues.start_value,
                  end_value: weightValues.end_value,
                });
              }
            }}
          />
        );
      },
      person_attribute_uuid: uuidMapping.weight,
    },
    {
      title: "Eye color",
      description: () => {
        return (
          <FilterMultiSelectBox
            onChange={(value) => {
              setSelectedEyeColor(value);
            }}
            title="EYE COLOR (Select one or several)"
            label="Select eye color or search"
            list={eyeColorList}
            selectedValue={selectedEyeColor}
            type="eye_color"
            btnContent="Select"
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.eyeColor, selectedEyeColor);
              } else {
                onRoleCompositionChange(uuidMapping.eyeColor, selectedEyeColor);
              }
            }}
          />
        );
      },
      person_attribute_uuid: uuidMapping.eyeColor,
    },
    {
      title: "Hair color",
      description: () => {
        return (
          <FilterMultiSelectBox
            onChange={(value) => {
              setSelectedHairColor(value);
            }}
            title="HAIR COLOR (Select one or several)"
            label="Select hair color or search"
            list={hairColorList}
            selectedValue={selectedHairColor}
            type="hair_color"
            btnContent="Select"
            onAddFilter={() => {
              if (showEditIcon) {
                addTagForEdit(uuidMapping.hairColor, selectedHairColor);
              } else {
                onRoleCompositionChange(
                  uuidMapping.hairColor,
                  selectedHairColor
                );
              }
            }}
          />
        );
      },
      person_attribute_uuid: uuidMapping.hairColor,
    },
  ];

  const clearMemoryLeak = (person_attribute_uuid) => {
    const isValueSelected = roleCompTemp?.value?.some(
      (temp) => temp.person_attribute_uuid === person_attribute_uuid
    );
    if (person_attribute_uuid === uuidMapping.age && !isValueSelected) {
      setAgeValues({
        start_value: 0,
        end_value: 0,
      });
    } else if (
      person_attribute_uuid === uuidMapping.gender &&
      !isValueSelected
    ) {
      setSelectedGender([]);
    } else if (
      person_attribute_uuid === uuidMapping.ethnicity &&
      !isValueSelected
    ) {
      setSelectedEthnicity([]);
    } else if (
      person_attribute_uuid === uuidMapping.height &&
      !isValueSelected
    ) {
      setMaxFeet("");
      setMaxInches("");
      setMinFeet("");
      setMinInches("");
    } else if (
      person_attribute_uuid === uuidMapping.weight &&
      !isValueSelected
    ) {
      setWeightValues({
        start_value: 0,
        end_value: 0,
      });
    } else if (
      person_attribute_uuid === uuidMapping.eyeColor &&
      !isValueSelected
    ) {
      setSelectedEyeColor([]);
    } else if (
      person_attribute_uuid === uuidMapping.hairColor &&
      !isValueSelected
    ) {
      setSelectedHairColor([]);
    }
  };

  const roleCompositionContent = (
    <>
      <List
        className="w-full"
        itemLayout="horizontal"
        dataSource={roleCompositionData}
        renderItem={(item) => (
          <List.Item>
            <Popover
              placement="rightTop"
              content={item.description}
              arrow={false}
              trigger="click"
              overlayClassName="role-composition-data-popover"
              open={
                showCompositionPopover
                  ? showCompositionPopover === item.title
                  : false
              }
              onOpenChange={(status) => {
                if (status) {
                  setShowCompositionPopover(item.title);
                } else {
                  setShowCompositionPopover(null);
                }
              }}
              afterOpenChange={(status) => {
                if (!status && !showEditIcon) {
                  clearMemoryLeak(item.person_attribute_uuid);
                }
              }}
            >
              <List.Item.Meta title={<span>{item.title}</span>} />
              <div className="float-right">
                <CaretRightOutlined className="align-baseline text-[#D9D9D9] text-[11px]" />
              </div>
            </Popover>
          </List.Item>
        )}
      ></List>
    </>
  );

  const onSaveEdit = () => {
    let person_attribute_arr = [];
    // Gender
    if (selectedGender.length) {
      person_attribute_arr.push({
        person_attribute_uuid: uuidMapping.gender,
        contains: false,
        start_value: null,
        end_value: null,
        has_all_in_list: false,
        list: selectedGender,
      });
    }
    // Age
    if (ageValues.start_value && ageValues.end_value) {
      const { start_value, end_value } = ageValues;
      person_attribute_arr.push({
        start_value: parseInt(start_value) || 0,
        end_value: parseInt(end_value) || 0,
        person_attribute_uuid: uuidMapping.age,
        contains: false,
        has_all_in_list: false,
        list: null,
      });
    }
    // Ethinicity
    if (selectedEthnicity.length) {
      person_attribute_arr.push({
        person_attribute_uuid: uuidMapping.ethnicity,
        contains: false,
        start_value: null,
        end_value: null,
        has_all_in_list: false,
        list: selectedEthnicity,
      });
    }
    // height
    if (
      maxFeet > 0 &&
      maxInches > 0 &&
      minFeet > 0 &&
      minInches > 0 &&
      parseInt(maxFeet) * 12 + parseInt(maxInches) >
        parseInt(minFeet) * 12 + parseInt(minInches)
    ) {
      const calcValues = {
        start_value: parseInt(minFeet) * 12 + parseInt(minInches),
        end_value: parseInt(maxFeet) * 12 + parseInt(maxInches),
      };
      person_attribute_arr.push({
        start_value: calcValues.start_value,
        end_value: calcValues.end_value,
        person_attribute_uuid: uuidMapping.height,
        contains: false,
        has_all_in_list: false,
        list: null,
      });
    }
    // weight
    if (
      weightValues.start_value &&
      weightValues.end_value &&
      weightValues.start_value > 0 &&
      weightValues.end_value > 0 &&
      parseInt(weightValues.end_value) > parseInt(weightValues.start_value)
    ) {
      const { start_value, end_value } = weightValues;
      person_attribute_arr.push({
        start_value: parseInt(start_value) || 0,
        end_value: parseInt(end_value) || 0,
        person_attribute_uuid: uuidMapping.weight,
        contains: false,
        has_all_in_list: false,
        list: null,
      });
    }
    // Eye Color
    if (selectedEyeColor.length) {
      person_attribute_arr.push({
        person_attribute_uuid: uuidMapping.eyeColor,
        contains: false,
        start_value: null,
        end_value: null,
        has_all_in_list: false,
        list: selectedEyeColor,
      });
    }
    // Hair color
    if (selectedHairColor.length) {
      person_attribute_arr.push({
        person_attribute_uuid: uuidMapping.hairColor,
        contains: false,
        start_value: null,
        end_value: null,
        has_all_in_list: false,
        list: selectedHairColor,
      });
    }
    roleCompTemp["value"] = person_attribute_arr;
    triggerUpdateShiftComp({ roleCompDetails: roleCompTemp });
    const payload = {
      person_search_obj: {
        replace_items: true,
        person_attributes: person_attribute_arr
      }
    }
    updateRoleOnEdit(payload);
  };

  return (
    <>
      <fieldset className="w-full text-lg col-span-5">
        <Popover
          placement="bottomLeft"
          content={roleCompositionContent}
          arrow={false}
          trigger="click"
          overlayClassName="role-composition-popover"
          open={showCompositionParent}
          onOpenChange={(status) => {
            if (status) {
              setShowCompositionParent(true);
            } else if (!status && !showEditIcon) {
              setShowCompositionPopover(false);
              setTimeout(() => {
                setShowCompositionParent(false);
              }, 300);
            }
          }}
        >
          <div
            className="border border-grayLight rounded w-full uppercase p-2 gap-1 text-xs relative [&.ant-popover-open]:border-richBlue"
            onClick={(e) => {
              if (showEditIcon && !isEditable) {
                setIsEditable(true);
                e.stopPropagation();
              }
            }}
          >
            <div className="flex flex-row">
              <label
                className="font-semibold font-inter text-sm text-[#393636]"
                htmlFor={roleCompTemp?.id}
              >
                {roleCompTemp?.label}
              </label>
              {roleCompTemp?.required && squareRounded()}
            </div>
            {roleCompTags.length > 0 ? (
              <div
                className={`mt-2 ${
                  !showEditIcon || isEditable ? "opacity-100" : "opacity-70"
                }`}
              >
                {roleCompTags?.map((comp, i) => {
                  return (
                    <Tag
                      color="#F1EEFA"
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "500",
                        marginInlineEnd: "2px",
                        whiteSpace: "break-spaces",
                        wordBreak: "break-word",
                      }}
                      bordered={false}
                      key={i}
                      closable
                      closeIcon={
                        <CloseOutlined className={`!text-black !font-bold`} />
                      }
                      onClose={(e) => {
                        e.preventDefault();
                        if (showEditIcon) {
                          setIsEditable(true);
                          setShowCompositionParent(true);
                          e.stopPropagation();
                        }
                        onCloseRoleCompTag(comp);
                      }}
                    >
                      {comp?.data}
                    </Tag>
                  );
                })}
              </div>
            ) : (
              <div className="mt-1 text-grayNobel text-sm font-inter normal-case">
                <p>Select all that apply</p>
              </div>
            )}
            {!showEditIcon && (
              <CaretDownOutlined className="absolute text-[#BFBFBF] bottom-[18px] right-[5px]" />
            )}
            {isEditable && (
              <div className="flex z-[1] gap-2 absolute bottom-[18px] right-[5px]">
                <img
                  src={saveIcon}
                  alt="save edit"
                  className="w-6 h-6 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditable(false);
                    setShowCompositionPopover(false);
                    setTimeout(() => {
                      setShowCompositionParent(false);
                    }, 300);
                    onSaveEdit();
                  }}
                />
                <img
                  src={clearIcon}
                  alt="clear edit"
                  className="w-6 h-6 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditable(false);
                    setShowCompositionPopover(false);
                    setTimeout(() => {
                      setShowCompositionParent(false);
                    }, 300);
                    updateRoleCompTemplate();
                  }}
                />
              </div>
            )}
          </div>
        </Popover>
      </fieldset>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  triggerUpdateShiftComp: updateRoleCompositionTemplate,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleComposition);
