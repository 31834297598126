import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './components/layout/ProtectedRoute';
import LoginForm from "./components/pages/login/LoginForm";
import PageNotFound from "./components/pages/page-not-found/pageNotFound";
import Calender from "./components/pages/calender/calender";
import ForgotPassword from "./components/pages/forget-password/forgotPassword";
import TalentList from "./components/pages/talents/talentListNew/talentList";
import { RoutingConstants } from "./constants";
import TalentForm from "./components/pages/talents/talentAdd/talentForm";
import TalentRegister from "./components/pages/talents/talentAdd/talentRegister";
import TalentRegisterThanks from "./components/pages/talents/talentAdd/talentRegisterThanks";
import EventForm from "./components/pages/events/event/EventForm";
import EventList from "./components/pages/events/EventList";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const App = () => {
  const {
    root,
    login,
    talents,
    events,
    forgotPassword,
    calendar,
    register,
    registerThanks,
  } = RoutingConstants;

  return (
    <ViewportProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path={login} element={<LoginForm />} />
          <Route exact path={forgotPassword} element={<ForgotPassword />} />
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={root} element={<TalentList />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={talents.list} element={<TalentList />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={talents.create} element={<TalentForm />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={talents.view} element={<TalentForm isTalentView />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={events.list} element={<EventList />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={events.create} element={<EventForm />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={events.view} element={<EventForm isEventView />} />
          </Route>
          <Route exact path={root} element={<ProtectedRoute />}>
            <Route exact path={calendar} element={<Calender />} />
          </Route>
          <Route exact path={register} element={<TalentRegister />} />
          <Route exact path={registerThanks} element={<TalentRegisterThanks />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ViewportProvider>
  );
}

export default App;
