import axios from "axios";
import localCacheStorage from "../utils/localCacheStorage";

const postRequest = async ({ url, payload = {}, addToken = true, timeout = 0 }) => {
  if (!url) {
    return {}
  }
  let headers = {};
  if (addToken) {
    headers['Authorization'] = `Bearer ${localCacheStorage.get("ACCESS_TOKEN")}`;
  }
  if (timeout) {
    headers['timeout'] = timeout;
  }
  const response = await axios.post(url, payload, {
    headers: headers
  });
  return response;
}

const putRequest = async ({ url, payload = {} }) => {
  if (!url) {
    return {}
  }
  let headers = {};
  headers['Authorization'] = `Bearer ${localCacheStorage.get("ACCESS_TOKEN")}`;

  const response = await axios.put(url, payload, {
    headers: headers
  });
  return response;
}

const getRequest = async (url, encodeUrl=false) => {
  if (!url) {
    return {}
  }
  const headers = {
    Authorization: `Bearer ${localCacheStorage.get("ACCESS_TOKEN")}`,
  };
  if (encodeUrl) {
    headers['content-type'] = 'application/x-www-form-urlencoded'
  }
  const response = await axios.get(url, {
    headers: headers,
  });
  return response;
}

const deleteRequest = async (url) => {
  if (!url) {
    return {}
  }
  const headers = {
    Authorization: `Bearer ${localCacheStorage.get("ACCESS_TOKEN")}`,
  };
  const response = await axios.delete(url, {
    headers: headers,
  });
  return response;
}

export { postRequest, getRequest, deleteRequest, putRequest };
