import React, { useState, useEffect, useCallback, useRef } from "react";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import caretDown from "../../../assets/svg/caretDown.svg";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

export const ReactSelectPaginate = (props) => {
  const { 
    placeholder,
    loadMoreOptions,
    onCreateOption,
    onChange,
    value,
    Menu,
    isDisabled,
    isValidNewOption=true,
    debounce,
    showEditIcon,
    editSaveCustomStyle,
    saveEdit,
    id,
    name,
    required,
    options,
    onSiteContact=false,
    clearUpdatedValue= () => {},
    isPrimary=false,
    removeEditOptions,
    isOpen,
    createLabel='Create and Save',
    altIds=[],
    hasCreateBtn,
    minInputToCreate=false
  } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState({});
  const [selectedValue, setSelectedValue] = useState({});
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    if (removeEditOptions) {
      setIsEditable(false);
    }
  }, [removeEditOptions])

  useEffect(() => {
    if (isOpen) {
      setMenuIsOpen(isOpen);
    }
  }, [isOpen])

  
  useEffect(() => {
    setSelectedValue(value);
    if(onSiteContact) {
      onMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const closeDropDownOptions = (event) => {
      if (dropDownRef && dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        onMenuClose();
      }
    };
    document.addEventListener('mousedown', closeDropDownOptions);
    return () => {
      document.removeEventListener('mousedown', closeDropDownOptions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownRef]);

  const style = {
    control: (base, state) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important"
      },
      backgroundColor: "#fff",
      minHeight: "0 !important",
      marginLeft: "8px !important",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#E8E8E8' : 'inherit',
      borderBottom: (state && state.isSelected) ? '1px #CBCBCB solid' : '1px #c4c4c4 solid',
      color: '#393636',
      '&:hover': { 
        backgroundColor: '#E8E8E8',
        pointerEvents: (minInputToCreate && state?.value?.length < minInputToCreate) ? "none" : "auto"
      },
      pointerEvents: (minInputToCreate && state?.value?.length < minInputToCreate) ? "none" : "auto"
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "0 !important"
    }),
    placeholder: (base, state) => ({
      ...base,
      margin: "0 !important",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#747373",
      textTransform: "none"
    }),
    container: (base, state) => ({
      ...base,
      marginTop: "0.125rem !important"
    }),
    input:  (base, state) => ({
      ...base,
      margin: "0 !important",
      padding: "0 !important",
      color: "#393636",
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "inter"
    }),
    clearIndicator:  (base, state) => ({
      ...base,
      padding: "0 !important"
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#393636",
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "inter",
      marginTop: "0.25rem !important"
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: "0 !important"
    }),
    menuList: (base, state) => ({
      ...base,
      border: "1px #c4c4c4 solid",
      borderRadius: "6px",
      borderBottomLeftRadius: (onSiteContact || hasCreateBtn) && '0',
      borderBottomRightRadius: (onSiteContact || hasCreateBtn) && '0',
      borderBottom: (onSiteContact || hasCreateBtn) && 'none',
      padding: "0 !important",
      height: "200px"
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: "6px"
    }),
  };

  const onChangeValue = (option) => {
    setSelectedValue(option);
    if(showEditIcon) {
      setUpdatedVal(option);
    }
    else {
      onChange(option);
    }
  }

  const onSaveEdit = () => {
    if (onSiteContact) {
      saveEdit(id, updatedVal, name, isPrimary)
      return setIsEditable(false);
    }
    if (!Object.keys(updatedVal).length || (value && value?.value === updatedVal?.value)) {
      setIsEditable(false);
    } else {
      saveEdit(id, updatedVal, name)
      setIsEditable(false);
    }
  }

  const onOptionCreate = (option) => {
    if(showEditIcon) {
      onCreateOption(option);
      setIsEditable(false);
    } else {
      onCreateOption(option);
    }
  }

  const debounceTimeoutProp = debounce ? { debounceTimeout: debounce } : {};

  const components = {
    IndicatorSeparator: () => null,
    Menu
  }
  if (onSiteContact) {
    components['Option'] = options
  }

  if(showEditIcon || isDisabled) {
    components['DropdownIndicator'] = () => null;
  } else {
    components['DropdownIndicator'] = () => {
      return (
        <img src={caretDown} alt="dropdown" className="w-[11px] h-[7px]" />
      )
    } 
  }

  const onMenuOpen = useCallback(() => {
    setMenuIsOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuIsOpen(false);
  }, []);

  return (
    <div className={`pointer-events-auto ${showEditIcon && "w-full flex justify-between"} ${showEditIcon && !isEditable && "cursor-pointer"} ${isDisabled && '!pointer-events-none'}`} ref={dropDownRef} onClick={(e) => {
      if(showEditIcon && !isEditable) {
        setIsEditable(true);
        onMenuOpen();
        e.stopPropagation();
      }
    }}>
      <CreatableAsyncPaginate
        className="react-select-container w-full normal-case"
        classNamePrefix="react-select"
        isDisabled={(isDisabled || (showEditIcon && !isEditable))}
        value={selectedValue}
        defaultValue={selectedValue}
        loadOptions={loadMoreOptions}
        onCreateOption={(option) => {
          if (!minInputToCreate || option.length >= minInputToCreate) {
            onOptionCreate(option);
          }
        }}
        onChange={onChangeValue}
        placeholder={placeholder}
        closeMenuOnSelect
        styles={style}
        id={id}
        formatCreateLabel={(inputValue) => `${createLabel} "${inputValue}"`}
        isValidNewOption={() => isValidNewOption}
        components={components}
        additional={{
          page: 1,
        }}
        {...debounceTimeoutProp}
        required={required}
        cacheUniqs={[isEditable, altIds]}
        openMenuOnClick={true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        blurInputOnSelect
      />
      {isEditable && (
        <div className={`flex -mt-[12px] -ml-[10px] z-[1] gap-2 ${editSaveCustomStyle}`}>
          <img
            src={saveIcon}
            alt="save edit"
            className="w-6 h-6 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onSaveEdit();
              onMenuClose();
            }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-6 h-6 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onMenuClose();
              setIsEditable(false);
              setSelectedValue(value);
              if (onSiteContact) {
                clearUpdatedValue(isPrimary, id);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
