import React, { useState } from "react";
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import { capitalizeFirstLetter } from "../../../constants/events";

const SelectField = ({
  options,
  optionList,
  name,
  id,
  onChangeOption,
  defaultValue,
  customStyle='',
  isReadOnly=false,
  showEditIcon=false,
  saveEdit,
  required,
  defaultCustomStyle,
  editCustomStyle,
  dataId="",
  optionCustomStyle=""
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState('');
  const isOptionUpdate = true;
  let notEditable = false;
  if (isReadOnly && !showEditIcon) {
    notEditable = true;
  }
  const onOptionSelect = (e) => {
    if (showEditIcon) {
      setUpdatedVal(e.target.value)
    }
    onSelect(e)
  }
  const onSelect = (e) => {
    onChangeOption(id, e.target.value, isOptionUpdate)
  }
  const onSaveEdit = () => {
    if (defaultValue === updatedVal) {
      setIsEditable(false);
    } else {
      const isValueEdited = Boolean(defaultValue);
      saveEdit(id, updatedVal, isValueEdited, name, isOptionUpdate, optionList)
      setIsEditable(false);
    }
  }
  return (
    <div className={`flex ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
      {
        showEditIcon && !isEditable && defaultValue && <p className={`text-sm text-left text-tileGray font-normal capitalize ${defaultCustomStyle ? 'mt-0' : 'mt-1'}`}>{defaultValue}</p>
      }
      {
        (!showEditIcon || (showEditIcon && isEditable) || !defaultValue) &&
          <select
          className={`w-3/5 border-none outline-none text-sm text-left text-[#393636] placeholder-grayNobel disabled:text-[#000] focus:text-tileGray font-normal h-5 ${defaultCustomStyle ? 'mt-0' : 'mt-1'} ${customStyle} appearance-none`}
          name={name}
          id={id}
          defaultValue={defaultValue}
          required={required}
          disabled={notEditable || (showEditIcon && !isEditable)}
          onChange={(e) => onOptionSelect(e)}
          data-id={dataId}
          >
          {
            options.map((option, index) => {
              return (
                <option className={optionCustomStyle} key={index} value={index === 0 ? "" : option} selected={defaultValue === option}>{capitalizeFirstLetter(option)}</option>
              )
            })
          }
        </select>
      }
      {
        showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className={`h-4 w-4 cursor-pointer ml-ten -mt-1 ${editCustomStyle}`}
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className="grid -mt-4 ml-2 gap-2"
        >
          <img
            src={saveIcon}
            alt="save edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => {setUpdatedVal(''); setIsEditable(false)}}
          />
        </div>
      }
    </div>
  )
}

export default SelectField;
