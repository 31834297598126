import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import EventSubHeaders from './EventSubHeaders';
import EventDetails from './EventDetails';
import Navbar from '../../../layout/navbar';
import { eventFormHeaders, eventStrings } from '../../../../constants/events';
import { setNewEventId, getEvent, clearEventDetailsForm } from '../../../../store/actions/events';
import { v4 as uuidv4 } from "uuid";
import Shifts from '../shift/Shifts';
import { clearShiftDetails } from '../../../../store/actions/shifts';

const EventForm = (props) => {
  const {
    isEventView,
    triggerSetNewEventId,
    triggerGetEvent,
    triggerClearEventDetailsForm,
    eventDataLoading,
    eventShortId,
    storeEventName='',
    triggerClearShiftDetails
  } = props;
  const { id } = useParams();
  const [selectedSection, setSelectedSection] = useState(1);
  const [eventName, setEventName] = useState(storeEventName);
  let eventNamePlaceholder = '[Event Name]'
  useEffect(() => {
    if (isEventView) {
      triggerGetEvent({ id })
      triggerClearEventDetailsForm();
      triggerClearShiftDetails();
    }
    else {
      triggerSetNewEventId({ id: uuidv4() });
    }
    return () => {
      triggerClearEventDetailsForm();
      triggerClearShiftDetails();
    }
  }, []);

  useEffect(() => {
    if(isEventView) {
      if(selectedSection == 1) {
        triggerGetEvent({ id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    if (storeEventName) {
      setEventName(storeEventName)
    }
  }, [storeEventName])
  

  const renderForm = () => {
    let selectedForm = "";
    Object.keys(eventFormHeaders).forEach((header) => {
      const headerNum = parseInt(header);
      const selectedSectionNum = parseInt(selectedSection);
      if (headerNum === selectedSectionNum) {
        selectedForm = eventFormHeaders[header];
      }
    });
    switch (selectedForm) {
      case eventStrings.eventDetails:
        return <EventDetails eventId={id} showEditIcon={isEventView} setSelectedSection={updateSelectedSection} userId={id} setEventName={setEventName} />
      case eventStrings.shifts:
        return <Shifts eventId={id} showEditIcon={isEventView} setSelectedSection={updateSelectedSection} userId={id} setEventName={setEventName} />
      default:
        return <EventDetails eventId={id} showEditIcon={isEventView} setSelectedSection={updateSelectedSection} userId={id} />
    }
  };

  const updateSelectedSection = (sectionNumber) => {
    setSelectedSection(sectionNumber);
    renderForm();
  };

  return (
    <div className='flex'>
      <Navbar />
      {eventDataLoading && <div className="bg-black opacity-30 z-10 absolute top-0 left-0 w-full h-full" />}
      <div className='h-screen overflow-auto w-full mb-8'>
        <div className="flex h-screen">
          <div className="block w-full" onClick={(e) => e.stopPropagation()}>
            <div className="w-full p-8">
              <div className="w-full text-left bg-white">
                <div className="w-full">
                  {
                    eventShortId && isEventView && <p className='text-grayNeutral pt-[6px] font-medium text-sm'>#{eventShortId}</p>
                  }
                  <div className='mt-4 flex'>
                    <h3 className="text-header leading-8 font-inter font-semibold">
                      {eventName || eventNamePlaceholder}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <EventSubHeaders selectedSection={selectedSection} setSelectedSection={setSelectedSection} isEventView={isEventView} />
            <div className='p-6 w-full'>
              {renderForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDataLoading: state.events.eventDataLoading,
    eventShortId: state.events.eventShortId,
    storeEventName: state.events.eventNameTemp?.value
  };
};

const mapDispatchToProps = {
  triggerSetNewEventId: setNewEventId,
  triggerGetEvent: getEvent,
  triggerClearEventDetailsForm: clearEventDetailsForm,
  triggerClearShiftDetails: clearShiftDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
