import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./events.css";
import Navbar from "../../layout/navbar";
import { Button, Dropdown, Input, Space, Table, Checkbox } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import DropdownIcon from "../../../assets/svg/dropdown.svg";
import Column from "antd/es/table/Column";
import moment from "moment";
import {
  getEventsCoreDef,
  getEventsList,
  getFilteredEventsList,
  getEventStatusOptions,
  clearEventListFilter,
  setEventSearchText
} from "../../../store/actions/events";
import {
  getSessionValue,
  setSessionValue,
} from "../talents/talentListNew/helper";
import { RoutingConstants } from "../../../constants";
import { getTimezones } from "../../../store/actions/shifts";
import { roleStatsColor } from "../../../constants/shifts";
import { squareRounded } from "../../../constants/events";

const Events = (props) => {
  const { triggerEventsList, eventsData, triggerGetFilteredEventsList, triggerFetchEventsCoreDef, triggerFetchTimezones, triggerEventStatusOptions, triggerClearEventListFilter, triggerSetEventSearchText } = props;
  const {
    loading,
    eventsList = [],
    eventsListPageNumber,
    eventsPageSize,
    totalEventsItemCount,
    filteredEventsList = [],
    filteredEventsListPageNumber,
    filteredEventsPageSize,
    totalFilteredEventsItemCount,
    filterObj,
    eventDateRangeDropdown,
    eventSortOrderDropdown,
    eventSearchText
  } = eventsData;

  const [searchMode, setSearchMode] = useState(false);
  const [searchTerms, setSearchTerms] = useState([]);

  const [dateRangeFilter, setDateRangeFilter] = useState("");
  const [sortOrderFilter, setSortOrderFilter] = useState("");
  const [includePast, setIncludePast] = useState(false);
  const [searchText, setSearchText] = useState("");

  const eventsFilter = getSessionValue("eventsFilter");
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  useEffect(() => {
    triggerFetchEventsCoreDef();
    const isFilterApplied = eventsFilter && Object.keys(eventsFilter).length > 1 && eventsFilter?.search_field?.length > 0;
    const fetchAllEventsData = async () => {
      const currPage = eventsListPageNumber || 1;
      const pageSize = eventsPageSize || 10;
      await fetchEventsList(currPage, pageSize);
    };
    const fetchFilterData = async () => {
      await onEventsFilter(
        eventsFilter?.search_field,
        parseInt(filteredEventsListPageNumber),
        parseInt(filteredEventsPageSize)
      );
    }; 
    isFilterApplied ? fetchFilterData() : fetchAllEventsData();
    triggerFetchTimezones();
    triggerEventStatusOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventsFilter?.dateRangeSlug) {
      setDateRangeFilter(eventsFilter?.dateRangeSlug);
    } else {
      setDateRangeFilter(filterObj?.dateRangeSlug);
    }
    if (eventsFilter?.sortOrderSlug) {
      setSortOrderFilter(eventsFilter?.sortOrderSlug);
    } else {
      setSortOrderFilter(filterObj?.sortOrderSlug);
    }
    if (eventsFilter?.includePastEvents) {
      setIncludePast(eventsFilter?.includePastEvents);
    } else {
      setIncludePast(filterObj?.includePastEvents);
    }
    if (eventsFilter?.search_field) {
      setSearchText(eventsFilter?.search_field);
    } else if (eventSearchText) {
      setSearchText(eventSearchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = async (pagination) => {
    searchMode
      ? await onEventsFilter(
          searchText,
          pagination.current,
          pagination.pageSize
        )
      : await fetchEventsList(pagination.current, pagination.pageSize);
  };

  const getEventFilterObj = () => {
    const eventsFilter = getSessionValue("eventsFilter");
    const eventFilterObj = {
      dateRangeSlug: eventsFilter?.dateRangeSlug || dateRangeFilter || filterObj?.dateRangeSlug,
      sortOrderSlug: eventsFilter?.sortOrderSlug || sortOrderFilter || filterObj?.sortOrderSlug,
      includePastEvents: eventsFilter?.includePastEvents ?? includePast ?? filterObj?.includePastEvents
    }
    return eventFilterObj;
  }

  const fetchEventsList = (number=1, size=10) => {
    setSearchMode(false);
    const payload = {
      number,
      size,
      eventFilterObj: getEventFilterObj(),
      onSuccess: () => {
        console.log("fetch events list success");
      },
      onError: () => {
        console.log("fetch events list error");
      },
    };
    triggerEventsList(payload);
  };

  const onSearch = (value) => {
    triggerSetEventSearchText({ value });
    const existingFilter = getSessionValue("eventsFilter");
      const newFilter = {
        ...existingFilter,
        search_field: value
      }
    setSessionValue("eventsFilter", newFilter);
    if (value) {
      onEventsFilter(value);
    }
    else {
      setSearchMode(false);
      setSearchTerms([]);
      triggerClearEventListFilter();
      fetchEventsList();
    }
  };

  const onEventsFilter = (searchText, currentPage = 1, pageSize = 10) => {
    setSearchMode(true);
    const payload = {
      filterPayload: {
        search_field: searchText,
        number: currentPage,
        size: pageSize,
      },
      eventFilterObj: getEventFilterObj(),
      onSuccess: (searchTerms) => {
        if(searchTerms && JSON.parse(searchTerms).length) {
          setSearchTerms(JSON.parse(searchTerms));
        }
      },
      onError: () => {
        console.log("fetch filter events list error");
      },
    };
    triggerGetFilteredEventsList(payload);
  };

  const { Search } = Input;

  const tableData = searchMode
    ? filteredEventsList?.length
      ? filteredEventsList
      : []
    : eventsList;

  const redirectToCreateEvent = () => {
    navigate(RoutingConstants.events.create);
  };

  const redirectToEditEvent = (uuid) => {
    const url = `${RoutingConstants.events.event}/${uuid}`;
    navigate(url);
  }

  const onChangeFilterDropdown = (selectedValue, filter) => {
    const existingFilter = getSessionValue("eventsFilter");
    const newFilter = {
      ...existingFilter,
      [filter]: selectedValue
    }
    setSessionValue("eventsFilter", newFilter);
    searchText ? onEventsFilter(searchText, eventsListPageNumber || 1, eventsPageSize || 10) : fetchEventsList(eventsListPageNumber || 1, eventsPageSize || 10);
  }

  const onChangeFilterCheckbox = (e) => {
    const existingFilter = getSessionValue("eventsFilter");
    const newFilter = {
      ...existingFilter,
      includePastEvents: e.target.checked
    }
    setSessionValue("eventsFilter", newFilter);
    setIncludePast(e.target.checked);
    searchText ? onEventsFilter(searchText, eventsListPageNumber || 1, eventsPageSize || 10) : fetchEventsList(eventsListPageNumber || 1, eventsPageSize || 10);
  }

  const getEventDateTime = (val) => {
    let startAt = val?.local_start_at?.split("T");
    let endAt = val?.local_end_at?.split("T");
    let dateTimeToReturn = "";
    if (val?.is_date_changeable) {
      if (startAt[0] === endAt[0]) {
        dateTimeToReturn = <span>{moment(startAt[0]).format("MMMM DD, YYYY")}</span>;
      } else {
        dateTimeToReturn = <span>{moment(startAt[0]).format("MMMM DD, YYYY")} → {moment(endAt[0]).format("MMMM DD, YYYY")}</span>;
      }
    } else {
      if (startAt[0] === endAt[0]) {
        dateTimeToReturn = (
          <div className="text-primary flex items-center">
            <span>{moment(startAt[0]).format("MMMM DD, YYYY")}</span>{" "}
            <span className="flex mx-1">{squareRounded("text-black bg-black inline-block")}</span>{" "}
            <span>{moment(startAt[1], "HH:mm:ss").format("hh:mm A")}</span>
            <span className="mx-1">→{" "}</span>
            <span>{moment(endAt[1], "HH:mm:ss").format("hh:mm A")}</span>
          </div>
        );
      } else {
        dateTimeToReturn = (
          <div className="text-primary flex items-center">
            <span>{moment(startAt[0]).format("MMMM DD, YYYY")}</span>{" "}
            <span className="flex mx-1">{squareRounded("text-black bg-black inline-block")}</span>{" "}
            <span>{moment(startAt[1], "HH:mm:ss").format("hh:mm A")}</span>{" "}
            <span className="mx-1">→{" "}</span>
            <span>{moment(endAt[0]).format("MMMM DD, YYYY")}</span>{" "}
            <span className="flex mx-1">{squareRounded("text-black bg-black inline-block")}</span>{" "}
            <span>{moment(endAt[1], "HH:mm:ss").format("hh:mm A")}</span>
          </div>
        );
      }
    }
    return dateTimeToReturn;
  };

  return (
    <div className="flex">
      <Navbar />
      <div className="h-screen overflow-auto w-full events-list font-inter">
        <div className="pt-[23px] h-[50px] w-[97%] mx-auto">
          <div className="text-header font-inter leading-header font-semibold text-black float-left">
            Event List
          </div>
          <div className="float-right flex font-inter font-medium text-sm text-fontGray">
            <Button
              className="flex font-inter font-medium text-sm text-fontGray border rounded-[8px]"
              onClick={redirectToCreateEvent}
              icon={<PlusOutlined className="align-baseline mt-three" />}
            >
              Create event
            </Button>
          </div>
        </div>
        <div className="pt-[23px] mx-auto w-[97%]">
          <Space>
            <Dropdown
              menu={{
                items: eventDateRangeDropdown?.options || [],
                onClick: ({ key }) => {
                  setDateRangeFilter(key);
                  onChangeFilterDropdown(key, "dateRangeSlug");
                },
                selectedKeys: [dateRangeFilter]
              }}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <Button
                className="w-auto bg-lightGray rounded-md filter-buttons"
                size="small"
              >
                <Space>
                  <p className="text-[#000] text-subText font-medium font-inter">
                   {eventDateRangeDropdown?.options?.find(obj => obj?.value === dateRangeFilter)?.label || "Date Range"}
                  </p>
                  <img src={DropdownIcon} alt="DropDown" />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              menu={{
                items: eventSortOrderDropdown?.options || [],
                onClick: ({ key }) => {
                  setSortOrderFilter(key);
                  onChangeFilterDropdown(key, "sortOrderSlug");
                },
                selectedKeys: [sortOrderFilter]
              }}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <Button
                className="w-auto bg-lightGray rounded-md filter-buttons"
                size="small"
              >
                <Space>
                  <p className="text-[#000] text-subText font-medium font-inter">
                  {eventSortOrderDropdown?.options?.find(obj => obj?.value === sortOrderFilter)?.label || "Sort Order"}
                  </p>
                  <img src={DropdownIcon} alt="DropDown" />
                </Space>
              </Button>
            </Dropdown>
            <Search
              placeholder="Search events"
              allowClear
              suffix={false}
              prefix={<SearchOutlined />}
              size="small"
              style={{
                width: "160px",
              }}
              className="search-input font-inter text-subText font-medium text-[#000]"
              onSearch={onSearch}
              defaultValue={eventsFilter?.search_field || searchText}
              onChange={e => {
                setSearchText(e.target.value);
              }}
            />
            <Checkbox onChange={onChangeFilterCheckbox} checked={includePast}>Include past events</Checkbox>
          </Space>
        </div>
        <div className="m-auto flex cursor-pointer relative w-[97%] mx-auto mt-[15px]">
          {(searchMode ? filteredEventsList?.length : eventsList?.length) ? (
            <>
              <div className="absolute text-sm left-1 top-[20px]">Show</div>
              <div className="absolute text-sm left-[10%] top-[20px]">
                cards
              </div>
            </>
          ) : (
            <div />
          )}
          <Table
            loading={loading}
            dataSource={tableData}
            showHeader={true}
            className="w-full"
            rowClassName="event-row"
            pagination={{
              responsive: true,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total}`,
              current: searchMode
                ? parseInt(filteredEventsListPageNumber)
                : parseInt(eventsListPageNumber),
              pageSize: searchMode
                ? parseInt(filteredEventsPageSize)
                : parseInt(eventsPageSize),
              total: searchMode
                ? parseInt(totalFilteredEventsItemCount)
                : parseInt(totalEventsItemCount),
              position: ["topRight", "bottomRight"],
              locale: { items_per_page: "" },
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
            locale={{
              emptyText: (
                <div className="py-12 text-center">No Events was found</div>
              ),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => redirectToEditEvent(record?.uuid)
              };
            }}
          >
            <Column
              title="Dates/Times"
              className="w-[30%] text-subText font-medium"
              render={(val, record) => {
                const dateTime = getEventDateTime(val);
                return (
                  <span className="text-primary">
                    {dateTime}
                  </span>
                )
              }}
            />
            <Column
              title="Event name"
              className="w-[20%] text-primary text-subText font-medium"
              render={(val, record) => {
                return (
                  <>
                  <Highlighter
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={val.name}
                  />
                  </>
                )
              }}
            />
            <Column
              title="Client"
              className="w-[15%] text-grayMuted text-subText font-medium"
              render={(val, record) => {
                return (
                  <Highlighter
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={val?.client_organization?.name}
                  />
                )
              }}
            />
            <Column
              title="City"
              className="w-[10%] text-primary text-subText font-medium"
              render={(val, record) => {
                return (
                  <Highlighter
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={val?.venue_address?.city}
                  />
                )
              }}
            />
            <Column
              title="Training"
              className="w-[5%] text-primary text-subText font-medium"
              render={(val) => (val.has_training_sessions ? "Yes" : "No")}
            />
            <Column
              title="Status"
              className="text-center w-[10%]"
              render={(val, record) => {
                let stats = "";
                let statsColor = "";
                if(record?.stats) {
                  if(record?.stats?.empty_shifts > 0) {
                    stats = "Empty Shifts";
                    statsColor = roleStatsColor.red;
                  } else if(record?.stats?.understaffed) {
                    stats = "Understaffed";
                    statsColor = roleStatsColor.yellow;
                  } else {
                    stats = "Fully Staffed";
                    statsColor = roleStatsColor.green;
                  }
                }
                return (
                  <>
                    <span
                      className={`border border-transparent rounded-[4px] py-[1px] px-[5px] text-subText font-medium ${statsColor}`}
                    >
                      {stats}
                    </span>
                    {/* <span className="text-primary text-subText font-medium">
                      2 of 4
                    </span> */}
                  </>
                );
              }}
            />
          </Table>
          {(searchMode ? filteredEventsList?.length : eventsList?.length) ? (
            <>
              <div className="absolute text-sm left-1 bottom-[20px]">Show</div>
              <div className="absolute text-sm left-[10%] bottom-[20px]">
                cards
              </div>
            </>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventsData: state.events || [],
  };
};

const mapDispatchToProps = {
  triggerEventsList: getEventsList,
  triggerGetFilteredEventsList: getFilteredEventsList,
  triggerFetchEventsCoreDef: getEventsCoreDef,
  triggerFetchTimezones: getTimezones,
  triggerEventStatusOptions: getEventStatusOptions,
  triggerClearEventListFilter: clearEventListFilter,
  triggerSetEventSearchText: setEventSearchText
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
