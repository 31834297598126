import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Strings, formBtnText, FormCategories } from '../../../../constants';
import HeightField from '../../components/heightField';
import InputField from '../../components/inputField';
import SocialMediaField from '../../components/socialMediaField';
import SelectField from '../../components/selectField';
import Spinner from '../../../Spinner';
import { personalInfoUpdatedForm, updateCoreAttribute, updateExistingTalentData, updateNewTalentData } from '../../../../store/actions/talents';
import TextAreaField from './textAreaField';
import DobField from '../../components/dobField';
import _ from 'lodash';
import getKeyByValue from '../../../../globalFunctions/getKeyByValue';
import { error } from '../../../toast';

const PersonalInformation = (props) => {
  const {
    setSelectedSection,
    isPersonalInfoUpdated,
    personalInfoUserUpdated,
    personalInfoTemplate,
    triggerPersonalInfoUpdate,
    showEditIcon,
    triggerUpdateCoreAttribute,
    userId,
    triggerUpdateExistingData,
    triggerCreateAttributeData,
    isRegister,
    allFieldsUpdated
  } = props;
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });
  const [loading, setLoading] = useState(false);
  const template = _.cloneDeep(personalInfoTemplate);
  const [userCoreFields, setUserCoreFields] = useState(template[FormCategories.core]);
  const [uncategorizedFields, setUncategorizedFields] = useState(template[FormCategories.uncategorized]);
  const [bioField, setBioField] = useState(template[FormCategories.bio]);

  useEffect(() => {
    if (isPersonalInfoUpdated) {
      const userUpdated = _.cloneDeep(personalInfoUserUpdated);
      setUserCoreFields(userUpdated[FormCategories.core]);
      setUncategorizedFields(userUpdated[FormCategories.uncategorized]);
      setBioField(userUpdated[FormCategories.bio]);
    }
  }, [isPersonalInfoUpdated, personalInfoUserUpdated])

  const onChangeFt = (ft) => {
    uncategorizedFields.forEach(uncategorizedField => {
      uncategorizedField.forEach(field => {
        if (field.label === Strings.height) {
          field.ft = parseInt(ft)
        }
      })
    })
    if (!showEditIcon) { updatePersonalInfo(); }
  }

  const onChangeInch = (inch) => {
    uncategorizedFields.forEach(uncategorizedField => {
      uncategorizedField.forEach(field => { 
        if (field.label === Strings.height) {
          field.inch = parseInt(inch)
        }
      })
    })
    if (!showEditIcon) { updatePersonalInfo(); }
  }
  const onChangeUncategorizedHandler = (id, value, isOptionUpdate = false) => {
    uncategorizedFields.forEach(uncategorizedField => {
      uncategorizedField.forEach(field => {
        if (field.uuid === id) {
          if (isOptionUpdate) {
            field.optionSelection = value;
          } else {
            field.value = value;
          }
        }
      })
    })
    if (!showEditIcon) { updatePersonalInfo(); }
  }

  const updatePersonalInfo = () => {
    const personalInfo = {}
    if (!showEditIcon) {
      let coreFieldVal = [];
      let uncategorizedFieldVal = [];
      userCoreFields.forEach(field => {
        if (field.id !== Strings.stageName) {
          coreFieldVal.push(Boolean(field?.value))
        }
      })
      uncategorizedFields.forEach(fields => fields.forEach(field => uncategorizedFieldVal.push(Boolean(field?.value || field?.optionSelection || field?.ft || field?.inch))))
      const isEmptyCoreField = coreFieldVal.includes(false)
      const isEmptyUncategorizedField = uncategorizedFieldVal.includes(false)
      if (bioField?.value && !isEmptyCoreField && !isEmptyUncategorizedField) {
        personalInfo['allFieldsUpdated'] = true;
      } else {
        personalInfo['allFieldsUpdated'] = false;
      }
    }
    personalInfo[FormCategories.core] = userCoreFields;
    personalInfo[FormCategories.uncategorized] = uncategorizedFields;
    personalInfo[FormCategories.bio] = bioField;
    triggerPersonalInfoUpdate({ personalInfo })
  }

  const submit = () => {
    if (isRegister && !allFieldsUpdated) {
      uncategorizedFields.forEach(fields => fields.forEach(field => {
        if (field.label === Strings.dob && !field.value) {
          error('Please fill in Date of Birth field');
        }
      }))
      return
    }
    setLoading(true);
    updatePersonalInfo();
    setSelectedSection('2');
    setLoading(false);
  }

  const onChangeCoreHandler = (id, value) => {
    userCoreFields.forEach(field => { 
      if (field.id === id) { 
        field.value = value
      }
    })
    if (!showEditIcon) { updatePersonalInfo(); }
  }

  const onChangeBio = (e) => {
    bioField.value = e.target.value;
    if (!showEditIcon) { updatePersonalInfo(); }
  }

  const onSaveEdit = (id, value, isValueEdited, name, isOptionUpdate=false, optionList=[]) => {
    if (!id && !value) {
      return
    }
    let selectedValue = value;
    let inputVal = value;
    const optionData = _.cloneDeep(optionList);
    if (isOptionUpdate) {
      selectedValue = getKeyByValue(optionData, value);
      inputVal = selectedValue;
    }
    const actualPayload = getSavePayload(isValueEdited, id, selectedValue, inputVal, isOptionUpdate);
    updateSaveData(actualPayload, isValueEdited, id);
  }

  const getSavePayload = (isValueEdited, id, optionVal, inputVal, isOptionUpdate=false) => {
    let actualPayload = {}
    if (isValueEdited) {
      actualPayload['uuid'] = id
      actualPayload['value'] = inputVal;
    } else {
      actualPayload['person_attribute_uuid'] = id
      actualPayload['person_uuid'] = userId
      actualPayload['value'] = inputVal;
    }
    if (isOptionUpdate && optionVal) {
      actualPayload['option_selection'] = optionVal;
      actualPayload['value'] = inputVal ? inputVal : optionVal;
    }
    return actualPayload;
  }

  const saveSocialEdit = (id, isValueEdited, selectedOpt, inputVal, optionList) => {
    if (!id) {
      return
    }
    let selectedOptionValue = selectedOpt;
    const optionData = _.cloneDeep(optionList);
    if (selectedOpt) {
      selectedOptionValue = getKeyByValue(optionData, selectedOpt);
    }
    const actualPayload = getSavePayload(isValueEdited, id, selectedOptionValue, inputVal, Boolean(selectedOpt));
    updateSaveData(actualPayload, isValueEdited, id);
  }

  const updateSaveData = (actualPayload, isValueEdited, id, ft, inch) => {
    const payload = {
      actualPayload: actualPayload,
      onSuccess: (uuid) => {
        if (!isValueEdited) {
          uncategorizedFields.forEach(uncategorizedField => {
            uncategorizedField.forEach(field => {
              if (field.uuid === id) {
                field.uuid = uuid;
              }
              if (field.uuid === id && field.label === Strings.height) {
                field.ft = ft
                field.inch = inch
              }
            })
          })
        }
        updatePersonalInfo();
      },
      onError: () => {
      }
    }
    if (isValueEdited) {
      triggerUpdateExistingData(payload);
    } else {
      triggerCreateAttributeData(payload);
    }
  }

  const saveHeightEdit = (id, ft, inch, isValueEdited) => {
    if (!id) {
      return
    }
    let heightVal = (ft * 12) + inch;
    const actualPayload = getSavePayload(isValueEdited, id, "", heightVal);
    updateSaveData(actualPayload, isValueEdited, id, ft, inch);
  }

  const saveCoreEdit = (id, value, isValueEdited, name) => {
    if (!name && !id && !value) {
      return
    }
    const actualPayload = {
      'uuid': userId
    }
    actualPayload[name] = value;
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        updatePersonalInfo();
      },
      onError: () => {
      }
    }
    triggerUpdateCoreAttribute(payload);
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full">
      <div className='gap-4 grid grid-cols-3'>
        {
          userCoreFields && userCoreFields.map((coreField, index) => {
            return (
              <fieldset key={index} className="w-full m-auto text-lg mb-4">
                <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
                  <label className="font-semibold font-inter text-sm text-[#393636]" htmlFor={coreField.id}>{coreField.label}{(coreField.required || (isRegister && coreField.id !== Strings.stageName)) && <span className="text-red-500 ml-1">*</span>}</label>
                  <InputField name={coreField?.name} id={coreField?.id} onChange={onChangeCoreHandler} placeholder={coreField?.placeholder} defaultValue={coreField.value}
                    maxAllowed={coreField.maxAllowed} minAllowed={coreField.minAllowed} required={coreField.required || (isRegister && coreField.id !== Strings.stageName)} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={saveCoreEdit} />
                </div>
              </fieldset>
            )
          })
        }
      </div>
      {
        uncategorizedFields && uncategorizedFields.map((uncategorizedField, index) => {
          return(
            <div key={index} className='gap-4 grid grid-cols-3'>
              {
                uncategorizedField && uncategorizedField.map((fieldData, index) => {
                  return (
                    <fieldset key={index} className="w-full m-auto text-lg mb-4">
                      <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
                        <label className="font-semibold font-inter text-sm text-[#393636]" htmlFor={fieldData?.uuid}>{fieldData?.label}{(fieldData?.required || isRegister) && <span className="text-red-500 ml-1">*</span>}</label>
                        {
                          !fieldData.options && fieldData.label === Strings.dob &&
                            <DobField defaultValue={fieldData.value} id={fieldData.uuid} placeholder={fieldData?.placeholder} onChange={onChangeUncategorizedHandler} showEditIcon={showEditIcon} saveEdit={onSaveEdit} required={isRegister} />
                        }
                        {
                          !fieldData.options && fieldData.label === Strings.height && fieldData.label !== Strings.dob &&
                            <HeightField defaultFt={fieldData.ft && parseInt(fieldData.ft)} defaultInch={fieldData.inch && parseInt(fieldData.inch)} name={fieldData?.uuid} id={fieldData?.uuid}
                              onChangeFt={onChangeFt} onChangeInch={onChangeInch} showEditIcon={showEditIcon} saveEdit={saveHeightEdit} required={fieldData.required || isRegister} />
                        }
                        {
                          !fieldData.options && fieldData.label !== Strings.height && fieldData.label !== Strings.dob &&
                          <InputField name={fieldData?.uuid} id={fieldData?.uuid} onChange={onChangeUncategorizedHandler} placeholder={fieldData?.placeholder} defaultValue={fieldData.value} type={fieldData.type}
                            maxAllowed={fieldData.maxAllowed} minAllowed={fieldData.minAllowed} required={fieldData.required || isRegister} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveEdit} />
                        }
                        {
                          fieldData.options && fieldData.label === Strings.socialMedia &&
                            <SocialMediaField name={fieldData?.uuid} optionList={fieldData.optionsList} id={fieldData?.uuid} onChange={onChangeUncategorizedHandler} options={fieldData.options} defaultValue={fieldData.value}
                              defaultOption={fieldData.optionSelection || ''} setSelectedOption={onChangeUncategorizedHandler} placeholder='Enter Handle'  maxAllowed={fieldData.maxAllowed}
                              minAllowed={fieldData.minAllowed} required={fieldData.required || isRegister} showEditIcon={showEditIcon} saveEdit={saveSocialEdit} />
                        }
                        {
                          fieldData.options && fieldData.label !== Strings.socialMedia &&
                            <SelectField options={fieldData.options} optionList={fieldData.optionsList} name={fieldData?.uuid} id={fieldData?.uuid} onChangeOption={onChangeUncategorizedHandler} defaultValue={fieldData.optionSelection}
                              isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveEdit} required={fieldData.required || isRegister} />
                        }
                      </div>
                    </fieldset>
                  )
                })
              }
            </div>
          )
        })
      }
      <div className='w-full'>
        <fieldset className="w-full m-auto text-lg mb-4">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
            <label className="font-semibold font-inter text-sm text-[#393636]" htmlFor={bioField?.id}>{bioField?.label}{isRegister && <span className="text-red-500 ml-1">*</span>}</label>
            <TextAreaField name="bio" id="bioValue" placeholder="Enter talent bio" defaultValue={bioField?.value}
              maxAllowed={bioField?.maxAllowed} minAllowed={bioField?.minAllowed} onChange={onChangeBio}
              required={isRegister} showEditIcon={showEditIcon} saveEdit={saveCoreEdit} className="resize-none"
            />
          </div>
        </fieldset>
      </div>
      <div className={`${showEditIcon ? 'hidden' : 'flex'} float-right`}>
        <button
          onClick={() => {
            reset(
              {
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
              }
            );
          }}
          disabled={loading}
          type="submit"
          className={`cursor-pointer font-inter float-right w-fit text-white font-medium rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-8 bg-secondaryLight hover:bg-secondary active:bg-secondaryDark`}
        >
          {loading ? (
            <div className="flex flex-row justify-center items-center">
              <span className="mx-2">{Strings.loading}</span>
              <Spinner color={"white"} loading={loading} size={15} />
            </div>
          ) : (
            `${formBtnText.personalInfo}`
          )}
        </button>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    isPersonalInfoUpdated: state.talent.isPersonalInfoUpdated,
    personalInfoUserUpdated: state.talent.personalInfoUserUpdated,
    personalInfoTemplate: state.talent.personalInfoTemplate,
    allFieldsUpdated: state.talent.personalInfoUserUpdated.allFieldsUpdated
  };
};

const mapDispatchToProps = {
  triggerPersonalInfoUpdate: personalInfoUpdatedForm,
  triggerUpdateCoreAttribute: updateCoreAttribute,
  triggerUpdateExistingData: updateExistingTalentData,
  triggerCreateAttributeData: updateNewTalentData
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
