const shiftFormAttributesName = {
  addShift: {
    name: 'name',
    shiftDate: 'shift_date',
    startTime: 'start_time',
    endTime: 'end_time',
    timeZone: 'timeZone',
    searchDistance: 'search_distance',
    role: 'role',
    numberOfStaff: 'number_of_staff',
    skills: 'skills',
    minRating: 'min_rating',
    rate: 'rate',
    roleComposition: 'role_composition',
    subLocation: 'sub_location',
    shiftType: 'shiftType',
    attire: 'attire',
    duties: 'duties',
    notes: 'notes'
  }
}

const addShiftName = {
  minAllowed: 3,
  label: 'Shift Name',
  maxAllowed: 200,
  required: true,
  options: null,
  placeholder: 'Enter shift name',
  id: "name",
  value: "Shift 1",
  type: "string",
  name: shiftFormAttributesName?.addShift?.name
}

const addShiftDateTime = {
  minAllowed: 2,
  label: 'Shift start and end date',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select event start and end dates',
  id: "shiftDates",
  name: shiftFormAttributesName?.addShift?.shiftDate,
  value: {
    startDate: "",
    endDate: ""
  }
}

const addShiftTimeZone = {
  minAllowed: 2,
  label: 'Time Zone',
  maxAllowed: 1,
  required: true,
  options: null,
  optionSelection: "",
  optionsList: [],
  placeholder: 'Shift start and end time zone',
  id: "timeZone",
  value: "",
  name: shiftFormAttributesName?.addShift?.timeZone
}

const addSearchRadius = {
  minAllowed: 2,
  label: 'Talent Search Radius',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select search distance',
  id: "searchDistance",
  name: shiftFormAttributesName?.addShift?.shiftDate,
  value: "",
  optionSelection: "",
  optionsList: []
}

const shiftApiEndPoints = {
  baseUrl: process.env.REACT_APP_API_URL,
  event: '/v1/event',
  shift: '/shift',
  shifts: '/event/shifts',
  role: '/shift/role',
  clone: '/clone',
  venue: '/v1/venue/environments',
  person: '/person'
}

const shiftBtnName = {
  'createRole': 'Create role'
}

const roleStatsColor = {
  red: "bg-darkRed text-white",
  yellow: "bg-yellowGreen text-black",
  green: "bg-algae text-white"
}

const shiftRoleTemplate = {
  minAllowed: 2,
  label: 'Role',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select a role',
  id: 'role',
  name: shiftFormAttributesName?.role,
  value: "",
  selectedOption: "",
  personRecordUUID:""
}

const numberOfStaffTemplate = {
  minAllowed: 2,
  label: 'Number of staff',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Enter number',
  id: "numberOfStaff",
  name: shiftFormAttributesName?.numberOfStaff,
  value: "",
  type: "number"
}

const shiftSkillsTemplate = {
  minAllowed: 2,
  label: 'Skills',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Select all required skills for the role',
  id: 'skills',
  name: shiftFormAttributesName?.skills,
  value: [],
  selectedOption: [],
  personRecordUUID:""
}

const minRatingTemplate = {
  minAllowed: 2,
  label: 'Minimum performance rating',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select rating between 1 - 5',
  id: "minRating",
  name: shiftFormAttributesName?.minRating,
  value: "",
  type: "number"
}

const rateTemplate = {
  minAllowed: 2,
  label: 'Hourly rate (USD)',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Enter hour rate',
  id: "rate",
  name: shiftFormAttributesName?.rate,
  value: "",
  type: "number"
}

const shiftRoleCompositionTemplate = {
  minAllowed: 2,
  label: 'Role composition',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Select all that apply',
  id: 'roleComposition',
  name: shiftFormAttributesName?.roleComposition,
  value: [],
  selectedOption: [],
  personRecordUUID:""
}

const subLocationTemplate = {
  minAllowed: 2,
  label: 'Sublocation',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Enter additional information about location',
  id: "subLocation",
  name: shiftFormAttributesName?.subLocation,
  value: "",
  type: "string"
}

const shiftTypeTemplate = {
  minAllowed: 2,
  label: 'Indoor or outdoor shift',
  maxAllowed: 100,
  required: false,
  options: [],
  optionsList: {},
  placeholder: 'Select one',
  id: "shiftType",
  name: shiftFormAttributesName?.shiftType,
  value: "",
  selectedOption: "",
  personRecordUUID:""
}

const shiftNotesTemplate = {
  minAllowed: 2,
  label: 'Notes',
  maxAllowed: 250,
  required: false,
  options: null,
  placeholder: 'Enter additional important information about event location, dates, contacts, etc.',
  id: "notes",
  name: shiftFormAttributesName?.notes,
  value: "",
  selectedOption: ""
}

const shiftAttireTemplate = {
  minAllowed: 2,
  label: 'Attire',
  maxAllowed: 250,
  required: false,
  options: null,
  placeholder: 'Describe the attire; what is provided and what is required.',
  id: "attire",
  name: shiftFormAttributesName?.attire,
  value: "",
  selectedOption: ""
}

const shiftDutiesTemplate = {
  minAllowed: 2,
  label: 'Duties',
  maxAllowed: 250,
  required: false,
  options: null,
  placeholder: 'Describe duties',
  id: "duties",
  name: shiftFormAttributesName?.duties,
  value: "",
  selectedOption: ""
}

const shiftStrings = {
  addShift: 'Add Shift',
  editShift: 'Edit Shift',
  addTalentRole: "Add Person to Role"
}

export {
  shiftFormAttributesName,
  addShiftName,
  addShiftDateTime,
  addSearchRadius,
  addShiftTimeZone,
  shiftApiEndPoints,
  shiftBtnName,
  shiftRoleTemplate,
  numberOfStaffTemplate,
  minRatingTemplate,
  rateTemplate,
  shiftSkillsTemplate,
  shiftRoleCompositionTemplate,
  subLocationTemplate,
  shiftTypeTemplate,
  shiftNotesTemplate,
  shiftAttireTemplate,
  shiftDutiesTemplate,
  shiftStrings,
  roleStatsColor
}
