
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete,
    postFile,
    postLogin,
    getFile,
  postMaps,
    resolveZipToCoordinates
  };

function removeAndRedirect() {

  const authToken = 'authToken';
  localStorage.removeItem(authToken);
  localStorage.removeItem("CompanyUUID");
  localStorage.removeItem("Talent Information");
  localStorage.removeItem("Core Components");
  sessionStorage.removeItem("talentFilterTags");
  sessionStorage.removeItem("talentFilter");
  window.location.href = "/";
}
  

  async function get(url) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  async function getFile(url) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  async function post(url, body) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
        "X-REQUEST-ID": "ThisIsMyRequestID",
        "Server": "Werkzeug/2.0.1 Python/3.10.9",
        "Date": "Tue, 26 Oct 2021 15:00:00 GMT",
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  async function postLogin(url, body) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
        "X-REQUEST-ID": "ThisIsMyRequestID",
        "Server": "Werkzeug/2.0.1 Python/3.10.9",
        "Date": "Tue, 26 Oct 2021 15:00:00 GMT",
      },
      body: JSON.stringify(body),
    };

    const fetchResult = await fetch(url, requestOptions);
    let result;
    if(fetchResult.status !== 200){
      result = await fetchResult.json();
      toast.error(result.msg);
    } else {
      result = await fetchResult.json();
      toast.success("Login successful");
      return result;
    }
  }

  async function put(url, body) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  
  async function postFile(url, body) {
    const token = localStorage.getItem("authToken");
    let formData = new FormData();
    // console.log(body)
    formData.append('asset_file', body);
    const requestOptions = {
      method: "POST",
      headers: {
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
      body: formData,
    };
    const response = await fetch(url, requestOptions);
    // console.log(response)
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  
  
  
  // prefixed with underscored because delete is a reserved word in javascript
  async function _delete(url) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }
  
  // helper functions
  
  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
  }

  //google API
  async function postMaps(url, body) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
  }

async function resolveZipToCoordinates(url) {
    const token = localStorage.getItem("authToken");
    const requestOptions = {
      method: "GET",
       headers: {
        "Authorization": token ? "Bearer " + JSON.parse(token) : "Bearer my-auth-token",
      },
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 401) removeAndRedirect()
    return handleResponse(response);
}
  