import { COUNTRY_UUID, PHONE_UUID, personTypeUUID } from ".";
import moment from "moment";
import { radiusList } from "../components/pages/talents/talentListNew/helper";

const EVENT_CATEGORY_UUID="27bb9e59-033e-4735-ab0d-a5bddc41f62d";
const VENUE_CATEGORY_UUID = 'c3ae89c3-b255-4838-8595-cea225a043d3'
const OTHER_EVENT_CATEGORY_UUID = 'ba4b4b58-fdf7-4d7d-94cd-6d2c5337c4e6'
const ORGANIZATION_CATEGORY_UUID = '96e2cb3c-796b-44b9-8a02-f4cddd32c386'
const ATTACHMENT_EVENT_ATTRIBUTE_UUID = "55dfd6d2-c2d5-4b80-b9e2-d0c670e4e19a";
const ACCOUNT_MANAGER_ATTRIBUTE_UUID = "96e2cb3c-796b-44b9-8a02-f4cddd32c386";
const PRIMARY_CONTACT_ATTRIBUTE_UUID = "ed3207d3-6f0b-4e94-a927-f74a648c409e";

const eventApiEndPoints = {
  baseUrl: process.env.REACT_APP_API_URL,
  event: '/v1/event',
  eventCoreFields: '/v1/event/core/defs',
  events: "/v1/events",
  filterEvents: "/v1/events/search",
  organization: "/v1/organization",
  organizationSuggestion: "/v1/organization/autocomplete",
  venue: "/v1/venue",
  venueSuggestion: "/v1/venue/autocomplete",
  assetService: "/v1/asset",
  addEvent: "/event",
  personSuggestion: "/v1/people/search",
  personRecord: "/v1/event/person",
  attribute: "/event/attribute",
  secure: "/secure",
  timezones: '/v1/timezones',
  eventShifts: '/v1/event/shift',
  shifts: '/event/shifts',
  eventStatuses: '/v1/event/statuses',
  search: "/search",
  dateRange: "/date/range",
  sortOrder: "/sort/order",
  defaults: "/defaults",
  list: "/list"
}

const eventFormHeaders = {
  1: "Event Details",
  2: "Shifts",
  3: "Tiers",
  4: "Role Status",
  5: "Trainings"
};

const eventStrings = {
  eventDetails: "Event Details",
  shifts: "Shifts",
  tiers: "Tiers",
  roleStatus: "Role Status",
  trainings: "Trainings",
  noStaff: "No Staff",
  overStaffed: "Overstaffed",
  fullyStaffed: "Fully Staffed",
  underStaffed: "Understaffed",
  eventName: "eventName",
  clientName: "clientName",
  brandName: "brandName",
  managerName: "managerName",
  eventDates: "eventDates",
  locationSubtitle: "Location Details",
  onsiteSubtitle: "On-Site Contact",
  attachmentSubtitle: "Additional Information",
  attachedDocuments: "Attached documents",
  createVenueTitle: "Create New Venue",
  createContactTitle: "Create New Contact",
  editContactTitle: "Edit Contact",
  primaryContact: "Primary Contact",
  addRole: "Add Role",
  editRole: "Edit Role",
  viewRole: "View Role",
  disabledContent: "Field cannot be edited",
  eventVenueUpdate: "Event venue update"
}

const defaultUniqueIds = {
  client: '96e2cb3c-796b-44b9-8a02-f4cddd32c386'
}

const eventFormAttributesName = {
  eventName: "event_name",
  clientName: "client_name",
  brandName: "brand_name",
  managerName: "manager_name",
  eventDates: "event_dates",
  location: "location",
  primaryContact: "primary_contact",
  alternateContact: "alternate_contact",
  attachments: "attachments",
  notes: "notes",
  venueName: "venue_name",
  searchRadius: "search_radius",
  status: "status",
  createContact: {
    firstName: "first_name",
    lastName: "last_name",
    phoneNumber: "phone_number",
    title: "title"
  },
  editContact: {
    name: "name",
    phoneNumber: "phone_number",
    title: "title"
  },
  event: "event"
}

const eventNameTemplate = {
  minAllowed: 2,
  label: 'Event Name',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Enter event name',
  id: "eventName",
  name: eventFormAttributesName?.eventName,
  value: ""
}

const eventClientNameTemplate = {
  minAllowed: 2,
  label: 'Client',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select from existing or create a new client',
  id: "clientName",
  name: eventFormAttributesName?.clientName,
  value: "",
  selectedOption: "",
  personRecordUUID:""
}

const eventBrandNameTemplate = {
  minAllowed: 2,
  label: 'Brand',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Select from existing or create a new brand',
  id: "brandName",
  name: eventFormAttributesName?.brandName,
  value: "",
  selectedOption: "",
  personRecordUUID:""
}

const eventAccountManagerTemplate = {
  minAllowed: 2,
  label: 'Account Manager',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select from existing account manager',
  id: "managerName",
  name: eventFormAttributesName?.managerName,
  value: "",
  selectedOption: "",
  personRecordUUID:""
}

const eventDatesTemplate = {
  minAllowed: 2,
  label: 'Event Dates',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select event start and end dates',
  id: "eventDates",
  name: eventFormAttributesName?.eventDates,
  value: {
    startDate: "",
    endDate: ""
  },
  isAutoGenerated: true,
  isDateChangeable: false
}

const eventLocationTemplate = {
  minAllowed: 2,
  label: 'Venue location',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select from existing or create a new venue',
  id: "location",
  name: eventFormAttributesName?.location,
  value: "",
  selectedOption: ""
}

const eventPrimaryContactTemplate = {
  minAllowed: 2,
  label: 'primary contact',
  maxAllowed: 100,
  required: true,
  options: null,
  placeholder: 'Select from existing or add a new contact',
  id: "primaryContact",
  name: eventFormAttributesName?.primaryContact,
  value: "",
  selectedOption: "",
  personRecordUUID: ""
}

const eventAlternateContactTemplate = [{
  minAllowed: 2,
  label: 'alternate contact',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Select from existing or add a new contact',
  id: "alternateContact",
  name: eventFormAttributesName?.alternateContact,
  value: "",
  selectedOption: "",
  personRecordUUID: ""
}]

const eventAttachmentsTemplate = {
  minAllowed: 2,
  label: 'Attachments',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Accepted file types: .doc, .docx, .pdf, .jpg and .png.  Max file size 5MB.',
  id: "attachments",
  name: eventFormAttributesName?.attachments,
  value: "",
  selectedOption: ""
}

const eventNotesTemplate = {
  minAllowed: 2,
  label: 'Notes',
  maxAllowed: 250,
  required: false,
  options: null,
  placeholder: 'Enter additional important information about event location, dates, contacts, etc.',
  id: "notes",
  name: eventFormAttributesName?.notes,
  value: "",
  selectedOption: ""
}

const eventVenueNameTemplate = {
  minAllowed: 2,
  label: 'Venue name',
  maxAllowed: 100,
  required: false,
  options: null,
  placeholder: 'Enter venue name (if no name exists this field can be left blank)',
  id: "venueName",
  name: eventFormAttributesName?.venueName,
  value: "",
  selectedOption: ""
}

const searchRadiusOptions = () => {
  const options = []
  radiusList.forEach(radius => options.push(radius?.label))
  return options
}

const searchRadiusOptionList = () => {
  const options = {}
  radiusList.forEach(radius => options[radius?.value]=radius?.label)
  return options
}

const eventSearchRadius = {
  minAllowed: 2,
  label: 'Talent Search Radius',
  maxAllowed: 100,
  required: true,
  options: searchRadiusOptions(),
  placeholder: 'Select search distance',
  id: "searchRadius",
  name: eventFormAttributesName?.searchRadius,
  value: "",
  optionSelection: "",
  optionsList: searchRadiusOptionList()
}

const eventStatusTemplate = {
  minAllowed: 2,
  label: 'Status',
  maxAllowed: 100,
  required: true,
  options: [],
  placeholder: 'Select event status',
  id: "searchRadius",
  name: eventFormAttributesName?.status,
  value: "",
  optionSelection: "Draft",
  optionsList: {}
}

const editContactTemplate = [
  {
    minAllowed: 3,
    label: 'Contact Name',
    maxAllowed: 200,
    required: false,
    options: null,
    placeholder: 'Enter contact name',
    id: "name",
    value: "",
    type: "string",
    disabled: true,
    name: eventFormAttributesName?.editContact?.name
  },
  {
    minAllowed: 3,
    label: 'Contact Phone Number',
    maxAllowed: 25,
    required: true,
    options: null,
    placeholder: 'Enter phone number',
    id: "phone",
    value: "",
    type: "tel",
    disabled: false,
    name: eventFormAttributesName?.editContact?.phoneNumber
  },
  {
    minAllowed: 2,
    label: 'Contact Title',
    maxAllowed: 100,
    required: false,
    options: null,
    placeholder: 'Enter contact title',
    id: "title",
    value: "",
    disabled: false,
    name: eventFormAttributesName?.editContact?.title
  }
]

const createContactTemplate = [[
  {
    minAllowed: 2,
    label: 'contact first name',
    maxAllowed: 100,
    required: true,
    options: null,
    placeholder: 'Enter first name',
    id: "firstName",
    name: eventFormAttributesName?.createContact?.firstName,
    value: ""
  },
  {
    minAllowed: 2,
    label: 'contact last name',
    maxAllowed: 100,
    required: true,
    options: null,
    placeholder: 'Enter last name',
    id: "lastName",
    name: eventFormAttributesName?.createContact?.lastName,
    value: ""
  }
],[
  {
    minAllowed: 2,
    label: 'contact phone number',
    maxAllowed: 100,
    required: true,
    options: null,
    placeholder: '(___) ___-____',
    id: "phoneNumber",
    name: eventFormAttributesName?.createContact?.phoneNumber,
    value: ""
  },
  {
    minAllowed: 2,
    label: 'Contact Title',
    maxAllowed: 100,
    required: false,
    options: null,
    placeholder: 'Enter contact title',
    id: "title",
    name: eventFormAttributesName?.createContact?.title,
    value: "",
  }
]]

const eventApiParams = {
  pageNumber: "page_number",
  pageSize: "page_size",
  listType: "list_type",
  condensedType: "condensed",
  dropdownType: "dropdown",
  phrase: "phrase",
  datetimeStart: "datetime_start",
  searchField: "search_field",
  dropdownObj: "dropdown_object",
  includePastEventsQP: "include_past_events",
  sortOrderSlugQP: "sort_order_slug",
  dateRangeSlugQP: "date_range_slug"
};

const eventBtnText = {
  save: "save",
  createSave: "Create and Save",
  dismiss: "Dismiss",
  editSave: "Edit and Save",
  createVenue: "+ CREATE NEW VENUE",
  createPrimaryContact: "+ CREATE NEW PRIMARY CONTACT",
  createAlternateContact: "+ Add Alternate Contact",
  createContact: "+ CREATE NEW CONTACT",
  editContact: "EDIT SELECTED CONTACT",
  createEvent: "Create event",
  cancelRemoveEvent: "Cancel",
  createShift: "Create shift",
  proceed: "Proceed"
}

export const createdSuccessMessage = (name) => {
  return `${name} created successfully`;
}

export const createdErrorMessage = (name) => {
  return `Error while creating ${name}, please try again.`;
}

export const updatedSuccessMessage = (name) => {
  return name ? `${name} updated successfully` : 'Successfully updated';
}

export const updatedErrorMessage = (name) => {
  return `Error while updating ${name}, please try again.`;
}

export const deletedSuccessMessage = (name) => {
  return name ? `${name} deleted successfully` : 'Successfully deleted';
}

export const deletedErrorMessage = (name) => {
  return `Error while deleting ${name}, please try again.`;
}

const personPayload = {
  "status":"available",
  "person_type_uuid": personTypeUUID,
  "person_phones":[
    {
      "primary":true,
      "phone_type_uuid": PHONE_UUID,
      "country_uuid": COUNTRY_UUID,
      "extension":null
    }
  ]
}

const date30DaysAgo = moment().subtract(30, "days").format('YYYY-MM-DD');

const eventShiftStart = '09:00:00';
const eventShiftEnd = '15:00:00';

const squareRounded = (defaultClass="text-darkRed bg-darkRed ml-[5px] mt-[2px]") => {
  return (
    <div className={`w-1 h-1 border rounded-[1px] ${defaultClass}`}></div>
  )
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const ifContainsAlphabets = (str) => /[a-zA-Z]/g.test(str);

export {
  VENUE_CATEGORY_UUID,
  OTHER_EVENT_CATEGORY_UUID,
  ORGANIZATION_CATEGORY_UUID,
  ATTACHMENT_EVENT_ATTRIBUTE_UUID,
  ACCOUNT_MANAGER_ATTRIBUTE_UUID,
  PRIMARY_CONTACT_ATTRIBUTE_UUID,
  EVENT_CATEGORY_UUID,
  eventApiEndPoints,
  eventFormHeaders,
  eventStrings,
  eventNameTemplate,
  eventClientNameTemplate,
  eventAccountManagerTemplate,
  eventDatesTemplate,
  eventApiParams,
  eventBrandNameTemplate,
  defaultUniqueIds,
  eventBtnText,
  eventLocationTemplate,
  eventPrimaryContactTemplate,
  eventAlternateContactTemplate,
  eventAttachmentsTemplate,
  eventNotesTemplate,
  eventVenueNameTemplate,
  editContactTemplate,
  createContactTemplate,
  personPayload,
  date30DaysAgo,
  eventShiftStart,
  eventShiftEnd,
  eventFormAttributesName,
  eventSearchRadius,
  eventStatusTemplate,
  squareRounded,
  capitalizeFirstLetter
}
