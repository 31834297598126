import React, { useState, useRef, useEffect } from 'react';
import { Select } from "antd";
import { CaretDownOutlined } from '@ant-design/icons'
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import "../styles/selectDropdown.css";
import { checkIsTwoArrayEqual } from '../talents/talentListNew/helper';

const  SelectDropdown = (props) => {
  const { options, placeholder="", onChange = () => {}, selectedValue=undefined, mode=null,
    optionsClass="", showEditIcon=false, editSaveCustomStyle, saveEdit, isReadOnly, id, required = false,
    isDisabled=false
  } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOptionBackup, setSelectedOptionBackup] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const inputRef = useRef(null);

  let notEditable = false;
  if (isReadOnly && !showEditIcon) {
    notEditable = true;
  }

  useEffect(() => {
    if (selectedValue) {
      setDefaultValue({
        'label': selectedValue,
        'value': ''
      })
    }
    setSelectedOption(selectedValue);
  }, [selectedValue])

  useEffect(() => {
    if(showEditIcon) {
      setSelectedOptionBackup(selectedValue);
    }
  }, [selectedValue, showEditIcon]);
  

  const onInputChange = (value) => {
    if (showEditIcon) {
      setIsEditable(true);
      setSelectedOption(value);
      if(!value.length && required) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    else {
      onChange(value, options)
    }
  }

  const onSaveEdit = () => {
    if(!disableSubmit) {
      if((typeof selectedOption === "object" && typeof selectedOptionBackup === "object" && checkIsTwoArrayEqual(selectedOption, selectedOptionBackup)) || (selectedOption === selectedOptionBackup)) {
        setIsEditable(false);
        setSelectedOption(selectedOptionBackup);
        
      } else {
        setIsEditable(false);
        if(saveEdit) saveEdit(selectedOption, options, id);
        else onChange(selectedOption, options);
      }
    }
  }

  const style = {
    width: "100%",
    textTransform: "none"
  }
  const defaultValueProp = defaultValue ? { defaultValue: defaultValue } : {};

  return (
    <div className="select-dropdown">
      <Select
        ref={inputRef}
        placeholder={placeholder}
        onChange={(value) => onInputChange(value)}
        options={options}
        style={style}
        popupClassName={optionsClass}
        value={selectedOption || undefined}
        mode={mode}
        maxTagCount="responsive"
        suffixIcon={!showEditIcon && <CaretDownOutlined className="absolute -right-[5px] bottom-2" />}
        onClick={() => showEditIcon && setIsEditable(true)}
        filterOption={(inputValue, option) => option?.label?.toLowerCase()?.includes(inputValue.toLowerCase())}
        readOnly={notEditable || (showEditIcon && !isEditable)}
        {...defaultValueProp}
        disabled={isDisabled}
      />
      {isEditable && (
        <div className={`flex z-[1] gap-2 absolute bottom-[15px] right-[5px] ${editSaveCustomStyle}`}>
          <img
            src={saveIcon}
            alt="save edit"
            className={`w-6 h-6 cursor-pointer ${disableSubmit && "opacity-60 cursor-not-allowed"}`}
            onClick={() => {
              onSaveEdit();
            }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              setIsEditable(false);
              setSelectedOption(selectedOptionBackup);
              if (inputRef.current) {
                inputRef.current.value = selectedOptionBackup;
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
