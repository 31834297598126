import React from 'react';
import { eventFormHeaders } from '../../../../constants/events';

const EventSubHeaders = (props) => {
  const {
    selectedSection,
    setSelectedSection,
    isEventView
  } = props;
  
  const headerCustomStyle = (header) => {
    let style = 'text-policeBlue border-white border-b-3 cursor-pointer pointer-events-auto';
    if (parseInt(header) === parseInt(selectedSection)) {
      style = 'text-secondary border-secondary border-b-3 cursor-pointer pointer-events-auto';
    } else if (isEventView && parseInt(header) <= 2) {
      style = 'text-policeBlue border-white border-b-3 cursor-pointer pointer-events-auto';
    } else {
      // if (isEventView) {
      //   style = 'text-policeBlue border-white border-b-3 cursor-pointer pointer-events-auto';
      // } else {
        style = 'text-cadetBlue border-white border-b-3 disabled cursor-auto pointer-events-none';
      // }
    }
    return style
  }

  const onHeaderClick = (header) => {
    setSelectedSection(header)
  }

  return (
    <div>
      <div className="flex border-b border-grayLight">
        {
          Object.keys(eventFormHeaders).map((header) => {
            return (
              <div
                key={header}
                className={`flex items-center pl-8 pr-12 pt-2 justify-center ${headerCustomStyle(header)}`}
                onClick={() => onHeaderClick(header)}
              >
                <div className="text-lg font-medium flex items-center gap-2">
                  {eventFormHeaders[header]}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}


export default EventSubHeaders;