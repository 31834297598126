export const objToArrayOfVal = (obj) => {
  if (obj && !Object.keys(obj).length) {
    return [];
  }
  return Object.keys(obj).map(val => obj[val]);
}

export const objToArrayOfKey = (obj) => {
  if (obj && !Object.keys(obj).length) {
    return [];
  }
  return Object.keys(obj).map(val => val);
}

export const objToArrayOfValWithKey = (obj) => {
  const resultArray = [];
  Object.entries(obj).forEach(([key, label], index) => {
      resultArray.push({
        // key: (index + 1).toString(),
        key: key,
        label: label,
        value: key
      });
  });
  return resultArray;
}
