import { Button, Divider, Rate, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const FilterRating = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(()=>{
       if(props.isUnrated) {
        setIsDisabled(false)
      } else if (props.rating.end_value === 0 || props.rating.start_value === 0) {
        setIsDisabled(true)
      }else {
        setIsDisabled(props.rating.end_value < props.rating.start_value );
      }
  },[props.isUnrated, props.rating]);
  return (
    <div className="px-[12px] py-[12px] max-w-12 ">
      <div className="pb-[10px]">Select a range</div>
      <div className="mb-[16px]">
        <div>MIN</div>
        <Rate
          allowHalf
          onChange={props.onMinValueChange}
          value={props.rating.start_value}
        />
        <span className="ant-rate-text">
          Rating: {props.rating.start_value} out of 5
        </span>
      </div>

      <div>MAX</div>

      <Rate
        allowHalf
        onChange={props.onMaxValueChange}
        value={props.rating.end_value}
      />
      <span className="ant-rate-text">
        Rating: {props.rating.end_value} out of 5
      </span>
      <Divider className="!text-xs">AND</Divider>

      <div>
        <div className="mb-[8px]">INCLUDE UNRATED PROFILES</div>
        <Switch checked={props.isUnrated} onChange={props.onUnratedChange} checkedChildren="Included" unCheckedChildren="Not included" />
      </div>
      <div className="text-right">
        <Button
          disabled={isDisabled}
          className="mt-[10px]"
          onClick={() => props.onAddFilter("rating")}
          icon={<PlusOutlined className="align-baseline" />}
        >
          Add Filter
        </Button>
      </div>
    </div>
  );
};
export default FilterRating;
