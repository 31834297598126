export default Object.freeze({
    // get from local storage
    get(key) {
      try {
        return JSON.parse(window.localStorage.getItem(key)) || {};
      } catch (err) {
        return {};
      }
    },
    // save to local storage
    set(key, state) {
      window.localStorage.setItem(key, JSON.stringify(state));
    },
    // clear from local storage
    clear(key) {
      window.localStorage.removeItem(key);
    },
  });
  