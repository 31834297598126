import { eventLocationTemplate } from "../../constants/events";
import { addShiftDateTime, addShiftName, addShiftTimeZone, shiftRoleTemplate,
  numberOfStaffTemplate, minRatingTemplate, rateTemplate, shiftSkillsTemplate,
  shiftRoleCompositionTemplate, subLocationTemplate, shiftTypeTemplate,
  shiftNotesTemplate, shiftAttireTemplate, shiftDutiesTemplate } from "../../constants/shifts";
import actionTypes from "../types";

const {
  GET_TIMEZONES_SUCCESS, UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS, UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS, UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS, GET_EVENT_SHIFTS_SUCCESS, UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS, UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS, UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS, UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS, UPDATE_MIN_RATING_TEMPLATE_SUCCESS,
  UPDATE_RATE_TEMPLATE_SUCCESS, UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS, UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS, UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS, RESET_ROLE_MODAL_TEMPLATE, CLEAR_SHIFT_DETAILS_SUCCESS, GET_VENUE_ENVIRONMENT_SUCCESS
} = actionTypes.shifts;

const { LOGOUT_SUCCESS } = actionTypes.auth;

const initialState = {
  timezones: [],
  shiftNameTemp: addShiftName,
  shiftDateTimeTemp: addShiftDateTime,
  shiftLocationTemp: eventLocationTemplate,
  shiftTimezoneTemp: addShiftTimeZone,
  shiftList: [],
  loading: false,
  shiftRoleTemp: shiftRoleTemplate,
  numberOfStaffTemp: numberOfStaffTemplate,
  shiftSkillsTemp: shiftSkillsTemplate,
  minRatingTemp: minRatingTemplate,
  rateTemp: rateTemplate,
  shiftRoleCompositionTemp: shiftRoleCompositionTemplate,
  subLocationTemp: subLocationTemplate,
  shiftTypeTemp: shiftTypeTemplate,
  shiftAttireTemp: shiftAttireTemplate,
  shiftDutiesTemp: shiftDutiesTemplate,
  shiftNotesTemp: shiftNotesTemplate,
}

const shiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS: 
      return {
        ...initialState
      }
    case GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action?.response?.timezone,
        shiftTimezoneTemp: action?.response?.timezoneTemp
      };
    case UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftNameTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftTimezoneTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftDateTimeTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftLocationTemp: action?.response?.shiftDetails
      }
    case GET_EVENT_SHIFTS_SUCCESS:
        return {
          ...state,
          shiftList: action?.response?.eventShifts
        };
    case UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftRoleTemp: action?.response?.roleDetails
      }
    case UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS:
      return {
        ...state,
        numberOfStaffTemp: action?.response?.shiftDetails
      }
    case UPDATE_MIN_RATING_TEMPLATE_SUCCESS:
      return {
        ...state,
        minRatingTemp: action?.response?.shiftDetails
      }
    case UPDATE_RATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        rateTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftSkillsTemp: action?.response?.skillsDetails
      }
    case UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftRoleCompositionTemp: action?.response?.roleCompDetails
      }
    case UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        subLocationTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftTypeTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftNotesTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftAttireTemp: action?.response?.shiftDetails
      }
    case UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS:
      return {
        ...state,
        shiftDutiesTemp: action?.response?.shiftDetails
      }
    case RESET_ROLE_MODAL_TEMPLATE:
      return {
        ...state,
        shiftRoleTemp: shiftRoleTemplate,
        numberOfStaffTemp: numberOfStaffTemplate,
        shiftSkillsTemp: shiftSkillsTemplate,
        minRatingTemp: minRatingTemplate,
        rateTemp: rateTemplate,
        shiftRoleCompositionTemp: shiftRoleCompositionTemplate,
        subLocationTemp: subLocationTemplate,
        shiftTypeTemp: shiftTypeTemplate,
        shiftAttireTemp: shiftAttireTemplate,
        shiftDutiesTemp: shiftDutiesTemplate,
        shiftNotesTemp: shiftNotesTemplate,
      }
    case GET_VENUE_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        shiftTypeTemp: action?.response?.venueEnvironmentTemp
      }
    case CLEAR_SHIFT_DETAILS_SUCCESS:
      return {
        ...state,
        shiftNameTemp: action?.response?.shiftName,
        shiftDateTimeTemp: action?.response?.shiftDateTime,
        shiftLocationTemp: action?.response?.shiftLocation,
        shiftTimezoneTemp: action?.response?.shiftTimezone,
      }
    default:
      return { ...state };
  }
}

export default shiftReducer;
