import arrayRange from "../globalFunctions/getArrayRange"

const apiEndpoints = {
  baseUrl: process.env.REACT_APP_API_URL,
  login: '/v1/user/login',
  logout: '/v1/user/logout',
  people: '/v1/people',
  provinces: '/v1/provinces',
  eventRoles: '/v1/event/roles',
  talentFilter: '/v1/people/search',
  talentAttributes: '/v1/person/talent/attributes',
  addressAttributes: '/v1/address/types',
  emailAttributes: '/v1/email/types',
  phoneAttributes: '/v1/phone/types',
  talentsCoreAttributes: '/v1/person/talent/core/fields',
  person: '/v1/person',
  assetSecure: '/v1/asset/secure',
  asset: '/v1/asset',
  addPerson: '/person',
  personAttribute: '/v1/person/person/attribute',
  personPhone: '/v1/person/phone',
  personEmail: '/v1/person/email',
  personEventRole: '/v1/person/event/role',
  personAddress: '/v1/person/address',
  register: '/register',
  object: '/v1/object',
  phoneValidate: '/v1/phonenumber/validate',
  getLatLngFromZip: '/v1/google/maps/geocode?postal_code=',
  getLocationPredictions: '/v1/google/maps/place/autocomplete',
  getAddressFromPlaceId: '/v1/google/maps/geocode',
  includeTzName: "include_tz_name"
}

const apiParams = {
  listType: 'list_type',
  pageNumber: 'page_number',
  pageSize: 'page_size',
  countryISO: 'country_iso_alpha2',
  condensedType: 'condensed',
  personTypes: 'person_types',
  subDomain: 'subdomain',
  domain: 'domain',
  opus: 'opus',
  eventsApp: 'events.app',
  gRecaptcha: 'g-recaptcha-response',
  excludeEventShiftRole: 'excl_existing_person_for_event_shift2role_uuid'
}

const RoutingConstants = {
  root: '/',
  talents: {
    list: '/people',
    create: '/person/create',
    view: '/person/:id',
    internalView: '/person'
  },
  dashboard: '/dashboard',
  events: {
    list: '/events',
    create: '/event/create',
    view: '/event/:id',
    event: '/event'
  },
  forgotPassword: '/forgot/password',
  calendar: '/calender',
  login: '/login',
  register: '/register',
  registerThanks: '/register/thanks'
}

const apiCodes = {
  logout: 'LOGOUT-SUCCESS'
}

const Strings = {
  loginEmailPlaceholder: "Enter account email address",
  loginPasswordPlaceholder: "Enter password",
  loginEmailLabel: "Email Address",
  loginPasswordLabel: "Password",
  loginHeader: "Log In",
  forgotPassword: "Forgot password?",
  loginBtn: "Log in",
  help: "Help",
  privacy: "Privacy",
  noAccount: "Don’t have an account?",
  signUp: "Sign up here",
  loading: "Loading",
  forgotPwdHeader: "Forgot Password",
  forgotPwdPlaceholder: "Enter email you registered with",
  forgotPwdSucessTitle: "Password Reset Email Sent",
  forgotPwdSuccessContent:
    "A reset email has been sent. If you can’t find it, check your spam folder.",
  backToLogin: "Go Back to Login",
  continue: "CONTINUE",
  defaultProvince: "US",
  upload: "UPLOAD",
  cancel: "CANCEL",
  personalInformation: "Personal Information",
  contact: "Contact",
  emergencyContact: "Emergency Contact",
  experience: "Experience",
  heightInFt: "heightInFt",
  heightInInch: "heightInInch",
  height: "Height",
  socialMedia: "Social Media",
  dob: "Date of Birth",
  contactNumber: "Phone Number",
  home: "Home",
  other: "Other",
  emergencyContactName: "Contact Name",
  emergencyContactNumber: "Contact Phone Number",
  emergencyContactRelations: "Contact Relations",
  contactName: "contactName",
  relationship: "relationship",
  email: "Email",
  residentialTitle: "Residential Address",
  mailingTitle: "Mailing Address",
  mailingSubTitle: "if different than the residential",
  residential: "Residential",
  mailing: "Mailing",
  streetAddress: "Street Address",
  stateLabel: "State",
  hospitality: "Hospitality",
  experiential: "Experiential",
  security: "Security",
  skills: "Skills",
  languages: "Languages Spoken",
  languagesId: "Languages",
  firstName: "firstName",
  lastName: "lastName",
  city: "City",
  state: "State",
  stageName: "stageName",
  photoLabel: "Photo",
  loginSuccessMsg: "LOGIN SUCCESSFUL",
  notes: "Notes",
  dev: "opusdev",
  qa: "opusqa",
  prod: "opus",
  apt: "street_2"
};

const devRecaptcha = '6LeYNU0mAAAAAEy-Z3H1Jckv8V2B1tuUW0u3Zf-U';
const qaRecaptcha = '6Ldyp1AmAAAAAE8UXVBE2hKmuZ1VXr-stTRUfaa9';
const prodRecaptcha = '6LcRqVAmAAAAAPdV1i6VAY4mQmIlVURWS8YJEQzf';

const talentRegisterHeaders = {
  1: "Personal Information",
  2: "Contact",
  3: "Experience",
};

const talentFormHeaders = {
  1: "Personal Information",
  2: "Contact",
  3: "Experience",
  4: "Notes",
};

const personalInfoUncategorizedLabels = [
  'Date of Birth',
  'Gender',
  'Ethnicity',
  'Height',
  'Weight',
  'Eye Color',
  'Hair Color',
  'T-Shirt Size',
  'Shoe Size',
  'Dress Size',
  'Cup Size',
  'Inseam',
  'Chest Size',
  'Waist Size',
  'Suit Size',
  'Social Media'
]

const tempProfileImageLink = '/static/media/avatar_04.606cef8affa5c9610ebd.webp';

const skillsCategorizedLabels = [
  'hospitality_skills',
  'experiential_skills',
  'model_skills'
]

const languageLabel = 'language_skills'

const addressTypes = ['Residential', 'Mailing']
const emailTypes = ['Home']
const phoneTypes = ['Home', 'Other']

const personalInfoCoreFields = [
  {
    minAllowed: 2,
    label: 'Legal First Name',
    maxAllowed: 100,
    required: true,
    options: null,
    placeholder: 'Enter legal first name',
    id: "firstName",
    name: "first_name",
    value: ""
  },
  {
    minAllowed: 2,
    label: 'Legal Last Name',
    maxAllowed: 100,
    required: true,
    options: null,
    placeholder: 'Enter legal last name',
    id: "lastName",
    name: "last_name",
    value: ""
  },
  {
    minAllowed: 2,
    label: 'PREFERRED/STAGE NAME',
    maxAllowed: 100,
    required: false,
    options: null,
    placeholder: 'Enter preferred/stage name',
    id: "stageName",
    name: "preferred_name",
    value: ""
  }
]

const heightInFt = arrayRange(3, 8, 1);
const heightInInch = arrayRange(0, 11, 1);

const bioField = {
  minAllowed: 3,
  label: 'Bio',
  maxAllowed: 2048,
  required: false,
  options: null,
  placeholder: 'Enter Talent Bio',
  id: "bio",
  name: "bio",
  value: "",
  type: "string"
}

const formBtnText = {
  personalInfo: 'Next Step - Contact',
  contact: 'Next Step - Emergency Contact',
  emergencyContactInfo: 'Next Step - Experiences',
  finalField: 'Create Talent Profile',
  back: 'Back',
  addToEvent: 'Add To Event'
}

const emergencyContactFields = [
  {
    minAllowed: 3,
    label: 'Contact Name',
    maxAllowed: 200,
    required: false,
    options: null,
    placeholder: 'Enter contact name',
    id: "contactName",
    value: "",
    type: "string"
  },
  {
    minAllowed: 3,
    label: 'Contact Phone Number',
    maxAllowed: 25,
    required: false,
    options: null,
    placeholder: 'Enter phone number',
    id: "",
    value: "",
    type: "tel"
  },
  {
    minAllowed: 1,
    label: 'Contact Relations',
    maxAllowed: 1,
    required: false,
    options: null,
    optionSelection: "",
    placeholder: 'Select relationship',
    id: "relationship",
    value: "",
    optionsList: []
  }
]

const contactFields = [
  {
    minAllowed: 0,
    label: 'Phone Number',
    maxAllowed: 25,
    required: false,
    options: null,
    placeholder: 'Enter phone Number',
    id: "",
    value: "",
    type: "tel"
  },
  {
    minAllowed: 3,
    label: 'Email',
    maxAllowed: 255,
    required: false,
    options: null,
    placeholder: 'Enter email',
    id: "",
    value: "",
    type: "email"
  }
]

const addressFields = [
  [
    {
      minAllowed: 3,
      label: 'Street Address',
      maxAllowed: 255,
      required: false,
      options: null,
      placeholder: 'Enter street address',
      id: "street_1",
      value: "",
      type: "string"
    },
    {
      minAllowed: 1,
      label: 'UNIT/APT',
      maxAllowed: 100,
      required: false,
      options: null,
      placeholder: 'Enter UNIT/APT',
      id: "street_2",
      value: "",
      type: "string"
    }
  ],
  [
    {
      minAllowed: 1,
      label: 'Zip',
      maxAllowed: 15,
      required: false,
      options: null,
      placeholder: 'Enter zip',
      id: "postal_code",
      value: "",
      type: "string"
    },
    {
      minAllowed: 2,
      label: 'City',
      maxAllowed: 100,
      required: false,
      options: null,
      placeholder: 'Enter city',
      id: "city",
      value: "",
      type: "string"
    },
    {
      minAllowed: 3,
      label: 'State',
      maxAllowed: 128,
      required: false,
      options: true,
      optionSelection: '',
      showProvince: true,
      placeholder: 'Enter state',
      id: "state",
      value: ""
    }
  ]
]

const experienceFields = [
  {
    minAllowed: 1,
    label: 'Hospitality',
    maxAllowed: 1000,
    required: false,
    options: true,
    optionSelectionList: [],
    placeholder: 'Type to search...',
    id: "hospitality",
    value: ""
  },
  {
    minAllowed: 1,
    label: 'Experiential',
    maxAllowed: 1000,
    required: false,
    options: true,
    optionSelectionList: [],
    placeholder: 'Type to search...',
    id: "experiential",
    value: ""
  },
  {
    minAllowed: 1,
    label: 'Security',
    maxAllowed: 1000,
    required: false,
    options: true,
    optionSelectionList: [],
    placeholder: 'Type to search...',
    id: "security",
    value: ""
  }
]

const skillFields = [
  {
    minAllowed: 1,
    label: 'Skills',
    maxAllowed: 1000,
    required: false,
    options: true,
    optionSelectionList: [],
    placeholder: 'Type to search...',
    id: "skills",
    value: ""
  },
  {
    minAllowed: 1,
    label: 'Languages Spoken',
    maxAllowed: 1000,
    required: false,
    options: true,
    optionSelectionList: [],
    placeholder: 'Type to search...',
    id: "languages",
    value: ""
  }
]

const photoTemplate = {
  fileLink: '',
  uuid: '',
  primary: false
}

const FormCategories = {
  core: 'core',
  bio: 'bio',
  uncategorized: 'uncategorized',
  contact: 'contact',
  residentialAddress: 'residentialAddress',
  mailingAddress: 'mailingAddress'
}

const COUNTRY_UUID = '24154722-8a10-11ed-8643-00163e896a87'
const PHONE_UUID = '314b4db5-8c79-11ed-a67d-00163e896a87'
const EMAIL_UUID = '061e5152-8c79-11ed-a67d-00163e896a87'
const EMERGENCY_PHONE_UUID = '386a9398-8c79-11ed-a67d-00163e896a87'
const MAILING_ADDRESS_UUID = '5f9dfcf1-0af5-46fb-9cce-4e44c84f671d'
const PERSON_ATTR_UUID = '77f624ca-c05e-417b-967f-f842c28fd129'
const ADDRESS_TYPE_UUID = '3f5ba1e7-8c79-11ed-a67d-00163e896a87'

const addPhotoAttribute = {
  primary: true,
  value: "",
  asset_mimetype: "",
  asset_secured: true,
  caption: "",
  self_rating: "",
  company_rating: "",
  person_attribute_uuid: ""
}

const addProfileUncategorizedData = {
  value: "",
  option_selection: "",
  self_rating: "",
  company_rating: "",
  person_attribute_uuid: ""
}

const addSkillsData = {
  value: true,
  option_selection: "",
  self_rating: "",
  company_rating: "",
  person_attribute_uuid: ""
}

const addExperienceData = {
  event_role_uuid: "",
  self_rating: "",
  company_rating: ""
}

const addEmailData = {
  primary: true,
  email_type_uuid: "",
  email_address: ""
}

const addAddressData = {
  primary: true,
  address_type_uuid: "",
  address_co_name: "",
  street_1: "",
  street_2: "",
  street_3: "",
  city: "",
  state_province_uuid: "",
  postal_code: ""
}

const addEmergencyPhone = {
  emergency: true,
  phone_type_uuid: "",
  country_uuid: COUNTRY_UUID,
  phone_number: "",
  extension: ""
}

const addPhone = {
  primary: true,
  phone_type_uuid: "",
  country_uuid: COUNTRY_UUID,
  phone_number: "",
  extension: ""
}

const personTypeUUID = "3c823506-8adf-11ed-8643-00163e896a87"

const createTalentTemplate = {
  uuid: "",
  first_name: "",
  last_name: "",
  status: "available",
  person_type_uuid: personTypeUUID,
  person_phones: [],
  person_addresses: [],
  person_emails: [],
  person2event_roles: [],
  person2person_attribute: []
}

const experienceContent = 'To make your profile shine, include all relevant professional experiences and skills. Presenting a comprehensive range of abilities and experiences will make your profile stand out.'

export const notesRating = [
  {
    iconColor: "#FF0000",
    label: "No show",
    value: 1
  },
  {
    iconColor: "#F38B75",
    label: "Poor performance",
    value: 2
  },
  {
    iconColor: "#DEE208",
    label: "Needs improvement",
    value: 3
  },
  {
    iconColor: "#AFC622",
    label: "Well done",
    value: 4
  },
  {
    iconColor: "#22C663",
    label: "Outstanding",
    value: 5
  },
]

const starRatingArray = ['Unrated'].concat(arrayRange(1, 5, 0.5))

const starRate = {
  minAllowed: 1,
  label: '',
  maxAllowed: 1,
  required: false,
  options: starRatingArray,
  optionSelection: "",
  placeholder: '0.0',
  id: "starRating",
  value: "",
  optionsList: starRatingArray
}

const payrollField = {
  minAllowed: 1,
  label: '',
  maxAllowed: 7,
  required: false,
  options: null,
  placeholder: '',
  id: "payrollId",
  value: "",
  type: "number"
}

const imageContent = 'You must upload at least 1 image to create your talent profile; 5MB max size per image.'

export {
  RoutingConstants, apiEndpoints, Strings, apiCodes, apiParams,
  talentFormHeaders, personalInfoUncategorizedLabels,
  skillsCategorizedLabels, languageLabel, addressTypes, emailTypes,
  phoneTypes, personalInfoCoreFields, heightInFt, heightInInch,
  bioField, formBtnText, emergencyContactFields, FormCategories,
  COUNTRY_UUID, contactFields, addressFields, experienceFields,
  skillFields, photoTemplate, createTalentTemplate, addPhotoAttribute,
  addProfileUncategorizedData, addSkillsData, addExperienceData, PHONE_UUID,
  addEmailData, addAddressData, addEmergencyPhone, addPhone, personTypeUUID,
  EMAIL_UUID, EMERGENCY_PHONE_UUID, MAILING_ADDRESS_UUID, tempProfileImageLink,
  PERSON_ATTR_UUID, experienceContent, devRecaptcha, qaRecaptcha, prodRecaptcha,
  talentRegisterHeaders, starRate, payrollField, imageContent, ADDRESS_TYPE_UUID
};
