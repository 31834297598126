import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Tag,
  Button,
  Input,
  Popover,
  List,
  Select,
  Space,
} from "antd";
import _ from "lodash";
import {
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./talentList.css";
import {
  radiusList,
  ethnicityList,
  genderList,
  eyeColorList,
  hairColorList,
  lookupList,
  minHeightFeetList,
  maxHeightFeetList,
  inchesList,
  uuidMapping,
  personTypeUuids,
  getSessionValue,
  setSessionValue,
  clearSessionValue,
} from "./helper";
import { geoLocationService } from "../../../../_services/geoLocation.service";
import FilterLocation from "./filterComponents/filterLocation";
import FilterRating from "./filterComponents/filterRating";
import FilterAge from "./filterComponents/filterAge";
import FilterWeight from "./filterComponents/filterWeight";
import FilterSelectBox from "./filterComponents/filterSelectBox";
import FilterMultiSelectBox from "./filterComponents/filterMultiSelectBox";

const { Search } = Input;

const SearchComponent = (props) => {
  const { eventRoles, skillCategorized, languageCategorized, isAddPersonToRole=false } = props;

  const talentFilter = getSessionValue("talentFilter") || {};
  const talentFilterTags = getSessionValue("talentFilterTags") || [];

  const [skillsList, setSkillsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [zipValue, setZipValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [rating, setRating] = useState(talentFilter?.company_rating || { start_value: 0, end_value: 0, include_unrated: false });
  const [ageValues, setAgeValues] = useState({ start_value: 0, end_value: 0 });
  const [weightValues, setWeightValues] = useState({
    start_value: 0,
    end_value: 0,
  });
  const [heightValues, setHeightValues] = useState({
    start_value: 0,
    end_value: 0,
  });
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedGenderNames, setSelectedGenderNames] = useState([])
  const [ethnicity, setEthnicity] = useState([]);
  const [selectedEthnicityNames, setSelectedEthnicityNames] = useState([]);
  const [selectedEyeColor, setSelectedEyeColor] = useState([]);
  const [selectedEyeColorNames, setSelectedEyeColorNames] = useState([]);
  const [selectedHairColor, setSelectedHairColor] = useState([]);
  const [selectedHairColorNames, setSelectedHairColorNames] = useState([]);
  const [minFeet, setMinFeet] = useState("");
  const [minInches, setMinInches] = useState("");
  const [maxFeet, setMaxFeet] = useState("");
  const [maxInches, setMaxInches] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRoleNames, setSelectedRoleNames] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSkillNames, setSelectedSkillNames] = useState([]);
  const [radiusValue, setRadiusValue] = useState("");
  const [locationType, setLocationType] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [personAttributes, setPersonAttributes] = useState([]);
  const [isClearAll, setIsClearAll] = useState(props.isClearAll);
  const [isUnrated, setIsUnrated] = useState(false);

  useEffect(() => {
    if(talentFilter) {
      if(talentFilter.search_field) {
        setSearchText(talentFilter.search_field);
      }
      if(talentFilter.location) {
        if(talentFilter.location.type === "state") {
          setSelectedState(talentFilter.location.value);
          setLocationType("state");
          if(props?.talentData?.provinces) {
            props.talentData.provinces.map(val => {
              if(val.uuid == talentFilter.location.value) {
                setSelectedStateName(val.name);
              }
            })
          }
        }
        else if(talentFilter.location.type === "city_state") {
          const [city, state] = talentFilter.location.value.split("::");
          setSelectedState(state);
          setCityValue(city);
          setLocationType("city_state");
          if(props?.talentData?.provinces) {
            props.talentData.provinces.map(val => {
              if(val.uuid == state) {
                setSelectedStateName(val.name);
              }
            })
          }
        }
        else if(talentFilter.location.type === "radius") {
          setLocationType("radius");
          setZipValue(getSessionValue("zipCode") || "");
          setRadiusValue(talentFilter.location.value || "");
        }
      }
      if(talentFilter.company_rating) {
        setIsUnrated(talentFilter.company_rating.include_unrated);
      }
      if(talentFilter.person_attributes) {
        talentFilter.person_attributes.forEach(person_attribute => {
          switch (person_attribute.person_attribute_uuid) {
            case uuidMapping.age:
              setAgeValues({ start_value: person_attribute.start_value, end_value: person_attribute.end_value });
              break;
            case uuidMapping.gender:
              setSelectedGender(person_attribute.list);
              break;
            case uuidMapping.ethnicity:
              setEthnicity(person_attribute.list);
              break;
            case uuidMapping.height:
              let minHeight = person_attribute.start_value;
              let maxHeight = person_attribute.end_value;
              setMinFeet(Math.floor(minHeight/12));
              setMinInches(minHeight%12);
              setMaxFeet(Math.floor(maxHeight/12));
              setMaxInches(maxHeight%12);
              break;
            case uuidMapping.weight:
              setWeightValues({ start_value: person_attribute.start_value, end_value: person_attribute.end_value });
              break;
            case uuidMapping.eyeColor:
              setSelectedEyeColor(person_attribute.list);
              break;
            case uuidMapping.hairColor:
              setSelectedHairColor(person_attribute.list);
              break;
            default:
              break;
          }
        })
      }
      if(talentFilter.event_roles && talentFilter.event_roles.list) {
        setSelectedRoles(talentFilter.event_roles.list);
      }
      if(talentFilter.skills && talentFilter.skills.list) {
        setSelectedSkills(talentFilter.skills.list);
      }
    }
  }, []);

  useEffect(() => {
    const rolesList = [
      {
        label: "Experiental",
        options:
          eventRoles?.experiential?.event_roles.map(
            (val) => {
              return { label: val.name, value: val.uuid };
            }
          ),
      },
      {
        label: "Hospitality",
        options:
          eventRoles?.hospitality?.event_roles.map(
            (val) => {
              return { label: val.name, value: val.uuid };
            }
          ),
      },
      {
        label: "Security",
        options: eventRoles?.security?.event_roles.map(
          (val) => {
            return { label: val.name, value: val.uuid };
          }
        ),
      },
    ];
    setRolesList(rolesList);
  }, [eventRoles])

  useEffect(() => {
    const skillsList = [
      {
        label: "Skills",
        options: skillCategorized.map((val, i) => {
          return { label: Object.keys(val)[0], value: Object.values(val)[0] };
        }),
      },
      {
        label: "Languages Spoken",
        options: languageCategorized.map((val, i) => {
          return { label: Object.keys(val)[0], value: Object.values(val)[0] };
        }),
      },
    ];
    setSkillsList(skillsList)
  }, [skillCategorized, languageCategorized])

  const extractNameFromSelectedOption = (options, values, callback) => {
    let namesList = [];
    _.filter(options, (o) => {
      if(_.indexOf(values, o.value) > -1) {
        namesList.push(o.label);
      }
    });
    callback(namesList);
  }


  const onMultiSelectChange = (type, values) => {
    if (type === "gender") {
      setSelectedGender([...values]);
      extractNameFromSelectedOption(genderList[0].options, values, (namesList) => setSelectedGenderNames(namesList));
    } else if (type === "ethnicity") {
      setEthnicity([...values]);
      extractNameFromSelectedOption(ethnicityList[0].options, values, (namesList) => setSelectedEthnicityNames(namesList));
    } else if (type === "eye_color") {
      setSelectedEyeColor([...values]);
      extractNameFromSelectedOption(eyeColorList[0].options, values, (namesList) => setSelectedEyeColorNames(namesList));
    } else if (type === "hair_color") {
      setSelectedHairColor([...values]);
      extractNameFromSelectedOption(hairColorList[0].options, values, (namesList) => setSelectedHairColorNames(namesList));
    }
  };  

  const onRoleChange = (value) => {
    const getList = [
      ...rolesList[0].options,
      ...rolesList[1].options,
      ...rolesList[2].options,
    ];
    let namesList = [];
    _.filter(getList, function (o, i) {
      if (_.indexOf(value, o.value) > -1) {
        namesList.push(o.label);
      }
    });
    setSelectedRoleNames(namesList);
    setSelectedRoles(value);
  };
  const onSkillChange = (value) => {
    const getList = [...skillsList[0].options, ...skillsList[1].options];
    let namesList = [];
    _.filter(getList, function (o, i) {
      if (_.indexOf(value, o.value) > -1) {
        namesList.push(o.label);
      }
    });
    setSelectedSkillNames(namesList);
    setSelectedSkills(value);
  };
  const onMinValueChange = (value) => {
    setRating({ ...rating, start_value: value });

  };
  const onMaxValueChange = (value) => {
    setRating({ ...rating, end_value: value });
  };
  const onZipChange = async (value) => {
    if(value && value.length === 5) {
      await geoLocationService.getCoordinatesFromZipCode(value)
      .then(function (value) {
        setSessionValue("Lat", value.results[0]?.geometry?.location.lat);
        setSessionValue("Long",value.results[0]?.geometry?.location.lng);
      });
    }
    setZipValue(value);
    setLocationType("radius");
    setCityValue("");
    setSelectedState("");
    setSelectedStateName("");
  };
  const onRadiusChange = async (e) => {
    setRadiusValue(e.target.value);
    setLocationType("radius");
    setCityValue("");
    setSelectedState("");
    setSelectedStateName("");
  };
  const onStateChange = (value, label) => {
    setSelectedState(value);
    setSelectedStateName(label.children);
    if(value.length && !cityValue){
      setLocationType("state");
    }
    else if(cityValue) {
      setLocationType("city_state");
    }
    else {
      setLocationType("");
    }
    setZipValue("");
    setRadiusValue("");
    clearSessionValue("Lat");
    clearSessionValue("Long");
    clearSessionValue("zipCode");
  };
  const onCityChange = (e) => {
    setCityValue(e.target.value);
    if(e.target.value.length) {
      setLocationType("city_state");
    }
    else if(selectedState) {
      setLocationType("state");
    }
    setRadiusValue("");
    setZipValue("");
    clearSessionValue("Lat");
    clearSessionValue("Long");
    clearSessionValue("zipCode");
  };
  const addToTag = (lookupId, value, removeTags=[]) => {
    const tagsInSession = talentFilterTags;
    if(tagsInSession && removeTags && removeTags.length) {
      removeTags.forEach(tag => {
        const index = _.findIndex(tagsInSession, {
          uuid: tag,
        });
        if(index >= 0) tagsInSession.splice(index, 1)
      })
    }
    const getTag = _.filter(lookupList, {
      uuid: lookupId,
    });
    const tagData = {
      uuid: getTag[0].uuid,
      data: getTag[0].tag(value),
    };
    const tagIndex = _.findIndex(tagsInSession, {
      uuid: tagData.uuid,
    });
    if (tagIndex < 0) {
      tagsInSession.push(tagData);
    } else {
      tagsInSession[tagIndex].data = tagData.data;
    }
    setSessionValue("talentFilterTags", tagsInSession);
  };

  const onAddFilter = (value) => {
    let postObj = {
      person_type_uuids: [personTypeUuids]
    };

    let person_attribute_arr = [];
    if(talentFilter && talentFilter.person_attributes && talentFilter.person_attributes.length) {
      person_attribute_arr = talentFilter.person_attributes;
    }

    if (talentFilter && talentFilter.hasOwnProperty('person_type_uuids')) {
      postObj = talentFilter
      if (talentFilter.hasOwnProperty('person_attributes')) {
        person_attribute_arr = talentFilter['person_attributes']
      }
    }

    // search field
    if (searchText) {
      postObj['search_field'] = searchText;
    }
    // ethnicity
    if (ethnicity && value == "ethnicity") {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.ethnicity,
      });
      if (index < 0) {
        person_attribute_arr.push({
          person_attribute_uuid: uuidMapping.ethnicity,
          contains: false,
          start_value: null,
          end_value: null,
          has_all_in_list: false,
          list: ethnicity,
        });
      } else {
        person_attribute_arr[index].list = ethnicity;
      }
      addToTag(uuidMapping.ethnicity, selectedEthnicityNames);
    }
    // location- zip code and radius or state or city
    if (locationType && value == "location") {
      const locationObj = {
        type: locationType,
        value:
          locationType == "radius"
            ? radiusValue
            : locationType == "state" ? selectedState : `${cityValue}::${selectedState}`,
      };
      postObj['location'] = locationType == "radius"
      ? {
          ...locationObj,
          lat: parseFloat(getSessionValue("Lat")),
          long: parseFloat(getSessionValue("Long")),
        }
      : locationObj
      if (locationType == "radius") {
        addToTag("radius", {
          zipValue: zipValue,
          radiusValue: postObj.location.value,
        }, ["city", "state"]);
        setSessionValue("zipCode", zipValue);
      } else if(locationType == "state") {
        addToTag("state", {
          stateValue: selectedStateName,
        }, ["city", "radius"]);
      } else {
        addToTag("city", {
          stateValue: selectedStateName,
          cityValue: cityValue,
        }, ["state", "radius"]);
      }
    }
    // rating or rated or unrated
    if (value == "rating" && ((rating.end_value >= 0 && isUnrated) || (rating.end_value > 0 && !isUnrated))) {
      setRating({ ...rating, include_unrated: isUnrated });
      postObj['company_rating'] = { ...rating, include_unrated: isUnrated }
      addToTag("rating", { ...rating, include_unrated: isUnrated });
    }
    // age
    if (ageValues.end_value > 0 && value == "age") {
      const ageIndex = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.age,
      });
      if (ageIndex < 0) {
        person_attribute_arr.push({
          start_value: parseInt(ageValues.start_value),
          end_value: parseInt(ageValues.end_value),
          person_attribute_uuid: uuidMapping.age,
          contains: false,
          has_all_in_list: false,
          list: null,
        });
      } else {
        person_attribute_arr[ageIndex].start_value = parseInt(
          ageValues.start_value
        );
        person_attribute_arr[ageIndex].end_value = parseInt(
          ageValues.end_value
        );
      }
      addToTag(uuidMapping.age, ageValues);
    }

    // weight
    if (weightValues.end_value > 0 && value == "weight") {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.weight,
      });
      if (index < 0) {
        person_attribute_arr.push({
          start_value: parseInt(weightValues.start_value),
          end_value: parseInt(weightValues.end_value),
          person_attribute_uuid: uuidMapping.weight,
          contains: false,
          has_all_in_list: false,
          list: null,
        });
      } else {
        person_attribute_arr[index].start_value = parseInt(
          weightValues.start_value
        );
        person_attribute_arr[index].end_value = parseInt(
          weightValues.end_value
        );
      }
      addToTag(uuidMapping.weight, weightValues);
    }
    // gender
    if (selectedGender && value == "gender") {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.gender,
      });
      if (index < 0) {
        person_attribute_arr.push({
          person_attribute_uuid: uuidMapping.gender,
          contains: false,
          start_value: null,
          end_value: null,
          has_all_in_list: false,
          list: selectedGender,
        });
      } else {
        person_attribute_arr[index].list = selectedGender;
      }

      addToTag(uuidMapping.gender, selectedGenderNames);
    }
    // eye_color
    if (selectedEyeColor && value == "eye_color") {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.eyeColor,
      });
      if (index < 0) {
        person_attribute_arr.push({
          person_attribute_uuid: uuidMapping.eyeColor,
          contains: false,
          start_value: null,
          end_value: null,
          has_all_in_list: false,
          list: selectedEyeColor,
        });
      } else {
        person_attribute_arr[index].list = selectedEyeColor;
      }

      addToTag(uuidMapping.eyeColor, selectedEyeColorNames);
    }
    // hair_color
    if (selectedHairColor && value == "hair_color") {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.hairColor,
      });
      if (index < 0) {
        person_attribute_arr.push({
          person_attribute_uuid: uuidMapping.hairColor,
          contains: false,
          start_value: null,
          end_value: null,
          has_all_in_list: false,
          list: selectedHairColor,
        });
      } else {
        person_attribute_arr[index].list = selectedHairColor;
      }

      addToTag(uuidMapping.hairColor, selectedHairColorNames);
    }
    // roles
    if (selectedRoles.length > 0 && value == "roles") {
      postObj['event_roles'] = { has_all_in_list: true, list: selectedRoles }
      addToTag("roles", selectedRoleNames);
    }
    // height
    if (value == "height" && (parseInt(maxFeet) * 12 + parseInt(maxInches) > parseInt(minFeet) * 12 + parseInt(minInches))) {
      const index = _.findIndex(person_attribute_arr, {
        person_attribute_uuid: uuidMapping.height,
      });
      const calcValues = {
        start_value: parseInt(minFeet) * 12 + parseInt(minInches),
        end_value: parseInt(maxFeet) * 12 + parseInt(maxInches),
      };
      setHeightValues(calcValues);
      if (index < 0) {
        person_attribute_arr.push({
          start_value: calcValues.start_value,
          end_value: calcValues.end_value,
          person_attribute_uuid: uuidMapping.height,
          contains: false,
          has_all_in_list: false,
          list: null,
        });
      } else {
        person_attribute_arr[index].start_value = parseInt(
          calcValues.start_value
        );
        person_attribute_arr[index].end_value = parseInt(calcValues.end_value);
      }

      const tagValues = {
        start_value: `${parseInt(minFeet)}'${parseInt(minInches)}"`,
        end_value: `${parseInt(maxFeet)}'${parseInt(maxInches)}"`,
      };
      addToTag(uuidMapping.height, tagValues);
    }

    // skills
    if (selectedSkills.length > 0 && value == "skills") {
      postObj['skills'] = { has_all_in_list: true, list: selectedSkills }
      addToTag("skills", selectedSkillNames);
    }
    // Adding person attributes to post object
    if (person_attribute_arr && Boolean(person_attribute_arr.length)) {
      postObj['person_attributes'] = person_attribute_arr;
    }

    setSessionValue("talentFilter", postObj);
    props.onFilter(postObj);
  };

  const data = [
    {
      title: "Location(US Only)",
      description: () => (
        <>
          <FilterLocation
            zipValue={zipValue}
            onZipChange={onZipChange}
            onRadiusChange={onRadiusChange}
            radiusValue={radiusValue}
            radiusList={radiusList}
            onStateChange={onStateChange}
            talentData={props?.talentData}
            selectedState={selectedState}
            cityValue={cityValue}
            onCityChange={onCityChange}
            onAddFilter={onAddFilter}
          />
        </>
      ),
    },
    {
      title: "Rating",
      description: () => (
        <FilterRating
          rating={rating}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
          onAddFilter={onAddFilter}
          isUnrated={isUnrated}
          onUnratedChange={(value) => { 
            setIsUnrated(value); 
          }
        }
        />
      ),
    },
    {
      title: "Age",
      description: () => (
        <FilterAge
          ageValues={ageValues}
          onMinValueChange={(value) =>
            setAgeValues({ ...ageValues, start_value: value })
          }
          onMaxValueChange={(value) =>
            setAgeValues({ ...ageValues, end_value: value })
          }
          onAddFilter={onAddFilter}
        />
      ),
    },
    {
      title: "Gender",
      description: () => (
        <FilterMultiSelectBox
          onChange={(value) => {
            setIsClearAll(false);
            onMultiSelectChange("gender", value);
          }}
          title="GENDER (Select one or several)"
          label="Select gender or search"
          list={genderList}
          selectedValue={selectedGender}
          onAddFilter={onAddFilter}
          type="gender"
        />
      ),
    },
    {
      title: "Ethnicity",
      description: () => {
        return (
          <FilterMultiSelectBox
            onChange={(value) => {
              setIsClearAll(false);
              onMultiSelectChange("ethnicity", value);
            }}
            title="ETHNICITY (Select one or several)"
            label="Select ethnicity or search"
            list={ethnicityList}
            selectedValue={ethnicity}
            onAddFilter={onAddFilter}
            type="ethnicity"
          />
        );
      },
    },
    {
      title: "Height",
      description: () => (
        <div className="p-[12px]">
          <div>Select height range</div>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select mb-2">
            <div>MIN</div>
            <Select
              placeholder="Feet"
              style={{
                width: 120,
              }}
              value={minFeet}
              options={minHeightFeetList}
              onChange={(value) => setMinFeet(value)}
            />
            <Select
              placeholder="Inches"
              style={{
                width: 120,
              }}
              value={minInches}
              options={inchesList}
              onChange={(value) => setMinInches(value)}
            />
          </div>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select">
            <div>MAX</div>
            <Select
              placeholder="Feet"
              style={{
                width: 120,
              }}
              value={maxFeet}
              options={maxHeightFeetList}
              onChange={(value) => setMaxFeet(value)}
            />
            <Select
              placeholder="Inches"
              style={{
                width: 120,
              }}
              value={maxInches}
              options={inchesList}
              onChange={(value) => setMaxInches(value)}
            />
          </div>
          <div className="text-right">
            <Button
              disabled={
                parseInt(maxFeet) * 12 + parseInt(maxInches) <=
                  parseInt(minFeet) * 12 + parseInt(minInches) ||
                !minFeet ||
                !maxFeet ||
                !minInches ||
                !maxInches
              }
              className="mt-[10px]"
              onClick={() => onAddFilter("height")}
              icon={<PlusOutlined className="align-baseline" />}
            >
              Add Filter
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Weight",
      description: () => (
        <FilterWeight
          weightValues={weightValues}
          onMinValueChange={(value) =>
            setWeightValues({ ...weightValues, start_value: value })
          }
          onMaxValueChange={(value) =>
            setWeightValues({ ...weightValues, end_value: value })
          }
          onAddFilter={onAddFilter}
        />
      ),
    },
    {
      title: "Eye color",
      description: () => (
        <FilterMultiSelectBox
          onChange={(value) => {
            setIsClearAll(false);
            onMultiSelectChange("eye_color", value);
          }}
          title="EYE COLOR (Select one or several)"
          label="Select eye color or search"
          list={eyeColorList}
          selectedValue={selectedEyeColor}
          onAddFilter={onAddFilter}
          type="eye_color"
        />
      ),
    },
    {
      title: "Hair color",
      description: () => (
        <FilterMultiSelectBox
          onChange={(value) => {
            setIsClearAll(false);
            onMultiSelectChange("hair_color", value);
          }}
          title="HAIR COLOR (Select one or several)"
          label="Select hair color or search"
          list={hairColorList}
          selectedValue={selectedHairColor}
          onAddFilter={onAddFilter}
          type="hair_color"
        />
      ),
    },
    {
      title: "Role(s)",
      description: () => (
        <FilterMultiSelectBox
          onChange={(value) => {
            setIsClearAll(false);
            onRoleChange(value);
          }}
          title="ROLE (Select one or several)"
          label="Select role or search"
          list={rolesList}
          selectedValue={selectedRoles}
          onAddFilter={onAddFilter}
          type="roles"
        />
      ),
    },
    {
      title: "Skill(s)",
      description: () => (
        <FilterMultiSelectBox
          onChange={(value) => {
            setIsClearAll(false);
            onSkillChange(value);
          }}
          title="SKILLS (Select one or several)"
          label="Select skill or search"
          list={skillsList}
          selectedValue={selectedSkills}
          onAddFilter={onAddFilter}
          type="skills"
        />
      ),
    },
  ];
  const content = (
    <div>
      <List
        className="w-full"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item style={{}}>
            <Popover
              placement="rightTop"
              content={item.description}
              arrow={false}
              trigger="click"
            >
              <List.Item.Meta title={<span>{item.title}</span>} />
              <div className="float-right">
                <CaretRightOutlined className="align-baseline text-[#D9D9D9] text-[11px]" />
              </div>
            </Popover>
          </List.Item>
        )}
      />
    </div>
  );

  const onSearch = (value) => {
    setSearchText(value);
    let storeFilterData = talentFilter;
    let postObj = {};
    if(value) {
      if(Object.keys(storeFilterData).length > 0) {
        postObj = {
          ...storeFilterData,
          search_field: value,
        }
      } else {
        postObj = {
          person_type_uuids: [personTypeUuids],
          search_field: value,
        };
      }
    }
    else {
      delete storeFilterData['search_field']
      postObj = {
        ...storeFilterData,
      }
    }
    if(Object.keys(postObj).length === 1 && postObj.hasOwnProperty("person_type_uuids")) { // check if the postobj has person_type_uuids only then clear filter
      setSessionValue("talentFilter", {});
    }
    else {
      setSessionValue("talentFilter", postObj);
    }
    props.onFilter(postObj);
  };

  const onTagClose = (value) => {
    let postObj = {
      person_type_uuids: [personTypeUuids]
    };

    let person_attribute_arr = [];
    if(talentFilter && talentFilter.person_attributes && talentFilter.person_attributes.length) {
      person_attribute_arr = talentFilter.person_attributes;
    }

    if (talentFilter && talentFilter.hasOwnProperty('person_type_uuids')) {
      postObj = talentFilter
      if (talentFilter.hasOwnProperty('person_attributes')) {
        person_attribute_arr = talentFilter['person_attributes']
      }
    }

    let tags = talentFilterTags;
    const index = _.findIndex(tags, {
      uuid: value.uuid,
    });
    tags.splice(index, 1)
    setSessionValue("talentFilterTags", tags);

    const postIndex = _.findIndex(person_attribute_arr, {
      person_attribute_uuid: value.uuid,
    });
    const attributesCleanedUp = _.pullAt(person_attribute_arr, [postIndex]);

    if (person_attribute_arr && !Boolean(person_attribute_arr.length)) {
      delete postObj['person_attributes']
    }

    if(value.uuid == uuidMapping.ethnicity) {
      setEthnicity([]);
      setSelectedEthnicityNames([]);
    }
    if(value.uuid == uuidMapping.gender) {
      setSelectedGender([]);
      setSelectedGenderNames([]);
    }
    if (value.uuid == "roles") {
      setSelectedRoles([]);
      setSelectedRoleNames([]);
    }
    if (value.uuid == "skills") {
      setSelectedSkills([]);
      setSelectedSkillNames([]);
    }
    if (value.uuid == uuidMapping.age) {
      setAgeValues({
        start_value: 0,
        end_value: 0,
      });
    }
    if (value.uuid == "rating") {
      setRating({
        start_value: 0,
        end_value: 0,
        include_unrated: false
      });
      setIsUnrated(false);
      delete postObj['company_rating'];
    }
    if (value.uuid == uuidMapping.eyeColor) {
      setSelectedEyeColor([]);
      setSelectedEyeColorNames([]);
    }
    if (locationType && (value.uuid == "city" || value.uuid == "state" || value.uuid == "radius")) {
      const locationObj = {
        type: locationType,
        value:
          locationType == "radius"
            ? radiusValue
            : locationType == "state" ? selectedState : `${cityValue}::${selectedState}`,
      };
      if (value.uuid == "city" || value.uuid == "state") {
        if (locationType == "radius") {
          postObj['location'] = {
            ...locationObj,
            lat: parseFloat(getSessionValue("Lat")),
            long: parseFloat(getSessionValue("Long")),
          }
        } else {
          if (talentFilter.hasOwnProperty('location')) {
            delete postObj['location']
          }
          setLocationType("");
          setCityValue("");
          setSelectedState("");
          setSelectedStateName("");
        }
      } else if (value.uuid == "radius") {
        if (cityValue || selectedState) {
          postObj['location'] = locationObj
        } else {
          if (talentFilter.hasOwnProperty('location')) {
            delete postObj['location']
          }
        }
      } else {
        postObj['location'] = locationType == "radius"
        ? {
            ...locationObj,
            lat: parseFloat(getSessionValue("Lat")),
            long: parseFloat(getSessionValue("Long")),
          }
        : locationObj
      }
      setLocationType("");
      setCityValue("");
      setSelectedState("");
      setSelectedStateName("");
      setZipValue("");
      setRadiusValue("");
      clearSessionValue("Lat");
      clearSessionValue("Long");
      clearSessionValue("zipCode");
    }
    if (value.uuid == uuidMapping.height) {
      setMinFeet("");
      setMinInches("");
      setMaxFeet("");
      setMaxInches("");
    }
    if (value.uuid == uuidMapping.weight) {
      setWeightValues({
        start_value: 0,
        end_value: 0
      });
    }
    if(value.uuid == uuidMapping.hairColor) {
      setSelectedHairColor([]);
      setSelectedHairColorNames([]);
    }
    if (person_attribute_arr && Boolean(person_attribute_arr.length)) {
      postObj['person_attributes'] = person_attribute_arr;
    }
    if (searchText) {
      postObj['search_field'] = searchText;
    }
    if ((selectedRoles && Boolean(selectedRoles.length)) || value.uuid == "roles") {
      postObj['event_roles'] = { has_all_in_list: true, list: value.uuid == "roles" ? [] : selectedRoles }
    }
    if ((selectedSkills && Boolean(selectedSkills.length)) || value.uuid == "skills") {
      postObj['skills'] = { has_all_in_list: true, list: value.uuid == "skills" ? [] : selectedSkills }
    }
    if(Object.keys(postObj).length === 1 && postObj.hasOwnProperty("person_type_uuids")) { // check if the postobj has person_type_uuids only then clear filter
      setSessionValue("talentFilter", {});
    }
    else {
      setSessionValue("talentFilter", postObj);
    }
    props.onFilter(postObj);
  };


  useEffect(() => {
    if (props.isClearAll) {
      setZipValue("");
      setCityValue("");
      setRating({ start_value: 0, end_value: 0,include_unrated: false });
      setAgeValues({
        start_value: 0,
        end_value: 0,
      });
      setWeightValues({
        start_value: 0,
        end_value: 0,
      });
      setSelectedState("");
      setSelectedStateName("");
      setSelectedGender([]);
      setSelectedGenderNames([]);
      setSelectedEyeColor([]);
      setSelectedEyeColorNames([]);
      setSelectedHairColor();
      setSelectedHairColorNames([]);
      setEthnicity([]);
      setSelectedEthnicityNames([]);
      setRadiusValue("");
      setLocationType("");
      setMinFeet("");
      setMinInches("");
      setMaxFeet("");
      setMaxInches("");
      setPersonAttributes([]);
      setSelectedRoles([]);
      setSelectedRoleNames([]);
      setSelectedSkills([]);
      setSelectedSkillNames([]);
      setSearchText("");
      setIsUnrated(false);
      clearSessionValue("Lat");
      clearSessionValue("Long");
      clearSessionValue("zipCode");
    }
  }, [props.isClearAll]);

  return (
    <>
      <div className={`${isAddPersonToRole ? "pt-[10px]" : " pt-[23px]"} w-[97%] mx-auto search-section`}>
        <Search
          placeholder="Search by name, email, social handle, or phone number..."
          allowClear
          suffix={false}
          defaultValue={talentFilter.search_field}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onSearch={onSearch}
          style={{
            width: 490,
          }}
          prefix={<SearchOutlined />}
        />
        {!isAddPersonToRole && (
        <Popover
          placement="bottomLeft"
          content={content}
          arrow={false}
          trigger="click"
        >
          <Button
            className="font-inter font-medium text-sm text-fontGray"
            onClick={() => setIsClearAll(false)}
            icon={<PlusOutlined className="text-sm align-text-bottom" />}
          >
            Add Filter
          </Button>
        </Popover>
        )}
        <div>
          <Space className="flex flex-wrap">
            {!isClearAll && talentFilterTags && Boolean(talentFilterTags.length) &&
              talentFilterTags.map((tag, i) => {
                return (
                  <Tag
                    bordered={false}
                    key={i}
                    closable
                    onClose={(e) => { e.preventDefault(); onTagClose(tag)}}
                  >
                    {tag.data}
                  </Tag>
              )})}
          </Space>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    tokenExpires: state.auth.loginDetails?.token_expires || "",
    talentData: state.talent || [],
    eventRoles: state.talent.eventRoles,
    skillCategorized: state.talent.skillCategorized,
    languageCategorized: state.talent.languageCategorized,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
