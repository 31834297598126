import actionTypes from "../types";

const {
  GET_TIMEZONES, GET_VENUE_ENVIRONMENT, GET_VENUE_ENVIRONMENT_SUCCESS, GET_TIMEZONES_SUCCESS, UPDATE_SHIFT_NAME_TEMPLATE, UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_DETAILS, UPDATE_SHIFT_DETAILS_SUCCESS, UPDATE_SHIFT_TIMEZONE_TEMPLATE, UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_DATE_TIME_TEMPLATE, UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS, UPDATE_SHIFT_LOCATION_TEMPLATE, UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS,
  CREATE_SHIFT, CREATE_SHIFT_SUCCESS, GET_EVENT_SHIFT_DETAILS, GET_EVENT_SHIFT_DETAILS_SUCCESS,
  GET_EVENT_SHIFTS,
  GET_EVENT_SHIFTS_SUCCESS,
  CLONE_ROLE,
  DELETE_ROLE,
  DELETE_SHIFT,
  UPDATE_SHIFT_ROLE_TEMPLATE, UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS, UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE, UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS,
  UPDATE_MIN_RATING_TEMPLATE, UPDATE_MIN_RATING_TEMPLATE_SUCCESS, UPDATE_RATE_TEMPLATE, UPDATE_RATE_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_SKILLS_TEMPLATE, UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS, UPDATE_ROLE_COMPOSITION_TEMPLATE, UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS,
  UPDATE_SUB_LOCATION_TEMPLATE, UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS, UPDATE_SHIFT_TYPE_TEMPLATE, UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_NOTES_TEMPLATE, UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS, UPDATE_SHIFT_ATTIRE_TEMPLATE, UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS,
  UPDATE_SHIFT_DUTIES_TEMPLATE, UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS, RESET_ROLE_MODAL_TEMPLATE, CLEAR_SHIFT_DETAILS, CLEAR_SHIFT_DETAILS_SUCCESS,
  CREATE_SHIFT_ROLE, UPDATE_SHIFT_ROLE, UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT,
  ADD_PERSON_TO_ROLE, UPDATE_PERSON_TO_ROLE, DELETE_PERSON_FROM_ROLE
} = actionTypes.shifts;

export const getTimezones = (payload) => {
  return {
    type: GET_TIMEZONES,
    payload,
  };
};

export const getTimezonesSuccess = (response) => {
  return {
    type: GET_TIMEZONES_SUCCESS,
    response,
  };
};

export const getVenueEnvironment = (payload) => {
  return {
    type: GET_VENUE_ENVIRONMENT,
    payload
  }
}

export const getVenueEnvironmentSuccess = (response) => {
  return {
    type: GET_VENUE_ENVIRONMENT_SUCCESS,
    response
  }
}

export const updateShiftNameTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_NAME_TEMPLATE,
    payload,
  };
};

export const updateShiftNameTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS,
    response,
  };
};

export const getEventShifts = (payload) => {
  return {
    type: GET_EVENT_SHIFTS,
    payload,
  }
}

export const getEventShiftsSuccess = (response) => {
  return {
    type: GET_EVENT_SHIFTS_SUCCESS,
    response,
  };
};

export const updateShiftTimezoneTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_TIMEZONE_TEMPLATE,
    payload,
  };
};

export const updateShiftTimezoneTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftDateTimeTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_DATE_TIME_TEMPLATE,
    payload,
  };
};

export const updateShiftDateTimeTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftDetails = (payload) => {
  return {
    type: UPDATE_SHIFT_DETAILS,
    payload,
  };
};

export const updateShiftDetailsSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_DETAILS_SUCCESS,
    response,
  };
};

export const updateShiftLocationTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_LOCATION_TEMPLATE,
    payload,
  };
};

export const updateShiftLocationTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS,
    response,
  };
};

export const createShift = (payload) => {
  return {
    type: CREATE_SHIFT,
    payload,
  };
};

export const createShiftSuccess = (response) => {
  return {
    type: CREATE_SHIFT_SUCCESS,
    response,
  };
};

export const getEventShiftDetails = (payload) => {
  return {
    type: GET_EVENT_SHIFT_DETAILS, 
    payload,
  };
};

export const getEventShiftDetailsSuccess = (response) => {
  return {
    type: GET_EVENT_SHIFT_DETAILS_SUCCESS,
    response,
  };
};
export const cloneRole = (payload) => {
  return {
    type: CLONE_ROLE,
    payload,
  }
}

export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    payload,
  }
}

export const deleteShift = (payload) => {
  return {
    type: DELETE_SHIFT,
    payload,
  }
}

export const updateShiftRoleTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_ROLE_TEMPLATE,
    payload,
  };
};

export const updateShiftRoleTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftNoOfStaffTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE,
    payload,
  };
};

export const updateShiftNoOfStaffTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateMinRatingTemplate = (payload) => {
  return {
    type: UPDATE_MIN_RATING_TEMPLATE,
    payload,
  };
};

export const updateMinRatingTemplateSuccess = (response) => {
  return {
    type: UPDATE_MIN_RATING_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateRateTemplate = (payload) => {
  return {
    type: UPDATE_RATE_TEMPLATE,
    payload,
  };
};

export const updateRateTemplateSuccess = (response) => {
  return {
    type: UPDATE_RATE_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftSkillsTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_SKILLS_TEMPLATE,
    payload,
  };
};

export const updateShiftSkillsTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateRoleCompositionTemplate = (payload) => {
  return {
    type: UPDATE_ROLE_COMPOSITION_TEMPLATE,
    payload,
  };
};

export const updateRoleCompositionTemplateSuccess = (response) => {
  return {
    type: UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateSubLocationTemplate = (payload) => {
  return {
    type: UPDATE_SUB_LOCATION_TEMPLATE,
    payload,
  };
};

export const updateSubLocationTemplateSuccess = (response) => {
  return {
    type: UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftTypeTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_TYPE_TEMPLATE,
    payload,
  };
};

export const updateShiftTypeTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftNotesTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_NOTES_TEMPLATE,
    payload,
  };
};

export const updateShiftNotesTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftAttireTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_ATTIRE_TEMPLATE,
    payload,
  };
};

export const updateShiftAttireTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateShiftDutiesTemplate = (payload) => {
  return {
    type: UPDATE_SHIFT_DUTIES_TEMPLATE,
    payload,
  };
};

export const updateShiftDutiesTemplateSuccess = (response) => {
  return {
    type: UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS,
    response,
  };
};

export const resetRoleModalTemp = () => {
  return {
    type: RESET_ROLE_MODAL_TEMPLATE
  }
};

export const clearShiftDetails = (payload) => {
  return {
    type: CLEAR_SHIFT_DETAILS,
    payload,
  };
};

export const clearShiftDetailsSuccess = (response) => {
  return {
    type: CLEAR_SHIFT_DETAILS_SUCCESS,
    response,
  };
};

export const createShiftRole = (payload) => {
  return {
    type: CREATE_SHIFT_ROLE,
    payload
  }
}

export const updateRoleToTemplateForEdit = (payload) => {
  return {
    type: UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT,
    payload
  }
}

export const updateShiftRole = (payload) => {
  return {
    type: UPDATE_SHIFT_ROLE,
    payload
  }
}

export const addPersonToRole = (payload) => {
  return {
    type: ADD_PERSON_TO_ROLE,
    payload
  }
}

export const updatePersonToRole = (payload) => {
  return {
    type: UPDATE_PERSON_TO_ROLE,
    payload
  }
}

export const deletePersonFromRole = (payload) => {
  return {
    type: DELETE_PERSON_FROM_ROLE,
    payload
  }
}
