import { useState, useRef } from "react";
import { connect } from "react-redux";
import { Button, Table, Tag, Popover, Space } from "antd";
import Column from "antd/es/table/Column";
import shiftAccept from "../../../../assets/svg/shiftAccept.svg";
import shiftAsterisk from "../../../../assets/svg/shiftAsterisk.svg";
import shiftMore from "../../../../assets/svg/shiftMore.svg";
import shiftEdit from "../../../../assets/svg/shiftEdit.svg";
import trash from "../../../../assets/svg/trash.svg";
import shiftView from "../../../../assets/svg/shiftView.svg";
import shiftDuplicate from "../../../../assets/svg/shiftDuplicate.svg";
import shiftTrash from "../../../../assets/svg/shiftTrash.svg";
import { DownOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import { defaultProfile, unCategorizedLookupList } from "../../talents/talentListNew/helper";
import { clearShiftDetails, cloneRole, deleteRole, deleteShift, getEventShiftDetails, updateRoleToTemplateForEdit, addPersonToRole, updatePersonToRole, deletePersonFromRole } from "../../../../store/actions/shifts";
import { success as toastSuccess, error as toastError } from "../../../toast";
import DeleteRole from "./DeleteRoleModal";
import DeleteShift from "./DeleteShiftModal";
import AddRole from "./AddRole";
import "./shifts.css";
import ShiftPopup from "./ShiftPopup";
import greenStar from "../../../../assets/svg/greenStar.svg"
import AddTalent from "./AddTalent";
import { roleStatsColor } from "../../../../constants/shifts";

const ShiftList = (props) => {
  const {
    eventUUID,
    shiftList,
    skillCategorized,
    triggerCloneRole,
    triggerDeleteRole,
    triggerDeleteShift,
    triggerGetEventShifts,
    triggerClearShiftDetails,
    triggerUpdateRoleToTemplateForEdit,
    getUpdatedShiftList,
    triggerAddPersonToRole,
    triggerUpdatePersonToRole,
    triggerDeletePersonFromRole
  } = props;
  const [moreOptionRoleUUID, setMoreOptionRoleUUID] = useState(null);
  const [loadingShift, setLoadingShift] = useState(null);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [showDeleteShiftModal, setShowDeleteShiftModal] = useState(false);
  const [showAddRole, setShowAddRole] = useState(false);
  const [shiftUUIDForRole, setShiftUUIDForRole] = useState(null);
  const [roleUUIDForEdit, setRoleUUIDForEdit] = useState(null);
  const [showShiftPopup, setShowShiftPopup] = useState(false);
  const [isEditShift, setIsEditShift] = useState(false);
  const [currentShiftId, setCurrentShiftId] = useState(null);
  const [roleShiftToDelete, setRoleShiftToDelete] = useState({
    roleUUID: "",
    shiftUUID: ""
  });
  const [shiftToDelete, setShiftToDelete] = useState(null);
  const [isRoleEdit, setIsRoleEdit] = useState(false);
  const [isViewRole, setIsViewRole] = useState(false);
  const [showTalentAddPopup, setShowTalentAddPopup] = useState(false);
  const [selectedRoleUUID, setSelectedRoleUUID] = useState(null);
  const [selectedTalentForRole, setSelectedTalentForRole] = useState([]);
  const tableRef = useRef(null);
  const baseUrl = process.env.REACT_APP_API_URL;

  const toggleMoreOptions = (roleUUID) => {
    if(roleUUID) setMoreOptionRoleUUID(roleUUID);
    else setMoreOptionRoleUUID(null);
  };

  const getShiftDate = (startAt, startTZ, endAt, endTZ) => {
    if (!startAt || !endAt) {
      return ""
    }
    startAt = startAt.split("T");
    endAt = endAt.split("T");
    let startDate = moment(startAt[0]).format("MM/DD/YYYY");
    let endDate = moment(endAt[0]).format("MM/DD/YYYY");
    let startTime = startAt[1];
    let endTime = endAt[1];
    if (startDate === endDate) {
      endDate = "";
    }
    if (startTZ === endTZ) {
      startTZ = "";
    }
    const formattedStartDate = moment(startDate).format("MM/DD/YY");
    const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mm A");
    const formattedEndDate = endDate ? moment(endDate).format("MM/DD/YY") + ", " : "";
    const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");

    const dateToReturn = `${formattedStartDate + ", "}${formattedStartTime}${
      startTZ ? " " + startTZ : ""
    } - ${formattedEndDate}${formattedEndTime} ${endTZ ? endTZ : ""}`;
    return dateToReturn;
  };

  const duplicateRole = (roleUUID, shiftUUID) => {
    setLoadingShift(shiftUUID);
    const payload = {
      actualPayload : {
        roleUUID,
        eventUUID
      },
      onSuccess: () => {
        setLoadingShift(null);
      },
      onError: (error) => {
        setLoadingShift(null);
        console.log(error);
      }
    }
    triggerCloneRole(payload);
  }

  const deleteRoleFromShift = () => {
    const { roleUUID, shiftUUID } = roleShiftToDelete;
    setLoadingShift(shiftUUID);
    const payload = {
      actualPayload : {
        roleUUID,
        eventUUID
      },
      onSuccess: () => {
        setLoadingShift(null);
        setShowDeleteRoleModal(false);
        setRoleShiftToDelete({ roleUUID: "", shiftUUID: "" });
        toastSuccess("Role removed from shift");
      },
      onError: (error) => {
        setLoadingShift(null);
        setShowDeleteRoleModal(false);
        setRoleShiftToDelete({ roleUUID: "", shiftUUID: "" });
        console.log(error);
      }
    }
    triggerDeleteRole(payload);
  }

  const deleteShiftFromEvent = () => {
    setLoadingShift(shiftToDelete);
    const payload = {
      shiftUUID: shiftToDelete,
      onSuccess: () => {
        setLoadingShift(null);
        setShowDeleteShiftModal(false);
        setShiftToDelete(null);
        toastSuccess("Shift removed from event.");
      },
      onError: (error) => {
        setLoadingShift(null);
        setShowDeleteShiftModal(false);
        setShiftToDelete(null);
        console.log(error);
      }
    }
    triggerDeleteShift(payload);
  }

  const closeShiftDeleteModal = () => {
    setShowDeleteShiftModal(false);
    setShiftToDelete(null);
  }

  const closeRoleDeleteModal = () => {
    setShowDeleteRoleModal(false);
    setRoleShiftToDelete({ roleUUID: "", shiftUUID: "" });
  }

  const openEditShiftPopup = (id) => {
    const payload = {
      id,
      onSuccess: (response) => {
        setCurrentShiftId(id);
        setIsEditShift(true);
        setShowShiftPopup(true);
      },
      onError: (error) => {
        console.log('in here', error);
      }
    };
    triggerGetEventShifts(payload);    
  }

  const closePopup = () => {
    setCurrentShiftId(null);
    setIsEditShift(false);
    setShowShiftPopup(false);
    getUpdatedShiftList();
  }

  const closeRolePopup = () => {
    setShowAddRole(false);
    setLoadingShift(null);
    setShiftUUIDForRole(null);
    setIsRoleEdit(false);
    setRoleUUIDForEdit(null);
    setIsViewRole(false);
  }

  const openCreateShiftPopup = () => {
    const payload = {
      onSuccess: () => {
        setCurrentShiftId(null);
        setIsEditShift(false);
        setShowShiftPopup(true);
      }
    };
    triggerClearShiftDetails(payload);
  }

  const onEditRole = ({ roleUUID, shiftUUID }) => {
    triggerUpdateRoleToTemplateForEdit({ roleUUID, shiftUUID });
    setRoleUUIDForEdit(roleUUID);
    setIsRoleEdit(true)
    setShowAddRole(true);
  }

  const onViewRole = ({ roleUUID, shiftUUID }) => {
    triggerUpdateRoleToTemplateForEdit({ roleUUID, shiftUUID });
    setRoleUUIDForEdit(roleUUID);
    setIsViewRole(true)
    setShowAddRole(true);
  }

  const addPerson = (personUUID) => {
    if(selectedRoleUUID && personUUID && eventUUID) {
      const payload = {
        actualPayload: {
          event_shift2role_uuid: selectedRoleUUID,
          person_uuid: personUUID
        },
        eventUUID,
        onSuccess: (response) => {
          setSelectedTalentForRole([...selectedTalentForRole, response?.uuid]);
          toastSuccess("Person added successfully");
        },
        onError: (error) => {
          toastError("Error in person add");
          console.log(error);
        }
      }
      triggerAddPersonToRole(payload);
    }
  }

  const updatePerson = (personUUID) => {
    if(personUUID && eventUUID) {
      const payload = {
        actualPayload: {
          uuid: personUUID,
          approved_at:  moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
        eventUUID,
        onSuccess: () => {
          toastSuccess("Person confirmed successfully");
        },
        onError: (error) => {
          toastError("Error in person confirmation");
          console.log(error);
        }
      }
      triggerUpdatePersonToRole(payload);
    }
  }

  const removePerson = (rolePersonUUID) => {
    const payload = {
      eventShiftRolePersonUUID: rolePersonUUID,
      eventUUID,
      onSuccess: () => {
        toastSuccess("Person removed successfully");
      },
      onError: (error) => {
        toastError("Error in person delete");
        console.log(error);
      }
    }
    triggerDeletePersonFromRole(payload);
  }

  const closeTalentPopup = () => {
    setShowTalentAddPopup(false);
    setSelectedRoleUUID(null);
  }

  const handleClickPersonAddBtn = (record) => {
    const existingPersonUUID = record?.people?.map(r => r.uuid);
    setSelectedTalentForRole(existingPersonUUID);
    setSelectedRoleUUID(record.uuid);
    setShowTalentAddPopup(true);
  }

  return (
    <>
      {shiftList?.length > 0 &&
        shiftList.map((shift, index) => {
          shift?.roles?.map((role, index) => role["key"] = index + 1);
          let stats="";
          let statsColor="";
          if(shift?.stats) {
            if(shift?.stats?.shift_empty) {
              stats = "Empty Shift";
              statsColor = roleStatsColor.red;
            } else if(shift?.stats?.understaffed) {
              stats = "Understaffed";
              statsColor = roleStatsColor.yellow;
            } else {
              stats = "Fully Staffed";
              statsColor = roleStatsColor.green;
            }
          }
          return (
            <div
              className={`event-shifts border rounded-md border-[#ddd] p-2 ${
                index !== 0 && "mt-6"
              }`}
              key={shift?.UUID}
            >
              <div className="flex justify-between items-center border-b border-b-[#E3E1E1] pb-2">
                <div className="flex items-center">
                  <p className="text-grayBlack text-xl font-bold mr-3">
                    {shift?.name}
                  </p>
                  <div className="mx-3">
                    <span className={`border border-transparent rounded-[4px] py-[2px] px-[3px] text-subText font-medium ${statsColor}`}>
                      {stats}
                    </span>
                  </div>
                  <p className="mx-3 text-cadetBlue text-[14px] font-medium">
                    {getShiftDate(
                      shift?.startAt,
                      shift?.startTZ,
                      shift?.endAt,
                      shift?.endTZ
                    )}
                  </p>
                  <p className="mx-3 font-inter text-cadetBlue text-[14px] font-medium">
                    Shift location:{" "}
                    {shift?.primaryVenueAddressSpace?.display_name}
                  </p>
                </div>
                <div className="flex items-center">
                  <Button
                    className="flex font-inter font-medium text-sm text-fontGray border rounded-[8px] py-[6px] pr-[10px] pl-[6px] mr-3 hover:!text-fontGray !border-grayLight"
                    onClick={() => {
                      openEditShiftPopup(shift?.UUID);
                    }}
                    icon={
                      <img
                        src={shiftEdit}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                        alt="edit shift"
                      />
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                    onClick={() => {
                      setShiftToDelete(shift?.UUID);
                      setShowDeleteShiftModal(true);
                    }}
                  >
                    <img src={trash} alt="delete-shift" />
                  </Button>
                </div>
              </div>
              <div className="px-2 mt-2" ref={tableRef}>
                <Table
                  rowkey={(record) => record?.UUID}
                  expandable={{
                    expandedRowRender: (record) => {
                      let roleStats = "";
                      let statsColor = "";
                      if(record?.stats) {
                        if(record?.stats?.understaffed) {
                          roleStats = "Understaffed";
                          statsColor = roleStatsColor.yellow;
                        } else {
                          roleStats = "Fully Staffed";
                          statsColor = roleStatsColor.green;
                        }
                      }
                      return (
                        <div>
                          <div className="flex justify-between">
                            <div className="flex justify-around items-center">
                              <div className="flex items-center">
                                <span className="text-grayBlack text-xs font-bold">
                                  Staffing
                                </span>
                              </div>
                              <div className="flex items-center mx-4">
                                <Popover
                                  placement="top"
                                  content={
                                    <span className="text-black text-[13px] font-medium">
                                      Staff confirmed
                                    </span>
                                  }
                                  overlayClassName="event-shift-popover"
                                >
                                <div className="flex">
                                  <img src={shiftAccept} alt="shift-send" className="mr-[6px]" />
                                  <span className={`text-[13px] font-bold ${roleStats === "Understaffed" ? "text-darkRed": "text-algae"}`}>
                                    {record?.stats?.approved}
                                  </span>
                                </div>
                                </Popover>
                                <span className="text-silverGray mx-[6px]">/</span>
                                  <Popover
                                    placement="top"
                                    content={
                                      <span className="text-black text-[13px] font-medium">
                                        Staff needed
                                      </span>
                                    }
                                    overlayClassName="event-shift-popover"
                                  >
                                  <div className="flex">
                                    <img src={shiftAsterisk} alt="shift-send" className="mr-[6px]" />
                                    <span className={`text-[13px] font-bold ${roleStats === "Understaffed" ? "text-darkRed": "text-algae"}`}>
                                      {record?.talent_needed}
                                    </span>
                                  </div>
                                  </Popover>
                              </div>
                              <div>
                              <span className={`border border-transparent rounded-[4px] py-[2px] px-[3px] text-subText font-medium ${statsColor}`}>
                                {roleStats}
                              </span>
                              </div>
                            </div>
                            <div>
                              <Button
                                className="flex font-inter font-medium text-sm text-fontGray border rounded-[8px] px-[10px] py-[6px]"
                                onClick={() => {
                                  handleClickPersonAddBtn(record);
                                }}
                                icon={
                                  <PlusOutlined className="align-baseline mt-three" />
                                }
                              >
                                Add Staff
                              </Button>
                            </div>
                          </div>
                          <div className="max-h-[235px] overflow-auto">
                            {record?.people?.length > 0 ? record?.people?.map((p, index) => {
                              const imageStyle = "w-[32px] min-w-[32px] max-w-[32px] h-[35px] rounded-[6px]"
                              return (
                                <div
                                  className={`flex justify-between p-4 ${index !== 0 && "border-t-[1px] border-t-[#F2F2F2]"}`}
                                  key={p?.uuid}
                                >
                                  <div className="role-person-container">
                                    <div>
                                      {p?.person?.primary_photo_attribute
                                        ?.value ? (
                                        <img
                                          src={
                                            baseUrl +
                                            p?.person?.primary_photo_attribute
                                              ?.value
                                          }
                                          alt="profile"
                                          width={32}
                                          height={32}
                                          className={imageStyle}
                                          onError={(e) =>
                                            (e.target.src = defaultProfile)
                                          }
                                        />
                                      ) : (
                                        <img
                                          className={imageStyle}
                                          src={defaultProfile}
                                          alt="Profile not Found"
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <p className="text-black text-sm font-medium font-inter">
                                        {p?.person?.first_name +
                                          " " +
                                          p?.person?.last_name}
                                      </p>
                                      <p className="text-cadetBlue font-medium text-sm font-inter">
                                        {_.startCase(
                                          _.toLower(
                                            p?.person?.primary_address
                                              ?.state_province?.name
                                          )
                                        )}{" "}
                                        City,{" "}
                                        {_.startCase(
                                          _.toLower(
                                            p?.person?.primary_address
                                              ?.state_province?.country?.name
                                          )
                                        )}
                                      </p>
                                    </div>
                                    <div className="flex">
                                      <img src={greenStar} alt="rating-star" />
                                      <span className="ml-1 font-normal text-sm font-inter text-grayBlack">
                                        {p?.person?.company_rating || "Unrated"}
                                      </span>
                                    </div>
                                    <div className="text-right">
                                    {p?.status === "approved" ? (
                                      <p className="font-medium text-sm font-inter text-grayBlack">Confirmed</p>
                                    ) : (
                                      <Button
                                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                                      onClick={() => {
                                        updatePerson(p?.uuid);
                                      }}
                                    >
                                      Confirm
                                    </Button>
                                    )}
                                    </div>
                                    <Button
                                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                                      onClick={() => {
                                        removePerson(p?.uuid);
                                      }}
                                    >
                                      <img src={trash} alt="delete-shift" />
                                    </Button>
                                  </div>
                                </div>
                              );
                            }) : (
                              <p className="font-inter mt-4 ml-3 font-medium">No people was found</p>
                            )}
                          </div>
                        </div>
                      );
                    },
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <DownOutlined onClick={(e) => onExpand(record, e)} />
                      ) : (
                        <RightOutlined onClick={(e) => onExpand(record, e)} />
                      ),
                  }}
                  loading={loadingShift === shift?.UUID}
                  dataSource={shift?.roles}
                  showHeader={true}
                  className={`w-full font-inter ${
                    !shift?.roles?.length && "empty-table"
                  }`}
                  locale={{
                    emptyText: <div className="empty-shift"></div>,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        toggleMoreOptions();
                      },
                    };
                  }}
                  pagination={false}
                >
                  <Column
                    title="Role"
                    className="w-[12%]"
                    render={(val) => {
                      return (
                        <>
                          <Tag
                            color="#8193B1"
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            bordered={false}
                          >
                            {val?.event_role?.name}
                          </Tag>
                        </>
                      );
                    }}
                  />
                  <Column
                    title="Skills"
                    className="w-[14%]"
                    render={(val) => {
                      const skillsList = val?.person_search_obj?.skills?.list;
                      if(!skillsList?.length) {
                        return (
                          <p className="text-grayBlack text-xs font-medium">
                            N/A
                          </p>
                        )
                      }
                      let skillContents = [];
                      skillsList?.forEach((skill) => {
                        const result = _.find(
                          skillCategorized,
                          (entry) => _.values(entry)[0] === skill
                        );
                        const skillValue = _.keys(result)[0];
                        skillContents.push(skillValue);
                      });
                      return (
                        <>
                          {skillContents?.map(
                            (skill, i) =>
                              i <= 4 && (
                                <Tag
                                  color="#E0E4EB"
                                  style={{
                                    color: "#0A0C10",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginInlineEnd: "2px",
                                    textTransform: "capitalize",
                                  }}
                                  bordered={false}
                                  key={i}
                                >
                                  {skill}
                                </Tag>
                              )
                          )}

                          {skillContents.length > 5 && (
                            <Popover
                              placement="right"
                              content={
                                <div>
                                  {skillContents?.map((skill, i) => (
                                    <span
                                      className="text-grayMuted text-xs font-medium capitalize"
                                      key={i}
                                    >{`${skill}${
                                      i !== skillContents.length - 1 ? ", " : ""
                                    }`}</span>
                                  ))}
                                </div>
                              }
                              overlayClassName="event-shift-popover"
                            >
                              <Tag
                                color="#E0E4EB"
                                style={{
                                  color: "#0A0C10",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  marginInlineEnd: "2px",
                                }}
                                bordered={false}
                              >
                                +{skillContents.length - 5} more
                              </Tag>
                            </Popover>
                          )}
                        </>
                      );
                    }}
                  />
                  <Column
                    title="Min Rtg"
                    className="w-[7%] !text-center"
                    render={(val) =>
                      val.minimum_rating ? (
                        <span className="text-grayBlack text-[13px] font-medium">
                          {val.minimum_rating}
                        </span>
                      ) : (
                        <p className="text-grayBlack text-xs font-medium">
                          N/A
                        </p>
                      )
                    }
                  />
                  <Column
                    title="Composition"
                    className="w-[14%]"
                    render={(val) => {
                      const compList =
                        val?.person_search_obj?.person_attributes;
                      let compContents = [];
                      compList?.forEach((comp) => {
                        const getTag = _.filter(unCategorizedLookupList, {
                          uuid: comp?.person_attribute_uuid,
                        });
                        let valueArr = [];
                        if (comp?.list?.length) {
                          valueArr = _.map(comp?.list, (str) =>
                            _.startCase(_.toLower(str))
                          );
                        } else {
                          valueArr = {
                            start_value: comp?.start_value,
                            end_value: comp?.end_value,
                          };
                        }
                        const data = getTag[0].tag(valueArr);
                        compContents.push(data);
                      });
                      return compContents.length ? (
                        <>
                          {compContents?.map(
                            (comp, i) =>
                              i <= 4 && (
                                <Space>
                                  <Tag
                                    color="#E0E4EB"
                                    style={{
                                      color: "#0A0C10",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      marginInlineEnd: "2px",
                                      wordWrap: "break-word",
                                      whiteSpace: "break-spaces",
                                    }}
                                    bordered={false}
                                    key={i}
                                  >
                                    {comp}
                                  </Tag>
                                </Space>
                              )
                          )}
                          {compContents.length > 5 && (
                            <Popover
                              placement="right"
                              content={
                                <div>
                                  {compContents?.map((comp, i) => (
                                    <span
                                      className="text-grayMuted text-xs font-medium"
                                      key={i}
                                    >{`${comp}${
                                      i !== compContents.length - 1 ? ", " : ""
                                    }`}</span>
                                  ))}
                                </div>
                              }
                              overlayClassName="event-shift-popover"
                            >
                              <Tag
                                color="#E0E4EB"
                                style={{
                                  color: "#0A0C10",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  marginInlineEnd: "2px",
                                }}
                                bordered={false}
                              >
                                +{compContents.length - 5} more
                              </Tag>
                            </Popover>
                          )}
                        </>
                      ) : (
                        <p className="text-grayBlack text-xs font-medium w-fit">
                          N/A
                        </p>
                      );
                    }}
                  />
                  <Column
                    title="Sublocation"
                    className="w-[10%]"
                    render={(val) =>
                      val?.sub_location ? (
                        <Popover
                          placement="right"
                          content={
                            <span className="text-grayMuted text-xs font-medium">
                              {val?.sub_location}
                            </span>
                          }
                          overlayClassName="event-shift-popover"
                        >
                          <p className="text-grayBlack text-xs font-medium w-fit">
                            {val?.sub_location}
                          </p>
                        </Popover>
                      ) : (
                        <p className="text-grayBlack text-xs font-medium w-fit">
                          N/A
                        </p>
                      )
                    }
                  />
                  <Column
                    title="Notes"
                    className="w-[14%]"
                    render={(val) =>
                      val?.desc ? (
                        <Popover
                          placement="right"
                          content={
                            <span className="text-grayBlack text-xs font-medium">
                              {val?.desc}
                            </span>
                          }
                          overlayClassName="event-shift-popover"
                        >
                          <p className="text-grayBlack text-xs font-medium w-fit line-clamp-4">
                            {val?.desc}
                          </p>
                        </Popover>
                      ) : (
                        <p className="text-grayBlack text-xs font-medium">
                          N/A
                        </p>
                      )
                    }
                  />
                  <Column
                    title="Attire"
                    className="w-[14%]"
                    render={(val) =>
                      val?.attire_reqs ? (
                        <Popover
                          placement="right"
                          content={
                            <span className="text-grayBlack text-xs font-medium">
                              {val?.attire_reqs}
                            </span>
                          }
                          overlayClassName="event-shift-popover"
                        >
                          <p className="text-grayBlack text-xs font-medium w-fit line-clamp-4">
                            {val?.attire_reqs}
                          </p>
                        </Popover>
                      ) : (
                        <p className="text-grayBlack text-[13px] font-medium w-fit">
                          N/A
                        </p>
                      )
                    }
                  />
                  <Column
                    title="Rate"
                    className="w-[4%]"
                    render={(val) => (
                      <span className="text-grayBlack text-[13px] font-medium">
                        {val?.hourly_cost ? `$${val?.hourly_cost}` : "N/A" }
                      </span>
                    )}
                  />
                  <Column
                    title="Staff"
                    className="w-[8%] text-center"
                    render={(val) => (
                      <div className="flex items-center">
                        <Popover
                          placement="top"
                          content={
                            <span className="text-black text-[13px] font-medium">
                              Staff confirmed
                            </span>
                          }
                          overlayClassName="event-shift-popover"
                        >
                        <div className="flex mr-1">
                          <img src={shiftAccept} alt="shift-send" className="mr-[6px]" />
                          <span className={`text-[13px] font-bold ${val?.stats?.understaffed ? "text-darkRed": "text-algae"}`}>
                          {val?.stats?.approved}
                          </span>
                        </div>
                        </Popover>
                        <span className="text-silverGray mx-1">/</span>
                        <Popover
                          placement="top"
                          content={
                            <span className="text-black text-[13px] font-medium">
                              Staff needed
                            </span>
                          }
                          overlayClassName="event-shift-popover"
                        >
                          <div className="flex">
                            <img src={shiftAsterisk} alt="shift-send" className="mr-[6px]" />
                            <span className={`text-[13px] font-bold ${val?.stats?.understaffed ? "text-darkRed": "text-algae"}`}>
                            {val?.talent_needed}
                            </span>
                          </div>
                        </Popover>
                      </div>
                    )}
                  />
                  <Column
                    className="w-[3%] text-center"
                    render={(val, record, index) => {
                      return (
                        <div className="flex justify-around items-center">
                          <div className="flex relative">
                            <div
                              className="cursor-pointer rotate-90"
                              onClick={(e) => {
                                if(moreOptionRoleUUID === val?.uuid) toggleMoreOptions(); 
                                else toggleMoreOptions(val?.uuid);
                                e.stopPropagation();
                              }}
                            >
                              <img src={shiftMore} alt="shift-send" />
                            </div>
                            <div
                              className={`absolute top-[90%] -right-5 min-w-[125px] bg-white z-10 transition-all ${
                                moreOptionRoleUUID === val?.uuid
                                  ? "max-h-auto py-[5px]"
                                  : "max-h-0 overflow-hidden py-0"
                              }`}
                            >
                              <div
                                className={`flex flex-col border border-[#CBCBCB] rounded-md text-black font-medium text-xs`}
                              >
                                <div
                                  className="border-b border-b-[#CBCBCB] hover:bg-[#E0E4EB] cursor-pointer"
                                  onClick={() =>
                                    onViewRole({
                                      roleUUID: val?.uuid,
                                      shiftUUID: shift?.UUID,
                                    })
                                  }
                                >
                                  <div className="flex py-[5px] mx-[13px]">
                                    <img src={shiftView} alt="shift-view" />
                                    <span className="ml-[14px] text-grayBlack text-sm font-normal">View</span>
                                  </div>
                                </div>
                                <div
                                  className="border-b border-b-[#CBCBCB] hover:bg-[#E0E4EB] cursor-pointer"
                                  onClick={() =>
                                    duplicateRole(val?.uuid, shift?.UUID)
                                  }
                                >
                                  <div className="flex py-[5px] mx-[15px]">
                                    <img
                                      src={shiftDuplicate}
                                      alt="shift-copy"
                                    />
                                    <span className="ml-4 text-grayBlack text-sm font-normal">Duplicate</span>
                                  </div>
                                </div>
                                <div
                                  className="border-b border-b-[#CBCBCB] hover:bg-[#E0E4EB] cursor-pointer"
                                  onClick={() => {
                                    onEditRole({
                                      roleUUID: val?.uuid,
                                      shiftUUID: shift?.UUID,
                                    });
                                  }}
                                >
                                  <div className="flex py-[5px] mx-[15px]">
                                    <img src={shiftEdit} alt="shift-edit" />
                                    <span className="ml-4 text-grayBlack text-sm font-normal">Edit</span>
                                  </div>
                                </div>
                                <div
                                  className="hover:bg-[#E0E4EB] cursor-pointer"
                                  onClick={() => {
                                    setRoleShiftToDelete({
                                      roleUUID: val?.uuid,
                                      shiftUUID: shift?.UUID,
                                    });
                                    setShowDeleteRoleModal(true);
                                  }}
                                >
                                  <div className="flex py-[5px] mx-[15px]">
                                    <img src={shiftTrash} alt="shift-delete" />
                                    <span className="ml-4 text-grayBlack text-sm font-normal">Remove</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Table>
              </div>
              <div className="flex justify-between items-center mt-3">
                <div>
                  <Button
                    className="flex font-inter font-medium text-sm text-fontGray border rounded-[8px] px-[10px] py-[6px]"
                    onClick={() => {
                      setShiftUUIDForRole(shift?.UUID);
                      setShowAddRole(true);
                    }}
                    icon={<PlusOutlined className="align-baseline mt-three" />}
                  >
                    Add Role
                  </Button>
                </div>
                {shift?.roles?.length > 0 && (
                  <button
                    className="flex font-inter font-medium text-base text-white border rounded-[8px] bg-btnDisableGray py-[6px] px-4"
                    onClick={() => {}}
                  >
                    Generate Tiers
                  </button>
                )}
              </div>
            </div>
          );
        })}
      <div className="mt-8">
        <Button
          className="flex font-inter font-medium text-sm text-fontGray border rounded-[8px] px-[10px] py-[6px]"
          onClick={() => {
            openCreateShiftPopup();
          }}
          icon={<PlusOutlined className="align-baseline mt-three" />}
        >
          Add Shift
        </Button>
      </div>
      {showDeleteRoleModal && (
        <DeleteRole
          onCancel={closeRoleDeleteModal}
          onConfirm={deleteRoleFromShift}
        />
      )}
      {showDeleteShiftModal && (
        <DeleteShift
          onCancel={closeShiftDeleteModal}
          onConfirm={deleteShiftFromEvent}
        />
      )}
      {showAddRole && (
        <AddRole
          closeRolePopup={closeRolePopup}
          setLoadingShift={setLoadingShift}
          shiftUUIDForRole={shiftUUIDForRole}
          roleUUIDForEdit={roleUUIDForEdit}
          showEditIcon={isRoleEdit || isViewRole}
          isViewRole={isViewRole}
          eventUUID={eventUUID}
        />
      )}
      {showShiftPopup && (
        <ShiftPopup
          setShowShiftPopup={setShowShiftPopup}
          isEditShift={isEditShift}
          setIsEditShift={setIsEditShift}
          shiftId={currentShiftId}
          isAutoGeneratedAndUntouched={false}
          closePopup={closePopup}
        />
      )}
      {showTalentAddPopup && (
        <AddTalent closeTalentPopup={closeTalentPopup} addPerson={addPerson} selectedRoleUUID={selectedRoleUUID} selectedTalentForRole={selectedTalentForRole} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    skillCategorized: state.talent.skillCategorized
  };
};
const mapDispatchToProps = {
  triggerCloneRole: cloneRole,
  triggerDeleteRole: deleteRole,
  triggerDeleteShift: deleteShift,
  triggerGetEventShifts: getEventShiftDetails,
  triggerClearShiftDetails: clearShiftDetails,
  triggerUpdateRoleToTemplateForEdit: updateRoleToTemplateForEdit,
  triggerAddPersonToRole: addPersonToRole,
  triggerDeletePersonFromRole: deletePersonFromRole,
  triggerUpdatePersonToRole: updatePersonToRole
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftList);
