import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { components } from 'react-select';
import { notesRating } from "../../../../constants";
import {
  experienceUpdatedForm,
  skillsUpdatedForm,
  updateNewTalentData,
  updateTalentEventRole,
  getTalentNotes,
  createTalentNotes,
  deleteTalentNote,
  updateTalentNote,
  getTalentNotesDataTemplate
} from "../../../../store/actions/talents";
import "./talentAdd.css";
import {deleteIcon, editIcon} from "../library/assets/svg.js";
import { useParams } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Select, Input, Space, Popconfirm } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import { ReactSelectPaginate } from "../../components/reactSelectPaginate";
import { checkApiStatus } from "../../../../store/actions/events";
import { getEventSuggestion } from "../../../../api/events";
import { error, success } from '../../../toast';
import { createdErrorMessage, createdSuccessMessage, deletedErrorMessage, deletedSuccessMessage, updatedErrorMessage, updatedSuccessMessage } from '../../../../constants/events';
const { TextArea } = Input;
const { Option } = Select;

const Notes = (props) => {
  const {
    triggerTalentNotesList,
    notesData,
    triggerCreateTalentNote,
    triggerDeleteNote,
    triggerUpdateNote,
    triggerGetTalentNotesDataTemplate,
    triggerCheckApiStatus,
  } = props;
  const [rating, setRating] = useState(0);
  const [notesDesc, setNotesDesc] = useState("");
  const [event, setEvent] = useState("");
  const [ratingUpdate, setRatingUpdate] = useState(0);
  const [notesDescUpdate, setNotesDescUpdate] = useState("");
  const [eventUpdate, setEventUpdate] = useState("");
  const [editOn, setEditOn] = useState("");
  const [ratingErr, setRatingErr] = useState(false);
  const [notesDescErr, setNotesDescErr] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    triggerTalentNotesList({ id: id });
    return () => {
      triggerGetTalentNotesDataTemplate([]);
    }
  }, []);

  const onDeleteNote = (noteId) => {
    const payload = {
      uuid: noteId,
      onSuccess: (res) => {
        triggerTalentNotesList({ id: id });
        setNotesDesc("");
        setRating(0);
        setEvent("");
        setRatingErr(false);
        setNotesDescErr(false);
        success(deletedSuccessMessage('Note'))
      },
      onError: () => {
        console.log("Error - Note not deleted");
        error(deletedErrorMessage('Note'))
      },
    };
    triggerDeleteNote(payload);
  };

  const submit = () => {
    if (rating === 0) {
      setRatingErr(true);
    } else if (notesDesc === "") {
      setNotesDescErr(true);
    } else {
      const payload = {
        person_uuid: id,
        note_type_uuid: "5cfa8740-3aad-4625-88ad-5a114235bc64",
        desc: notesDesc,
        author_rating: rating,
        event_uuid: event?.value
      };
      createNote(payload);
    }
  };

  const createNote = (notePayload) => {
    const payload = {
      actualPayload: notePayload,
      onSuccess: (res) => {
        triggerTalentNotesList({ id: id });
        setNotesDesc("");
        setRating(0);
        setEvent("");
        setRatingErr(false);
        setNotesDescErr(false);
        success(createdSuccessMessage('Note'))
      },
      onError: () => {
        error(createdErrorMessage('Note'))        
      },
    };
    triggerCreateTalentNote(payload);
  };

  const updateNote = (uuid) => {
    let eventUUID = ''
    if ((eventUpdate && eventUpdate['value']) || (event && event['value'])) {
      eventUUID = eventUpdate['value'] || event['value']
    }
    const payload = {
      actualPayload: {
        uuid: uuid,
        title: "",
        desc: notesDescUpdate !== "" ? notesDescUpdate : notesDesc,
        author_rating: ratingUpdate !== "0" ? ratingUpdate : rating,
        event_uuid: eventUUID
      },
      onSuccess: (res) => {
        triggerTalentNotesList({ id: id });
        setNotesDesc("");
        setRating(0);
        setEvent("");
        setRatingUpdate(0);
        setNotesDescUpdate("");
        setEditOn("");
        success(updatedSuccessMessage('Note'))
      },
      onError: () => {
        error(updatedErrorMessage('Note'))
      },
    };
    triggerUpdateNote(payload);
  };

  const getEventOptions = async(search, prevOptions, { page }) => {
    try {
      const payload = {
        number: page,
        actualPayload: {
          "search_field": search
        },
        size: 10
      }
      const response = await getEventSuggestion(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const eventSuggestions = suggestions.map(({uuid: value, dropdown_menu_name: label}) => ({value, label}))
      const pageNumber = parseInt(response?.headers['response-page-number'])
      const totalPageCount = parseInt(response?.headers['total-page-count'])
      const hasMoreSuggestions = totalPageCount !== pageNumber;
      const options = getOptions(eventSuggestions, search, hasMoreSuggestions, prevOptions, page);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getOptions = (suggestions, search, hasMoreSuggestions, prevOptions, page) => {
    let filteredOptions = [];
    const hasMore = hasMoreSuggestions;
    if (!suggestions.length) return { options: [] }

    if(suggestions?.length && eventUpdate && eventUpdate?.value) { // to make selected option as first one
      suggestions = suggestions?.filter(option => (option?.value !== eventUpdate?.value));
      if (page === 1) {
        suggestions.unshift(eventUpdate);
      }
    }

    if (!search) {
      return {
        options: suggestions, hasMore,
        additional: {
          page: page + 1
        }
      };
    }
    const searchLower = search.toLowerCase();
    filteredOptions = suggestions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
    const slicedOptions = filteredOptions.slice( prevOptions.length, prevOptions.length + 10 );
    return {
      options: slicedOptions, hasMore,
      additional: {
        page: page + 1
      }
    };
  }

  const Menu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className='w-full text-sm font-medium leading-[17px] tracking-normal text-left overflow-auto'>
            {props.children}
          </div>
        </components.Menu>
      </Fragment>
    );
  }

  return (
    <div className="w-full">
      <div className="border border-grayLight rounded px-4 pt-[10px]">
        <div className="text-[24px] pb-4 text-[#344054] font-medium">Notes</div>
        <div className="border border-grayLight rounded mb-[15px] text-xs p-[14px]">
          <div class="grid grid-cols-2 gap-4 pb-2 mb-2">
            <div className="w-full border border-grayLight rounded p-2">
              <div className="font-semibold">EVENT</div>
              <ReactSelectPaginate
                placeholder="Select event"
                loadMoreOptions={getEventOptions}
                onChange={(option) => { setEvent(option); setEventUpdate(option); }}
                value={event}
                isValidNewOption={false}
                debounce={300}
                required={false}
                name='event'
                Menu={Menu}
              />
            </div>
            <div className="w-full border border-grayLight rounded ">
              <div className="font-semibold pt-2 px-2">TALENT RATING</div>
              <Select
                value={rating == 0 ? "" : rating}
                placeholder="Select rating"
                bordered={false}
                onChange={(value) => {
                  setRating(value);
                  setRatingUpdate(value);
                  value > 0 && setRatingErr(false);
                }}
                className="w-full "
              >
                {notesRating.map((option, i) => (
                  <Option className="rating-selectbox" key={i} value={option.value} label={option.value}>
                    <Space>
                      <span role="img" aria-label={option.value}>
                        <StarFilled
                          style={{
                            color: option.iconColor,
                          }}
                        />
                      </span>
                      {option.value} - {option.label}
                    </Space>
                  </Option>
                ))}
              </Select>
              {ratingErr && (
                <span className="text-[#FF0000]">Rating is required</span>
              )}
            </div>
          </div>
            <div className="w-full border border-grayLight rounded py-4 px-2">
            <div class="grid grid-cols-2 gap-4 ">
              <div>
                <TextArea
                  required
                  value={notesDesc}
                  placeholder="Type a comment here..."
                  onChange={(e) => {
                    setNotesDesc(e.target.value);
                    setNotesDescUpdate(e.target.value);
                    e.target.value != "" && setNotesDescErr(false);
                  }}
                  autoSize={{
                    minRows: 2,
                    maxRows: 3,
                  }} 
                  className="border-none !h-[32px] !min-h-[32px]"
                />
                {notesDescErr && (
                  <span className="text-[#FF0000]">Note is required</span>
                )}
              </div>
              <div className="text-right">
                <Button
                  onClick={submit}
                  className="bg-secondaryLight hover:bg-secondary active:bg-secondaryDark text-white"
                  size="large"
                >
                  {" "}
                  Add Note{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {notesData?.map((note, i) => (
          <div
            className="border border-grayLight rounded mb-[15px] text-xs py-2 px-2"
            key={i}
          >
            <div class="grid grid-cols-2 gap-4 border-b border-grayLight pb-2 mb-2">
              <div className="w-full pt-2">
                <span className="font-medium">AUTHOR:</span> <span className="px-[12px] font-medium">{note.author}</span>
                <span className="italic text-[#767676]">{note.created_at}</span>
              </div>
              <div className="text-right">
                {editOn != note.note_id ? (
                  <Button
                    className="mr-[5px] edit-note font-medium"
                    onClick={() => {setEditOn(note.note_id); setEventUpdate(note.event); setRatingUpdate(note.rating); setNotesDescUpdate(note.desc)}}
                    icon={<Icon component={editIcon} />}
                  >
                    {" "}
                    Edit
                  </Button>
                ) : (
                  <>
                    <span
                      className="bg-[#F3F4F7] p-[8px] cursor-pointer mr-[5px] rounded"
                      onClick={() => updateNote(note.note_id)}
                    >
                      <CheckOutlined style={{ color: "#5BB466" }} />
                    </span>{" "}
                    <span
                      className="bg-[#F3F4F7] p-[8px] cursor-pointer mr-[5px] rounded"
                      onClick={() => setEditOn("")}
                    >
                      <CloseOutlined style={{ color: "#FF0000" }} />
                    </span>
                  </>
                )}
                <Popconfirm
                  className="action-bar"
                  onConfirm={() => onDeleteNote(note.note_id)}
                  title="Delete the note"
                  description="Are you sure to delete this note?"
                >
                  <Button icon={<Icon component={deleteIcon} />} />
                </Popconfirm>
              </div>
            </div>
            {editOn != note.note_id ? (
              <>
                <div
                  id={`viewRate-${note.note_id}`}
                  class="grid grid-cols-2 gap-4 border-b border-grayLight pb-2 mb-2"
                >
                  <div>
                    <span className="font-medium">EVENT:</span>
                    <span className="italic text-[#767676] pl-[12px]">
                      {note?.event?.label || 'Not an event related note'}
                    </span>{" "}
                  </div>
                  <div className="text-right font-medium">
                    <StarFilled
                      style={{
                        color:
                          notesRating[parseInt(note.rating) - 1]?.iconColor,
                      }}
                      className="pr-[5px]"
                    />
                    {note.rating}{" "}
                    {notesRating[parseInt(note.rating) - 1]?.label}
                  </div>
                </div>
                <textarea className="w-full view-desc font-medium" rows={3} value={note.desc} readOnly></textarea>
                
              </>
            ) : (
              <>
                <div
                  id={`editRate-${note.note_id}`}
                  class="grid grid-cols-2 gap-4 border-b border-grayLight pb-2 mb-2"
                >
                  <div className="pt-2 font-medium">
                    EVENT:
                    <ReactSelectPaginate
                      placeholder='Select event'
                      loadMoreOptions={getEventOptions}
                      onChange={(option) =>setEventUpdate(option)}
                      value={eventUpdate}
                      isValidNewOption={false}
                      debounce={300}
                      required={false}
                      name='event'
                      Menu={Menu}
                    />
                  </div>
                  <div className="text-right">
                    <Select
                      defaultValue={note.rating}
                      value={ratingUpdate == 0 ? "" : ratingUpdate}
                      placeholder="Select rating"
                      bordered={false}
                      onChange={(value) => setRatingUpdate(value)}
                      className="w-full"
                    >
                      {notesRating.map((option, i) => (
                        <Option
                          key={i}
                          value={option.value}
                          label={option.value}
                        >
                          <Space>
                            <span role="img" aria-label={option.value}>
                              <StarFilled
                                style={{
                                  color: option.iconColor,
                                }}
                              />
                            </span>
                            {option.value} - {option.label}
                          </Space>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div id={`editDesc-${note.note_id}`}>
                  <TextArea
                    required
                    defaultValue={note.desc}
                    value={notesDescUpdate}
                    placeholder="Type a comment here..."
                    onChange={(e) => setNotesDescUpdate(e.target.value)}
                    className="border-none"
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isExperienceUpdated: state.talent.isExperienceUpdated,
    experienceUserUpdated: state.talent.experienceUserUpdated,
    experienceTemplate: state.talent.experienceTemplate,
    isSkillsUpdated: state.talent.isSkillsUpdated,
    skillsUserUpdated: state.talent.skillsUserUpdated,
    skillsTemplate: state.talent.skillsTemplate,
    notesData: state.talent.notesData
  };
};

const mapDispatchToProps = {
  triggerExperienceUpdate: experienceUpdatedForm,
  triggerSkillsUpdate: skillsUpdatedForm,
  triggerCreateTalentNote: createTalentNotes,
  triggerUpdateEventRole: updateTalentEventRole,
  triggerUpdateSkills: updateNewTalentData,
  triggerTalentNotesList: getTalentNotes,
  triggerDeleteNote: deleteTalentNote,
  triggerUpdateNote: updateTalentNote,
  triggerGetTalentNotesDataTemplate: getTalentNotesDataTemplate,
  triggerCheckApiStatus: checkApiStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
