import sessionStorage from "../../../../utils/sessionCacheStorage";

export const radiusList = [
  {
    label: "5 miles",
    value: "5",
  },
  {
    label: "10 miles",
    value: "10",
  },
  {
    label: "20 miles",
    value: "20",
  },
  {
    label: "50 miles",
    value: "50",
  },
  {
    label: "100 miles",
    value: "100",
  },
];
export const ethnicityList = [{
  label: "Ethnicity",
  options: [
    {
      value: "native american",
      label: "Native American",
    },
    {
      value: "asian",
      label: "Asian",
    },
    {
      value: "black",
      label: "Black / African American",
    },
    {
      value: "hispanic",
      label: "Hispanic / Latino",
    },
    {
      value: "hawaiian",
      label: "Native Hawaiian",
    },
    {
      value: "pacific islander",
      label: "Other Pacific Islander",
    },
    {
      value: "middle eastern",
      label: "Middle Eastern",
    },
    {
      value: "mixed",
      label: "Mixed",
    },
    {
      value: "white",
      label: "White",
    },
  ]
}];
export const genderList = [{
  label: "Gender",
  options: [
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Other",
      value: "other_gender",
    },
  ]
}];
export const minHeightFeetList = [
  {
    value: "",
    label: "Feet'",
  },
  {
    value: "3",
    label: "3'",
  },
  {
    value: "4",
    label: "4'",
  },
  {
    value: "5",
    label: "5'",
  },
  {
    value: "6",
    label: "6'",
  },
  {
    value: "7",
    label: "7'",
  },
  {
    value: "8",
    label: "8'",
  },
];
export const maxHeightFeetList = [
  {
    value: "",
    label: "Feet'",
  },
  {
    value: "5",
    label: "5'",
  },
  {
    value: "6",
    label: "6'",
  },
  {
    value: "7",
    label: "7'",
  },
  {
    value: "8",
    label: "8'",
  },
];
export const inchesList = [
  {
    value: "",
    label: 'Inches"',
  },
  {
    value: "0",
    label: '0"',
  },
  {
    value: "1",
    label: '1"',
  },
  {
    value: "2",
    label: '2"',
  },
  {
    value: "3",
    label: '3"',
  },
  {
    value: "4",
    label: '4"',
  },
  {
    value: "5",
    label: '5"',
  },
  {
    value: "6",
    label: '6"',
  },
  {
    value: "7",
    label: '7"',
  },
  {
    value: "8",
    label: '8"',
  },
  {
    value: "9",
    label: '9"',
  },
  {
    value: "10",
    label: '10"',
  },
  {
    value: "11",
    label: '11"',
  },
];
export const eyeColorList = [
  {
    label: "Eye Color",
    options: [
      {
        value: "brown",
        label: "Brown",
      },
      {
        value: "blue",
        label: "Blue",
      },
      {
        value: "hazel",
        label: "Hazel",
      },
      {
        value: "amber",
        label: "Amber",
      },
      {
        value: "green",
        label: "Green",
      },
      {
        value: "gray",
        label: "Gray",
      },
      {
        value: "heterochromia",
        label: "Heterochromia",
      },
    ],
  },
];
export const hairColorList = [
  {
    label: "Hair Color",
    options: [
      {
        value: "black",
        label: "Black",
      },
      {
        value: "brown",
        label: "Brown",
      },
      {
        value: "blonde",
        label: "Blonde",
      },
      {
        value: "white",
        label: "White",
      },
      {
        value: "gray",
        label: "Gray",
      },
      {
        value: "red",
        label: "Red",
      },
      {
        value: "multi-color",
        label: "Multi-Color",
      },
    ],
  },
];
export const uuidMapping = {
  age: "6312e98e-96a1-11ed-8d77-00163e896a87",
  weight: "630c30ad-96a1-11ed-8d77-00163e896a87",
  gender: "63133663-96a1-11ed-8d77-00163e896a87",
  eyeColor: "36286c41-c89f-40ec-9843-62cfb82f8075",
  hairColor: "ca6c76f2-24b7-478a-963d-af3731bf55dc",
  roles: "roles",
  skills: "skills",
  height: "6302241b-96a1-11ed-8d77-00163e896a87",
  ethnicity: "0e9d1231-b39a-4f90-8f79-8311009ccf75",
  zipCode: "zipCode",
  radius: "radius",
  rating: "rating",
};
export const lookupList = [
  {
    uuid: "6312e98e-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `Age is between ${value.start_value} and ${value.end_value}`,
  },
  {
    uuid: "630c30ad-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `Weight is between ${value.start_value} lbs and ${value.end_value} lbs`,
  },
  {
    uuid: "63133663-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `Gender is ${value.join(", ")}`,
  },
  {
    uuid: "36286c41-c89f-40ec-9843-62cfb82f8075",
    tag: (value) => `Eye color is ${value.join(", ")}`,
  },
  {
    uuid: "ca6c76f2-24b7-478a-963d-af3731bf55dc",
    tag: (value) => `Hair color is ${value.join(", ")}`,
  },
  {
    uuid: "roles",
    tag: (value) => `Roles: ${value.join(",")}`,
  },
  {
    uuid: "skills",
    tag: (value) => `Skills: ${value.join(",")}`,
  },
  {
    uuid: "6302241b-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `Height is between ${value.start_value} and ${value.end_value}`,
  },
  {
    uuid: "0e9d1231-b39a-4f90-8f79-8311009ccf75",
    tag: (value) => `Ethnicity is ${value.join(", ")}`,
  },
  {
    uuid: "state",
    tag: (value) => `State is ${value.stateValue}`,
  },
  {
    uuid: "city",
    tag: (value) => `State is ${value.stateValue} City is ${value.cityValue}`,
  },
  {
    uuid: "radius",
    tag: (value) => ` Zip is ${value.zipValue} Radius is ${value.radiusValue} miles`,
  },
  {
    uuid: "rating",
    tag: (value) => (value.start_value > 0 && !value.include_unrated ? `Rating is between ${value.start_value} and ${value.end_value}` : value.start_value > 0 && value.include_unrated ? `Rating is between ${value.start_value} and ${value.end_value} & Includes Unrated` : `Rating is Unrated` ),
  },
];

export const unCategorizedLookupList = [
  {
    uuid: "6312e98e-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `${value.start_value} - ${value.end_value} yo`,
  },
  {
    uuid: "630c30ad-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `${value.start_value} - ${value.end_value} lb`,
  },
  {
    uuid: "63133663-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `${value.join(", ")}`,
  },
  {
    uuid: "36286c41-c89f-40ec-9843-62cfb82f8075",
    tag: (value) => `Eye: ${value.join(", ")}`,
  },
  {
    uuid: "ca6c76f2-24b7-478a-963d-af3731bf55dc",
    tag: (value) => `Hair: ${value.join(", ")}`,
  },
  {
    uuid: "6302241b-96a1-11ed-8d77-00163e896a87",
    tag: (value) => `${parseInt(value?.start_value / 12)}'${parseInt(value?.start_value % 12)}" - ${parseInt(value?.end_value / 12)}'${parseInt(value?.end_value % 12)}"` ,
  },
  {
    uuid: "0e9d1231-b39a-4f90-8f79-8311009ccf75",
    tag: (value) => `${value.join(", ")}`,
  }
];

export const lookupListMarkup = [
  {
    uuid: "6312e98e-96a1-11ed-8d77-00163e896a87",
    tag: (value) => (
      <span className="mr-1">
        Age is between{" "}
        <span className="text-[#2400FF]">{value.start_value}</span> and{" "}
        <span className="text-[#2400FF]">{value.end_value}</span>
      </span>
    ),
  },
  {
    uuid: "630c30ad-96a1-11ed-8d77-00163e896a87",
    tag: (value) => (
      <span className="mr-1">
        Weight is between{" "}
        <span className="text-[#2400FF]">{value.start_value} lbs</span> and{" "}
        <span className="text-[#2400FF]">{value.end_value} lbs</span>
      </span>
    ),
  },
  {
    uuid: "63133663-96a1-11ed-8d77-00163e896a87",
    tag: (value) => (
      <span className="mr-1">
        Gender is <span className="text-[#2400FF] capitalize">{value.join(", ")}</span>
      </span>
    ),
  },
  {
    uuid: "36286c41-c89f-40ec-9843-62cfb82f8075",
    tag: (value) => (
      <span className="mr-1">
        Eye color is <span className="text-[#2400FF] capitalize">{value.join(", ")}</span>
      </span>
    ),
  },
  {
    uuid: "ca6c76f2-24b7-478a-963d-af3731bf55dc",
    tag: (value) => (
      <span className="mr-1">
        Hair color is <span className="text-[#2400FF] capitalize">{value.join(", ")}</span>
      </span>
    ),
  },
  {
    uuid: "roles",
    tag: (value) => (
      <>
        Roles: <span className="text-[#2400FF]">{value.join(",")}</span>
      </>
    ),
  },
  {
    uuid: "skills",
    tag: (value) => (
      <>
        Skills: <span className="text-[#2400FF]">{value.join(",")}</span>
      </>
    ),
  },
  {
    uuid: "6302241b-96a1-11ed-8d77-00163e896a87",
    tag: (value) => (
      <span className="mr-1">
        Height is between{" "}
        <span className="text-[#2400FF]">{value.start_value}</span> and{" "}
        <span className="text-[#2400FF]">{value.end_value}</span>
      </span>
    ),
  },
  {
    uuid: "0e9d1231-b39a-4f90-8f79-8311009ccf75",
    tag: (value) => (
      <span className="mr-1">
        Ethnicity is <span className="text-[#2400FF] capitalize">{value.join(", ")}</span>
      </span>
    ),
  },
  {
    uuid: "city",
    tag: (value) => (
      <>
        State is <span className="text-[#2400FF]">{value.stateValue}</span> City
        is <span className="text-[#2400FF]">{value.cityValue}</span>
      </>
    ),
  },
  {
    uuid: "radius",
    tag: (value) => (
      <>
        Zip is <span className="text-[#2400FF]">{value.zipValue}</span> Radius
        is <span className="text-[#2400FF]">{value.radiusValue} miles</span>
      </>
    ),
  },
  {
    uuid: "rating",
    tag: (value) => (<>
      {value.start_value > 0 ? <>
       Rating is between{" "}
        <span className="text-[#2400FF]">{value.start_value}</span> and{" "}
        <span className="text-[#2400FF]">{value.end_value}</span>
        {value.include_unrated && <span> & Includes Unrated</span>}
      </> : <>Rating is <span className="text-[#2400FF]">Unrated</span></> }
      </>),
  },
];

export const defaultProfile =
  "/static/media/avatar_04.606cef8affa5c9610ebd.webp";

export const personTypeUuids = "3c823506-8adf-11ed-8643-00163e896a87";

export const setSessionValue = (key, jsonValue) => {
  if(key && jsonValue) {
    sessionStorage.set(key, jsonValue);
  }
}

export const getSessionValue = (key) => {
  if(key) {
    return sessionStorage.get(key);
  }
  return;
}

export const clearSessionValue = (key) => {
  if(key) {
    sessionStorage.clear(key);
  }
}

export const phoneNumberRegex = /^(?:\+?\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;

export const convertObjToSelectOptions = (obj) => {
  const options = []
  for (const key in obj) {
    options.push({
      label: obj[key],
      value: key
    });
  }
  return options;
}

export const checkIsTwoArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every(element => arr2.includes(element)) && arr2.every(element => arr1.includes(element));
}
