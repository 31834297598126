import { Strings, apiEndpoints, apiParams } from "../constants";
import { postRequest, getRequest, deleteRequest, putRequest } from "./requests";

const appDomain = window.location.hostname && window.location.hostname.includes('.') && window.location.hostname.split('.');
const { opus, eventsApp } = apiParams
let subDomainName = opus;
let restDomain = eventsApp;
if (appDomain && Boolean(appDomain.length)) {
  subDomainName = appDomain[0];
  restDomain = `${appDomain[1]}.${appDomain[2]}`;
}

export const getTalentsList = async (payload = {}) => {
  const { number, size, eventShiftRoleUUID='' } = payload;
  const { baseUrl, people } = apiEndpoints;
  const { listType, pageNumber, pageSize, condensedType, personTypes, excludeEventShiftRole } = apiParams;
  let talentListUrl = `${baseUrl}${people}?${personTypes}=["talent"]&${listType}=${condensedType}&${pageNumber}=${number}&${pageSize}=${size}`;
  if (eventShiftRoleUUID) {
    talentListUrl = `${talentListUrl}&${excludeEventShiftRole}=${eventShiftRoleUUID}`
  }
  try {
    const response = getRequest(talentListUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getProvincesList = async (isRegister) => {
  const { baseUrl, provinces, register } = apiEndpoints;
  const { countryISO, subDomain, domain } = apiParams
  let provinceListUrl = `${baseUrl}${provinces}?${countryISO}=${Strings.defaultProvince}`;
  if (isRegister) {
    provinceListUrl = `${baseUrl}${provinces}${register}?${countryISO}=${Strings.defaultProvince}&${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(provinceListUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEventRoles = async (isRegister) => {
  const { baseUrl, eventRoles, register } = apiEndpoints;
  const { subDomain, domain } = apiParams
  let eventRolesUrl = `${baseUrl}${eventRoles}`;
  if (isRegister) {
    eventRolesUrl = `${baseUrl}${eventRoles}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(eventRolesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilteredTalents = async (payload = {}) => {
  const { number, size, eventShiftRoleUUID='' } = payload;
  const filterPayload = { ...payload };
  delete filterPayload.number;
  delete filterPayload.size;
  delete filterPayload.eventShiftRoleUUID;

  const { baseUrl, talentFilter } = apiEndpoints;
  const { listType, pageNumber, pageSize, condensedType, excludeEventShiftRole } = apiParams;
  let filterTalentsUrl = `${baseUrl}${talentFilter}?${listType}=${condensedType}&${pageNumber}=${number}&${pageSize}=${size}`;
  if (eventShiftRoleUUID) {
    filterTalentsUrl = `${filterTalentsUrl}&${excludeEventShiftRole}=${eventShiftRoleUUID}`
  }
  try {
    const response = postRequest({
      url: filterTalentsUrl,
      payload: filterPayload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getTalentAttributes = async (isRegister) => {
  const { baseUrl, talentAttributes, register } = apiEndpoints;
  const { subDomain, domain } = apiParams
  let talentAttributesUrl = `${baseUrl}${talentAttributes}`;
  if (isRegister) {
    talentAttributesUrl = `${baseUrl}${talentAttributes}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(talentAttributesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmailAttributes = async (isRegister) => {
  const { baseUrl, emailAttributes, register } = apiEndpoints;
  const { subDomain, domain } = apiParams
  let emailAttributesUrl = `${baseUrl}${emailAttributes}`;
  if (isRegister) {
    emailAttributesUrl = `${baseUrl}${emailAttributes}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(emailAttributesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAddressAttributes = async (isRegister) => {
  const { baseUrl, addressAttributes, register } = apiEndpoints;
  const { subDomain, domain } = apiParams
  let addressAttributesUrl = `${baseUrl}${addressAttributes}`;
  if (isRegister) {
    addressAttributesUrl = `${baseUrl}${addressAttributes}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(addressAttributesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPhoneAttributes = async (isRegister) => {
  const { baseUrl, phoneAttributes, register } = apiEndpoints;
  const { subDomain, domain } = apiParams;
  let phoneAttributesUrl = `${baseUrl}${phoneAttributes}`;
  if (isRegister) {
    phoneAttributesUrl = `${baseUrl}${phoneAttributes}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(phoneAttributesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCoreAttributes = async (isRegister) => {
  const { baseUrl, talentsCoreAttributes, register } = apiEndpoints;
  const { subDomain, domain } = apiParams;
  let talentsCoreAttributesUrl = `${baseUrl}${talentsCoreAttributes}`;
  if (isRegister) {
    talentsCoreAttributesUrl = `${baseUrl}${talentsCoreAttributes}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  try {
    const response = getRequest(talentsCoreAttributesUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getTalentData = async (id) => {
  const { baseUrl, person } = apiEndpoints;
  const talentDataUrl = `${baseUrl}${person}/${id}`;
  try {
    const response = getRequest(talentDataUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const getTalentNotesData = async (id) => {
  const { baseUrl, person } = apiEndpoints;
  const talentNotesDataUrl = `${baseUrl}${person}/${id}/notes`;
  try {
    const response = getRequest(talentNotesDataUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const setAssetSecure = async (payload) => {
  const { companyId, personId, asset, isRegister } = payload;
  const { baseUrl, assetSecure, addPerson, register } = apiEndpoints;
  const { subDomain, domain } = apiParams;
  let assetSecureUrl = `${baseUrl}${assetSecure}/${companyId}${addPerson}/${personId}`;
  if (isRegister) {
    assetSecureUrl = `${baseUrl}${assetSecure}${addPerson}/${personId}${register}?${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  }
  let form = new FormData();
  form.append('asset_file', asset);
  try {
    const response = postRequest({ url: assetSecureUrl, payload: form });
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteAsset = async (companyId, assetUUID) => {
  const { baseUrl, asset } = apiEndpoints;
  const deleteAssetUrl = `${baseUrl}${asset}/${companyId}/${assetUUID}`;
  try {
    const response = deleteRequest(deleteAssetUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const deletePersonAttribute = async (uuid) => {
  const { baseUrl, personAttribute } = apiEndpoints;
  const deleteAttributeUrl = `${baseUrl}${personAttribute}/${uuid}`;
  try {
    const response = deleteRequest(deleteAttributeUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const createPerson = async (payload) => {
  const { baseUrl, person } = apiEndpoints;
  const createTalentUrl = `${baseUrl}${person}`;
  try {
    const response = postRequest({ url: createTalentUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const registerPerson = async (payload) => {
  const { baseUrl, person, register } = apiEndpoints;
  const { gRecaptcha, subDomain, domain } = apiParams;
  const { tempCreateTemplate, captcha } = payload;
  const createTalentUrl = `${baseUrl}${person}${register}?${gRecaptcha}=${captcha}&${subDomain}=${subDomainName}&${domain}=${restDomain}`;
  try {
    const response = postRequest({ url: createTalentUrl, payload: tempCreateTemplate });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateCorePersonAttributes = async (payload) => {
  const { baseUrl, person } = apiEndpoints;
  const updatePersonUrl = `${baseUrl}${person}`;
  try {
    const response = putRequest({ url: updatePersonUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updatePersonAttribute = async (payload) => {
  const { baseUrl, personAttribute } = apiEndpoints;
  const updatePersonAttribute = `${baseUrl}${personAttribute}`;
  try {
    const response = postRequest({ url: updatePersonAttribute, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateExistingPersonAttribute = async (payload) => {
  const { baseUrl, personAttribute } = apiEndpoints;
  const updatePersonAttribute = `${baseUrl}${personAttribute}`;
  try {
    const response = putRequest({ url: updatePersonAttribute, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createPhoneAttribute = async (payload) => {
  const { baseUrl, personPhone } = apiEndpoints;
  const createUrl = `${baseUrl}${personPhone}`;
  try {
    const response = postRequest({ url: createUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updatePhoneAttribute = async (payload) => {
  const { baseUrl, personPhone } = apiEndpoints;
  const updateUrl = `${baseUrl}${personPhone}`;
  try {
    const response = putRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createEmailAttribute = async (payload) => {
  const { baseUrl, personEmail } = apiEndpoints;
  const createUrl = `${baseUrl}${personEmail}`;
  try {
    const response = postRequest({ url: createUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEmailAttribute = async (payload) => {
  const { baseUrl, personEmail } = apiEndpoints;
  const updateUrl = `${baseUrl}${personEmail}`;
  try {
    const response = putRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEventRole = async (payload) => {
  const { baseUrl, personEventRole } = apiEndpoints;
  const updateUrl = `${baseUrl}${personEventRole}`;
  try {
    const response = postRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteEventRole = async (uuid) => {
  const { baseUrl, personEventRole } = apiEndpoints;
  const deleteUrl = `${baseUrl}${personEventRole}/${uuid}`;
  try {
    const response = deleteRequest(deleteUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const createAddressAttribute = async (payload) => {
  const { baseUrl, personAddress } = apiEndpoints;
  const createUrl = `${baseUrl}${personAddress}`;
  try {
    const response = postRequest({ url: createUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateAddressAttribute = async (payload) => {
  const { baseUrl, personAddress } = apiEndpoints;
  const updateUrl = `${baseUrl}${personAddress}`;
  try {
    const response = putRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createNotes = async (payload) => {
  const { baseUrl, object } = apiEndpoints;
  const createUrl = `${baseUrl}${object}/note`;
  try {
    const response = postRequest({ url: createUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteTalentNote = async (uuid) => {
  const { baseUrl, object } = apiEndpoints;
  const deleteUrl = `${baseUrl}${object}/note/${uuid}`;
  try {
    const response = deleteRequest(deleteUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateTalentNote = async (payload) => {
  const { baseUrl, object } = apiEndpoints;
  const updateUrl = `${baseUrl}${object}/note`;
  try {
    const response = putRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const checkIsPhoneValid = async (phone) => {
  const { baseUrl, phoneValidate } = apiEndpoints;
  const phoneValidUrl = `${baseUrl}${phoneValidate}/${phone}`;
  try {
    const response = getRequest(phoneValidUrl, true);
    return response;
  } catch (error) {
    return error;
  }
}


export const getPredictedPlaces = async (payload) => {
  const { input, isLoggedIn = true } = payload;
  const { baseUrl, getLocationPredictions ,register} = apiEndpoints;
  let url;
  if (isLoggedIn) {
    url = (baseUrl + getLocationPredictions + "?input="+input)
  } else { 
    url =
      baseUrl +
      getLocationPredictions +
      register +
      "?input=" +
      input +
      `&subdomain=${subDomainName}&domain=${restDomain}`;
  }
   try {
     const response = getRequest(url, true);
     return response;
   } catch (error) {
     return error;
   }
}

export const getAddressDetailFromPlaceId = async (payload) => {
  const { placeId, isLoggedIn=true } = payload;
  const { baseUrl, getAddressFromPlaceId,register, includeTzName } = apiEndpoints;
   let url;
   if (isLoggedIn) {
     url = baseUrl + getAddressFromPlaceId + "?place_id=" + placeId + `&${includeTzName}=true`;
   } else {
     url =
       baseUrl +
       getAddressFromPlaceId +
       register +
       "?place_id=" +
       placeId+`&subdomain=${subDomainName}&domain=${restDomain}` +
       `&${includeTzName}=true`
   }
  try {
    const response = getRequest(url, true);
    return response;
  } catch (error) {
    return error;
  }
};

