import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NumericInput from "../numericInput";

const FilterAge = (props) => {
  return (
    <div className="p-[12px] w-[250px]">
      <div>Select age range</div>
      <div className="grid grid-cols-2 gap-2">
        <div className="border border-[#D9D9D9] rounded py-1 px-2">
          <div className="text-[11px]">MIN</div>
          <NumericInput
            className="border-none p-0"
            value={props.ageValues.start_value}
            placeholder="Example: 18"
            maxLength={2}
            onChange={(value) => props.onMinValueChange(value)}
          />
        </div>
        <div className="border border-[#D9D9D9] rounded py-1 px-2">
          <div className="text-[11px]">MAX</div>
          <NumericInput
            className="border-none p-0"
            value={props.ageValues.end_value}
            maxLength={3}
            placeholder="Example: 45"
            onChange={(value) => props.onMaxValueChange(value)}
          />
        </div>
      </div>
      <div className="text-right">
        <Button
          disabled={props.ageValues.end_value <= props.ageValues.start_value}
          className="mt-[10px]"
          onClick={() => props.onAddFilter("age")}
          icon={<PlusOutlined className="align-baseline" />}
        >
          {props.btnContent || "Add Filter"}
        </Button>
      </div>
    </div>
  );
};
export default FilterAge;
