import { eventApiEndPoints } from "../constants/events";
import { shiftApiEndPoints } from "../constants/shifts";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

export const timezones = () => {
  const { baseUrl, timezones } = eventApiEndPoints;
  const timeZoneURL = `${baseUrl}${timezones}`;
  try {
    const response = getRequest(timeZoneURL);
    return response;
  } catch (error) {
    return error;
  }
};

export const venueAddress = () => {
  const { baseUrl, venue } = shiftApiEndPoints;
  const venueURL = `${baseUrl}${venue}`;
  try {
    const response = getRequest(venueURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEventShiftDetails = (payload) => {
  const { baseUrl, eventShifts } = eventApiEndPoints;
  const updateEventUrl = `${baseUrl}${eventShifts}`;
  try {
    const response = putRequest({ url: updateEventUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const getShifts = async (id) => {
  const { baseUrl, event, shifts } = shiftApiEndPoints;
  const shiftsURL = `${baseUrl}${event}/${id}${shifts}`;
  try {
    const response = getRequest(shiftsURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const createEventShift = (payload) => {
  const { baseUrl, eventShifts } = eventApiEndPoints;
  const createShiftURL = `${baseUrl}${eventShifts}`;
  try {
    const response = postRequest({ url: createShiftURL, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const cloneShiftRole = async (id) => {
  const { baseUrl, event, role, clone } = shiftApiEndPoints;
  try {
    const cloneURL = `${baseUrl}${event}${role}/${id}${clone}`;
    const response = postRequest({ url: cloneURL });
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteShiftRole = async (id) => {
  const { baseUrl, event, role } = shiftApiEndPoints;
  try {
    const deleteURL = `${baseUrl}${event}${role}/${id}`;
    const response = deleteRequest(deleteURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteShift = async (id) => {
  const { baseUrl, event, shift } = shiftApiEndPoints;
  try {
    const deleteURL = `${baseUrl}${event}${shift}/${id}`;
    const response = deleteRequest(deleteURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getShift = async (id) => {
  const { baseUrl, event, shift } = shiftApiEndPoints;
  const shiftsURL = `${baseUrl}${event}${shift}/${id}`;
  try {
    const response = getRequest(shiftsURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const createRole = async (payload) => {
  const { baseUrl, event, role } = shiftApiEndPoints;
  const createURL = `${baseUrl}${event}${role}`;
  try {
    const response = postRequest({ url: createURL, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateRole = async (payload) => {
  const { baseUrl, event, role } = shiftApiEndPoints;
  const updateURL = `${baseUrl}${event}${role}`;
  try {
    const response = putRequest({ url: updateURL, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const addPerson = async (payload) => {
  const { baseUrl, event, role, person} = shiftApiEndPoints;
  const addURL = `${baseUrl}${event}${role}${person}`;
  try {
    const response = postRequest({ url: addURL, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const updatePerson = async (payload) => {
  const { baseUrl, event, role, person} = shiftApiEndPoints;
  const addURL = `${baseUrl}${event}${role}${person}`;
  try {
    const response = putRequest({ url: addURL, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const deletePerson = async (id) => {
  const { baseUrl, event, role, person} = shiftApiEndPoints;
  const deleteURL = `${baseUrl}${event}${role}${person}/${id}`;
  try {
    const response = deleteRequest(deleteURL);
    return response;
  } catch (error) {
    return error;
  }
}
