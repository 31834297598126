import { takeLatest, call, put, all, select } from "redux-saga/effects";
import moment from "moment";

import actionTypes from "../types";
import {
  getTalentsList,
  getProvincesList,
  getEventRoles,
  getFilteredTalents,
  getTalentAttributes,
  getEmailAttributes,
  getAddressAttributes,
  getPhoneAttributes,
  getCoreAttributes,
  getTalentData,
  setAssetSecure,
  createPerson,
  updateCorePersonAttributes,
  updatePersonAttribute,
  updateExistingPersonAttribute,
  createPhoneAttribute,
  updatePhoneAttribute,
  createEmailAttribute,
  updateEmailAttribute,
  updateEventRole,
  deleteEventRole,
  createAddressAttribute,
  updateAddressAttribute,
  deleteAsset,
  deletePersonAttribute,
  getTalentNotesData,
  registerPerson,
  createNotes,
  deleteTalentNote,
  updateTalentNote,
  checkIsPhoneValid,
  getPredictedPlaces,
  getAddressDetailFromPlaceId
} from "../../api/talent";
import {
  getTalentsListError, getTalentsListSuccess,
  getProvincesSuccess, getProvincesError,
  getEventRolesError, getEventRolesSuccess,
  getFilteredTalentsListSuccess, getFilteredTalentsListError,
  clearFilteredTalentsListSuccess,
  getAttributesSuccess, getAttributesError,
  getEmailUUIDSuccess, getAddressUUIDSuccess, getPhoneUUIDSuccess,
  getTalentCoreAttributesSuccess, getTalentCoreAttributesError, 
  personalInfoFormTemplate, contactFormTemplate, emergencyContactFormTemplate,
  experienceFormTemplate, skillsFormTemplate,
  personalInfoUpdatedForm, contactUpdatedForm, emergencyContactUpdatedForm, 
  experienceUpdatedForm, skillsUpdatedForm, photoUserUpdated, getTalentNotesDataTemplate, updateOtherAttributesSuccess, addressFieldTemplate
} from "../actions/talents";
import { FormCategories, PERSON_ATTR_UUID, RoutingConstants, Strings, addAddressData, addEmailData, addEmergencyPhone, addPhone, addressFields, addressTypes, contactFields, createTalentTemplate, emailTypes, emergencyContactFields, experienceFields, languageLabel, personalInfoUncategorizedLabels, phoneTypes, skillFields, skillsCategorizedLabels } from "../../constants";
import spliceIntoChunks from "../../globalFunctions/splitArrayIntoChunks";
import { objToArrayOfVal } from "../../globalFunctions/convertObjToArrayOfVal";
import { personalInfoCoreFields } from "../../constants";
import { bioField } from "../../constants";
import _ from "lodash";
import getKeyByValue from "../../globalFunctions/getKeyByValue";
import getDateInYearFormat from "../../globalFunctions/getDateInYearFormat";
import { createNewTalentSuccess } from "../actions/talents";
import { createNewTalentError } from "../actions/talents";
import { logOut } from "../actions/auth";
import { logoutUser } from "./auth";

const {
  GET_TALENTS_LIST,
  GET_PROVINCES,
  GET_EVENT_ROLES,
  GET_FILTERED_TALENTS_LIST,
  CLEAR_FILTERED_TALENTS_LIST,
  GET_ATTRIBUTES,
  GET_EMAIL_UUID,
  GET_ADDRESS_UUID,
  GET_PHONE_UUID,
  GET_TALENTS_CORE_ATTRIBUTES,
  TALENT_DATA,
  UPLOAD_NEW_USER_IMAGE,
  CREATE_NEW_TALENT,
  UPDATE_CORE_ATTRIBUTE,
  UPDATE_EXISTING_TALENT_DATA,
  UPDATE_NEW_TALENT_DATA,
  CREATE_TALENT_PHONE_ATTR, UPDATE_TALENT_PHONE_ATTR,
  CREATE_TALENT_EMAIL_ATTR, UPDATE_TALENT_EMAIL_ATTR,
  UPDATE_TALENT_EVENT_ROLE, DELETE_TALENT_EVENT_ATTR,
  CREATE_TALENT_ADDRESS_ATTR, UPDATE_TALENT_ADDRESS_ATTR,
  DELETE_EXISTING_USER_IMAGE, UPDATE_ASSET_PRIMARY, DELETE_TALENT_ATTR,
  GET_TALENT_NOTES_DATA, CREATE_TALENT_NOTES, DELETE_TALENT_NOTE,
  UPDATE_OTHER_ATTRIBUTES, UPDATE_TALENT_NOTE, CHECK_PHONE_NUMBER_VALIDITY, CHECK_CONTACT_NUMBER_VALIDITY,
  GET_GMAP_ADDRESS_PREDICTIONS, GET_ADDRESS_BY_PLACE_ID
} = actionTypes.talents;

const { CHECK_API_STATUS } = actionTypes.events;

export const statePhoneAttributes = (state) => state.talent.phoneAttributes;
export const stateEmailAttributes = (state) => state.talent.emailAttributes;
export const stateAddressAttributes = (state) => state.talent.addressAttributes;
export const stateEmergencyRelationshipOptions = (state) => state.talent.emergencyRelationship;
export const stateEmergencyRelationOptions = (state) => state.talent.emergencyRelationOptions;
export const stateProvincesList = (state) => state.talent.provinces;
export const statePersonalInfoUpdated = (state) => state.talent.isPersonalInfoUpdated;
export const statePersonalInfoUserUpdated = (state) => state.talent.personalInfoUserUpdated;
export const statePersonalInfoTemplate = (state) => state.talent.personalInfoTemplate;
export const stateContactUpdated = (state) => state.talent.isContactUpdated;
export const statecontactUserUpdated = (state) => state.talent.contactUserUpdated;
export const statecontactTemplate = (state) => state.talent.contactTemplate;
export const stateEmergencyContactUpdated = (state) => state.talent.isEmergencyContactUpdated;
export const stateemergencyContactUserUpdated = (state) => state.talent.emergencyContactUserUpdated;
export const stateemergencyContactTemplate = (state) => state.talent.emergencyContactTemplate;
export const stateExperienceUpdated = (state) => state.talent.isExperienceUpdated;
export const stateexperienceUserUpdated = (state) => state.talent.experienceUserUpdated;
export const stateexperienceTemplate = (state) => state.talent.experienceTemplate;
export const stateSkillsUpdated = (state) => state.talent.isSkillsUpdated;
export const stateskillsUserUpdated = (state) => state.talent.skillsUserUpdated;
export const stateskillsTemplate = (state) => state.talent.skillsTemplate;
export const stateProvinces = (state) =>  state.talent.provinces;
export const stateNewTalentId = (state) => state.talent.newTalentId;
export const statePhotoUUID = (state) => state.talent.photoUUID;
export const statePhotos = (state) => state.talent.photos;
export const stateNotes = (state) => state.talent.notesData;

export function* checkApiStatus(action) {
  const { payload } = action;
  if(payload?.response) yield call(verifyApiStatus, payload?.response);
  else yield call(verifyApiStatus, payload);
}

export function* verifyApiStatus(response) {
  if (response?.status === 401) {
    yield call(logoutUser)
  }
}

export function* fetchTalentList(action) {
  const { payload } = action;
  const { onSuccess, onError, ...rest } = payload;
  try {
    const response = yield call(getTalentsList, rest);

    yield call(verifyApiStatus, response);

    const talentsListData = response?.data;
    const totalItemCount = response?.headers['total-item-count']
    const pageSize = response?.headers['page-size']
    const totalPageCount = response?.headers['total-page-count']
    const responsePagenumber = response?.headers['response-page-number']
    yield put(getTalentsListSuccess({ talentsListData, totalItemCount, pageSize, totalPageCount, responsePagenumber }));

    onSuccess();
  } catch (error) {
    onError(error.message);
    yield put(getTalentsListError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchProvinces(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    const response = yield call(getProvincesList, isRegister);
    yield call(verifyApiStatus, response);

    const provincesList = response?.data;
    yield put(getProvincesSuccess({ provincesList }));
    return provincesList;
  } catch (error) {
    yield put(getProvincesError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchEventRoles(action) {
  const { payload = {} } = action;
  const { onSuccess = () => {}, onError = () => {}, isRegister = false } = payload;
  try {
    const response = yield call(getEventRoles, isRegister);
    yield call(verifyApiStatus, response);

    const eventRolesList = response?.data;

    let tempExperienceFields = experienceFields;

    tempExperienceFields.forEach(field => {
      let options = [];
      if (eventRolesList[field.id]) {
        eventRolesList[field.id].event_roles.forEach(role => {
          options.push({'uuid': role.uuid, 'name': role.name})
        })
      }
      field.options = options;
    })

    yield put(experienceFormTemplate({ formTemplate: tempExperienceFields }));
    yield put(getEventRolesSuccess({ eventRolesList }));
    onSuccess();
    return eventRolesList;
  } catch (error) {
    onError(error.message);
    yield put(getEventRolesError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchfilteredTalents(action) {
  const { payload } = action;
  const { onSuccess, onError, filterPayload } = payload;
  try {
    const response = yield call(getFilteredTalents, filterPayload);
    yield call(verifyApiStatus, response);

    const filteredTalentsList = response?.data;
    const totalItemCount = response?.headers['total-item-count']
    const pageSize = response?.headers['page-size']
    const totalPageCount = response?.headers['total-page-count']
    const responsePagenumber = response?.headers['response-page-number']
    yield put(getFilteredTalentsListSuccess({ filteredTalentsList, totalItemCount, pageSize, totalPageCount, responsePagenumber }));
    onSuccess();
  } catch (error) {
    onError(error.message);
    yield put(getFilteredTalentsListError({}));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* clearFilteredTalents() {
  yield put(clearFilteredTalentsListSuccess())
}

export function* fetchAttributes(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    const response = yield call(getTalentAttributes, isRegister);
    yield call(verifyApiStatus, response);

    const attributes = response?.data;
    // extracting personal info form uncategorized fields data
    let personalInfoUncategorized = [];
    let companyUUID = '';
    personalInfoUncategorizedLabels.forEach(label => {
      const labelData = attributes && attributes?.physical && attributes?.physical['uncategorized'] && attributes?.physical['uncategorized'].find(attribute => attribute?.person_attribute?.label === label);
      const tempPersonalInfo = {
        value: '',
        optionSelection: ''
      }
      tempPersonalInfo['uuid'] = labelData?.person_attribute?.uuid;
      tempPersonalInfo['minAllowed'] = labelData?.min_number_size;
      tempPersonalInfo['label'] = label;
      tempPersonalInfo['maxAllowed'] = labelData?.max_number_size || 2048;
      tempPersonalInfo['required'] = labelData?.required;
      if (!labelData?.person_attribute?.options_list && labelData?.person_attribute?.component_type === 'float') {
        tempPersonalInfo['type'] = 'float'
      } else {
        tempPersonalInfo['type'] = 'string'
      }
      if (label === Strings.height) {
        tempPersonalInfo['ft'] = null;
        tempPersonalInfo['inch'] = null;
      }
      if (labelData?.person_attribute?.options_list) {
        tempPersonalInfo['options'] = objToArrayOfVal(labelData?.person_attribute?.options_list);
        if (label === Strings.socialMedia) {
          tempPersonalInfo['options'] = ['Select Channel'].concat(tempPersonalInfo['options']);
        } else {
          tempPersonalInfo['options'] = [labelData?.person_attribute?.edit_placeholder].concat(tempPersonalInfo['options']);
        }
        tempPersonalInfo['optionsList'] = labelData?.person_attribute?.options_list;
      }
      tempPersonalInfo['placeholder'] = labelData?.person_attribute?.edit_placeholder;
      companyUUID = labelData?.person_attribute?.company;
      return personalInfoUncategorized.push(tempPersonalInfo);
    })
    
    personalInfoUncategorized = spliceIntoChunks(personalInfoUncategorized, 3);

    let formTemplate = {}
    formTemplate[FormCategories.core] = personalInfoCoreFields;
    formTemplate[FormCategories.bio] = bioField;
    formTemplate[FormCategories.uncategorized] = personalInfoUncategorized;
    yield put(personalInfoFormTemplate({ formTemplate }))

    let photoUUID = '';
    // extracting photo uuid
    const photoAttribute = attributes && attributes?.photo && attributes?.photo['uncategorized'] && attributes.photo['uncategorized'].find(attribute => attribute?.person_attribute?.label === Strings.photoLabel)
    photoUUID = photoAttribute?.person_attribute?.uuid;
    // extracting all skills label and uuid
    let skillCategorized = [];
    let tempSkillFields = skillFields;
    let tempSkillOptions = [];
    let tempLanguageOptions = [];

    skillsCategorizedLabels.forEach(label => attributes?.skill?.categorized[label]?.items.forEach(skill => {
      let tempSkillInfo = {}
      tempSkillInfo[skill?.person_attribute?.label] = skill?.person_attribute?.uuid;
      tempSkillOptions.push({'uuid': skill?.person_attribute?.uuid, 'name': skill?.person_attribute?.label});
      return skillCategorized.push(tempSkillInfo);
    }))
    // extracting language skills label and uuid
    let languageCategorized = [];
    attributes?.skill && attributes?.skill?.categorized && attributes?.skill?.categorized[languageLabel] && attributes?.skill?.categorized[languageLabel]?.items && 
    attributes?.skill?.categorized[languageLabel]?.items.forEach(language => {
      let tempLangInfo = {}
      tempLangInfo[language?.person_attribute?.label] = language?.person_attribute?.uuid;
      tempLanguageOptions.push({'uuid': language?.person_attribute?.uuid, 'name': language?.person_attribute?.label});
      return languageCategorized.push(tempLangInfo);
    })

    tempSkillFields.forEach(field => {
      if (field.label === Strings.skills) {
        field.options = tempSkillOptions;
      }
      if (field.label === Strings.languages) {
        field.options = tempLanguageOptions;
      }
    })

    yield put(skillsFormTemplate({ formTemplate: tempSkillFields }));
    yield put(getAttributesSuccess({ photoUUID, companyUUID, skillCategorized, languageCategorized }));
  } catch (error) {
    console.log('error in fetch attributes', error);
    yield put(getAttributesError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchEmailAttributes(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    const response = yield call(getEmailAttributes, isRegister);
    yield call(verifyApiStatus, response);

    
    let emailAttributeList = [];
    const emailAttributes = response?.data;
    emailTypes.forEach(type => {
      const emailAttribute = emailAttributes && Boolean(emailAttributes.length) && emailAttributes.find(attribute => attribute.name === type)
      let tempEmailAttribute = {}
      tempEmailAttribute[emailAttribute.name] = emailAttribute.uuid;
      return emailAttributeList.push(tempEmailAttribute);
    }) 
    yield put(getEmailUUIDSuccess({ emailAttributeList }));
    return emailAttributeList;
  } catch (error) {
    console.log('error on fetching email attributes', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchAddressAttributes(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    const response = yield call(getAddressAttributes, isRegister);
    yield call(verifyApiStatus, response);
    
    let addressAttributeList = []
    const addressAttributes = response?.data;
    addressTypes.forEach(type => {
      const addressAttribute = addressAttributes && Boolean(addressAttributes.length) && addressAttributes.find(attribute => attribute.name === type)
      let tempAddressAttribute = {}
      tempAddressAttribute[addressAttribute.name] = addressAttribute.uuid;
      return addressAttributeList.push(tempAddressAttribute);
    }) 
    yield put(getAddressUUIDSuccess({ addressAttributeList }));
    return addressAttributeList;
  } catch (error) {
    console.log('error on fetching email attributes', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchPhoneAttributes(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    let emailAttributes = yield call(fetchEmailAttributes, action);
    let addressAttributes = yield call(fetchAddressAttributes, action);
    let provincesList = yield call(fetchProvinces, action);
    yield call(fetchEventRoles, action);

    const response = yield call(getPhoneAttributes, isRegister);
    yield call(verifyApiStatus, response);
    
    let phoneAttributeList = []
    const phoneAttributes = response?.data;
    let tempContactFields = contactFields;
    let tempEmergencyContactFields = emergencyContactFields;
    let emergencyRelationshipOptions = yield select(stateEmergencyRelationshipOptions);
    let emergencyRelationOptionsList = yield select(stateEmergencyRelationOptions);
    phoneTypes.forEach(type => {
      const phoneAttribute = phoneAttributes && Boolean(phoneAttributes.length) && phoneAttributes.find(attribute => attribute.name === type)
      let tempPhoneAttribute = {}
      tempPhoneAttribute[phoneAttribute.name] = phoneAttribute.uuid;
      if (phoneAttribute.name === Strings.home) {
        tempContactFields.forEach(contactField => {
          if (contactField.label === Strings.contactNumber) {
            contactField.id = phoneAttribute.uuid
          }
        })
      }
      if (phoneAttribute.name === Strings.other) {
        tempEmergencyContactFields.forEach(contactField => {
          if (contactField.label === Strings.emergencyContactNumber) {
            contactField.id = phoneAttribute.uuid
          }
          if (contactField.label === Strings.emergencyContactRelations) {
            contactField.options = emergencyRelationshipOptions;
            contactField.optionsList = emergencyRelationOptionsList;
          }
        })
      }
      return phoneAttributeList.push(tempPhoneAttribute);
    })

    tempContactFields.forEach(contactField => {
      if (contactField.label === Strings.email) {
        emailAttributes.forEach(attribute => {
          if (attribute[Strings.home]) {
            contactField.id = attribute[Strings.home];
          }
        })
      }
    })
    
    let updatedAddressFields = addressFields;
    updatedAddressFields.forEach(addressField => {
      addressField.forEach(field => {
        if (field.options && field.showProvince) {
          let provinces = []
          provincesList.forEach(option => provinces.push(option.name));
          field.options = ['SELECT STATE'].concat(provinces);
        }
      })
    })

    let addressTemplate = {
      addressFields: _.cloneDeep(updatedAddressFields),
      id: '',
      title: '',
      subTitle: '',
      uuid: ''
    }

    let residentialAddress = {
      addressFields: _.cloneDeep(updatedAddressFields),
      id: '',
      title: Strings.residentialTitle,
      subTitle: '',
      uuid: ''
    }

    let mailingAddress = {
      addressFields: _.cloneDeep(updatedAddressFields),
      id: '',
      title: `${Strings.mailingTitle} (${Strings.mailingSubTitle})`,
      subTitle: "",
      uuid: ''
    }

    addressAttributes.forEach(address => {
      if (address[Strings.residential]) {
        residentialAddress['id'] = address[Strings.residential]
      }
      if (address[Strings.mailing]) {
        mailingAddress['id'] = address[Strings.mailing]
      }
    })

    let contactForm = {};
    contactForm[FormCategories.contact] = tempContactFields;
    contactForm[FormCategories.residentialAddress] = residentialAddress;
    contactForm[FormCategories.mailingAddress] = mailingAddress;

    let emergencyContactForm = {};
    emergencyContactForm[FormCategories.contact] = tempEmergencyContactFields;

    yield put(contactFormTemplate({ formTemplate: contactForm }));
    yield put(addressFieldTemplate({ addressTemplate: addressTemplate }));
    yield put(emergencyContactFormTemplate({ formTemplate: emergencyContactForm }));
    yield put(getPhoneUUIDSuccess({ phoneAttributeList }));
  } catch (error) {
    console.log('error on fetching phone attributes', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchCoreAttributes(action) {
  const { payload = {} } = action;
  const { isRegister = false } = payload;
  try {
    const response = yield call(getCoreAttributes, isRegister);
    yield call(verifyApiStatus, response);
    
    const coreAttributes = response?.data;
    let emergencyRelationshipOptions = objToArrayOfVal(coreAttributes?.person?.fields?.emergency_relationship?.options_list);
    emergencyRelationshipOptions = ['Select relationship'].concat(emergencyRelationshipOptions);
    const emergencyRelationOptionList = coreAttributes?.person?.fields?.emergency_relationship?.options_list;
    yield put(getTalentCoreAttributesSuccess({ emergencyRelationshipOptions: emergencyRelationshipOptions, emergencyRelationOptionList: emergencyRelationOptionList }));
  } catch (error) {
    console.log('error on fetching core attributes', error);
    yield put(getTalentCoreAttributesError({}));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* setPersonalInfo(talentData) {
  const personalInfoUpdated = yield select(statePersonalInfoUpdated);
  const personalInfoUserUpdated = yield select(statePersonalInfoUserUpdated);
  const personalInfoTemplate = yield select(statePersonalInfoTemplate);

  let template = _.cloneDeep(personalInfoTemplate);

  if (personalInfoUpdated) {
    template = _.cloneDeep(personalInfoUserUpdated);
  }
  const uncategorized = template[FormCategories.uncategorized]
  const core = template[FormCategories.core]
  const bio = template[FormCategories.bio]

  uncategorized.forEach(formFields => {
    formFields.forEach(field => {
      const labelData = talentData?.person_attributes && talentData?.person_attributes?.physical && Boolean(talentData?.person_attributes?.physical.length) && talentData?.person_attributes?.physical.find(uncategorized => uncategorized?.person_attribute?.uuid === field?.uuid);
      if (labelData) {
        field.uuid = (labelData.value || labelData.optionSelection) && labelData?.uuid
        if (field.label === Strings.height) {
          field.ft = labelData.value / 12;
          field.inch = labelData.value % 12;
        }
        if (field.options && labelData.option_selection) {
          const selectedOption = labelData?.person_attribute?.options_list[labelData?.option_selection];
          field.optionSelection = selectedOption;
        }
        field.value = labelData.value;
      }
    })
  })

  const firstName = talentData?.first_name;
  const lastName = talentData?.last_name;
  const stageName = talentData?.preferred_name;

  core.forEach(field => {
    if (field.id === Strings.firstName) {
      field.value = firstName
    }
    if (field.id === Strings.lastName) {
      field.value = lastName
    }
    if (field.id === Strings.stageName) {
      field.value = stageName
    }
  })

  if (talentData?.bio) {
    bio.value = talentData?.bio;
  }

  let personalInfo = {}
  personalInfo[FormCategories.core] = core;
  personalInfo[FormCategories.bio] = bio;
  personalInfo[FormCategories.uncategorized] = uncategorized;
  yield put(personalInfoUpdatedForm({ personalInfo }))
  yield put(updateOtherAttributesSuccess({ starRating: talentData?.company_rating, payrollId: talentData?.payroll_reference }))
}

export function* setContactInfo(talentData) {
  const contactUpdated = yield select(stateContactUpdated);
  const contactUserUpdated = yield select(statecontactUserUpdated);
  const contactTemplate = yield select(statecontactTemplate);

  let template = _.cloneDeep(contactTemplate);

  if (contactUpdated) {
    template = _.cloneDeep(contactUserUpdated);
  }
  const contact = template[FormCategories.contact]
  const residentialAddress = template[FormCategories.residentialAddress]
  const mailingAddress = template[FormCategories.mailingAddress]

  contact.forEach(field => {
    if (field.label === Strings.contactNumber) {
      const phoneNumber = talentData?.primary_phone_number?.formatted_phone_number;
      field.value = phoneNumber;
      if (phoneNumber) { field.id = talentData?.primary_phone_number?.uuid }
    }
    if (field.label === Strings.email) {
      const emailAddress = talentData?.primary_email?.email_address;
      field.value = emailAddress;
      if (emailAddress) { field.id = talentData?.primary_email?.uuid }
    }
  })

  const residentialData = talentData?.addresses && Boolean(talentData.addresses.length) && talentData?.addresses.find(field => field?.address_type?.uuid === residentialAddress.id);
  if (residentialData) {
    residentialAddress['uuid'] = residentialData?.uuid;
    residentialAddress.addressFields.forEach(fields => {
      fields.forEach(field => {
        field.value = residentialData[field.id];
        if (field.label === Strings.state) {
          field.optionSelection = residentialData?.state_province?.name;
        }
      })
    })
    residentialAddress["lat"] = residentialData?.lat;
    residentialAddress["long"] = residentialData?.long;
    residentialAddress["map_lookup_ref"] = residentialData?.map_lookup_ref;
    residentialAddress["tz_name"] = residentialData?.tz_name;

    const mailingData = talentData?.addresses && Boolean(talentData.addresses.length) && talentData?.addresses.find(field => field?.address_type?.uuid === mailingAddress.id);
    if (mailingData) {
      mailingAddress['uuid'] = mailingData?.uuid;
      mailingAddress.addressFields.forEach(fields => {
        fields.forEach(field => {
          field.value = mailingData[field.id];
          if (field.label === Strings.state) {
            field.optionSelection = mailingData?.state_province?.name;
          }
        })
      })
      mailingAddress["lat"] = mailingData?.lat;
      mailingAddress["long"] = mailingData?.long;
      mailingAddress["map_lookup_ref"] = mailingData?.map_lookup_ref;
      mailingAddress["tz_name"] = mailingData?.tz_name;
    }
  }

  let contactForm = {};
  contactForm[FormCategories.contact] = contact;
  contactForm[FormCategories.residentialAddress] = residentialAddress;
  contactForm[FormCategories.mailingAddress] = mailingAddress;

  yield put(contactUpdatedForm({ contact: contactForm }));
}

export function* setEmergencyContactInfo(talentData) {
  const emergencyContactUpdated = yield select(stateEmergencyContactUpdated);
  const emergencyContactUserUpdated = yield select(stateemergencyContactUserUpdated);
  const emergencyContactTemplate = yield select(stateemergencyContactTemplate);

  let template = _.cloneDeep(emergencyContactTemplate);

  if (emergencyContactUpdated) {
    template = _.cloneDeep(emergencyContactUserUpdated);
  }

  const contact = template[FormCategories.contact];

  contact.forEach(field => {
    if (field.label === Strings.emergencyContactName) {
      field.value = talentData?.emergency_name
    }
    if (field.label === Strings.emergencyContactRelations) {
      field.optionSelection = talentData?.emergency_relationship
    }
    if (field.label === Strings.emergencyContactNumber) {
      field.value = talentData?.emergency_phone?.formatted_phone_number
    }
  })
  
  let emergencyContactForm = {};
  emergencyContactForm[FormCategories.contact] = contact;
  yield put(emergencyContactUpdatedForm({ emergencyContact: emergencyContactForm }));
}

export function* setExperience(talentData) {
  const experienceUpdated = yield select(stateExperienceUpdated);
  const experienceUserUpdated = yield select(stateexperienceUserUpdated);
  const userExperienceTemplate = yield select(stateexperienceTemplate);

  let experienceTemplate = _.cloneDeep(userExperienceTemplate);
  if (experienceUpdated) {
    experienceTemplate = _.cloneDeep(experienceUserUpdated);
  }

  const hospitalitySelectedOptions = [];
  const experientialSelectedOptions = [];
  const securitySelectedOptions = [];

  talentData?.event_roles && talentData?.event_roles.forEach(role => {
    let name = role?.event_role?.event_role_category?.name;
    let value = {};
    value['uuid'] = role?.uuid;
    value['name'] = role?.event_role?.name;
    if (name === Strings.hospitality) { hospitalitySelectedOptions.push(value) }
    if (name === Strings.experiential) { experientialSelectedOptions.push(value) }
    if (name === Strings.security) { securitySelectedOptions.push(value) }
  })

  experienceTemplate.forEach(field => {
    if (field.label === Strings.hospitality) field.optionSelectionList = hospitalitySelectedOptions;
    if (field.label === Strings.experiential) field.optionSelectionList = experientialSelectedOptions;
    if (field.label === Strings.security) field.optionSelectionList = securitySelectedOptions;
  })

  yield put(experienceUpdatedForm({ experience: experienceTemplate }));
}

export function* setSkills(talentData) {
  const skillsUpdated = yield select(stateSkillsUpdated);
  const skillsUserUpdated = yield select(stateskillsUserUpdated);
  const userSkillsTemplate = yield select(stateskillsTemplate);
  let skillsTemplate = _.cloneDeep(userSkillsTemplate);
  if (skillsUpdated) {
    skillsTemplate = _.cloneDeep(skillsUserUpdated);
  }

  const skillsSelectedOptions = [];
  const languagesSelectedOptions = [];

  talentData?.person_attributes?.skill && talentData?.person_attributes?.skill.forEach(skill => {
    let name = skill?.person_attribute?.person_attribute_category?.label;
    let value = {};
    value['uuid'] = skill?.uuid;
    value['name'] = skill?.person_attribute?.label;
    if (name === Strings.languagesId) languagesSelectedOptions.push(value);
    if (name !== Strings.languagesId) skillsSelectedOptions.push(value);
  })

  skillsTemplate.forEach(field => {
    if (field.label === Strings.skills) field.optionSelectionList = skillsSelectedOptions;
    if (field.label === Strings.languages) field.optionSelectionList = languagesSelectedOptions;
  })
  yield put(skillsUpdatedForm({ skills: skillsTemplate }));
}

export function* setPhoto(talentData) {
  const primaryPhotoUUID = talentData?.primary_photo_attribute?.uuid;
  let userPhotoUpdated = [];
  if (talentData?.person_attributes?.photo) {
    talentData?.person_attributes?.photo.forEach(photo => {
      let photoTemplate = {}
      const assetLinkSplit = photo?.asset_link.split('/');
      const assetUUID = assetLinkSplit[assetLinkSplit.length - 1];
      photoTemplate['fileLink'] = photo.value;
      photoTemplate['assetUUID'] = assetUUID;
      photoTemplate['attributeUUID'] = photo.uuid
      photoTemplate['mimeType'] = photo.asset_mimetype;
      photoTemplate['primary'] = false;
      if (primaryPhotoUUID === photo.uuid) {
        photoTemplate['primary'] = true;
      }
      userPhotoUpdated.push(photoTemplate);
    })
  }

  yield put(photoUserUpdated({ photo: userPhotoUpdated }))
}

export function* updateTalentData(talentData) {
  try {
    yield call(setPersonalInfo, talentData);
    yield call(setContactInfo, talentData);
    yield call(setEmergencyContactInfo, talentData);
    yield call(setExperience, talentData);
    yield call(setSkills, talentData);
    yield call(setPhoto, talentData);
  } catch (error) {
    console.log('error in updateTalentData', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchTalentData(action) {
  try {
    const { payload } = action;

    const response = yield call(getTalentData, payload?.id);
    yield call(verifyApiStatus, response);

    const talentData = response?.data;
    yield call(updateTalentData, talentData);
  } catch (error) {
    console.log('talent data', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchTalentNotesData(action) {
  try {
    const { payload } = action;

    const response = yield call(getTalentNotesData, payload?.id);
    yield call(verifyApiStatus, response);

    const talentData = response?.data;
    let userNotes = [];
    if (talentData){
      talentData.forEach(note => {
        const { created_by, created_at, title, author_rating, desc, uuid, associated_objects } = note
        const eventObject = associated_objects.filter(obj => obj.object_type.slug === 'event')
        let eventName = ''
        let eventId = ''
        if (eventObject && Boolean(eventObject.length)) {
          eventName = eventObject[0]?.object?.display_name;
          eventId = eventObject[0]?.object?.uuid;
        }
        let noteTemplate = {};
        noteTemplate["author"] = `${created_by.first_name} ${created_by.last_name}`;
        noteTemplate["created_at"] = moment(created_at).format('LLL');
        noteTemplate["event"] = {
          'value': eventId,
          'label': eventName
        };
        noteTemplate["rating"] = author_rating;
        noteTemplate["title"] = title;
        noteTemplate["desc"] = desc;
        noteTemplate["note_id"] = uuid;
        userNotes.push(noteTemplate);
      })
    }
      yield put(getTalentNotesDataTemplate(userNotes))

  } catch (error) {
    console.log('talent data', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateNewUserImage(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(setAssetSecure, actualPayload);
    const photoUUID = yield select(statePhotoUUID);
    const photos = yield select(statePhotos);
    const isPrimaryPhoto = photos.find(photo => photo.primary === true)
    yield call(verifyApiStatus, response);

    const imageResponse = response?.data;
    let userPhotoUpdated = [...photos];
    let photoTemplate = {}
    photoTemplate['fileLink'] = imageResponse?.tokenized_asset_link;
    photoTemplate['assetUUID'] = imageResponse?.uuid;
    photoTemplate['mimeType'] = imageResponse?.mimetype;
    photoTemplate['primary'] = isPrimaryPhoto ? false : true;
    let attributeResponse = {};
    if (actualPayload?.isTalentView) {
      const payload = {
        "person_uuid": imageResponse?.object_uuid,
        "value": imageResponse?.asset_link,
        "asset_mimetype": imageResponse?.mimetype,
        "asset_secured": true,
        "caption": " ",
        "self_rating": null,
        "company_rating": null,
        "person_attribute_uuid": photoUUID
      }
      attributeResponse = yield call(updatePersonAttribute, payload);
      yield call(verifyApiStatus, attributeResponse);

      photoTemplate['attributeUUID'] = attributeResponse?.data?.uuid;
    }
    userPhotoUpdated.push(photoTemplate);
    yield put(photoUserUpdated({ photo: userPhotoUpdated }));
    onSuccess();
  } catch (error) {
    console.log('talent data', error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createTalent(action) {
  const { payload = {} } = action;
  const { isRegister = false, captchaVal = '', onSuccess, onError } = payload;
  try {
    const personUUID = yield select(stateNewTalentId);
    let personalInfoUpdated = yield select(statePersonalInfoUpdated);
    let personalInfoUserUpdated = yield select(statePersonalInfoUserUpdated);
    let contactUpdated = yield select(stateContactUpdated);
    let contactUserUpdated = yield select(statecontactUserUpdated);
    let emergencyContactUpdated = yield select(stateEmergencyContactUpdated);
    let emergencyContactUserUpdated = yield select(stateemergencyContactUserUpdated);
    let experienceUpdated = yield select(stateExperienceUpdated);
    let experienceUserUpdated = yield select(stateexperienceUserUpdated);
    let skillsUpdated = yield select(stateSkillsUpdated);
    let skillsUserUpdated = yield select(stateskillsUserUpdated);
    let provinces = yield select(stateProvinces);
    let photos = yield select(statePhotos);
    
    const tempCreateTemplate = _.cloneDeep(createTalentTemplate);
    const personalUserData = _.cloneDeep(personalInfoUserUpdated);
    const contactUserData = _.cloneDeep(contactUserUpdated);
    const emergencyContactUserData = _.cloneDeep(emergencyContactUserUpdated);
    const experienceUserData = _.cloneDeep(experienceUserUpdated);
    const skillsUserData = _.cloneDeep(skillsUserUpdated);
    const photosUserData = _.cloneDeep(photos);
    tempCreateTemplate.uuid = personUUID;
    let userContactNumber = '';
    let userEmergencyContactNumber = '';
    if (personalInfoUpdated) {
      personalUserData.core.forEach(corefields => {
        if (corefields.value) {
          if (corefields.id === 'firstName') { tempCreateTemplate['first_name'] = corefields.value; }
          if (corefields.id === 'lastName') { tempCreateTemplate['last_name'] = corefields.value; }
          if (corefields.id === 'stageName') { tempCreateTemplate['preferred_name'] = corefields.value; }
        }
      })
      if (personalUserData.bio.value) { tempCreateTemplate['bio'] = personalUserData.bio.value; }
      personalUserData.uncategorized.forEach(uncategorized => {
        uncategorized.forEach(field => {
          if (field.value || field.optionSelection || field?.ft || field?.inch) {
            const attributeObj = {
              value: "",
              option_selection: "",
              person_attribute_uuid: ""
            }
            attributeObj['person_attribute_uuid'] = field.uuid;
            if (field.label === Strings.height) {
              attributeObj['value'] = ((parseInt(field.ft) * 12) + parseInt(field.inch));
            }
            if (field.label === Strings.dob) {
              attributeObj['value'] = getDateInYearFormat(field.value);
            }
            if (field.label !== Strings.height && field.label !== Strings.dob) {
              if (Boolean(field.value) && !Boolean(field.optionSelection)) {
                attributeObj['value'] = field.value;
              } else if (Boolean(field.optionSelection)) {
                const selectedOption = getKeyByValue(field.optionsList, field.optionSelection);
                attributeObj['value'] = field.value ? field.value : selectedOption;
                attributeObj['option_selection'] = selectedOption;
              }
            }
            tempCreateTemplate.person2person_attribute.push(attributeObj);
          }
        })
      })
    }
    if (photosUserData) {
      photosUserData.forEach(photo => {
        const assetLinkArr = photo?.fileLink.split('/');
        assetLinkArr.pop();
        const assetLink = assetLinkArr.join('/');
        let attributeObj = {
          "primary": photo?.primary ? true : false,
          "value": assetLink,
          "asset_mimetype": photo?.mimeType,
          "asset_secured": true,
          "caption": " ",
          "person_attribute_uuid": PERSON_ATTR_UUID
        };
        tempCreateTemplate.person2person_attribute.push(attributeObj)
      })
    }
    if (contactUpdated) {
      contactUserData.contact.forEach(contact => {
        if (contact.value) {
          if (contact.label === 'Phone Number') {
            let tempAddPhone = _.cloneDeep(addPhone);
            tempAddPhone.phone_type_uuid = contact.id;
            tempAddPhone.phone_number = contact.value;
            userContactNumber = contact.value;
            tempCreateTemplate.person_phones.push(tempAddPhone);
          }
          if (contact.label === 'Email') {
            let tempAddEmailData = _.cloneDeep(addEmailData);
            tempAddEmailData.email_type_uuid = contact.id;
            tempAddEmailData.email_address = contact.value;
            tempCreateTemplate.person_emails.push(tempAddEmailData);
          }
        }
      })
      if (contactUserData.residentialAddress.id) {
        let tempAddressField = _.cloneDeep(addAddressData);
        tempAddressField.address_type_uuid = contactUserData.residentialAddress.id
        contactUserData.residentialAddress.addressFields.forEach(address => {
          address.forEach(field => {
            if (field.value || field.optionSelection) {
              if (field.id === 'street_1') { tempAddressField.street_1 = field.value }
              if (field.id === 'street_2') { tempAddressField.street_2 = field.value }
              if (field.id === 'postal_code') { tempAddressField.postal_code = field.value }
              if (field.id === 'city') { tempAddressField.city = field.value }
              if (field.id === 'state') {
                const selectedProvince = provinces && provinces.find(province => province.name === field.optionSelection)
                tempAddressField.state_province_uuid = selectedProvince && selectedProvince.uuid;
              }
            }
          })
        })
        if (tempAddressField.street_1 || tempAddressField.street_2 || tempAddressField.postal_code || tempAddressField.city || tempAddressField.state_province_uuid) {
          tempCreateTemplate.person_addresses.push(tempAddressField);
        }
      }
      if (contactUserData.mailingAddress.id) {
        let tempMailingField = _.cloneDeep(addAddressData);
        tempMailingField.primary = false;
        tempMailingField.address_type_uuid = contactUserData.mailingAddress.id
        contactUserData.mailingAddress.addressFields.forEach(address => {
          address.forEach(field => {
            if (field.value || field.optionSelection) {
              if (field.id === 'street_1') { tempMailingField.street_1 = field.value }
              if (field.id === 'street_2') { tempMailingField.street_2 = field.value }
              if (field.id === 'postal_code') { tempMailingField.postal_code = field.value }
              if (field.id === 'city') { tempMailingField.city = field.value }
              if (field.id === 'state') {
                const selectedProvince = provinces && provinces.find(province => province.name === field.optionSelection)
                tempMailingField.state_province_uuid = selectedProvince && selectedProvince.uuid;
              }
            }
          })
        })
        if (tempMailingField.street_1 || tempMailingField.street_2 || tempMailingField.postal_code || tempMailingField.city || tempMailingField.state_province_uuid) {
          tempCreateTemplate.person_addresses.push(tempMailingField);
        }
      } 
    }
    if (emergencyContactUpdated) {
      emergencyContactUserData.contact.forEach(field => {
        if (field.value || field.optionSelection) {
          if (field.id === "contactName") { tempCreateTemplate['emergency_name'] = field.value }
          if (field.id === "relationship") {
            const selectedOption = getKeyByValue(field.optionsList, field.optionSelection);
            tempCreateTemplate['emergency_relationship'] = selectedOption
          }
          if (field.label === "Contact Phone Number") {
            let tempEmergencyPhone = _.cloneDeep(addEmergencyPhone);
            tempEmergencyPhone.phone_type_uuid = field.id;
            tempEmergencyPhone.phone_number = field.value;
            userEmergencyContactNumber = field.value;
            tempCreateTemplate.person_phones.push(tempEmergencyPhone);
          }
        }
      })
    }
    if (experienceUpdated) {
      let tempExperienceList = [];
      experienceUserData.forEach(experience => {
        if (Boolean(experience.optionSelectionList.length)) {
          experience.optionSelectionList.forEach(field => {
            let eventRole = {
              event_role_uuid: "",
              company_rating: 0,
              self_rating: 0
            }
            eventRole.event_role_uuid = field.uuid;
            tempExperienceList.push(eventRole);
          })
        }
      })
      tempCreateTemplate.person2event_roles = tempExperienceList;
    }
    if (skillsUpdated) {
      skillsUserData.forEach(skills => {
        if (Boolean(skills.optionSelectionList.length)) {
          skills.optionSelectionList.forEach(field => {
            let skillAttr = {
              value: true,
              option_selection: "",
              person_attribute_uuid: ""
            }
            skillAttr.person_attribute_uuid = field.uuid;
            tempCreateTemplate.person2person_attribute.push(skillAttr);
          })
        }
      })
    }
    let response;
    if (userContactNumber) {
      const contactResponse = yield call(checkIsPhoneValid, userContactNumber);
      yield call(verifyApiStatus, contactResponse);
    }
    if (userEmergencyContactNumber) {
      const emergencyContactResponse = yield call(checkIsPhoneValid, userEmergencyContactNumber);
      yield call(verifyApiStatus, emergencyContactResponse);
    }
    if (isRegister) {
      const payload = {
        tempCreateTemplate,
        captcha: captchaVal
      }
      response = yield call(registerPerson, payload);
    } else {
      response = yield call(createPerson, tempCreateTemplate);
    }
    yield call(verifyApiStatus, response);

    yield put(createNewTalentSuccess())
    onSuccess();
    let personRedirectionUrl = `${window.location.origin}${RoutingConstants.talents.internalView}/${personUUID}`
    if (isRegister) {
      personRedirectionUrl = `${window.location.origin}${RoutingConstants.registerThanks}`
    }
    window.location.href = personRedirectionUrl;
  } catch (error) {
    yield put(createNewTalentError())
    onError(error?.response?.data?.msg);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonAttr(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateCorePersonAttributes, actualPayload);
    yield call(verifyApiStatus, response);

    const talentData = response?.data;
    yield call(updateTalentData, talentData);
    onSuccess();
  } catch (error) {
    console.log('error in updatePersonAttr', error);
    onError(error);
    yield put(logOut);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateExistingUserData(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateExistingPersonAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateNewUserData(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updatePersonAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getAddressPredictions(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  if(actualPayload) {
    try {
      const response = yield call(getPredictedPlaces, actualPayload);
      yield call(verifyApiStatus, response);
  
      onSuccess(response);
    } catch(error) {
      onError(error);
      yield call(verifyApiStatus, error?.response);
    }
  }
}

export function* getAddressByPlaceId(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  if(actualPayload) {
    try {
      const response = yield call(getAddressDetailFromPlaceId, actualPayload);
      yield call(verifyApiStatus, response);

      onSuccess(response);
    } catch(error) {
      onError(error);
      yield call(verifyApiStatus, error?.response);
    }
  }
}

export function* createPersonPhone(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(createPhoneAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonPhone(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updatePhoneAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createPersonEmail(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(createEmailAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonEmail(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateEmailAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonEventRole(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateEventRole, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deletePersonEventRole(action) {
  const { payload } = action;
  const { uuid, onSuccess, onError } = payload;
  try {
    const response = yield call(deleteEventRole, uuid);
    yield call(verifyApiStatus, response);

    onSuccess('Successfully removed');
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createPersonAddress(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(createAddressAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonAddress(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateAddressAttribute, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deleteExistingUserAsset(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  const { companyId, assetUUID } = actualPayload;
  try {
    const response = yield call(deleteAsset, companyId, assetUUID);
    yield call(verifyApiStatus, response);

    const { attributeUUID } = actualPayload;
    if (attributeUUID) {
      const attributeResponse = yield call(deletePersonAttribute, attributeUUID);
      yield call(verifyApiStatus, attributeResponse);

    }
    const photos = yield select(statePhotos);
    let userPhotoUpdated = [...photos];
    const filteredPhotos = userPhotoUpdated.filter((item) => item.assetUUID !== assetUUID);
    yield put(photoUserUpdated({ photo: filteredPhotos }))
    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deletePersonAttr(action) {
  const { payload } = action;
  const { uuid, onSuccess, onError } = payload;
  try {
    const response = yield call(deletePersonAttribute, uuid);
    yield call(verifyApiStatus, response);

    onSuccess('Successfully removed');
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updatePersonAssetPrimary(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateCorePersonAttributes, actualPayload);
    yield call(verifyApiStatus, response);

    const photos = yield select(statePhotos);
    let userPhotoUpdated = [...photos];
    userPhotoUpdated.forEach((item) => {
      if (item?.attributeUUID === actualPayload?.primary_photo_attribute_uuid) {
        item.primary = true;
      }
      if (item.primary && item?.attributeUUID !== actualPayload?.primary_photo_attribute_uuid) {
        item.primary = false;
      }
    });
    yield put(photoUserUpdated({ photo: userPhotoUpdated }))
    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createNote(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(createNotes, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess(response?.data?.uuid);
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}


export function* deleteTalentNoteCreated(action) {
  const { payload } = action;
  const { uuid, onSuccess, onError } = payload;
  try {
    const response = yield call(deleteTalentNote, uuid);
    yield call(verifyApiStatus, response);

    onSuccess('Successfully removed');
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* talentNoteUpdate(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateTalentNote, actualPayload);
    yield call(verifyApiStatus, response);

    onSuccess();
  } catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateAttributes(action) {
  const { payload } = action;
  yield put(updateOtherAttributesSuccess({ starRating: payload?.rating, payrollId: payload?.payroll }))
}

export function* phoneNumberValid(action) {
  const { payload } = action;
  const { userContactNumber, onSuccess, onError } = payload;
  try {
    if (userContactNumber) {
      const contactResponse = yield call(checkIsPhoneValid, userContactNumber);
      yield call(verifyApiStatus, contactResponse);

    }
    if(onSuccess) onSuccess();
  } catch (error) {
    if(onError) onError(error?.response?.data?.msg);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* checkContactNumberValidity(action) {
  const { payload } = action;
  const { userEmergencyContactNumber, onEmergencySuccess, onEmergencyError } = payload;
  try {
    if (userEmergencyContactNumber) {
      const emergencyContactResponse = yield call(checkIsPhoneValid, userEmergencyContactNumber);
      yield call(verifyApiStatus, emergencyContactResponse);

    }
    if(onEmergencySuccess) onEmergencySuccess();
  } catch (error) {
    if(onEmergencyError) onEmergencyError(error?.response?.data?.msg);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* saga() {
  yield takeLatest(GET_TALENTS_LIST, fetchTalentList);
  yield takeLatest(GET_PROVINCES, fetchProvinces);
  yield takeLatest(GET_EVENT_ROLES, fetchEventRoles);
  yield takeLatest(GET_FILTERED_TALENTS_LIST, fetchfilteredTalents);
  yield takeLatest(CLEAR_FILTERED_TALENTS_LIST, clearFilteredTalents);
  yield takeLatest(GET_ATTRIBUTES, fetchAttributes);
  yield takeLatest(GET_EMAIL_UUID, fetchEmailAttributes);
  yield takeLatest(GET_ADDRESS_UUID, fetchAddressAttributes);
  yield takeLatest(GET_PHONE_UUID, fetchPhoneAttributes);
  yield takeLatest(GET_TALENTS_CORE_ATTRIBUTES, fetchCoreAttributes);
  yield takeLatest(TALENT_DATA, fetchTalentData);
  yield takeLatest(UPLOAD_NEW_USER_IMAGE, updateNewUserImage);
  yield takeLatest(CREATE_NEW_TALENT, createTalent);
  yield takeLatest(UPDATE_CORE_ATTRIBUTE, updatePersonAttr);
  yield takeLatest(UPDATE_EXISTING_TALENT_DATA, updateExistingUserData);
  yield takeLatest(UPDATE_NEW_TALENT_DATA, updateNewUserData);
  yield takeLatest(CREATE_TALENT_PHONE_ATTR, createPersonPhone);
  yield takeLatest(UPDATE_TALENT_PHONE_ATTR, updatePersonPhone);
  yield takeLatest(CREATE_TALENT_EMAIL_ATTR, createPersonEmail);
  yield takeLatest(UPDATE_TALENT_EMAIL_ATTR, updatePersonEmail);
  yield takeLatest(UPDATE_TALENT_EVENT_ROLE, updatePersonEventRole);
  yield takeLatest(DELETE_TALENT_EVENT_ATTR, deletePersonEventRole);
  yield takeLatest(CREATE_TALENT_ADDRESS_ATTR, createPersonAddress);
  yield takeLatest(UPDATE_TALENT_ADDRESS_ATTR, updatePersonAddress);
  yield takeLatest(DELETE_EXISTING_USER_IMAGE, deleteExistingUserAsset);
  yield takeLatest(UPDATE_ASSET_PRIMARY, updatePersonAssetPrimary);
  yield takeLatest(DELETE_TALENT_ATTR, deletePersonAttr);
  yield takeLatest(GET_TALENT_NOTES_DATA, fetchTalentNotesData);
  yield takeLatest(CREATE_TALENT_NOTES, createNote);
  yield takeLatest(DELETE_TALENT_NOTE, deleteTalentNoteCreated);
  yield takeLatest(UPDATE_OTHER_ATTRIBUTES, updateAttributes);
  yield takeLatest(UPDATE_TALENT_NOTE, talentNoteUpdate);
  yield takeLatest(CHECK_PHONE_NUMBER_VALIDITY, phoneNumberValid);
  yield takeLatest(CHECK_CONTACT_NUMBER_VALIDITY, checkContactNumberValidity);
  yield takeLatest(GET_GMAP_ADDRESS_PREDICTIONS, getAddressPredictions);
  yield takeLatest(GET_ADDRESS_BY_PLACE_ID, getAddressByPlaceId);
  yield takeLatest(CHECK_API_STATUS, checkApiStatus);
}

export function* talentSaga() {
  yield all([call(saga)]);
}
