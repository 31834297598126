import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NumericInput from "../numericInput";

const FilterWeight = (props) => {
  return (
    <div className="p-[12px] w-[250px]">
      <div>Select weight range</div>
      <div className="grid grid-cols-2 gap-2">
        <div className="border border-[#D9D9D9] rounded py-1 px-2">
          <div className="text-[11px]">MIN</div>
          <NumericInput
            className="border-none !p-0"
            placeholder="Example: 100"
            maxLength={3}
            value={props.weightValues.start_value}
            suffix="lbs"
            onChange={(value) => props.onMinValueChange(value)}
          />
        </div>
        <div className="border border-[#D9D9D9] rounded py-1 px-2">
          <div className="text-[11px]">MAX</div>
          <NumericInput
            className="border-none !p-0"
            maxLength={3}
            placeholder="Example: 200"
            value={props.weightValues.end_value}
            suffix="lbs"
            onChange={(value) => props.onMaxValueChange(value)}
          />
        </div>
      </div>
      <div className="text-right">
        <Button
          disabled={
            Number(props.weightValues.end_value) <= Number(props.weightValues.start_value)
          }
          className="mt-[10px]"
          onClick={() => props.onAddFilter("weight")}
          icon={<PlusOutlined className="align-baseline" />}
        >
          {props.btnContent || "Add Filter"}
        </Button>
      </div>
    </div>
  );
};
export default FilterWeight;
