const shiftActions = {
    GET_TIMEZONES: "GET_TIMEZONES",
    GET_VENUE_ENVIRONMENT: "GET_VENUE_ENVIRONMENT",
    GET_VENUE_ENVIRONMENT_SUCCESS: "GET_VENUE_ENVIRONMENT_SUCCESS",
    GET_TIMEZONES_SUCCESS: "GET_TIMEZONES_SUCCESS",
    UPDATE_SHIFT_NAME_TEMPLATE: "UPDATE_SHIFT_NAME_TEMPLATE",
    UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS: "UPDATE_SHIFT_NAME_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_DETAILS: "UPDATE_SHIFT_DETAILS",
    UPDATE_SHIFT_DETAILS_SUCCESS: "UPDATE_SHIFT_DETAILS_SUCCESS",
    UPDATE_SHIFT_TIMEZONE_TEMPLATE: "UPDATE_SHIFT_TIMEZONE_TEMPLATE",
    UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS: "UPDATE_SHIFT_TIMEZONE_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_DATE_TIME_TEMPLATE: "UPDATE_SHIFT_DATE_TIME_TEMPLATE",
    UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS: "UPDATE_SHIFT_DATE_TIME_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_LOCATION_TEMPLATE: "UPDATE_SHIFT_LOCATION_TEMPLATE",
    UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS: "UPDATE_SHIFT_LOCATION_TEMPLATE_SUCCESS",
    CREATE_SHIFT: "CREATE_SHIFT",
    CREATE_SHIFT_SUCCESS: "CREATE_SHIFT_SUCCESS",
    GET_EVENT_SHIFT_DETAILS: "GET_EVENT_SHIFT_DETAILS",
    GET_EVENT_SHIFT_DETAILS_SUCCESS: "GET_EVENT_SHIFT_DETAILS_SUCCESS",
    GET_EVENT_SHIFTS: "GET_EVENT_SHIFTS",
    GET_EVENT_SHIFTS_SUCCESS: "GET_EVENT_SHIFTS_SUCCESS",
    CLONE_ROLE: "CLONE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    DELETE_SHIFT: "DELETE_SHIFT",
    UPDATE_SHIFT_ROLE_TEMPLATE: "UPDATE_SHIFT_ROLE_TEMPLATE",
    UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS: "UPDATE_SHIFT_ROLE_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE: "UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE",
    UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS: "UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE_SUCCESS",
    UPDATE_MIN_RATING_TEMPLATE: "UPDATE_MIN_RATING_TEMPLATE",
    UPDATE_MIN_RATING_TEMPLATE_SUCCESS: "UPDATE_MIN_RATING_TEMPLATE_SUCCESS",
    UPDATE_RATE_TEMPLATE: "UPDATE_RATE_TEMPLATE",
    UPDATE_RATE_TEMPLATE_SUCCESS: "UPDATE_RATE_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_SKILLS_TEMPLATE: "UPDATE_SHIFT_SKILLS_TEMPLATE",
    UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS: "UPDATE_SHIFT_SKILLS_TEMPLATE_SUCCESS",
    UPDATE_ROLE_COMPOSITION_TEMPLATE: "UPDATE_ROLE_COMPOSITION_TEMPLATE",
    UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS: "UPDATE_ROLE_COMPOSITION_TEMPLATE_SUCCESS",
    UPDATE_SUB_LOCATION_TEMPLATE: "UPDATE_SUB_LOCATION_TEMPLATE",
    UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS: "UPDATE_SUB_LOCATION_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_TYPE_TEMPLATE: "UPDATE_SHIFT_TYPE_TEMPLATE",
    UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS: "UPDATE_SHIFT_TYPE_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_NOTES_TEMPLATE: "UPDATE_SHIFT_NOTES_TEMPLATE",
    UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS: "UPDATE_SHIFT_NOTES_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_ATTIRE_TEMPLATE: "UPDATE_SHIFT_ATTIRE_TEMPLATE",
    UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS: "UPDATE_SHIFT_ATTIRE_TEMPLATE_SUCCESS",
    UPDATE_SHIFT_DUTIES_TEMPLATE: "UPDATE_SHIFT_DUTIES_TEMPLATE",
    UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS: "UPDATE_SHIFT_DUTIES_TEMPLATE_SUCCESS",
    RESET_ROLE_MODAL_TEMPLATE: "RESET_ROLE_MODAL_TEMPLATE",
    CLEAR_SHIFT_DETAILS: "CLEAR_SHIFT_DETAILS",
    CLEAR_SHIFT_DETAILS_SUCCESS: "CLEAR_SHIFT_DETAILS_SUCCESS",
    CREATE_SHIFT_ROLE: "CREATE_SHIFT_ROLE",
    UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT: "UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT",
    UPDATE_SHIFT_ROLE: "UPDATE_SHIFT_ROLE",
    ADD_PERSON_TO_ROLE: "ADD_PERSON_TO_ROLE",
    UPDATE_PERSON_TO_ROLE: "UPDATE_PERSON_TO_ROLE",
    DELETE_PERSON_FROM_ROLE: "DELETE_PERSON_FROM_ROLE"
  }
  
  export default shiftActions;
  