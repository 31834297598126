import { Strings, devRecaptcha, prodRecaptcha, qaRecaptcha } from "../constants";

const getRecaptchaKey = () => {
  const appDomain = window.location.hostname && window.location.hostname.includes('.') && window.location.hostname.split('.');
  let subDomainName = 'opusdev';
  if (appDomain && Boolean(appDomain.length)) {
    subDomainName = appDomain[0];
  }

  if (subDomainName === Strings.dev) {
    return devRecaptcha
  } else if (subDomainName === Strings.qa) {
    return qaRecaptcha
  } else if (subDomainName === Strings.prod) {
    return prodRecaptcha
  } else {
    return devRecaptcha
  }
}

export default getRecaptchaKey;
