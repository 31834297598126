import React from "react";
import { connect } from "react-redux";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { RoutingConstants } from "../../constants";
import calender from "../../assets/images/calenderNew.png";
import pplLogo from "../../assets/svg/pplLogo.svg";
import EventNewSelected from "../../assets/svg/eventNewSelected.svg";
import EventNew from "../../assets/svg/eventNew.svg";
import talents from "../../assets/svg/talents.svg";
import talentsSelected from "../../assets/svg/talentsSelected.svg";
import logoutIcon from "../../assets/images/logoutNew.png";
import dummy from "../../assets/images/avatar_04.webp";
import { logOut } from "../../store/actions/auth";
import { useLocation, Link } from 'react-router-dom'

const NavBar = (props) => {
  const {
    triggerLogout,
    username
  } = props;

  const setLogout = () => {
    triggerLogout();
  };
  const location = useLocation();
  const currentRoute = location.pathname;

  const isEventPage = () => currentRoute?.includes("event");

  return (
    <div className="bg-alabaster w-nav">
      <div className="h-screen flex flex-col justify-between items-center">
        <div className="flex flex-col h-screen justify-between items-center">
          <div className="grid mt-5 gap-7">
            <Link to={RoutingConstants.talents.list} className="my-anchor-element01" data-tooltip-content="Dashboard">
              <img src={pplLogo} alt="ppl logo" />
            </Link>
            <Link to={RoutingConstants.calendar} className="my-anchor-element02" data-tooltip-content="Calendar">
              <img src={calender} className="w-5 h-5" alt="calendar" />
            </Link>
            <Link to={RoutingConstants.events.list} className="my-anchor-element03" data-tooltip-content="Events">
              <img src={isEventPage() ? EventNewSelected : EventNew} className="w-5 h-5" alt="events" />
            </Link>
            <Link to={RoutingConstants.talents.list} className="my-anchor-element04" data-tooltip-content="Talents">
              <img src={(currentRoute === RoutingConstants.talents.list || currentRoute === RoutingConstants.root) ? talentsSelected : talents} alt="talents" />
            </Link>
          </div>
          <div>
            <div
              className="my-anchor-element06 cursor-pointer"
              data-tooltip-content={username}
            >
              <img
                src={dummy}
                className="w-6 h-6 rounded-md"
                alt="talents"
              />
            </div>
            <br />
            <div
              className="my-anchor-element05 cursor-pointer"
              data-tooltip-content="Logout"
              onClick={() => { 
                setLogout()
              }}
            >
              <img
                src={logoutIcon}
                className="w-6 h-6"
                alt="talents"
              />
            </div>
            <br />
          </div>
        </div>
        <Tooltip anchorSelect=".my-anchor-element01" />
        <Tooltip anchorSelect=".my-anchor-element02" />
        <Tooltip anchorSelect=".my-anchor-element03" className="z-10" />
        <Tooltip anchorSelect=".my-anchor-element04" />
        <Tooltip anchorSelect=".my-anchor-element05" className="z-10" />
        <Tooltip anchorSelect=".my-anchor-element06" className="z-10" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.loginDetails?.username
  };
};

const mapDispatchToProps = {
  triggerLogout: logOut
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
