import React, { useState, useEffect } from "react";
import editIcon from "../../../../assets/images/editIcon02.png"
import saveIcon from "../../../../assets/images/Check.svg";
import clearIcon from "../../../../assets/images/X.svg";

const TextAreaField = ({ name, id, placeholder, defaultValue,
maxAllowed, minAllowed, onChange, required, showEditIcon=false, saveEdit, className, isEventPage = false, editSaveCustomStyle="", editCustomStyle="" }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState('');
  const [valLength, setValLength] = useState(defaultValue?.length);

  useEffect(() => {
    setValLength(defaultValue?.length)
    setUpdatedVal(defaultValue);
  }, [defaultValue])

  const onInputChange = (e) => {
    const value = e.target.value
    setValLength(value?.length)
    if (showEditIcon) {
      return setUpdatedVal(value)
    }
    onChange(e)
  }

  const onSaveEdit = () => {
    if (defaultValue === updatedVal) {
      setIsEditable(false);
    } else {
      saveEdit(id, updatedVal, true, name)
      setIsEditable(false);
    }
  }
  return (
    <div className={`flex flex-row ${showEditIcon ? `justify-between ${editCustomStyle}` : 'justify-start'}`}>
      <div className={`flex flex-col h-20 overflow-auto w-full ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
        <textarea
          type="text"
          id="bioValue"
          defaultValue={defaultValue}
          className={`text-sm placeholder-grayNobel text-tileGray font-normal mt-1 border-none w-full outline-none ${className ? className : ''} ${isEventPage && showEditIcon && !isEditable && "cursor-pointer"}`}
          name="bioValue"
          minLength={minAllowed}
          maxLength={maxAllowed}
          placeholder={placeholder}
          rows={10}
          cols={10}
          readOnly={showEditIcon && !isEditable}
          onChange={(e) => onInputChange(e)}
          required={required}
          onClick={() => isEventPage && showEditIcon && setIsEditable(true)}
          value={updatedVal}
        />
        <p className='font-normal text-xs text-right w-full text-tileGray'>
          {valLength}/{maxAllowed}
        </p>
      </div>
      {
        !isEventPage && showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className="h-4 w-4 cursor-pointer ml-ten -mt-4"
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className={`${isEventPage ? "flex -mt-[12px] -ml-[10px] z-[1]" : "grid -mt-4 mb-9 ml-2"} gap-2 ${editSaveCustomStyle}`}
        >
          <img
            src={saveIcon}
            alt="save edit"
            className={`${isEventPage ? "w-6 h-6" : "w-4 h-4"} cursor-pointer`}
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className={`${isEventPage ? "w-6 h-6" : "w-4 h-4"} cursor-pointer`}
            onClick={() => {
              setUpdatedVal(defaultValue);
              setIsEditable(false)
            }}
          />
        </div>
      }
    </div>
  )
}

export default TextAreaField;
