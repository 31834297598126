import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import "./LoginForm.css";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { logIn } from "../../../store/actions/auth";
import { RoutingConstants, Strings } from "../../../constants";
import LoginHeader from "./LoginHeader";
import isTokenExpired from "../../../utils/isTokenExpired";
import Spinner from "../../Spinner";
import LoginFooter from "./LoginFooter";
import LoginCoverImage from "./LoginCoverImage";
import {
  getAttributes,
  getPhoneUUID,
  getTalentCoreAttributes,
} from "../../../store/actions/talents";
import { error, success } from "../../toast";
import { clearEventDetailsForm, getEventDateDropdownOptions, getEventSortDropdownOptions, getDefaultEventFilters } from "../../../store/actions/events";

const LoginForm = (props) => {
  const {
    triggerLogin,
    loading,
    loggedIn,
    tokenExpires,
    triggerAttributes,
    triggerCoreAttributes,
    triggerPhoneAttributes,
    triggerClearEventForm,
    triggerGetEventDateDropdownOptions,
    triggerGetEventSortDropdownOptions,
    triggerGetDefaultEventFilters
  } = props;

  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [autofilled, setAutofilled] = useState(false);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  var full = window.location.host;
  var parts = full.split(".");
  var sub = parts[0];

  useEffect(() => {
    const isValidToken = isTokenExpired(tokenExpires);
    if (loggedIn && isValidToken) {
      navigate(RoutingConstants.root);
    }
    const emailField = document.getElementById("username");
    emailField?.focus();
  }, []);

  useEffect(() => {
    const username = document.querySelector("#username");
    const password = document.querySelector("#password");
    setTimeout(() => {
      const emailAutofilled =
        getComputedStyle(username).backgroundColor === "rgb(232, 240, 254)";
      const passwordAutofilled =
        getComputedStyle(password).backgroundColor === "rgb(232, 240, 254)";
      if (!Object.keys(errors).length) {
        setAutofilled(emailAutofilled && passwordAutofilled);
      } else {
        setAutofilled(false);
      }
    }, 1000);
  }, [errors, autofilled]);

  const inputsValidated =
    userData.username?.length >= 6 &&
    userData.username?.length <= 100 &&
    !errors.username &&
    userData.password?.length >= 8 &&
    userData.password?.length <= 60 &&
    !errors.password;

  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("username");
    const inputTagElement_2 = document.getElementById("password");
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (data) => {
    const payload = {
      username: data.username,
      password: data.password,
      domain: null,
      subdomain: sub,
      // subdomain: 'opus',
      onSuccess: async () => {
        success(Strings.loginSuccessMsg)
        await triggerClearEventForm();
        await triggerCoreAttributes();
        await triggerAttributes();
        await triggerPhoneAttributes();
        await triggerGetEventDateDropdownOptions();
        await triggerGetEventSortDropdownOptions();
        await triggerGetDefaultEventFilters()
        navigate(RoutingConstants.root);
      },
      onError: (msg) => {
        error(msg)
      },
    };
    triggerLogin(payload);
  };

  return (
    <div className="flex">
      <div className="flex flex-col w-full sm:w-3/4 h-100vh">
        <LoginHeader />
        <section className="w-96 m-auto mt-12 font-inter p-4">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <h2 className="font-semibold text-black text-[28px] leading-[34px] mb-6">
              {Strings.loginHeader}
            </h2>
            <fieldset className="mb-4">
              <div
                className={`border rounded p-2.5 ${
                  errors?.username ? "border-formWarning" : "border-grayLight"
                }`}
              >
                <div className="w-full font-semibold text-[11px] uppercase text-tileGray">
                  {Strings.loginEmailLabel}
                </div>
                <input
                  type="email"
                  className="placeholder-grayNobel placeholder:font-medium text-grayNobel w-full outline-none text-sm font-normal overflow-x-auto"
                  placeholder={Strings.loginEmailPlaceholder}
                  id="username"
                  name="username"
                  {...register("username", {
                    required: {
                      value: true,
                      message: "EMAIL IS MANDATORY",
                    },
                    maxLength: {
                      value: 300,
                      message: "Enter valid email address",
                    },
                    minLength: {
                      value: 6,
                      message: "Enter valid email address",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Enter valid email address",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
              {errors.username && (
                <span className="text-[10px] text-formWarning uppercase font-normal">
                  {errors.username.message}
                </span>
              )}
            </fieldset>
            <fieldset className="mb-4">
              <div
                className={`border rounded p-2.5 ${
                  errors?.password ? "border-formWarning" : "border-grayLight"
                }`}
              >
                <div className="w-full font-semibold text-[11px] uppercase text-tileGray">
                  {Strings.loginPasswordLabel}
                </div>
                <input
                  type="password"
                  className="placeholder-grayNobel placeholder:font-medium text-grayNobel w-full outline-none text-sm font-normal overflow-x-auto"
                  placeholder={Strings.loginPasswordPlaceholder}
                  id="password"
                  name="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is mandatory",
                    },
                    maxLength: {
                      value: 60,
                      message: "Maximum 60 characters allowed",
                    },
                    minLength: {
                      value: 8,
                      message: "Minimum 8 characters required",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
              {errors.password && (
                <span className="text-[10px] text-formWarning uppercase font-normal">
                  {errors.password.message}
                </span>
              )}
            </fieldset>
            <button
              onClick={() => {
                const autosavedUsername =
                  document.getElementById("username").value;
                const autosavedPassword =
                  document.getElementById("password").value;
                reset(
                  {
                    username: autosavedUsername,
                    password: autosavedPassword,
                  },
                  {
                    keepErrors: false,
                    keepDirty: true,
                    keepIsSubmitted: false,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                  }
                );
                setUserData({
                  username: autosavedUsername,
                  password: autosavedPassword,
                });
              }}
              disabled={errorFromApi || loading}
              type="submit"
              className={`cursor-pointer text-white font-semibold rounded w-full text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-4 ${
                errorFromApi || loading
                  ? `bg-gray pointer-events-none`
                  : inputsValidated && !errorFromApi
                  ? ` bg-secondaryLight hover:bg-secondary active:bg-secondaryDark`
                  : autofilled
                  ? "bg-secondaryLight hover:bg-secondary active:bg-secondaryDark"
                  : `bg-gray`
              }`}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                  <span className="mx-2">{Strings.loading}</span>
                  <Spinner color={"white"} loading={loading} size={15} />
                </div>
              ) : (
                `${Strings.loginBtn}`
              )}
            </button>
          </form>
          <div className="w-full flex flex-col items-center">
            {errorFromApi !== null ? (
              <div className="flex items-center mt-[20px] ">
                <div className="px-6 py-2 flex items-center">
                  <p className="text-[10px] text-formWarning uppercase font-normal">{`${errorFromApi}`}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Link
              to={RoutingConstants.forgotPassword}
              className="text-cyanBlue font-medium text-sm mt-3 mb-12 text-start items-start"
            >
              {Strings.forgotPassword}
            </Link>
          </div>
        </section>
        <LoginFooter />
      </div>
      <LoginCoverImage />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    tokenExpires: state.auth.loginDetails?.token_expires || "",
  };
};

const mapDispatchToProps = {
  triggerLogin: logIn,
  triggerAttributes: getAttributes,
  triggerPhoneAttributes: getPhoneUUID,
  triggerCoreAttributes: getTalentCoreAttributes,
  triggerClearEventForm: clearEventDetailsForm,
  triggerGetEventDateDropdownOptions: getEventDateDropdownOptions,
  triggerGetEventSortDropdownOptions: getEventSortDropdownOptions,
  triggerGetDefaultEventFilters: getDefaultEventFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
