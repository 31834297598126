import { apiEndpoints } from "../constants";
import { postRequest } from "./requests";

export const login = async (payload = {}) => {
  const { baseUrl, login } = apiEndpoints;
  const loginUrl = `${baseUrl}${login}`;
  try {
    const response = await postRequest({ url: loginUrl, payload, timeout: 5000, addToken: false });
    return response;
  } catch (error) {
    return error;
  }
};

export const logout = async () => {
  const { baseUrl, logout } = apiEndpoints;
  const logoutUrl = `${baseUrl}${logout}`;
  try {
    const response = await postRequest({ url: logoutUrl, addToken: false });
    return response?.data;
  } catch (error) {
    return error;
  }
};
