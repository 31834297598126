import React from "react";
import Logo from "../../../assets/svg/logo.svg";

const LoginHeader = () => {
  return (
    <header className="flex mt-12 items-center justify-center">
      <img className="h-[130px]" src={Logo} alt="people events logo" />
    </header>
  )
}

export default LoginHeader;
