import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import editIcon from "../../../assets/images/editIcon02.png";
import InputField from "./inputField";
import SelectField from "./selectField";
import { Strings } from "../../../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGmapAddressByPlaceId, getGmapAddressPredictions, updateDefaultContactAddress } from "../../../store/actions/talents";
import { squareRounded } from "../../../constants/events";
toast.configure();

const AddressField = ({
  title,
  subTitle,
  fieldData,
  onChange,
  showEditIcon=false,
  saveEdit=true,
  fieldId = "",
  required,
  triggerGetGmapAddressPredictions,
  triggerGetGmapAddressByPlaceId,
  addressVal,
  defaultMailingAddressBackup,
  defaultResidentialAddressBackup,
  triggerUpdateDefaultContactAddress,
  serverLatLong,
  serverPlaceId,
  fieldCustomStyle="",
  suggestionClass="",
  isEventPage=false
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [placeId, setPlaceId] = useState(null);
  const [placeIdBackup, setPlaceIdBackup] = useState(null);
  const [showPredictions, setShowPredictions] = useState(false);
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [latLongBackup, setLatLongBackup] = useState({
    lat: "",
    long: ""
  })
  const [isUserAddressSelected, setIsUserAddressSelected] = useState(false);
  const [placeIdTimezone, setPlaceIdTimezone] = useState("");
  const defaultAddressVal = addressVal;

  useEffect(() => {
    if(serverLatLong && serverLatLong?.lat && serverLatLong?.long) {
      setLat(serverLatLong?.lat);
      setLong(serverLatLong?.long);
      setLatLongBackup({
        lat: serverLatLong?.lat,
        long: serverLatLong?.long
      })
    }
  }, [serverLatLong]);

  useEffect(() => {
    if (placeId && isUserAddressSelected) {
      fetchAddress();
    }
  }, [placeId]);

  useEffect(() => {
    function handleClickOutside(e) {
      const targetElement = document.querySelector(`.prediction-fieldset_${fieldId}`);
      if(targetElement && !targetElement?.contains(e.target)) {
        setPredictions([]);
        setShowPredictions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  const getAddressFromResponse = (data) => {
    const mappedAddress = {
      city: "",
      state: "",
      streetAddress: [],
      country: [],
      pincode: "",
    };
    data?.results?.[0]?.address_components?.map((add) => {
      if (
        add?.types?.includes("street_number") ||
        add?.types?.includes("route")
      ) {
        mappedAddress.streetAddress = [
          ...mappedAddress?.streetAddress,
          add?.long_name,
        ];
      }

      if (add?.types?.includes("postal_code")) {
        mappedAddress.pincode = add?.short_name;
      }
      if(add?.types?.includes("locality")) { // give locality for high precedence
        mappedAddress.city = add?.short_name;
      }
      if (
       ((add?.types?.includes("sublocality") ||
        add?.types?.includes("neighbourhood") ||
        add?.types?.includes("administrative_area_level_2")) &&
        !mappedAddress.city
        )
      ) {
        mappedAddress.city = add?.short_name;
      }
      if (add?.types?.includes("administrative_area_level_1")) {
        mappedAddress.state = add?.long_name;
      }
    });
    fieldData.forEach((addressField) => {
      addressField.forEach((field) => {
        
        if (field.id === "street_1") {
          field.value = mappedAddress?.streetAddress?.join(" ");
          if(document.querySelector(`[data-id="${fieldId}_street_1"]`)) {
            document.querySelector(`[data-id="${fieldId}_street_1"]`).value = mappedAddress?.streetAddress?.join(" ")
          }
        }

        if (field.id === "postal_code") {
          field.value = mappedAddress.pincode;
          if(document.querySelector(`[data-id="${fieldId}_postal_code"]`)) {
            document.querySelector(`[data-id="${fieldId}_postal_code"]`).value = mappedAddress?.pincode;
          }
        }
        if (field.id === "city") {
          field.value = mappedAddress?.city;
          if(document.querySelector(`[data-id="${fieldId}_city"]`)) {
            document.querySelector(`[data-id="${fieldId}_city"]`).value = mappedAddress?.city;
          }
        }
        if (field.id === "state") {
          field?.options?.map((option) => {
            if (
              option.toLowerCase() === mappedAddress?.state?.toLowerCase()
            ) {
              field.optionSelection = option;
              if(document.querySelector(`[data-id="${fieldId}_state"]`)) {
                document.querySelector(`[data-id="${fieldId}_state"]`).value = option;
              }
              return option;
            }
          });
        }
      });
    });
  };

  const fetchAddress = async () => {
    const isLoggedIn =
      localStorage.getItem("authToken") !== undefined &&
      localStorage.getItem("authToken") !== null;
      try {
      const payload = {
        actualPayload: { placeId, isLoggedIn },
        onSuccess: async (response) => {
          if(response?.data) {
            await getAddressFromResponse(response?.data);
            const addressResponse = response?.data;
            const lat = addressResponse?.results?.[0]?.geometry?.location?.lat;
            const long = addressResponse?.results?.[0]?.geometry?.location?.lng;
            const timeZone = addressResponse?.results?.[0]?.geometry?.tz_name;
            if(timeZone) {
              setPlaceIdTimezone(timeZone);
            }
            setLat(lat);
            setLong(long);
            setLatLongBackup({ lat: lat, long: long });
            await onChange(fieldData, fieldId, true, lat, long, placeId, timeZone);
          }
          else {
            throw new Error(response);
          }
        },
        onError: (error) => {
          console.error(error);
        }
      }
      triggerGetGmapAddressByPlaceId(payload);
    }
    catch (e) { 
        toast.error(e.msg);
    }
  };

  const onOptionChange = (id, value, isOptionUpdate) => {
    fieldData.forEach((addressField) => {
      addressField.forEach((field) => {
        if (field.id === id) {
          field.optionSelection = value;
        }
      });
    });
    onChange(fieldData, fieldId);
  };

  const handleLocationChange = async (input) => {
      const isLoggedIn =
        localStorage.getItem("authToken") !== undefined &&
        localStorage.getItem("authToken") !== null;
    if (input !== undefined && input !== "") {
      const payload = {
        actualPayload: { input, isLoggedIn },
        onSuccess: (response) => {
          setPredictions(response?.data?.predictions);
          setShowPredictions(true);
        },
        onError: (error) => {
          setPredictions([]);
          setShowPredictions(false);
          console.error("Prediction error", error);
        }
      };
      triggerGetGmapAddressPredictions(payload);
    } else {
      setPredictions([]);
      setShowPredictions(false);
    }
  };

  const [timer,setTimer]=useState(null)
  function debounce(func, timeout = 500) {
  // let timer;
    return (...args) => {
      clearTimeout(timer);
      setTimer(setTimeout(() => {
        func.apply(this, args);
      }, timeout))
    };
  }

  const betterFunction = debounce((e) => handleLocationChange(e));

  const onValueChange = async (id, value) => {
    const updatedLat = id !== "street_2" ? null : lat;
    const updateLong = id !== "street_2" ? null : long;
    const updatedPlaceId = (id !== "street_2" || id === "street_1") ? null : placeId;
    const timezone = id !== "street_2" ? null : placeIdTimezone
    setLat(updatedLat);
    setLong(updateLong);
    setPlaceId(updatedPlaceId);
    setPlaceIdTimezone(timezone);

    if (id === "street_1") {
      if (value.length > 4) {
        betterFunction(value);
      }
    }

    fieldData.forEach((addressField) => {
      addressField.forEach((field) => {
        if (field.id === id) {
          field.value = value;
        }
      });
    });
    onChange(fieldData, fieldId, false, updatedLat, updateLong, updatedPlaceId, timezone);
  };

  const onSaveEdit = () => {
    saveEdit(fieldData, fieldId, lat, long, placeId, placeIdTimezone);
    setIsEditable(false);
  };

  const handleFocus = (e) => {
    if (e.target.name !== "street_1") {
      setShowPredictions(false)
    }
  }

  const resetAddress = (addressVal) => {
    addressVal && 
    addressVal.forEach((addressField) => {
        addressField.forEach((field) => {
          if (field.id === "street_1" && document.querySelector(`[data-id="${fieldId}_street_1"]`)) {
            document.querySelector(`[data-id="${fieldId}_street_1"]`).value = field.value
          }
          if (field.id === "street_2" && document.querySelector(`[data-id="${fieldId}_street_2"]`)) {
            document.querySelector(`[data-id="${fieldId}_street_2"]`).value = field.value;
          }
          if (field.id === "postal_code" && document.querySelector(`[data-id="${fieldId}_postal_code"]`)) {
            document.querySelector(`[data-id="${fieldId}_postal_code"]`).value = field.value;
          }
          if (field.id === "city" && document.querySelector(`[data-id="${fieldId}_city"]`)) {
            document.querySelector(`[data-id="${fieldId}_city"]`).value = field.value;
          }
          if (field.id === "state" && document.querySelector(`[data-id="${fieldId}_state"]`)) {
            document.querySelector(`[data-id="${fieldId}_state"]`).value = field.optionSelection
          }
        });
      });
  }

  const onCancelSave = async () => {
    if(placeId) {
      if(defaultResidentialAddressBackup && defaultResidentialAddressBackup?.addressFields) {
        await resetAddress(defaultResidentialAddressBackup?.addressFields);
        await triggerUpdateDefaultContactAddress({ residentialAddress: defaultResidentialAddressBackup });
      }
      else if(defaultMailingAddressBackup && defaultMailingAddressBackup?.addressFields) {
        await resetAddress(defaultMailingAddressBackup?.addressFields);
        await triggerUpdateDefaultContactAddress({ mailingAddress: defaultMailingAddressBackup });
      }
    } else {
      resetAddress(defaultAddressVal);
    }

    setShowPredictions(false);
    setPlaceId(null);
  }

  return (
    <div className={`${title && 'shadow-card mb-6'} rounded-lg`}>
    {title &&
      <div className="text-2xl text-black font-medium p-3 flex">
        {title}&nbsp;
        <span
          className={`font-medium text-sm ${
            subTitle ? "inline mt-2" : "hidden"
          }`}
        >
          ({subTitle})
        </span>
        {showEditIcon && !isEditable && (
          <img
            src={editIcon}
            alt="edit"
            className="h-4 w-4 cursor-pointer ml-ten mt-2"
            onClick={() => {
              setIsEditable(true);
              if(lat || long) {
                setLatLongBackup({ // get lat, long for backup when user clicks on edit
                  lat: lat,
                  long: long
                })
                if(placeId) {
                  setPlaceIdBackup(placeId);
                }
              }
            }}
          />
        )}
        {isEditable && (
          <div className="grid -mt-2 ml-2 gap-2">
            <img
              src={saveIcon}
              alt="save edit"
              className="w-4 h-4 cursor-pointer"
              onClick={() => {
                onSaveEdit();
                setShowPredictions(false);
                setPlaceId("");
                setLatLongBackup({ // clear lat, long backup when user submits the field
                  lat: "",
                  long: ""
                });
              }}
            />
            <img
              src={clearIcon}
              alt="clear edit"
              className="w-4 h-4 cursor-pointer"
              onClick={() => {
                onCancelSave();
                setIsEditable(false);
                if(latLongBackup && latLongBackup.lat && latLongBackup.long) { // restore lat, long from backup when user clicks on close
                  setLat(latLongBackup.lat);
                  setLong(latLongBackup.long);
                }
              }}
            />
          </div>
        )}
      </div>
    }
    {fieldData &&
        fieldData.map((addressField, index) => {
          return (
            <div key={index} className={`${title && 'px-3'} gap-4 grid grid-cols-3`}>
              {addressField &&
                addressField.map((addressData, index) => {
                  return (
                    <fieldset
                      key={index}
                      className={`w-full m-auto text-lg mb-4 ${
                        addressData.label === Strings.streetAddress &&
                        `col-span-2 prediction-fieldset_${fieldId}`
                      }`}
                    >
                      <div className="relative border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                      <div className='flex flex-row'>
                        <label
                          className="font-semibold text-[#393636] text-sm"
                          htmlFor={addressData?.id}
                        >
                          {addressData?.label}
                          {addressData.id !== Strings.apt &&
                            ((addressData.required || required) && !isEventPage) && (
                              <span className="text-red-500 ml-1">*</span>
                            )}
                        </label>
                        {(addressData.id !== Strings.apt && (addressData.required || required) && isEventPage) && squareRounded()}
                      </div>
                        {!addressData.options && (
                          <div>
                            <InputField
                              focusChange={handleFocus}
                              isLocationInput={true}
                              name={addressData?.id}
                              id={addressData?.id}
                              onChange={onValueChange}
                              placeholder={addressData?.placeholder}
                              type={addressData?.type}
                              value={addressData?.value}
                              defaultValue={addressData?.value}
                              maxAllowed={addressData.maxAllowed}
                              minAllowed={addressData.minAllowed}
                              required={
                                addressData.id !== Strings.apt &&
                                (addressData.required || required)
                              }
                              isReadOnly={showEditIcon && !isEditable}
                              dataId={`${fieldId}_${addressData?.id}`}
                              fieldCustomStyle={fieldCustomStyle || "uppercase"}
                            />
                          </div>
                        )}
                        {addressData?.options && (
                          <SelectField
                            options={addressData?.options}
                            name={addressData?.id}
                            id={addressData?.id}
                            onChangeOption={onOptionChange}
                            defaultValue={addressData.optionSelection}
                            isReadOnly={showEditIcon && !isEditable}
                            required={addressData.required || required}
                            dataId={`${fieldId}_${addressData?.id}`}
                          />
                        )}
                        {!placeId &&
                          showPredictions &&
                          addressData?.id === "street_1" &&
                          predictions?.length > 0 && (
                            <div className={`absolute z-10 left-0 mt-2 top-18 max-h-[200px] w-full bg-white overflow-y-scroll border-[1px] border-richBlue rounded-b-lg shadow-sm ${suggestionClass}`}>
                              {predictions.map((prediction) => {
                                return (
                                  <p
                                    key={prediction?.place_id}
                                    className="text-sm cursor-pointer px-2 py-4 "
                                    onClick={() => {
                                      setPlaceId(prediction?.place_id);
                                      setPlaceIdBackup(prediction?.place_id);
                                      setIsUserAddressSelected(true);
                                    }}
                                  >
                                    {prediction?.description}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    </fieldset>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = {
  triggerGetGmapAddressPredictions: getGmapAddressPredictions,
  triggerGetGmapAddressByPlaceId: getGmapAddressByPlaceId,
  triggerUpdateDefaultContactAddress: updateDefaultContactAddress
};

export default connect(null, mapDispatchToProps)(AddressField);
