import { Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const FilterSelectbox = (props) => {
  return (
    <div className="p-[12px]">
          <div>Select one</div>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select">
            <div className="text-[11px]">{props.title}</div>

            <Select
            placeholder={props.placeholder || ""}
            onChange={(value) => props.onChange(value)}
            options={props.list}
            style={{ width: "100%" }}
            value={props.selectedValue}
          />
          </div>
          <div className="text-right">
            <Button
              disabled={!props.selectedValue ? true : false}
              className="mt-[10px]"
              onClick={() => props.onAddFilter(props.type)}
              icon={<PlusOutlined className="align-baseline" />}
            >
              Add Filter
            </Button>
          </div>
        </div>
  );
};
export default FilterSelectbox;
