import { takeLatest, call, put, all, select } from "redux-saga/effects";
import actionTypes from "../types";
import {
  getEventsListError, getEventsListSuccess, getEventsCoreDefSuccess,
  getEventsCoreDefError, getFilteredEventsListSuccess, getFilteredEventsListError,
  updateEventNameTemplateSuccess, updateEventClientNameTemplateSuccess,
  updateEventBrandNameTemplateSuccess, updateEventManagerNameTemplateSuccess,
  getClientSuggestionsSuccess, createVenueSuccess,
  getVenueSuggestionsSuccess, createOnsiteContactSuccess, addAttachmentSuccess,
  alternateContactTempSuccess, deleteAlternateContactSuccess, primaryContactSuccess,
  updateEventNameTemplate, updateEventDatesTemplateSuccess, uploadEventNotesTemplateSuccess,
  addFilesToUpload, updateEventClientNameTemplate, updateEventBrandNameTemplate, setVenue, updateEventNotesTemplate, updateEventManagerNameTemplate, primaryContact, deleteAlternateContact, getEventSuccess,
  updateEventDatesTemplate, updateSearchRadiusTemplate,
  updateEventIdSuccess,
  updateSearchRadiusTemplateSuccess, updateEventStatusTemplateSuccess,
  getEventDateDropdownOptionsSuccess, getEventSortDropdownOptionsSuccess, updateEventFilters
 } from "../actions/events";
import { eventCoreDef, eventsList, eventsFilter, getClientSuggestion, createVenue, createOrganization, uploadAsset, updateEventPerson, getEventData, createEvent, deleteAsset, deleteAttribute, deleteEvent, updateAttribute, updateEvent, createEventPerson, deleteEventPerson, getAllEventShifts, updateShiftVenue, eventStatuses, getEventSearchDefaults, getEventSortOrderList, getEventDateRangeList } from "../../api/events";
import { verifyApiStatus } from "./talent";
import { updateEventShifts as updateEventShiftToRedux } from "./shifts";
import { ACCOUNT_MANAGER_ATTRIBUTE_UUID, ATTACHMENT_EVENT_ATTRIBUTE_UUID, EVENT_CATEGORY_UUID, OTHER_EVENT_CATEGORY_UUID, PRIMARY_CONTACT_ATTRIBUTE_UUID, eventAlternateContactTemplate } from "../../constants/events";
import _ from 'lodash';
import { createPerson, updateCorePersonAttributes } from "../../api/talent";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import getKeyByValue from "../../globalFunctions/getKeyByValue";
import { getEventShifts as getUpdatedEventShifts } from "../actions/shifts"
import { objToArrayOfKey, objToArrayOfVal, objToArrayOfValWithKey } from "../../globalFunctions/convertObjToArrayOfVal";

const {
  GET_EVENTS_LIST, GET_EVENTS_CORE_DEF, GET_FILTERED_EVENTS_LIST,
  UPDATE_EVENT_NAME_TEMPLATE, UPDATE_EVENT_CLIENT_NAME_TEMPLATE,
  UPDATE_EVENT_BRAND_NAME_TEMPLATE, UPDATE_EVENT_MANAGER_NAME_TEMPLATE,
  GET_CLIENT_SUGGESTIONS, CREATE_ONSITE_CONTACT, EDIT_ONSITE_CONTACT,
  CREATE_ORGANIZATION, CREATE_VENUE, GET_VENUE_SUGGESTIONS, UPDATE_SEARCH_RADIUS_TEMPLATE,
  SET_VENUE, ADD_ATTACHMENT, ALTERNATE_CONTACT_TEMP, DELETE_ALTERNATE_CONTACT_TEMP,
  PRIMARY_CONTACT, UPDATE_EVENT_PERSON_RECORD, GET_EVENT, CREATE_EVENT, UPDATE_EVENT_DATES_TEMPLATE,
  UPDATE_EVENT_NOTES_TEMPLATE, DELETE_ASSET, ADD_NEW_ASSET, DELETE_EVENT_PERSON_RECORD,
  DELETE_EVENT, UPDATE_EVENT_CORE_ATTR, CREATE_EVENT_PERSON_RECORD, UPDATE_EVENT_SHIFT_VENUE,
  UPDATE_EVENT_STATUS_TEMPLATE, GET_EVENT_STATUS_OPTIONS,
  GET_EVENT_DATE_DROPDOWN_OPTIONS, GET_EVENT_SORT_DROPDOWN_OPTIONS, GET_DEFAULT_EVENT_FILTERS
} = actionTypes.events;

export const stateEventAttachedDocuments = (state) => state.events.attachedDocuments;
export const eventId = (state) => state.events.newEventId;
export const stateEventNameTemp = (state) => state.events.eventNameTemp;
export const stateEventClientNameTemp = (state) => state.events.eventClientNameTemp;
export const stateEventBrandNameTemp = (state) => state.events.eventBrandNameTemp;
export const stateEventManagerNameTemp = (state) => state.events.eventManagerNameTemp;
export const stateEventDatesTemp = (state) => state.events.eventDatesTemp;
export const stateEventLocationTemp = (state) => state.events.eventLocationTemp;
export const stateEventPrimaryContactTemp = (state) => state.events.eventPrimaryContactTemp;
export const stateAlternateContact = (state) => state.events.alternateContactTemp;
export const eventOnSiteContact = (state) => state.events.onSiteContact;
export const stateFilesToUpload = (state) => state.events.filesToUpload;
export const stateCompanyUUID = (state) => state.talent.companyUUID;
export const stateEventNotesTemp = (state) => state.events.eventNotesTemp;
export const stateEventSearchRadiusTemp = (state) => state.events.eventSearchRadiusTemp;
export const stateEventStatusTemp = (state) => state.events.eventStatusTemp;

export function* fetchEventsList(action) {
  const { payload } = action;
  const { onSuccess, onError, number, size, eventFilterObj } = payload;
  try {
    const response = yield call(eventsList, { number, size, ...eventFilterObj });

    yield call(verifyApiStatus, response);

    const eventsListData = response?.data;
    const totalItemCount = response?.headers["total-item-count"];
    const pageSize = response?.headers['page-size']
    const totalPageCount = response?.headers['total-page-count']
    const responsePagenumber = response?.headers['response-page-number']
    
    yield put(getEventsListSuccess({ eventsListData, totalItemCount, pageSize, totalPageCount, responsePagenumber }));

    onSuccess();
  } catch (error) {
    onError(error.message);
    yield put(getEventsListError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchFilteredEvents(action) {
  const { payload } = action;
  const { onSuccess, onError, filterPayload, eventFilterObj } = payload;
  try {
    const response = yield call(eventsFilter, { ...filterPayload, ...eventFilterObj });
    yield call(verifyApiStatus, response);

    const eventsListData = response?.data;
    const totalItemCount = response?.headers["total-item-count"];
    const pageSize = response?.headers['page-size'];
    const totalPageCount = response?.headers['total-page-count'];
    const responsePagenumber = response?.headers['response-page-number'];
    const searchTermsFound = response?.headers['search-terms-found'];

    yield put(getFilteredEventsListSuccess({ eventsListData, totalItemCount, pageSize, totalPageCount, responsePagenumber }));
    onSuccess(searchTermsFound);
  } catch(error) {
    onError();
    yield put(getFilteredEventsListError({}));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchEventsCoreDef(action) {
  try {
    const response = yield call(eventCoreDef);
    yield call(verifyApiStatus, response);
    const coreDef = response?.data;
    yield put(getEventsCoreDefSuccess());
  } catch (error) {
    yield put(getEventsCoreDefError(error));
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateEventName(action) {
  const { payload } = action;
  yield put(updateEventNameTemplateSuccess({ eventDetails: payload?.eventDetails }))
}

export function* updateClientName(action) {
  const { payload } = action;
  yield put(updateEventClientNameTemplateSuccess({ eventDetails: payload?.eventDetails }))
}

export function* updateBrandName(action) {
  const { payload } = action;
  yield put(updateEventBrandNameTemplateSuccess({ eventDetails: payload?.eventDetails }))
}

export function* updateOnsiteContact(value) {
  const existingOnsiteContact = yield select(eventOnSiteContact);
  const updatedOnsiteContact = _.cloneDeep(existingOnsiteContact);
  const index = updatedOnsiteContact.findIndex(contact => contact.event_contact_category_uuid === value?.event_contact_category_uuid);
  if(index !== -1) { // update
    updatedOnsiteContact[index] = value;
  } else { // create
    updatedOnsiteContact.push(value);
  }
  yield put(createOnsiteContactSuccess({ contact: updatedOnsiteContact }));
}

export function* updateAccountManagerName(action) {
  const { payload } = action;
  try {
    const accountManager = payload?.eventDetails;
    const onSiteContact = {
      person_uuid: accountManager?.value,
      event_contact_category_uuid: ACCOUNT_MANAGER_ATTRIBUTE_UUID
    }
    yield put(updateEventManagerNameTemplateSuccess({ eventDetails: accountManager }));
    yield call(updateOnsiteContact, onSiteContact);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateEventDates(action) {
  const { payload } = action;
  yield put(updateEventDatesTemplateSuccess({ eventDetails: payload?.eventDetails }));
}

export function* updateEventNotes(action) {
  const { payload } = action;
  yield put(uploadEventNotesTemplateSuccess({ eventDetails: payload?.eventDetails }));
}

export function* updateSearchRadius(action) {
  const { payload } = action;
  yield put(updateSearchRadiusTemplateSuccess({ eventDetails: payload?.eventDetails }));
}

export function* updateEventStatus(action) {
  const { payload } = action;
  yield put(updateEventStatusTemplateSuccess({ eventDetails: payload?.eventDetails }));
}

export function* fetchClientSuggestions(action) {
  const { payload } = action;
  try {
    const response = yield call(getClientSuggestion, payload);
    yield call(verifyApiStatus, response);

    const suggestions = response?.data;
    const clientSuggestions = suggestions.map(({uuid: value, name: label}) => ({value, label}))
    const pageNumber = response?.headers['response-page-number'];
    const hasMoreSuggestions = parseInt(response?.headers['total-page-count']) !== parseInt(response?.headers['response-page-number'])
    yield put(getClientSuggestionsSuccess({ clientSuggestions, pageNumber, hasMoreSuggestions }));
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchVenueSuggestions(action) {
  const { payload } = action;
  const { suggestions, pageNumber, hasMoreSuggestions } = payload;
  try {
    yield put(getVenueSuggestionsSuccess({ suggestions, pageNumber, hasMoreSuggestions }));
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createClient(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  try {
    const response = yield call(createOrganization, actualPayload);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const selectedOption = {
      "label": data?.name,
      "value": data?.uuid
    }
    onSuccess(selectedOption);
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError();
  }
}

export function* setSelectedVenue(action) {
  const { payload } = action;
  yield put(createVenueSuccess({ eventDetails: payload?.eventDetails }));
}

export function* addVenue(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  try {
    const response = yield call(createVenue, actualPayload);
    yield call(verifyApiStatus, response);
    const locationTemp = yield select(stateEventLocationTemp);
    const data = response?.data;
    const selectedOption = {
      "label": data?.display_name,
      "value": data?.uuid
    }
    locationTemp['selectedOption'] = selectedOption;
    locationTemp["value"] = data?.primary_address?.primary_venue_address_space?.uuid;
    yield put(createVenueSuccess({ eventDetails: locationTemp }));
    onSuccess(data);
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError();
  }
}

export function* setContact(action) {
  const { payload } = action;
  const { onSuccess, onError, title, isPrimary, displayName, phoneNumber, actualPayload } = payload;
  try {
    const response = yield call(createPerson, actualPayload);
    const data = response?.data;
    yield call(verifyApiStatus, response);
    const onsiteContact = {
      "person_uuid": data?.uuid,
      "event_contact_category_uuid": isPrimary ? PRIMARY_CONTACT_ATTRIBUTE_UUID : OTHER_EVENT_CATEGORY_UUID,
      "phone_number": phoneNumber, 
      "alt_contact_cat": title
    }
    const selectedOption = {
      "label": displayName,
      "value": data?.uuid
    }
    onSuccess(selectedOption);
    yield call(updateOnsiteContact, onsiteContact);
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError();
  }
}

export function* updateContact(action) {
  const { payload } = action;
  const { onSuccess, onError, title, phoneNumber, actualPayload } = payload;
  try {
    const response = yield call(updateCorePersonAttributes, actualPayload);
    yield call(verifyApiStatus, response);
    const onsiteContact = {
      "person_uuid": response?.data?.uuid,
      "event_contact_category_uuid": OTHER_EVENT_CATEGORY_UUID,
      "phone_number": phoneNumber, 
      "alt_contact_cat": title
    }
    onSuccess();
    yield call(updateOnsiteContact, onsiteContact);
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError();
  }
}

export function* uploadAttachment(assetArray) {
  try {
    const companyUUID = yield select(stateCompanyUUID);
    let attachmentResponseArray = [];
    const response = yield all(assetArray.map(asset => call(uploadAsset, { asset, companyUUID, eventUUID: uuidv4() })));
    yield all(response.map(resp => call(verifyApiStatus, resp)));
    response.forEach(({data}, index) => {
      attachmentResponseArray.push({
        value: data?.asset_link,
        option_selection: null,
        asset_mimetype: data?.mimetype,
        asset_filename: data?.asset_orig_filename,
        asset_secured: true,
        caption: data?.asset_orig_filename,
        self_rating: null,
        company_rating: null,
        event_attribute_uuid: ATTACHMENT_EVENT_ATTRIBUTE_UUID
      });
    });
    yield put(addFilesToUpload([]));
    return attachmentResponseArray;
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    return error;
  }
}

export function* addAttachment(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  try {
    const attachmentResponseArray = yield call(uploadAttachment, actualPayload);
    yield put(addAttachmentSuccess({ attachments: attachmentResponseArray }));
    onSuccess(attachmentResponseArray);
  }
  catch (error) {
    onError()
    yield call(verifyApiStatus, error?.response);
  }
}

export function* setAlternateContactTemp(action) {
  const { payload } = action;
  try {
    yield put(alternateContactTempSuccess({ altContact: payload?.alternateContact }));
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateAlternateContactTemp(action) {
  const { payload } = action;
  const { alternateContact, updatedContact='', onSuccess=()=>{}, onError=()=>{} } = payload
  try {
    let altIdList = []
    alternateContact.forEach(alt => altIdList.push(alt['value']))
    yield put(deleteAlternateContactSuccess({ altContact: alternateContact, altIdList: altIdList }));
    onSuccess(updatedContact)
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError()
  }
}

export function* setPrimaryContact(action) {
  const { payload } = action;
  const { eventDetails, onSuccess=()=>{}, onError=()=>{} } = payload
  try {
    const primaryContact = eventDetails;
    const onsiteContact = {
      "person_uuid": primaryContact?.value,
      "event_contact_category_uuid": PRIMARY_CONTACT_ATTRIBUTE_UUID,
      "alt_contact_cat": "Primary contact"
    }
    yield put(primaryContactSuccess({ contact: primaryContact }));
    onSuccess(eventDetails)
    yield call(updateOnsiteContact, onsiteContact);
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    onError()
  }
}

export function* updateEventPersonRecord(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  try {
    const response = yield call(updateEventPerson, actualPayload);
    if(onSuccess) onSuccess(response);
  }
  catch (error) {
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getEventPeople(action) {
  try {
    const altContact = yield select(stateAlternateContact);
    const people = []
    altContact.forEach(contact => {
      const tempObj = {
        "person_uuid": contact?.value,
        "event_contact_category_uuid": OTHER_EVENT_CATEGORY_UUID,
        "alt_contact_cat": contact?.title
      }
      people.push(tempObj)
    })
    const eventPeople = yield select(eventOnSiteContact);
    return [...eventPeople, ...people];
  }
  catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getEventShifts(action) {
  try {
    const eventShifts = [];
    const eventNameTemp = yield select(stateEventNameTemp);
    const eventDateTemp = yield select(stateEventDatesTemp);
    const { name } = eventNameTemp;
    eventShifts.push({
      name: name,
      local_start_at: eventDateTemp?.value?.startDate,
      local_end_at: eventDateTemp?.value?.endDate,
      is_auto_generated: !!eventDateTemp?.isAutoGenerated
    });

    return eventShifts;
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getEventAttributes(action) {
  // for attachments
  let eventAttributes = [];
  const attachementsToUpload = yield select(stateFilesToUpload);
  if(attachementsToUpload?.length) {
    const attachmentResponseArray = yield call(uploadAttachment, attachementsToUpload);
    eventAttributes = [...eventAttributes, ...attachmentResponseArray];
  }
  return eventAttributes;
}

export function* createEventRecord(action) {
  const { payload } = action;
  const { onSuccess, onError } = payload;
  try {
    const name = yield select(stateEventNameTemp);
    const clientOrganizationUUID = yield select(stateEventClientNameTemp);
    const oboOrganizationUUID = yield select(stateEventBrandNameTemp);
    const notesTemp = yield select(stateEventNotesTemp);
    const searchRadiusTemp = yield select(stateEventSearchRadiusTemp);
    const eventDateTemp = yield select(stateEventDatesTemp);
    const statusTemp = yield select(stateEventStatusTemp);
    const eventLocationTemp = yield select(stateEventLocationTemp);

    const searchRadiusOption = getKeyByValue(searchRadiusTemp?.optionsList, searchRadiusTemp?.optionSelection);
    const statusOption = getKeyByValue(statusTemp?.optionsList, statusTemp?.optionSelection);

    const eventPayload = {};
    eventPayload['event_category_uuid'] = EVENT_CATEGORY_UUID;
    eventPayload['name'] = name?.value;
    eventPayload['client_organization_uuid'] = clientOrganizationUUID?.value;
    eventPayload['people'] = yield call(getEventPeople);
    eventPayload['event_attributes'] = yield call(getEventAttributes);
    eventPayload['event_shifts'] = yield call(getEventShifts);
    eventPayload['primary_venue_address_space_uuid'] = eventLocationTemp?.value;
    eventPayload["local_start_at"] = eventDateTemp?.value?.startDate;
    eventPayload["local_end_at"] = eventDateTemp?.value?.endDate;
    if(oboOrganizationUUID?.value) {
      eventPayload['obo_organization_uuid'] = oboOrganizationUUID?.value;
    }
    if(notesTemp?.value) {
      eventPayload['desc'] = notesTemp.value;
    }
    if (searchRadiusOption) {
      eventPayload['search_radius'] = parseFloat(searchRadiusOption);
    }
    if (statusOption) {
      eventPayload['status'] = statusOption;
    }
    const response = yield call(createEvent, eventPayload);
    onSuccess(response);
  }
  catch (error) {
    console.log("error", error);
    onError(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateEventDetails(eventData) {
  try {
    const eventNameTemp = yield select(stateEventNameTemp);
    const eventClientNameTemp = yield select(stateEventClientNameTemp);
    const eventBrandNameTemp = yield select(stateEventBrandNameTemp);
    const eventManagerNameTemp = yield select(stateEventManagerNameTemp);
    const eventLocationTemp = yield select(stateEventLocationTemp);
    const eventPrimaryContactTemp = yield select(stateEventPrimaryContactTemp);
    const eventNotesTemp = yield select(stateEventNotesTemp);
    const searchRadiusTemp = yield select(stateEventSearchRadiusTemp);
    const eventDatesTemp = yield select(stateEventDatesTemp);
    const statusTemp = yield select(stateEventStatusTemp);

    const {
      name,
      desc,
      client_organization,
      obo_organization,
      event_attributes,
      people,
      local_start_at,
      local_end_at,
      event_shifts,
      simplified_id,
      search_radius,
      is_date_changeable,
      primary_venue_address_space,
      status,
      status_options
    } = eventData;
    if (name) {
      eventNameTemp.value = name
      yield put(updateEventNameTemplate({ eventDetails: eventNameTemp }));
    }
    if (simplified_id) {
      yield put(updateEventIdSuccess({ id: simplified_id }))
    }
    if (desc) {
      eventNotesTemp.value = desc
      yield put(updateEventNotesTemplate({ eventDetails: eventNotesTemp }));
    }
    if (search_radius) {
      searchRadiusTemp.optionSelection = searchRadiusTemp?.optionsList[search_radius];
      yield put(updateSearchRadiusTemplate({ eventDetails: searchRadiusTemp }))
    }
    if (client_organization) {
      eventClientNameTemp.value = client_organization?.uuid;
      const clientOption = {};
      delete Object.assign(clientOption, client_organization, {'value': client_organization['uuid'] })['uuid'];
      delete Object.assign(clientOption, client_organization, {'label': client_organization['name'] })['name'];
      eventClientNameTemp.selectedOption = clientOption;
      yield put(updateEventClientNameTemplate({ eventDetails: eventClientNameTemp }))
    }
    if (obo_organization) {
      eventBrandNameTemp.value = obo_organization?.uuid;
      const brandOption = {};
      delete Object.assign(brandOption, obo_organization, {'value': obo_organization['uuid'] })['uuid'];
      delete Object.assign(brandOption, obo_organization, {'label': obo_organization['name'] })['name'];
      eventBrandNameTemp.selectedOption = brandOption;
      yield put(updateEventBrandNameTemplate({ eventDetails: eventBrandNameTemp }));
    }
    if (primary_venue_address_space) {
      const { display_name, uuid } = primary_venue_address_space;
      const selectedVenue = {};
      selectedVenue['value'] = uuid;
      selectedVenue['label'] = display_name;
      eventLocationTemp.value = uuid;
      eventLocationTemp.selectedOption = selectedVenue;
      yield put(setVenue({ eventDetails: eventLocationTemp }));
    }
    if (people) {
      let altContactIdList = []
      let altContact = []
      yield people.forEach(person => {
        const slug = person?.event_contact_category_uuid?.slug;
        const name = person?.event_contact_category_uuid?.name;
        const personUUID = person?.uuid;
        const value = person?.person_uuid?.uuid;
        const label = person?.display_name;
        const selectedOption = {}
        selectedOption['value'] = value;
        selectedOption['label'] = label;
        if (slug === 'am') {
          eventManagerNameTemp.value = value;
          eventManagerNameTemp.selectedOption = selectedOption;
          eventManagerNameTemp.personRecordUUID = personUUID;
          put(updateEventManagerNameTemplate({ eventDetails: eventManagerNameTemp }));
        } else if (slug === 'contact' && name.toLowerCase() === 'primary contact') {
          eventPrimaryContactTemp.value = value;
          eventPrimaryContactTemp.selectedOption = selectedOption;
          eventPrimaryContactTemp.personRecordUUID = personUUID;
          put(primaryContact({ eventDetails: eventPrimaryContactTemp }));
        } else {
          const contact = _.cloneDeep(eventAlternateContactTemplate[0]);
          const length = altContact.length + 1;
          contact['id'] = `${contact['id']}${length}`;
          contact['name'] = `${contact['name']}_${length}`;
          contact['value'] = value;
          contact['selectedOption'] = selectedOption;
          contact['personRecordUUID'] = personUUID;
          altContact.push(contact)
          altContactIdList.push(value)
        }
      })
      yield put(deleteAlternateContact({ alternateContact: altContact, altIdList: altContactIdList }));
    }
    if(event_attributes?.file && event_attributes?.file?.length) {
      yield put(addAttachmentSuccess({ attachments: event_attributes.file }));
    }
    if(local_start_at && local_end_at) {
      const formattedStart = moment(local_start_at).format("YYYY-MM-DD");
      const formattedEnd = moment(local_end_at).format("YYYY-MM-DD");
      eventDatesTemp.value.startDate = formattedStart;
      eventDatesTemp.value.endDate = formattedEnd;
    }
    eventDatesTemp.isDateChangeable = !!is_date_changeable;
    yield put(updateEventDatesTemplate({ eventDetails: eventDatesTemp }));
    if(event_shifts.length) {
      yield call(updateEventShiftToRedux, event_shifts);
    }
    if (status_options) {
      statusTemp['optionsList'] = status_options;
      const statusTempOptions = objToArrayOfVal(status_options)
      statusTemp['options'] = statusTempOptions;
      statusTemp['optionSelection'] = statusTemp?.optionsList[status]
      yield put(updateEventStatusTemplateSuccess({ eventDetails: statusTemp }));
    }
    yield put(getEventSuccess())
  } catch (error) {
    console.log('error on fetching event data', error)
  }
}

export function* updateEventData(eventData) {
  try {
    yield call(updateEventDetails, eventData);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchEvent(action) {
  try {
    const { payload } = action;
    const response = yield call(getEventData, payload?.id);
    yield call(verifyApiStatus, response);

    const eventData = response?.data;
    yield call(updateEventData, eventData);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deleteEventAttribute(id) {
  try {
    const response = yield call(deleteAttribute, id);
    yield call(verifyApiStatus, response);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deleteEventAsset(action) {
  const { payload } = action;
  const { asset, onSuccess } = payload;
  const { uuid, asset_uuid, event_attribute } = asset;
  try {
    const stateAttachments = yield select(stateEventAttachedDocuments);
    const existingAttachments = _.cloneDeep(stateAttachments);
    const response = yield call(deleteAsset, { asset_uuid, company_uuid: event_attribute?.company });
    yield call(verifyApiStatus, response);
    yield call(deleteEventAttribute, uuid)
    const index = existingAttachments.findIndex(attachment => attachment.asset_uuid === asset_uuid);
    if(index !== -1) {
      existingAttachments.splice(index, 1);
    }
    yield put(addAttachmentSuccess({ attachments: existingAttachments }));
    onSuccess();
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error.response);
  }
}

export function* addnewEventAsset(action) {
  const { payload } = action;
  const { assets, onSuccess, eventId } = payload;
  try {
    const stateAttachments = yield select(stateEventAttachedDocuments);
    const existingAttachments = _.cloneDeep(stateAttachments);
    const attachmentResponseArray = yield call(uploadAttachment, assets);
    const response = yield all(attachmentResponseArray.map(asset => call(updateAttribute, { ...asset, event_uuid: eventId  })));
    yield call(verifyApiStatus, response);
    response.forEach(resp => existingAttachments.push(resp?.data));
    yield put(addAttachmentSuccess({ attachments: existingAttachments }));
    onSuccess();
  } catch(error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error.response);
  }
}

export function* clearEvent(action) {
  const { payload } = action;
  const { onSuccess, onError, id } = payload;
  try {
    const response = yield call(deleteEvent, id);
    yield call(verifyApiStatus, response);
    onSuccess();
  } catch (error) {
    onError();
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateEventCore(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  try {
    const statusTemp = yield select(stateEventStatusTemp);
    const response = yield call(updateEvent, actualPayload);
    const data = response?.data;
    yield call(verifyApiStatus, response);
    onSuccess();
    if (data) {
      const { status_options, status, is_date_changeable, local_start_at, local_end_at } = data;
      const eventDate = yield select(stateEventDatesTemp);
      const eventDateTemp = _.cloneDeep(eventDate);
      eventDateTemp["isDateChangeable"] = !!is_date_changeable;
      if(local_start_at && local_end_at) {
        eventDateTemp.value.startDate = moment(local_start_at).format("YYYY-MM-DD");
        eventDateTemp.value.endDate = moment(local_end_at).format("YYYY-MM-DD");
      }
      yield put(updateEventDatesTemplate({ eventDetails: eventDateTemp }));
      if (status_options) {
        statusTemp["optionsList"] = status_options;
        const statusTempOptions = objToArrayOfVal(status_options);
        statusTemp["options"] = statusTempOptions;
        statusTemp["optionSelection"] = statusTemp?.optionsList[status];
        yield put(
          updateEventStatusTemplateSuccess({ eventDetails: statusTemp })
        );
      }
    }
  } catch (error) {
    onError();
    yield call(verifyApiStatus, error?.response);
  }
}

export function* createPersonRecord(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload, contact, isPrimary } = payload;
  try {
    const response = yield call(createEventPerson, actualPayload);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const contactLabel = data?.display_name;
    const personRecordUUID = data?.uuid;
    if (contactLabel) { contact['selectedOption']['label'] = contactLabel }
    if (personRecordUUID) { contact['personRecordUUID'] = personRecordUUID }
    onSuccess(contact, isPrimary);
  } catch (error) {
    onError();
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deletePersonRecord(action) {
  const { payload } = action;
  const { onSuccess, onError, id, contact, isPrimary } = payload;
  try {
    const response = yield call(deleteEventPerson, id);
    yield call(verifyApiStatus, response);
    onSuccess(contact, isPrimary);
  } catch (error) {
    onError();
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateEventShiftsVenue(payload) {
  try {
    yield call(updateShiftVenue, payload);
  } catch (error) {
    console.log('Error on updateEventShiftsVenue', error)
  }
}

export function* updateEventPrimaryVenue(action) {
  const { payload } = action;
  const { id, venueId } = payload;
  try {
    const response = yield call(getAllEventShifts, id);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const shiftIdList = objToArrayOfKey(data)
    const payload = {}
    payload['uuids'] = shiftIdList;
    payload['primary_venue_address_space_uuid'] = venueId;
    yield call(updateEventShiftsVenue, payload);
    yield put(getUpdatedEventShifts({ id }));
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* fetchEventStatusOptions() {
  try {
    const response = yield call(eventStatuses);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const statusTemp = yield select(stateEventStatusTemp);
    statusTemp['optionsList'] = data;
    const statusTempOptions = objToArrayOfVal(data)
    statusTemp['options'] = statusTempOptions;
    yield put(updateEventStatusTemplateSuccess({ eventDetails: statusTemp }));
  } catch (error) {
    console.log('error occured on fetching time zones', error);
  }
}

export function* getEventDateDropdownOptions() {
  try {
    const response = yield call(getEventDateRangeList);
    yield call(verifyApiStatus, response);
    const dropdownResponse = {};
    if(response?.data) {
      dropdownResponse['optionsList'] = response?.data;
      dropdownResponse['options'] = objToArrayOfValWithKey(response?.data);
      console.log("objToArrayOfVal", objToArrayOfVal(response?.data));
      yield put(getEventDateDropdownOptionsSuccess({ dropdownResponse }));
    }
  } catch (error) {
    console.log(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getEventSortDropdownOptions() {
  try {
    const response = yield call(getEventSortOrderList);
    yield call(verifyApiStatus, response);
    const dropdownResponse = {};
    if(response?.data) {
      dropdownResponse['optionsList'] = response?.data;
      dropdownResponse['options'] = objToArrayOfValWithKey(response?.data);
      yield put(getEventSortDropdownOptionsSuccess({ dropdownResponse }));
    }
  } catch (error) {
    console.log(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* getDefaultEventFilters() {
  try {
    const response = yield call(getEventSearchDefaults);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      const data = response?.data;
      const filterObj = {
        dateRangeSlug: data["date_range_slug"],
        sortOrderSlug: data["sort_order_slug"],
        includePastEvents: data["include_past_events"]
      }
      yield put(updateEventFilters({ filterObj }));
    }
  } catch (error) {
    console.log(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* saga() {
  yield takeLatest(GET_EVENTS_LIST, fetchEventsList);
  yield takeLatest(GET_EVENTS_CORE_DEF, fetchEventsCoreDef);
  yield takeLatest(GET_FILTERED_EVENTS_LIST, fetchFilteredEvents);
  yield takeLatest(UPDATE_EVENT_NAME_TEMPLATE, updateEventName);
  yield takeLatest(UPDATE_EVENT_CLIENT_NAME_TEMPLATE, updateClientName);
  yield takeLatest(UPDATE_EVENT_NOTES_TEMPLATE, updateEventNotes);
  yield takeLatest(UPDATE_EVENT_BRAND_NAME_TEMPLATE, updateBrandName);
  yield takeLatest(UPDATE_EVENT_MANAGER_NAME_TEMPLATE, updateAccountManagerName);
  yield takeLatest(UPDATE_EVENT_DATES_TEMPLATE, updateEventDates);
  yield takeLatest(GET_CLIENT_SUGGESTIONS, fetchClientSuggestions);
  yield takeLatest(CREATE_ORGANIZATION, createClient);
  yield takeLatest(GET_VENUE_SUGGESTIONS, fetchVenueSuggestions);
  yield takeLatest(CREATE_VENUE, addVenue);
  yield takeLatest(CREATE_ONSITE_CONTACT, setContact);
  yield takeLatest(ADD_ATTACHMENT, addAttachment);
  yield takeLatest(EDIT_ONSITE_CONTACT, updateContact);
  yield takeLatest(SET_VENUE, setSelectedVenue);
  yield takeLatest(ALTERNATE_CONTACT_TEMP, setAlternateContactTemp);
  yield takeLatest(DELETE_ALTERNATE_CONTACT_TEMP, updateAlternateContactTemp);
  yield takeLatest(PRIMARY_CONTACT, setPrimaryContact);
  yield takeLatest(UPDATE_EVENT_PERSON_RECORD, updateEventPersonRecord);
  yield takeLatest(GET_EVENT, fetchEvent);
  yield takeLatest(CREATE_EVENT, createEventRecord);
  yield takeLatest(DELETE_ASSET, deleteEventAsset);
  yield takeLatest(DELETE_EVENT, clearEvent);
  yield takeLatest(ADD_NEW_ASSET, addnewEventAsset);
  yield takeLatest(UPDATE_EVENT_CORE_ATTR, updateEventCore);
  yield takeLatest(CREATE_EVENT_PERSON_RECORD, createPersonRecord);
  yield takeLatest(DELETE_EVENT_PERSON_RECORD, deletePersonRecord);
  yield takeLatest(UPDATE_SEARCH_RADIUS_TEMPLATE, updateSearchRadius);
  yield takeLatest(UPDATE_EVENT_SHIFT_VENUE, updateEventPrimaryVenue);
  yield takeLatest(UPDATE_EVENT_STATUS_TEMPLATE, updateEventStatus);
  yield takeLatest(GET_EVENT_STATUS_OPTIONS, fetchEventStatusOptions);
  yield takeLatest(GET_EVENT_DATE_DROPDOWN_OPTIONS, getEventDateDropdownOptions);
  yield takeLatest(GET_EVENT_SORT_DROPDOWN_OPTIONS, getEventSortDropdownOptions);
  yield takeLatest(GET_DEFAULT_EVENT_FILTERS, getDefaultEventFilters);
}

export function* eventSagas() {
  yield all([call(saga)]);
}
