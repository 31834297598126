import React from 'react';
import { connect } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { RoutingConstants } from '../../constants';
import isTokenExpired from '../../utils/isTokenExpired';
import { logOut } from '../../store/actions/auth';
import localCacheStorage from '../../utils/localCacheStorage';

const ProtectedRoute = (props) => {
  const {
    accessToken,
    tokenExpires
  } = props;
  const isValidToken = isTokenExpired(tokenExpires);
  if (!accessToken) {
    localCacheStorage.clear("APP_STATE");
    localCacheStorage.clear("isFilter");
    localCacheStorage.clear("filters");
    localCacheStorage.clear("searchTags");
    localStorage.clear();
    localCacheStorage.clear();
    return (
      <Navigate to={RoutingConstants.login} />
    )
  }
  if (accessToken && isValidToken) {
    return (
      <Outlet />
    )
  }
  localCacheStorage.clear("APP_STATE");
  localCacheStorage.clear("isFilter");
  localCacheStorage.clear("filters");
  localCacheStorage.clear("searchTags");
  localStorage.clear();
  localCacheStorage.clear();
  return (
    <Navigate to={RoutingConstants.login} />
  )
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.loginDetails?.access_token,
    tokenExpires: state.auth.loginDetails?.token_expires || ''
  };
};

const mapDispatchToProps = {
  triggerLogout: logOut
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
