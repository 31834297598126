export default Object.freeze({
  // get from session storage
  get(key) {
    try {
      return JSON.parse(window.sessionStorage.getItem(key));
    } catch (err) {
      return;
    }
  },
  // save to session storage
  set(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state));
  },
  // clear from session storage
  clear(key) {
    window.sessionStorage.removeItem(key);
  },
});

