import React, { useCallback, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { OTHER_EVENT_CATEGORY_UUID, PRIMARY_CONTACT_ATTRIBUTE_UUID, eventAlternateContactTemplate, eventBtnText, eventStrings, squareRounded, updatedSuccessMessage, updatedErrorMessage, createdSuccessMessage, createdErrorMessage, ifContainsAlphabets, deletedErrorMessage, deletedSuccessMessage } from '../../../../constants/events';
import _ from 'lodash';
import { alternateContactTemp, checkApiStatus, createEventPersonRecord, deleteAlternateContact, deleteEventPersonRecord, primaryContact, updateEventPerson } from '../../../../store/actions/events';
import { ReactSelectPaginate } from '../../components/reactSelectPaginate';
import { getAlternateContactSuggestion, getPrimaryContactSuggestion } from '../../../../api/events';
import AddContact from './AddContact';
import trash from "../../../../assets/svg/trash.svg";
import { Button } from 'antd';
import EditContact from './EditContact';
import editIcon from "../../../../assets/images/editIcon02.png";
import { error, success } from '../../../toast';

const OnSiteContact = (props) => {
  const {
    eventPrimaryContactTemp,
    triggerCheckApiStatus,
    alternateContactTemp,
    triggerUpdateAltContact,
    triggerDeleteAltContact,
    triggerSetPrimaryContact, 
    isEditEvent,
    triggerUpdateEventPerson,
    eventId,
    triggerCreateEventPerson,
    triggerDeleteEventPerson,
    altIds=[]
  } = props;
  let primaryContact = _.cloneDeep(eventPrimaryContactTemp);
  const [showPrimaryContactModal, setShowPrimaryContactModal] = useState(false);
  const [showAltCreateContactModal, setAltCreateContactModal] = useState(false);
  const [showPrimaryEditContactModal, setShowPrimaryEditContactModal] = useState(false);
  const [showAltEditContactModal, setShowAltEditContactModal] = useState(false);
  const [showAlternateContactBtn, setShowAlternateContactBtn] = useState(true);
  const [selectedAltId, setSelectedAltId] = useState('');
  const [editData, setEditData] = useState({});
  const [removeEditBtn, setRemoveEditBtn] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');

  const resetBtns = () => {
    setRemoveEditBtn(false);
    setSelectedContact('');
    setEditData({});
    setSelectedAltId('');
  }
  
  const getPrimaryContactOptions = async(search, prevOptions, { page }) => {
    try {
      let payload = {
        number: page,
        size: 10,
        actualPayload: {
          search_field: search
        }
      }
      const response = await getPrimaryContactSuggestion(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const primarySuggestions = suggestions.map(({uuid: value, dropdown_menu_name: label}) => ({value, label}));
      if(!primarySuggestions.length) return { options: [] };
      const pageNumber = parseInt(response?.headers['response-page-number']);
      const totalPageCount = parseInt(response?.headers['total-page-count']);
      const hasMore = totalPageCount !== pageNumber;
      const options = getOptions(primarySuggestions, search, hasMore, prevOptions, page, eventPrimaryContactTemp?.selectedOption);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getAlternateContactOptions = async(search, prevOptions, { page }, selectedOption) => {
    try {
      let payload = {
        number: page,
        size: 10,
        userInput: search
      }
      const response = await getAlternateContactSuggestion(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const primarySuggestions = suggestions.map(({uuid: value, dropdown_menu_name: label}) => ({value, label}));
      if(!primarySuggestions.length) return { options: [] };
      const pageNumber = parseInt(response?.headers['response-page-number']);
      const totalPageCount = parseInt(response?.headers['total-page-count']);
      const hasMore = totalPageCount !== pageNumber;
      const options = getOptions(primarySuggestions, search, hasMore, prevOptions, page, selectedOption);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getOptions = (suggestions, search, hasMoreSuggestions, prevOptions, page, selectedOption = {}, oldOption = {}) => {
    let filteredOptions = [];
    const hasMore = hasMoreSuggestions;
    if (!suggestions.length) return { options: [] }
    if (suggestions?.length) {
      if(altIds?.length) {
        suggestions = suggestions?.filter(option => altIds.length && !altIds.includes(option?.value));
      }
      if(primaryContact?.value) {
        suggestions = suggestions?.filter((option) => option?.value !== primaryContact.value);
      }
      suggestions = filterOldOptions(suggestions)
    }

    if(suggestions?.length && selectedOption && selectedOption?.value) { // to make selected option as first one
      suggestions = suggestions?.filter(option => (option?.value !== selectedOption?.value));
      suggestions = filterOldOptions(suggestions);
      if (page === 1) {
        suggestions.unshift(selectedOption);
      }
    }

    if (!search) {
      return {
        options: suggestions, hasMore,
        additional: {
          page: page + 1
        }
      };
    }
    const searchLower = search.toLowerCase();
    filteredOptions = suggestions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
    const slicedOptions = filteredOptions.slice( prevOptions.length, prevOptions.length + 10 );
    return {
      options: slicedOptions, hasMore,
      additional: {
        page: page + 1
      }
    };
  }

  const filterOldOptions = (suggestions) => {
    const oldOptionIds = []
    const alternateContact = _.cloneDeep(alternateContactTemp);
    alternateContact.forEach(contact => {
      if (contact['oldOption']) {
        oldOptionIds.push(contact['oldOption']?.value)
      }
    })
    if (primaryContact?.oldOption) {
      oldOptionIds.push(primaryContact['oldOption']?.value)
    }
    if (!oldOptionIds.length) return suggestions;
    suggestions = suggestions.filter(suggestion => !oldOptionIds.includes(suggestion?.value))
    return suggestions
  }

  const updatePrimaryContact = (option) => {
    if (primaryContact['personRecordUUID'] && primaryContact['selectedOption']?.value !== option?.value) {
      primaryContact['oldOption'] = primaryContact['selectedOption']
    }
    primaryContact['value'] = option?.value;
    primaryContact['selectedOption'] = option;
    triggerSetPrimaryContact({ eventDetails: primaryContact });

    if (Object.keys(editData).length) {
      setEditData({})
    }
  }

  const createNewContact = (option, isPrimary) => {
    if (isEditEvent) {
      const contact = getSelectedContact(isPrimary)
      if (contact['personRecordUUID'] && contact['selectedOption']?.value !== option?.value) {
        contact['oldOption'] = contact['selectedOption']
      }
      contact['value'] = option?.value
      contact['selectedOption'] = option
      return saveEditContact(isPrimary, contact)
    }

    onUpdateContact(option, isPrimary)
  }

  const onUpdateContact = (option, isPrimary, altId='') => {
    const selectedId = altId ? altId : selectedAltId;
    if (isPrimary) {
      updatePrimaryContact(option)
    } else {
      updateAlternateContact(option, selectedId)
    }
  }

  const createContact = (contact, isPrimary, isDeleteAndCreate) => {
    const actualPayload = getSaveContactPayload(contact, isPrimary)
    const payload = {
      actualPayload : actualPayload,
      contact: contact,
      isPrimary: isPrimary,
      onSuccess: (contact, isPrimary) => {
        if (isPrimary) {
          triggerSetPrimaryContact({ eventDetails: contact });
          if (isDeleteAndCreate) {
            success(updatedSuccessMessage('Contact'))
          } else {
            success(createdSuccessMessage('Contact'))
          }
        }
        if (!isPrimary) {
          const altContact = _.cloneDeep(alternateContactTemp)
          if (contact['oldOption']) delete contact['oldOption']
          const index = altContact.findIndex(item => item.id === contact.id);
          altContact.splice(index, 1, contact);
          const payload = {
            alternateContact: altContact,
            updatedContact: contact,
            onSuccess: () => {
              resetBtns();
              if (isDeleteAndCreate) {
                success(updatedSuccessMessage('Contact'))
              } else {
                success(createdSuccessMessage('Contact'))
              }
            },
            onError: () => {
              if (isDeleteAndCreate) {
                error(updatedErrorMessage('Contact'))
              } else {
                error(createdErrorMessage('Contact'))
              }
              resetBtns();
            }
          }
          triggerDeleteAltContact(payload);
        }
        
      },
      onError: () => {
        error(createdErrorMessage('Contact'))
        resetBtns();
      }
    }
    triggerCreateEventPerson(payload);
  }

  const getSaveContactPayload = (contact, isPrimary) => {
    const actualPayload = {}
    const personData = getPersonData(contact?.selectedOption);
    const { phone='', title='' } = personData
    actualPayload['event_uuid'] = eventId
    actualPayload['person_uuid'] = contact?.value
    actualPayload['event_contact_category_uuid'] = isPrimary ? PRIMARY_CONTACT_ATTRIBUTE_UUID : OTHER_EVENT_CATEGORY_UUID
    if (phone) actualPayload['phone_number'] = phone
    if (title) actualPayload['alt_contact_cat'] = isPrimary ? eventStrings.primaryContact : title
    return actualPayload
  }

  const getSelectedContact = (isPrimary, altId='') => {
    let altContact = '';
    const alternateContact = _.cloneDeep(alternateContactTemp);
    if (!isPrimary) {
      alternateContact.forEach(contact => {
        if (altId && contact.id === altId) {
          altContact = contact
        } else if (!altId && selectedAltId && contact.id === selectedAltId) {
          altContact = contact
        }
      })
    }
    const contact = isPrimary ? primaryContact : altContact;
    return contact
  }

  const deleteContact = (contact, isPrimary, isUpdateContact) => {
    if (!contact['personRecordUUID']) {
      return
    }
    const payload = {
      id : contact['personRecordUUID'],
      contact: contact,
      isPrimary: isPrimary,
      onSuccess: (contact, isPrimary) => {
        if (isUpdateContact) {
          createContact(contact, isPrimary, true)
        } else {
          success(deletedSuccessMessage('Contact'));
        }
      },
      onError: () => {
        error(deletedErrorMessage('Contact'));
        resetBtns();
      }
    }
    triggerDeleteEventPerson(payload);
  }

  const deleteAndUpdateContact = (contact, isPrimary) => {
    deleteContact(contact, isPrimary, true)
  }

  const saveEditContact = (isPrimary, updatedContact='', altId='') => {
    const actualPayload = {}
    let contact = updatedContact
    if (!updatedContact) {
      contact = getSelectedContact(isPrimary, altId);
    }

    if (!contact?.value) {
      return
    }

    const isNewContact = contact['oldOption']?.value !== contact['selectedOption']?.value

    if(contact && !contact['personRecordUUID'].length) {
      return createContact(contact, isPrimary, false)
    }
    if(contact && contact['personRecordUUID'].length && isNewContact) {
      return deleteAndUpdateContact(contact, isPrimary)
    }
    const personData = getPersonData(contact?.selectedOption);
    const { phone='', title='' } = personData
    actualPayload['uuid'] = contact['personRecordUUID']
    actualPayload['person_uuid'] = contact['selectedOption']?.value
    if (phone) actualPayload['phone_number'] = phone
    if (title) actualPayload['alt_contact_cat'] = isPrimary ? eventStrings.primaryContact : title
    if (contact['oldOption']) delete contact['oldOption']
    if (isPrimary) {
      triggerSetPrimaryContact({ eventDetails: contact });
    } else {
      triggerDeleteAltContact({ alternateContact: contact });
    }
    const payload = {
      actualPayload : actualPayload,
      onSuccess: () => {
        success(updatedSuccessMessage('Contact'));
        resetBtns();
      },
      onError: () => {
        error(updatedErrorMessage('Contact'));
        resetBtns();
      }
    }
    triggerUpdateEventPerson(payload);
  }

  const removePrimaryEditOptions = useCallback(() => {
    if (selectedContact && selectedContact === 'primary' && removeEditBtn) {
      return true;
    }
    return false;
  }, [removeEditBtn, selectedContact])

  const removeAltEditOptions = useCallback((id) => {
    if (selectedAltId && selectedAltId === id && selectedContact === 'alt' && removeEditBtn) {
      return true;
    }
    return false;
  }, [removeEditBtn, selectedContact, selectedAltId])

  const removeEditIcons = async (isPrimary) => {
    const contact = isPrimary ? 'primary' : 'alt'
    await setSelectedContact(contact);
    await setRemoveEditBtn(true);
  }

  const editAndSave = async(contact, isPrimary, title='') => {
    const altContact = _.cloneDeep(alternateContactTemp);
    let selectedContact = getSelectedContact(isPrimary)
    if (selectedContact?.personRecordUUID && selectedContact['selectedOption']?.value !== contact?.value) {
      selectedContact["oldOption"] = selectedContact['selectedOption']
    }
    selectedContact['value'] = contact.value
    selectedContact['selectedOption'] = contact
    selectedContact['title'] = isPrimary ? 'Primary contact' : title
    if (isPrimary) {
      const payload = {
        eventDetails: selectedContact,
        onSuccess: (selectedContact) => {
          setRemoveEditBtn(true);
          setSelectedContact('primary');
          if (isEditEvent) { saveEditContact(isPrimary, selectedContact) }
        },
        onError: () => {
          setSelectedContact('');
          setRemoveEditBtn(false);
        }
      }
      await triggerSetPrimaryContact(payload);
    } else {
      const index = altContact.findIndex(item => item.id === selectedContact?.id);
      altContact.splice(index, 1, selectedContact);
      const payload = {
        alternateContact: altContact,
        updatedContact: selectedContact,
        onSuccess: (updatedContact) => {
          setRemoveEditBtn(true);
          setSelectedContact('alt');
          if (isEditEvent) { saveEditContact(isPrimary, updatedContact) }
        },
        onError: () => {
          setSelectedContact('');
          setRemoveEditBtn(false);
        }
      }
      await triggerDeleteAltContact(payload);
    }
  }

  const updateAlternateContact = (option, id) => {
    const altContact = _.cloneDeep(alternateContactTemp);
    altContact.forEach(contact => {
      if (contact.id === id) {
        if (contact['personRecordUUID'] && contact['selectedOption']?.value !== option?.value) {
          contact['oldOption'] = contact['selectedOption']
        }
        contact['value'] = option?.value;
        contact['selectedOption'] = option;
      }
    })
    triggerDeleteAltContact({ alternateContact: altContact });
    if (Object.keys(editData).length) {
      setEditData({})
    }
  }

  const updateAltContactTemplate = () => {
    const contact = _.cloneDeep(eventAlternateContactTemplate[0]);
    const length = alternateContactTemp.length + 1;
    contact['id'] = `${contact['id']}${length}`;
    contact['name'] = `${contact['name']}_${length}`;
    contact['newContact'] = true;
    triggerUpdateAltContact({ alternateContact: contact })
    if (length === 4 && showAlternateContactBtn) {
      setShowAlternateContactBtn(false);
    }
  }

  const deleteAltContact = (contactId) => {
    const alternateContact = _.cloneDeep(alternateContactTemp);
    const filteredContact = alternateContact.filter(({id}) => id !== contactId);
    const toBeDeletedContact = alternateContact.filter(({id}) => id === contactId);
    triggerDeleteAltContact({ alternateContact: filteredContact });
    if (!showAlternateContactBtn) {
      setShowAlternateContactBtn(true);
    }
    if (isEditEvent && Boolean(toBeDeletedContact.length)) {
      deleteContact(toBeDeletedContact[0], false, false);
    }
  }

  const getPersonData = (option) => {
    const personData = {}
    const temp = []
    const personLabel = option['label']?.split(',').map((item) => {
      return item.trim();
    });
    personLabel?.forEach(person => {
      if (!ifContainsAlphabets(person)) {
        personData['phone'] = person
      } else if (person.includes('@')) {
        personData['email'] = person
      } else {
        temp.push(person)
      }
    })
    if (temp.length === 1) {
      personData['name'] = temp[0];
    } else if (temp.length === 2) {
      personData['name'] = temp[0];
      personData['title'] = temp[1];
    }
    personData['id'] = option['value'];
    return personData
  }

  const setPersonData = (option) => {
    const personData = getPersonData(option)
    setEditData(personData)
  }

  const clearUpdatedValue = (isPrimary, id) => {
    if (isPrimary) {
      if (primaryContact['oldOption']) {
        primaryContact['value'] = primaryContact['oldOption']?.value;
        primaryContact['selectedOption'] = primaryContact['oldOption'];
        delete primaryContact['oldOption']
      }
      triggerSetPrimaryContact({ eventDetails: primaryContact });
    } else {
      const altContact = _.cloneDeep(alternateContactTemp);
      altContact.forEach(contact => {
        if (contact.id === id) {
          if (contact['oldOption']) {
            contact['value'] = contact['oldOption']?.value;
            contact['selectedOption'] = contact['oldOption'];
            delete contact['oldOption']
          } else {
            contact['value'] = '';
            contact['selectedOption'] = '';
          }
        }
      })
      triggerDeleteAltContact({ alternateContact: altContact });
    }
  }

  const triggerPrimaryEditModal = (option) => {
    setPersonData(option);
    setShowPrimaryEditContactModal(!showPrimaryEditContactModal)
  }

  const triggerAltEditModal = (option) => {
    setPersonData(option);
    setShowAltEditContactModal(!showAltEditContactModal)
  }

  const optionsPrimary = ({ children, innerProps, ...props }) => {  
    const { setValue } = props;
    return (
      <components.Option {...props}>
        <div className='w-full flex justify-between cursor-pointer normal-case' onClick={(event) => {
          onUpdateContact(props?.data, true);
          setValue(props?.data);
          event.stopPropagation();
        }}>
          <p className='my-auto'>{children}</p>
          <Button
            className="cursor-pointer border-none h-auto"
            onClick={(event) => {
              triggerPrimaryEditModal(props?.data);
              event.stopPropagation();
            }}
          >
            <img src={editIcon} alt="edit primary contact" className='h-4 w-4 cursor-pointer' />
          </Button>
        </div>
      </components.Option>
    );
  };

  const optionsAlt = ({ children, innerProps, ...props }) => { 
    const { setValue } = props; 
    return (
      <components.Option {...props}>
        <div className='w-full flex justify-between cursor-pointer normal-case' onClick={(event) => {
          onUpdateContact(props?.data, false, props?.selectProps?.id);
          setSelectedAltId(props?.selectProps?.id);
          setValue(props?.data);
          event.stopPropagation();
        }}>
          <p className='my-auto'>{children}</p>
          <Button
            className="cursor-pointer border-none h-auto"
            onClick={(event) => {
              setSelectedAltId(props?.selectProps?.id);
              triggerAltEditModal(props?.data);
              event.stopPropagation();
            }}
          >
            <img src={editIcon} alt="edit primary contact" className='h-4 w-4 cursor-pointer' />
          </Button>
        </div>
      </components.Option>
    );
  };

  const MenuPrimary = useCallback(
    props => {
      return (
        <Fragment>
          <components.Menu {...props}>
            <div className='w-full text-sm font-medium leading-[17px] tracking-normal text-left'>
              {props.children}
            </div>
            <button
              className='w-full text-sm font-medium leading-[17px] tracking-normal text-left px-3 py-2 border border-gray rounded-b-[6px] rounded-t-none'
              onClick={() => {setShowPrimaryContactModal(!showPrimaryContactModal);}}
            >
              {eventBtnText.createPrimaryContact}
            </button>
          </components.Menu>
        </Fragment>
      );
    },
    [showPrimaryContactModal, setShowPrimaryContactModal]
  );

  const MenuAlternate = useCallback(
    props => {
      return (
        <Fragment>
          <components.Menu {...props}>
            <div className='w-full text-sm font-medium leading-[17px] tracking-normal text-left'>
              {props.children}
            </div>
            <button
              className='w-full text-sm font-medium leading-[17px] tracking-normal text-left px-3 py-2 border border-gray rounded-b-[6px] rounded-t-none'
              onClick={(event) => {
                setSelectedAltId(props?.selectProps?.id);
                setAltCreateContactModal(!showAltCreateContactModal);
                event.stopPropagation();
              }}
            >
              {eventBtnText.createContact}
            </button>
          </components.Menu>
        </Fragment>
      );
    },
    [showAltCreateContactModal, setAltCreateContactModal, setSelectedAltId]
  );


  return (
    <div class="col-span-6 grid gap-4">
      <fieldset class="relative flex items-center col-span-6 w-full m-auto">
        <span class="flex-shrink mr-4 text-blackOlive font-bold">{eventStrings.onsiteSubtitle}</span>
        <div class="flex-grow border-t border-silver"></div>
      </fieldset>
      <div class="col-span-6 gap-4 grid">
        <fieldset className="w-full m-auto text-lg col-span-6">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 pl-0 gap-1 text-xs'>
            <div className='flex flex-row justify-between'>
              <div className='w-full mr-4'>
                <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={primaryContact?.id}>{primaryContact?.label}</label>{primaryContact?.required && squareRounded()}</div>
                <ReactSelectPaginate
                  placeholder={primaryContact?.placeholder}
                  loadMoreOptions={getPrimaryContactOptions}
                  onChange={(option) => { updatePrimaryContact(option) }}
                  value={eventPrimaryContactTemp?.selectedOption}
                  Menu={MenuPrimary}
                  isValidNewOption={false}
                  debounce={300}
                  showEditIcon={isEditEvent}
                  saveEdit={() => { saveEditContact(true) }}
                  required={primaryContact?.required}
                  options={optionsPrimary}
                  onSiteContact={true}
                  clearUpdatedValue={clearUpdatedValue}
                  isPrimary={true}
                  name={eventPrimaryContactTemp?.name}
                  removeEditOptions={removePrimaryEditOptions}
                />
              </div>
            </div>
          </div>
        </fieldset>
        {
          !!alternateContactTemp && alternateContactTemp.map((contact, index) => {
            return (
              <fieldset key={index} className="w-full m-auto text-lg col-span-6">
                <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 pl-0 gap-1 text-xs'>
                  <div className='flex flex-row justify-between'>
                    <div className='w-full mr-4'>
                      <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={contact?.id}>{contact?.label}</label>{contact?.required && squareRounded()}</div>
                      <ReactSelectPaginate
                        placeholder={contact?.placeholder}
                        loadMoreOptions={(...props) => getAlternateContactOptions(...props, contact?.selectedOption)}
                        onChange={(option) => { updateAlternateContact(option, contact?.id) }}
                        value={contact?.selectedOption}
                        Menu={MenuAlternate}
                        isValidNewOption={false}
                        debounce={300}
                        showEditIcon={isEditEvent}
                        id={contact?.id}
                        name={contact?.name}
                        saveEdit={() => { saveEditContact(false, '', contact?.id) }}
                        options={optionsAlt}
                        onSiteContact={true}
                        clearUpdatedValue={clearUpdatedValue}
                        isPrimary={false}
                        removeEditOptions={removeAltEditOptions(contact?.id)}
                        altIds={altIds}
                      />
                    </div>
                    <Button
                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment my-auto"
                      onClick={() => deleteAltContact(contact?.id)}
                    >
                      <img src={trash} alt="delete alternate contact" />
                    </Button>
                  </div>
                </div>
              </fieldset>
            )}
          )
        }
      </div>
      {showAlternateContactBtn && <fieldset className="w-auto ml-auto mr-0 text-lg col-span-6">
        <button
          className='w-auto flex h-7 items-center gap-1.5 pl-1.5 pr-2.5 py-1.5 border border-[color:var(--gray-alpha-400,rgba(152,162,179,0.50))] shadow-[0px_1px_3px_0px_rgba(50,50,93,0.06),0px_1px_1px_0px_rgba(0,0,0,0.04),0px_1px_1px_0px_rgba(60,66,87,0.00)] rounded-lg border-solid text-[color:var(--gray-700,fontGray)] text-sm not-italic font-medium leading-4;'
          type='button'
          onClick={() => {updateAltContactTemplate();}}
        >
          {eventBtnText.createAlternateContact}
        </button>
      </fieldset>}
      {showPrimaryContactModal && <AddContact createContact={createNewContact} setShowContactModal={setShowPrimaryContactModal} isPrimary={true} removeEditIcons={removeEditIcons} />}
      {showAltCreateContactModal && <AddContact createContact={createNewContact} setShowContactModal={setAltCreateContactModal} isPrimary={false} removeEditIcons={removeEditIcons} />}
      {showPrimaryEditContactModal && <EditContact isEdit={isEditEvent} editContact={editAndSave} setShowContactModal={setShowPrimaryEditContactModal} isPrimary={true} editData={editData} removeEditIcons={removeEditIcons} />}
      {showAltEditContactModal && <EditContact isEdit={isEditEvent} editContact={editAndSave} setShowContactModal={setShowAltEditContactModal} isPrimary={false} editData={editData} removeEditIcons={removeEditIcons} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    eventPrimaryContactTemp: state.events.eventPrimaryContactTemp,
    alternateContactTemp: state.events.alternateContactTemp,
    altIds: state.events.altIds
  };
};

const mapDispatchToProps = {
  triggerCheckApiStatus: checkApiStatus,
  triggerUpdateAltContact: alternateContactTemp,
  triggerDeleteAltContact: deleteAlternateContact,
  triggerSetPrimaryContact: primaryContact,
  triggerUpdateEventPerson: updateEventPerson,
  triggerCreateEventPerson: createEventPersonRecord,
  triggerDeleteEventPerson: deleteEventPersonRecord
};

export default connect(mapStateToProps, mapDispatchToProps)(OnSiteContact);
