import React, { useCallback, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { eventStrings, eventBtnText, squareRounded } from '../../../../constants/events';
import { checkApiStatus, setVenue } from '../../../../store/actions/events';
import { ReactSelectPaginate } from '../../components/reactSelectPaginate';
import { getVenueSuggestion } from '../../../../api/events';
import AddVenue from './AddVenue';
import getKeyByValue from '../../../../globalFunctions/getKeyByValue';
import SelectDropdown from '../../components/selectDropdown';
import UpdateVenue from './UpdateVenue';

const LocationDetails = (props) => {
  const {
    triggerSetVenue, triggerCheckApiStatus,
    eventLocationTemp, isEditEvent, eventDataLoading,
    isShift=false, onChangeShiftLocation = () => {},
    onSaveShiftLocation = () => {},
    searchRadiusTemplate, onChangeSearchRadius = () => {},
    onSaveSearchRadius = () => {}, shiftLocationTemp, showEditIcon = false
  } = props;
  const [location, setLocation] = useState(eventLocationTemp);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [showVenueUpdatePopup, setShowVenueUpdatePopup] = useState(false);
  const [eventVenue, setEventVenue] = useState('');
  const [removeEditBtn, setRemoveEditBtn] = useState(false);

  useEffect(() => {
    setLocation(eventLocationTemp);
  }, [eventDataLoading, eventLocationTemp])

  useEffect(() => {
    if (isShift) {
      setLocation(shiftLocationTemp)
    }
  }, [isShift, shiftLocationTemp])
  
  
  const getVenueOptions = async(search, prevOptions, { page }) => {
    try {
      const payload = {
        number: page,
        userInput: search,
        size: 10
      }
      const response = await getVenueSuggestion(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const venueSuggestions = suggestions.map(({venue_address_space_uuid: value, dropdown_menu_name: label}) => ({value, label}))
      const pageNumber = parseInt(response?.headers['response-page-number'])
      const totalPageCount = parseInt(response?.headers['total-page-count'])
      const hasMoreSuggestions = totalPageCount !== pageNumber;
      const options = getOptions(venueSuggestions, search, hasMoreSuggestions, prevOptions, page);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getOptions = (suggestions, search, hasMoreSuggestions, prevOptions, page) => {
    let filteredOptions = [];
    const hasMore = hasMoreSuggestions;
    if (!suggestions.length) return { options: [] }
    if (!search) {
      return {
        options: suggestions, hasMore,
        additional: {
          page: page + 1
        }
      };
    }
    const searchLower = search.toLowerCase();
    filteredOptions = suggestions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
    const slicedOptions = filteredOptions.slice( prevOptions.length, prevOptions.length + 10 );
    return {
      options: slicedOptions, hasMore,
      additional: {
        page: page + 1
      }
    };
  }

  const updateVenueUserInput = (option) => {
    location['value'] = option?.value;
    location['selectedOption'] = option;
    if (isShift) {
      return onChangeShiftLocation(location)
    }
    if (!isEditEvent) {
      triggerSetVenue({ eventDetails: location });
    }
  }

  const onSaveVenue = (id, updatedVal, name) => {
    setEventVenue(location['selectedOption']);
    location['value'] = updatedVal?.value;
    location['selectedOption'] = updatedVal;
    if (isShift) {
      triggerSetVenue({ eventDetails: location });
      return onSaveShiftLocation(location)
    } else {
      return setShowVenueUpdatePopup(true)
    }
  }

  const proceedToSaveVenue = () => {
    triggerSetVenue({ eventDetails: location });
    onSaveShiftLocation(location);
    setLocation(location);
    setShowVenueModal(false);
  }

  const cancelSaveVenue = () => {
    if (eventVenue) {
      location['value'] = eventVenue?.value;
      location['selectedOption'] = eventVenue;
    }
    setLocation(location);
    setShowVenueModal(false);
  }

  const proceedToSaveNewVenue = (updatedVal) => {
    location['value'] = updatedVal?.value;
    location['selectedOption'] = updatedVal;
    triggerSetVenue({ eventDetails: location });
    setRemoveEditBtn(true);
    onSaveShiftLocation(location);
    setLocation(location);
    setShowVenueModal(false);
    setRemoveEditBtn(false);
  }

  const onChangeTalentRadius = (value, options) => {
    let label = ''
    if (value) {
      label = options[0]?.options[value]?.label
    }
    searchRadiusTemplate.optionSelection = label;
    const selectedOptionValue = getKeyByValue(searchRadiusTemplate?.optionsList, label);
    if(selectedOptionValue) searchRadiusTemplate.value = Number(selectedOptionValue);
    onChangeSearchRadius(searchRadiusTemplate)
  }

  const onSaveTalentRadius = (value, optionList) => {
    let label = ''
    if (value) {
      label = optionList[0]?.options[value]?.label
    }
    let selectedValue = label;
    selectedValue = getKeyByValue(searchRadiusTemplate?.optionsList, label);
    searchRadiusTemplate.optionSelection = value;
    onSaveSearchRadius(selectedValue, searchRadiusTemplate)
  }

  const Menu = useCallback(
    props => {
      return (
        <Fragment>
          <components.Menu {...props}>
            <div className='w-full text-sm font-medium leading-[17px] tracking-normal text-left'>
              {props.children}
            </div>
            <button
              className='w-full text-sm font-medium leading-[17px] tracking-normal text-left px-3 py-2 border border-gray rounded-b-[6px] rounded-t-none'
              onClick={() => {setShowVenueModal(!showVenueModal)}}
            >
              {eventBtnText.createVenue}
            </button>
          </components.Menu>
        </Fragment>
      );
    },
    [showVenueModal, setShowVenueModal]
  );

  let searchRadiusOptions = []
  if (!isShift) {
    searchRadiusOptions = [{
      label: "Talent search radius",
      options: Object.keys(searchRadiusTemplate?.options).map(key => ({value: key, label: searchRadiusTemplate?.options[key]}))
    }]
  }

  const removeLocationEditOptions = useCallback(() => {
    if(removeEditBtn) return true;
    return false;
  }, [removeEditBtn])

  return (
    <>
      <fieldset className={`relative flex items-center w-full m-auto ${isShift ? 'col-span-3' : 'col-span-6'}`}>
        <span className="flex-shrink mr-4 text-blackOlive font-bold">{eventStrings.locationSubtitle}</span>
        <div className="flex-grow border-t border-silver"></div>
      </fieldset>
      <fieldset className={`w-full m-auto text-lg ${isShift ? 'col-span-3' : 'col-span-4'}`}>
        <div className='border active:border-richBlue border-grayLight rounded w-full uppercase p-2 pt-[10px] pl-0 gap-1 text-xs'>
          <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={location?.id}>{location?.label}</label>{location?.required && squareRounded()}</div>
          <ReactSelectPaginate
            placeholder={location?.placeholder}
            loadMoreOptions={getVenueOptions}
            onChange={updateVenueUserInput}
            value={location?.selectedOption}
            Menu={Menu}
            isValidNewOption={false}
            debounce={300}
            required={location?.required}
            showEditIcon={isEditEvent || showEditIcon}
            saveEdit={onSaveVenue}
            hasCreateBtn={true}
            removeEditOptions={removeLocationEditOptions}
          />
        </div>
      </fieldset>
      {
        !isShift && Object.keys(searchRadiusTemplate).length &&
        <fieldset className="w-full m-auto text-lg col-span-2">
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase pt-2 px-0 pb-0 gap-1 text-xs relative">
            <div className="flex flex-row">
              <label
                className="font-semibold font-inter text-sm text-[#393636] pl-2"
                htmlFor={searchRadiusTemplate?.id}>{searchRadiusTemplate?.label}
              </label>
                {searchRadiusTemplate?.required && squareRounded()}
            </div>
            <SelectDropdown 
              options={searchRadiusOptions} 
              placeholder={searchRadiusTemplate?.placeholder} 
              onChange={onChangeTalentRadius} 
              selectedValue={searchRadiusTemplate?.optionSelection} 
              showEditIcon={isEditEvent || showEditIcon}
              saveEdit={onSaveTalentRadius}
            />
          </div>
        </fieldset>
      }
      {showVenueModal && <AddVenue setShowVenueModal={setShowVenueModal} isShift={isShift} getConfirmation={!isShift && (isEditEvent || showEditIcon)} proceedToSaveNewVenue={proceedToSaveNewVenue} setRemoveEditBtn={setRemoveEditBtn} isCreate={!(isEditEvent || showEditIcon)} />}
      {showVenueUpdatePopup && <UpdateVenue setShowVenueUpdatePopup={setShowVenueUpdatePopup} saveVenue={proceedToSaveVenue} cancelVenue={cancelSaveVenue} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    eventLocationTemp: state.events.eventLocationTemp,
    eventDataLoading: state.events.eventDataLoading,
    shiftLocationTemp: state.shifts.shiftLocationTemp,
  };
};

const mapDispatchToProps = {
  triggerSetVenue: setVenue,
  triggerCheckApiStatus: checkApiStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
