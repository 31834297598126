import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import Spinner from '../../../Spinner';
import { COUNTRY_UUID, EMAIL_UUID, FormCategories, PHONE_UUID, Strings, formBtnText } from '../../../../constants';
import { checkPhoneNumberValidity, contactUpdatedForm, createTalentAddressAttr, createTalentEmailAttr, createTalentPhoneAttr, updateTalentAddressAttr, updateTalentEmailAttr, updateTalentPhoneAttr, setIsPhoneNumberValid } from '../../../../store/actions/talents';
import InputField from '../../components/inputField';
import AddressField from '../../components/addressField';
import BackArrow from '../../../BackArrow';
import EmergencyContact from './emergencyContact';
import { error } from '../../../toast';
import { phoneNumberRegex } from '../talentListNew/helper';

const Contact = (props) => {
  const {
    isContactUpdated,
    contactUserUpdated,
    contactTemplate,
    triggerContactUpdate,
    showEditIcon,
    setSelectedSection,
    triggerPhoneUpdated, triggerPhoneCreated,
    triggerEmailUpdated, triggerEmailCreated,
    triggerAddressUpdated, triggerAddressCreated,
    userId, provinces, isRegister,
    requiredFieldsUpdated,
    emergencyFieldsUpdated, triggerCheckPhoneValid,
    triggerSetIsPhoneNumberValid,
    isPhoneNumberValid,
    isEmergencyPhoneNumberValid
  } = props;
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });

  const [loading, setLoading] = useState(false);
  const template = _.cloneDeep(contactTemplate);
  const [contactField, setContactFields] = useState(template?.contact);
  const [residentialAddress, setResidentialAddress] = useState(template?.residentialAddress);
  const [mailingAddress, setMailingAddress] = useState(template?.mailingAddress);
  const [defaultResidentialAddress, setDefaultResidentialAddress] = useState(template?.residentialAddress);
  const [defaultMailingAddress, setDefaultMailingAddress] = useState(template?.mailingAddress);
  const [defaultResidentialAddressBackup, setDefaultResidentialAddressBackup] = useState(template?.residentialAddress);
  const [defaultMailingAddressBackup, setDefaultMailingAddressBackup] = useState(template?.mailingAddress);
  const [phoneError, setPhoneError] = useState("");
  const phoneRef = useRef(null);

  useEffect(() => {
    if (isContactUpdated) {
      const userUpdated = _.cloneDeep(contactUserUpdated);
      setContactFields(userUpdated?.contact);
      setResidentialAddress(userUpdated?.residentialAddress);
      setMailingAddress(userUpdated?.mailingAddress);
      setDefaultResidentialAddress(_.cloneDeep(userUpdated?.residentialAddress));
      setDefaultMailingAddress(_.cloneDeep(userUpdated?.mailingAddress));
    }
  }, [isContactUpdated, contactUserUpdated])

  useEffect(() => {
    if(isContactUpdated) {
     updateDefaultBackupValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactUpdated]);

  const updateDefaultBackupValues = () => {
    const userUpdated = _.cloneDeep(contactUserUpdated);
    setDefaultResidentialAddressBackup(_.cloneDeep(userUpdated?.residentialAddress));
    setDefaultMailingAddressBackup(_.cloneDeep(userUpdated?.mailingAddress));
  }

  useEffect(() => {
    if(isContactUpdated && contactUserUpdated) {
      const userUpdated = _.cloneDeep(contactUserUpdated);
      userUpdated?.contact?.forEach(field => {
        if(field?.label === Strings.contactNumber && field.value) {
          const value = field.value;
          if(!phoneNumberRegex.test(value)) {
            setPhoneError("Invalid Phone Number");
            triggerSetIsPhoneNumberValid(false);
          }
          else {
            const payload = {
              userContactNumber: value,
              onSuccess: () => {
                setPhoneError("");
                triggerSetIsPhoneNumberValid(true);
              },
              onError: (message) => {
                setPhoneError(message);
                triggerSetIsPhoneNumberValid(false);
              }
            }
            triggerCheckPhoneValid(payload);
          }
        }
      });
    }
  }, []);

  const updateContactInfo = () => {
    const contactInfo = {}
    if (!showEditIcon) {
      let contactFieldVal = [];
      let residentialAddressVal = [];
      let mailingAddressVal = [];
      contactField.forEach(field => contactFieldVal.push(Boolean(field?.value)));
      residentialAddress?.addressFields.forEach(fields => {
        fields.forEach(field => {
          if (field.id !== Strings.apt) {
            residentialAddressVal.push(Boolean(field?.value || field?.optionSelection));
          }
        })
      })
      mailingAddress?.addressFields.forEach(fields => fields.forEach(field => mailingAddressVal.push(Boolean(field?.value || field?.optionSelection))))
      const isEmptyResidentialField = residentialAddressVal.includes(false)
      const isEmptyMailingField = mailingAddressVal.includes(false)
      const isEmptyContactField = contactFieldVal.includes(false)
      if (!isEmptyContactField && !isEmptyResidentialField && !isEmptyMailingField) {
        contactInfo['allFieldsUpdated'] = true;
      }
      if (!isEmptyContactField && !isEmptyResidentialField) {
        contactInfo['requiredFieldsUpdated'] = true;
      }
    }
    contactInfo[FormCategories.contact] = contactField;
    contactInfo[FormCategories.residentialAddress] = residentialAddress;
    contactInfo[FormCategories.mailingAddress] = mailingAddress;
    triggerContactUpdate({ contact: contactInfo })
  }

  const submit = () => {
    if (
        (isRegister && (!emergencyFieldsUpdated || !requiredFieldsUpdated)) ||
        (Boolean(phoneError) || isPhoneNumberValid === false || isEmergencyPhoneNumberValid === false)
      ) {
      if(Boolean(phoneError) || isPhoneNumberValid === false) {
        phoneRef.current.scrollIntoView();
      }
      return
    }
    setSelectedSection('3');
    setLoading(false);
  }

  const onChangeContactHandler = (id, value) => {
    contactField.forEach(field => { 
      if (field.id === id) { 
        field.value = value
      }
    })
    if (!showEditIcon) { updateContactInfo(); }
  }

  const onBlurContactHandler = (id, value, type, inputBlurCallback) => {
    if(type === "tel") {
      if(value) {
        validatePhoneNumber(value, inputBlurCallback);
      }
      else {
        if(showEditIcon) {
          setPhoneError("Enter Phone Number");
          triggerSetIsPhoneNumberValid(false);
        } else {
          setPhoneError("");
          triggerSetIsPhoneNumberValid(true);
        }
      }
    }
  }

  const resetPhoneError = () => {
    setPhoneError("");
    triggerSetIsPhoneNumberValid(true);
  }
  const validatePhoneNumber = async (value, callBack = () => {}) => {
    if(!phoneNumberRegex.test(value)) {
      setPhoneError("Invalid Phone Number");
      callBack(true);
      triggerSetIsPhoneNumberValid(false);
    }
    else {
      const payload = {
        userContactNumber: value,
        onSuccess: () => {
          setPhoneError("");
          callBack();
          triggerSetIsPhoneNumberValid(true);
        },
        onError: (message) => {
          setPhoneError(message);
          callBack(true);
          triggerSetIsPhoneNumberValid(false);
        }
      }
      triggerCheckPhoneValid(payload);
    }
  }
  const onChangeResidentialVal = (fieldData, id, suggestionSelected=false, lat='', long='', placeId=null, timezone) => {
    if (residentialAddress.id === id) {
      residentialAddress.addressFields = fieldData
      if (lat && long) {
        residentialAddress['lat'] = lat;
        residentialAddress['long'] = long;
      }
      else {
        residentialAddress['lat'] = null;
        residentialAddress['long'] = null;
      }
      residentialAddress["map_lookup_ref"] = placeId;
      residentialAddress["tz_name"] = timezone || null;
    }

    if (suggestionSelected) updateContactInfo();
    if (!showEditIcon) {
      updateContactInfo();
    }
  }

  const onChangeMailingVal = (fieldData, id, suggestionSelected=false, lat='', long='', placeId=null, timezone) => {
    if (mailingAddress.id === id) {
      mailingAddress.addressFields = fieldData
      if (lat && long) {
        mailingAddress['lat'] = lat;
        mailingAddress['long'] = long;
      }
      else {
        mailingAddress['lat'] = null;
        mailingAddress['long'] = null;
      }
      mailingAddress["map_lookup_ref"] = placeId;
      mailingAddress["tz_name"] = timezone || null;
    }
    if (suggestionSelected) updateContactInfo();
    if (!showEditIcon) { updateContactInfo(); }
  }

  const onPhoneSave = (id, value, isValueEdited) => {
    if(value && phoneNumberRegex.test(value)) {
      const actualPayload = {
        "phone_number": value,
        "primary": true,
        "phone_type_uuid": PHONE_UUID,
        "country_uuid": COUNTRY_UUID,
      }
      if (isValueEdited) {
        actualPayload['uuid'] = id;
      } else {
        actualPayload['emergency'] = false;
        actualPayload['person_uuid'] = userId;
      }
      const payload = {
        actualPayload: actualPayload,
        onSuccess: (uuid) => {
          if (!isValueEdited) {
            contactField.forEach(field => { 
              if (field.id === id) { 
                field.id = uuid
              }
            })
          }
          const contactFieldCopy = [...contactField];
          contactFieldCopy.forEach(field => {
            if (field.id === id) {
              field.value = value
            }
          })
          setContactFields(contactFieldCopy);
          updateContactInfo();
        },
        onError: () => {
        }
      }
      if (isValueEdited) {
        triggerPhoneUpdated(payload)
      } else {
        triggerPhoneCreated(payload)
      }
    }
  }

  const onEmailSave = (id, value, isValueEdited) => {
    const actualPayload = {
      "email_address": value,
      "primary": true,
      "email_type_uuid": EMAIL_UUID
    }
    if (isValueEdited) {
      actualPayload['uuid'] = id;
    } else {
      actualPayload['person_uuid'] = userId;
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: (uuid) => {
        if (!isValueEdited) {
          contactField.forEach(field => { 
            if (field.id === id) { 
              field.id = uuid
            }
          })
        }
        updateContactInfo();
      },
      onError: () => {
      }
    }
    if (isValueEdited) {
      triggerEmailUpdated(payload)
    } else {
      triggerEmailCreated(payload)
    }
  }

  const onSaveEdit = (id, value, isValueEdited) => {
    contactField.forEach(field => { 
      if (field.id === id && (field.label === Strings.contactNumber)) { 
        onPhoneSave(id, value, isValueEdited)
      }
      if (field.id === id && (field.label === Strings.email)) {
        onEmailSave(id, value, isValueEdited)
      }
    })
  }

  const getAddressPayload = (fieldId, fieldData, uuid, lat='', long='', placeId, timezone) => {
    const actualPayload = {
      "primary": true,
      "address_type_uuid": fieldId
    }
    if (uuid) {
      actualPayload['uuid'] = uuid;
    } else {
      actualPayload['person_uuid'] = userId;
    }
    if (lat && long) {
      actualPayload['lat'] = lat;
      actualPayload['long'] = long;
    }
    else {
      actualPayload['lat'] = null;
      actualPayload['long'] = null;
    }
    actualPayload['map_lookup_ref'] = placeId;
    actualPayload['tz_name'] = timezone || null;
    fieldData.forEach(fields => {
      fields.forEach(field => {
        if (field?.optionSelection) {
          const selectedProvince = provinces.find(province => province.name === field.optionSelection)
          actualPayload['state_province_uuid'] = selectedProvince && selectedProvince.uuid;
        } else {
          actualPayload[field?.id] = field?.value;
        }
      })
    })
    return actualPayload;
  }

  const saveResidentialAddressEdit = (fieldData, fieldId, lat='', long='', placeId=null, timezone) => {
    const actualPayload = getAddressPayload(fieldId, fieldData, residentialAddress?.uuid, lat, long, placeId, timezone)
    const payload = {
      actualPayload: actualPayload,
      onSuccess: (uuid) => {
        if (!residentialAddress?.uuid) {
          residentialAddress.uuid = uuid
        }
        updateContactInfo();
        updateDefaultBackupValues();
      },
      onError: () => {
      }
    }
    if (residentialAddress?.uuid) {
      triggerAddressUpdated(payload)
    } else {
      triggerAddressCreated(payload)
    }
  }

  const saveMailingAddressEdit = (fieldData, fieldId, lat='', long='', placeId=null, timezone) => {
    const actualPayload = getAddressPayload(fieldId, fieldData, mailingAddress?.uuid, lat, long, placeId, timezone)
    const payload = {
      actualPayload: actualPayload,
      onSuccess: (uuid) => {
        if (!mailingAddress?.uuid) {
          mailingAddress.uuid = uuid
        }
        updateContactInfo();
        updateDefaultBackupValues();
      },
      onError: () => {
      }
    }
    if (mailingAddress?.uuid) {
      triggerAddressUpdated(payload)
    } else {
      triggerAddressCreated(payload)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full">
      <div className='gap-4 grid grid-cols-2 mb-4'>
        {
          contactField.map((field, index) => {
            return (
              <div className="w-full">
                <fieldset key={index} className="m-auto text-lg">
                  <div className={`border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs ${field.label === Strings.contactNumber && phoneError ? "border border-red-500" : ""}`} ref={field.label === Strings.contactNumber ? phoneRef : null}>
                    <label className="font-semibold text-[#393636] font-inter text-sm" htmlFor={field.id}>{field.label}{(field.required || isRegister) && <span className="text-red-500 ml-1">*</span>}</label>
                    <InputField name={field?.id} id={field?.id} onChange={onChangeContactHandler} placeholder={field?.placeholder} defaultValue={field.value} type={field.type} maxAllowed={field.maxAllowed}
                      minAllowed={field.minAllowed} required={field.required || isRegister} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveEdit} onInputBlur={onBlurContactHandler} resetPhoneError={field.label === Strings.contactNumber ? resetPhoneError : () => {}}
                    />
                  </div>
                  {field.label === Strings.contactNumber && phoneError && <span className="text-sm font-inter text-red-500">{phoneError}</span>}
                </fieldset>
              </div>
            )
          })
        }
      </div>
      {
        <AddressField
          title={residentialAddress?.title} subTitle={residentialAddress.subTitle} fieldData={residentialAddress.addressFields} onChange={onChangeResidentialVal}
          showEditIcon={showEditIcon} saveEdit={saveResidentialAddressEdit} fieldId={residentialAddress.id} fieldUUID={residentialAddress.uuid} required={isRegister}
          addressVal={defaultResidentialAddress.addressFields} defaultResidentialAddressBackup={defaultResidentialAddressBackup} serverLatLong={{lat:residentialAddress.lat, long: residentialAddress.long}} serverPlaceId={residentialAddress.map_lookup_ref}
        />
      }
      {
        <AddressField
          title={mailingAddress?.title} subTitle={mailingAddress.subTitle} fieldData={mailingAddress.addressFields} onChange={onChangeMailingVal}
          showEditIcon={showEditIcon} saveEdit={saveMailingAddressEdit} fieldId={mailingAddress.id} fieldUUID={mailingAddress.uuid}
          addressVal={defaultMailingAddress.addressFields} defaultMailingAddressBackup={defaultMailingAddressBackup} serverLatLong={{lat:mailingAddress.lat, long: mailingAddress.long}} serverPlaceId={mailingAddress.map_lookup_ref}
        />
      }
      <div className="shadow-card rounded-lg mb-6">
        <div className="text-2xl text-black font-medium p-3 flex">{Strings.emergencyContact}&nbsp;</div>
        <EmergencyContact showEditIcon={showEditIcon} setSelectedSection={setSelectedSection} userId={userId} isRegister={isRegister} />
      </div>
      <div className={`${showEditIcon ? 'hidden' : 'flex'} float-right`}>
        <button
          onClick={() => {
            setSelectedSection(1)
          }}
          disabled={loading}
          type="submit"
          className={`cursor-pointer font-inter float-right w-fit text-secondayNeutral font-medium rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-8 bg-catskill mr-3`}
        >
          <div className="flex flex-row justify-center items-center">
            <BackArrow />
            <span className="mx-2">{formBtnText.back}</span>
          </div>
        </button>
        <button
          onClick={() => {
            reset(
              {
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
              }
            );
          }}
          disabled={loading}
          type="submit"
          className={`cursor-pointer float-right w-fit text-white font-medium rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-8 bg-secondaryLight hover:bg-secondary active:bg-secondaryDark disabled:bg-gray`}
        >
          {loading ? (
            <div className="flex flex-row justify-center items-center">
              <span className="mx-2">{Strings.loading}</span>
              <Spinner color={"white"} loading={loading} size={15} />
            </div>
          ) : (
            `${formBtnText.emergencyContactInfo}`
          )}
        </button>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    isContactUpdated: state.talent.isContactUpdated,
    contactUserUpdated: state.talent.contactUserUpdated,
    contactTemplate: state.talent.contactTemplate,
    provinces: state.talent.provinces,
    requiredFieldsUpdated: state.talent.contactUserUpdated.requiredFieldsUpdated,
    emergencyFieldsUpdated: state.talent.emergencyContactUserUpdated.allFieldsUpdated,
    isPhoneNumberValid: state.talent.contactUserUpdated?.isPhoneNumberValid,
    isEmergencyPhoneNumberValid: state.talent.emergencyContactUserUpdated?.isEmergencyPhoneNumberValid
  };
};

const mapDispatchToProps = {
  triggerContactUpdate: contactUpdatedForm,
  triggerPhoneUpdated: updateTalentPhoneAttr,
  triggerPhoneCreated: createTalentPhoneAttr,
  triggerEmailUpdated: updateTalentEmailAttr,
  triggerEmailCreated: createTalentEmailAttr,
  triggerAddressUpdated: updateTalentAddressAttr,
  triggerAddressCreated: createTalentAddressAttr,
  triggerCheckPhoneValid: checkPhoneNumberValidity,
  triggerSetIsPhoneNumberValid: setIsPhoneNumberValid,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
