import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getTalentsList,
  getFilteredTalentsList,
  clearFilteredTalentsList,
  clearUpdatedFormDetails,
} from "../../../../store/actions/talents";
import { Table, Tag, Button } from "antd";
import { StarFilled, FlagFilled, PlusOutlined } from "@ant-design/icons";
import NavBar from "../../../layout/navbar";
import "./talentList.css";
import SearchComponent from "./searchFilter";
import { RoutingConstants } from "../../../../constants";
import Unfold from "../../../Unfold";
import { defaultProfile, getSessionValue } from "./helper";

const { Column } = Table;

const TalentList = (props) => {
  const {
    triggerTalentsList,
    triggerFilterTalentsList,
    triggerClearTalentsList,
    triggerClearFormTemplate,
    talentsPageSize,
    talentsListpagenumber,
    filteredTalentsPageSize,
    filteredTalentsListpagenumber,
    isAddPersonToRole=false,
    addPersonToRole = () => {},
    eventShiftRoleUUID='',
    eventShiftRoleSelectedTalent = []
  } = props;
  const talentFilter = getSessionValue("talentFilter");
  const navigate = useNavigate();
  const [isClearAll, setIsClearAll] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const baseUrl = process.env.REACT_APP_API_URL;
  const navigateTo = (val) => {
    console.log("Navigate to: ", val);
  };
  useEffect(() => {
    if(!isAddPersonToRole && !eventShiftRoleSelectedTalent.length) {
      fetchTalentList()
    }
  }, []);

  useEffect(() => {
    if(isAddPersonToRole) {
      fetchTalentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventShiftRoleSelectedTalent]);

  const fetchTalentList = () => {
    const isFilterApplied = talentFilter && Object.keys(talentFilter).length > 1 && talentFilter.hasOwnProperty("person_type_uuids");
    const fetchAllData = async () => {
      const currPage = parseInt(talentsListpagenumber) || 1;
      const pageSize = parseInt(talentsPageSize) || 50;
      await fetchTalentsList(currPage, pageSize);
    }
    const fetchFilterData = async () => {
      await onSearchFilter(talentFilter, parseInt(filteredTalentsListpagenumber), parseInt(filteredTalentsPageSize));
    }

    isFilterApplied ? fetchFilterData() : fetchAllData();
  }

  useEffect(() => {
    if(isAddPersonToRole) {
      onClearAllFilter();
    }
    return () => {
      if(isAddPersonToRole) onClearAllFilter();
    }
  }, []);

  const onClearAllFilter = () => {
    setIsClearAll(true);
    setSearchMode(false);
    triggerClearTalentsList();
    sessionStorage.removeItem("talentFilter");
    sessionStorage.removeItem("talentFilterTags");
    fetchTalentsList(1, 50);
  };

  const handleTableChange = async (pagination) => {
    const getLatest = searchMode
      ? await getFilterTalentsList(
          searchPayload,
          pagination.current,
          parseInt(pagination.pageSize)
        )
      : await fetchTalentsList(pagination.current, pagination.pageSize);
  };

  const fetchTalentsList = (pageNo, pageSize) => {
    const payload = {
      number: pageNo,
      size: pageSize,
      eventShiftRoleUUID: eventShiftRoleUUID,
      onSuccess: () => {
        console.log("fetch talents list success");
      },
      onError: () => {
        console.log("fetch talents list error");
      },
    };
    triggerTalentsList(payload);
  };

  const getFilterTalentsList = (filterPayload, pageNo, pageSize) => {
    const payload = {
      filterPayload: { ...filterPayload, number: pageNo, size: pageSize, eventShiftRoleUUID: eventShiftRoleUUID },
      onSuccess: () => {
        console.log("fetch filter talents list success");
      },
      onError: () => {
        console.log("fetch filter talents list error");
      },
    };
    triggerFilterTalentsList(payload);
  };

  const onSearchFilter = (payload, currPage = 1, pageSize = 50) => {
    if (payload) {
      setSearchPayload(payload);
      getFilterTalentsList(payload, currPage, pageSize);
      setIsClearAll(false);
      setSearchMode(true);
    }
  };

  const handleRowClick = (record) => {
    if(isAddPersonToRole) {
      addPersonToRole(record.uuid);
    } else {
      redirectToTalentView(record);
    }
  }

  const redirectToCreateTalent = () => {
    triggerClearFormTemplate();
    navigate(RoutingConstants.talents.create);
  };

  const redirectToTalentView = (record) => {
    const viewNavigation = `${RoutingConstants.talents.internalView}/${record.uuid}`;
    triggerClearFormTemplate();
    navigate(viewNavigation);
  };

  const tableData = searchMode
    ? props?.talentData?.filteredTalentsData.length
      ? props?.talentData?.filteredTalentsData
      : []
    : props?.talentData?.talentsData;

  return (
    <>
      <div className="flex">
        {!isAddPersonToRole && <NavBar /> }
        <div className="h-screen overflow-auto w-full">
        {!isAddPersonToRole && (
          <div className="pt-[23px] w-[97%] h-[50px] mx-auto pr-[4%]">
            <div className="text-header font-inter leading-header font-semibold text-black float-left">
              Talent
            </div>
            <div className="float-right gap-2 flex font-inter font-medium text-sm text-fontGray">
              <Button
                className="font-inter font-medium text-sm text-fontGray"
                onClick={onClearAllFilter}
              >
                Clear all filters
              </Button>
              <Button
                className="flex font-inter font-medium text-sm text-fontGray"
                onClick={redirectToCreateTalent}
                icon={<PlusOutlined className="align-baseline mt-three" />}
              >
                Create Talent
              </Button>
            </div>
          </div>
        )}
          <SearchComponent
            onFilter={onSearchFilter}
            isClearAll={isClearAll}
            onClearAll={onClearAllFilter}
            isAddPersonToRole={isAddPersonToRole}
          />
          <div className={`m-auto flex  cursor-pointer relative w-[97%] mx-auto ${isAddPersonToRole ? "mt-[20px]" : "mt-[50px]"}`}>
            {!isAddPersonToRole && <div className="absolute text-sm left-1 top-[20px]">Show</div>}
            {props?.talentData?.totalFilteredTalentsItemCount < 50 && (
              <div className="absolute left-[5%] top-[18px] px-[10px] border-b text-gray">
                50
              </div>
            )}
            {!isAddPersonToRole && <div className="absolute text-sm left-[10%] top-[20px]">cards</div>}
            <Table
              loading={props?.talentData?.loading}
              dataSource={tableData}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    handleRowClick(record);
                  },
                };
              }}
              showHeader={false}
              pagination={{
                responsive: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total}`,
                current: searchMode
                  ? parseInt(props?.talentData?.filteredTalentsListpagenumber)
                  : parseInt(props?.talentData?.talentsListpagenumber),
                pageSize: searchMode
                  ? props?.talentData?.filteredTalentsPageSize
                  : props?.talentData?.talentsPageSize,
                position: ["topRight", "bottomRight"],
                total: searchMode
                  ? props?.talentData?.totalFilteredTalentsItemCount
                  : props?.talentData?.totalTalentsItemCount,
                locale: { items_per_page: "" },
              }}
              onChange={handleTableChange}
              className="w-full"
              locale={{ emptyText: <div className="py-12 text-center">No talent was found</div> }}
            >
              <Column
                className="rounded-l-lg w-[4%]"
                dataIndex="primary_photo_attribute"
                render={(val, record) => {
                  const imageStyle =
                    "w-[32px] min-w-[32px] max-w-[32px] h-[40px] rounded-[6px]";
                  return val ? (
                    <img
                      className={imageStyle}
                      src={baseUrl + val?.value}
                      alt="profile"
                      onError={(e) => (e.target.src = defaultProfile)}
                    />
                  ) : (
                    <img
                      className={imageStyle}
                      src={defaultProfile}
                      alt="Profile not Found"
                    />
                  );
                }}
              />
              <Column
                dataIndex="first_name"
                className="w-[15%]"
                render={(val, record) => (
                  <>
                    <span className="text-[15px]">
                      {record.first_name} {record.last_name}
                    </span>
                    <div className="text-[#767676] text-[13px]">
                      {record?.primary_address_city}
                    </div>
                  </>
                )}
              />
              <Column
                dataIndex="rating"
                className="w-[10%]"
                render={(val, record) => (
                  <span className="text-[13px] pl-[10px]">
                    <StarFilled className="text-[#22C663] align-baseline" />{" "}
                    {record.company_rating || "Unrated"}
                  </span>
                )}
              />
              <Column
                dataIndex="event_roles"
                className="w-[65%]"
                render={(roles) => (
                  <>
                    {roles.map((tag, i) => (
                      <Tag key={i} bordered={false}>
                        {tag.event_role_name}
                      </Tag>
                    ))}
                  </>
                )}
              />
              <Column className="w-[20%]" />
              <Column
                className="w-[10%]"
                dataIndex="is_flagged"
                render={(val, record) => (
                  <>{val ? <FlagFilled className="text-[#F5A62F]" /> : <></>}</>
                )}
              />
              <Column
                className="rounded-r-lg"
                render={(val, record) => (
                  <>
                    <Button
                      className="bg-[#E6DDFF] table-button"
                      type="link"
                      icon={<PlusOutlined className="text-xl text-[#4200FF]" />}
                      onClick={() => navigateTo(record)}
                    />
                  </>
                )}
              />
            </Table>
            {tableData.length ? (
              <>
                {!isAddPersonToRole && <div className="absolute text-sm left-1 bottom-[20px]">
                  Show
                </div>}
                {props?.talentData?.totalFilteredTalentsItemCount < 50 && (
                  <div className="absolute left-[5%] bottom-[18px] px-[10px] border-b text-gray">
                    50
                  </div>
                )}
                {!isAddPersonToRole && <div className="absolute text-sm left-[10%] bottom-[20px]">
                  cards
                </div>}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    tokenExpires: state.auth.loginDetails?.token_expires || "",
    talentData: state.talent || [],
    talentsListpagenumber: state.talent.talentsListpagenumber,
    talentsPageSize: state.talent.talentsPageSize,
    filteredTalentsListpagenumber: state.talent.filteredTalentsListpagenumber,
    filteredTalentsPageSize: state.talent.filteredTalentsPageSize,
  };
};

const mapDispatchToProps = {
  triggerTalentsList: getTalentsList,
  triggerFilterTalentsList: getFilteredTalentsList,
  triggerClearTalentsList: clearFilteredTalentsList,
  triggerClearFormTemplate: clearUpdatedFormDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentList);
