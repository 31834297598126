import React, { useState } from "react";
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import starRatingImg from "../../../assets/images/green_star03.png";

const StarRating = ({ options, optionList, name, id, onChangeOption, defaultValue, customStyle='', isReadOnly=false, showEditIcon=false, saveEdit, required, defaultCustomStyle, editCustomStyle, editSaveCustomStyle }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState('');
  const isOptionUpdate = true;
  let notEditable = false;
  if (isReadOnly && !showEditIcon) {
    notEditable = true;
  }
  const onOptionSelect = (e) => {
    if (showEditIcon) {
      setUpdatedVal(e.target.value)
    }
    onSelect(e)
  }
  const onSelect = (e) => {
    onChangeOption(id, e.target.value, isOptionUpdate)
  }
  const onSaveEdit = () => {
    if (defaultValue === updatedVal) {
      setIsEditable(false);
    } else {
      const isValueEdited = Boolean(defaultValue);
      saveEdit(id, updatedVal, isValueEdited, name, isOptionUpdate, optionList)
      setIsEditable(false);
    }
  }
  return (
    <div className={`flex ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
      <div className="border border-saffronMango px-2.5 py-1 flex rounded w-fit">
        <img src={starRatingImg} alt='star rating' className="w-3 h-3 cursor-pointer mt-[3px] mr-1" />
        {
          showEditIcon && !isEditable && defaultValue && <p className={`text-sm text-left text-black font-medium capitalize ${defaultCustomStyle ? 'mt-0' : 'mt-1'}`}>{defaultValue}</p>
        }
        {
          (!showEditIcon || (showEditIcon && isEditable) || !defaultValue) &&
            <select
              className={`w-4/5 border-none outline-none text-sm text-left text-grayNobel placeholder-grayNobel disabled:text-grayNobel focus:text-tileGray font-normal h-5 ${defaultCustomStyle ? 'mt-0' : 'mt-1'} ${customStyle} appearance-none`}
              name={name}
              id={id}
              defaultValue={defaultValue}
              required={required}
              disabled={notEditable || (showEditIcon && !isEditable)}
              onChange={(e) => onOptionSelect(e)}>
              {
                options.map((option, index) => {
                  return (
                    <option key={index} value={option} selected={defaultValue === option}>{option}</option>
                  )
                })
              }
          </select>
        }
      </div>
      {
        showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className={`h-4 w-4 cursor-pointer ml-ten mt-[7px] ${editCustomStyle}`}
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className={`grid -mt-4 ml-2 gap-2 ${editSaveCustomStyle}`}
        >
          <img
            src={saveIcon}
            alt="save edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => {setUpdatedVal(''); setIsEditable(false)}}
          />
        </div>
      }
    </div>
  )
}

export default StarRating;
