/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ACCOUNT_MANAGER_ATTRIBUTE_UUID, ORGANIZATION_CATEGORY_UUID, createdErrorMessage, createdSuccessMessage, eventBtnText, eventShiftEnd, eventShiftStart, squareRounded, eventStrings } from '../../../../constants/events';
import InputField from '../../components/inputField';
import DateRangeField from '../../components/dateRangeField';
import _ from 'lodash';
import { checkApiStatus, createOrganization, updateEventBrandNameTemplate, updateEventClientNameTemplate, updateEventManagerNameTemplate, updateEventNameTemplate, addAttachment, addFilesToUpload, updateEventPerson, createEvent, updateEventDatesTemplate, updateEventNotesTemplate, clearEventDetailsForm, deleteEventAsset, addEventAsset, updateEventCoreAttr, updateSearchRadiusTemplate, updateEventShiftVenue, updateEventStatusTemplate, getEvent } from '../../../../store/actions/events';
import { ReactSelectPaginate } from '../../components/reactSelectPaginate';
import { getAccountManagerOptions as callAccountManagerOptionsAPI, getClientSuggestion } from '../../../../api/events';
import LocationDetails from './LocationDetails';
import OnSiteContact from './OnSiteContact';
import Attachments from './Attachments';
import { success, error } from "../../../toast";
import Notes from './Notes';
import Spinner from '../../../Spinner';
import { RoutingConstants, Strings } from '../../../../constants';
import SelectDropdown from '../../components/selectDropdown';
import getKeyByValue from '../../../../globalFunctions/getKeyByValue';

const EventDetails = (props) => {
  const {
    showEditIcon,
    triggerEventNameChange,
    triggerClientNameChange,
    triggerBrandNameChange,
    triggerManagerNameChange,
    setEventName,
    triggerCheckApiStatus,
    eventNameTemp,
    eventClientNameTemp,
    eventBrandNameTemp,
    eventAccountManagerTemp,
    eventDatesTemp,
    eventAttachmentTemp,
    eventNotesTemp,
    triggerCreateClient,
    triggerAddFilesToUpload,
    eventAttachments,
    triggerUpdateEventPerson,
    triggerCreateEvent,
    triggerUpdateEventDatesTemplate,
    triggerUpdateEventNotesTemplate,
    triggerDeleteEventAsset,
    triggerClearEventDetailsForm,
    triggerAddEventAsset,
    eventId,
    triggerUpdateEventCoreAttr,
    eventSearchRadiusTemp,
    triggerEventSearchRadiusUpdate,
    triggerUpdateShiftVenue,
    eventStatusTemp,
    triggerEventStatusUpdate,
    triggerGetEvent,
    eventLocationTemp,
    eventPrimaryContactTemp
  } = props;
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });
  let eventName = _.cloneDeep(eventNameTemp);
  let clientName = _.cloneDeep(eventClientNameTemp);
  let brandName = _.cloneDeep(eventBrandNameTemp);
  let accountManagerName = _.cloneDeep(eventAccountManagerTemp);
  let eventDate = _.cloneDeep(eventDatesTemp);
  let attachmentTemplate = _.cloneDeep(eventAttachmentTemp);
  let notesTemplate = _.cloneDeep(eventNotesTemp);
  let searchRadiusTemp = _.cloneDeep(eventSearchRadiusTemp);
  let statusTemp = _.cloneDeep(eventStatusTemp)
  let locationTemp = _.cloneDeep(eventLocationTemp);
  let primaryContactTemp = _.cloneDeep(eventPrimaryContactTemp);
  const [loading, setLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    triggerAddFilesToUpload([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventName = _.cloneDeep(eventNameTemp);
    clientName = _.cloneDeep(eventClientNameTemp);
    brandName = _.cloneDeep(eventBrandNameTemp);
    accountManagerName = _.cloneDeep(eventAccountManagerTemp);
    eventDate = _.cloneDeep(eventDatesTemp);
    attachmentTemplate = _.cloneDeep(eventAttachmentTemp);
    notesTemplate = _.cloneDeep(eventNotesTemp);
    searchRadiusTemp = _.cloneDeep(eventSearchRadiusTemp);
    statusTemp = _.cloneDeep(eventStatusTemp);
    locationTemp = _.cloneDeep(eventLocationTemp);
    primaryContactTemp = _.cloneDeep(eventPrimaryContactTemp);
    const requiredTemp = [eventName, clientName, accountManagerName, locationTemp, searchRadiusTemp, primaryContactTemp];
    const isAnyValueMissing = requiredTemp?.some(form => !form.value);
    const isInvalidDate = !eventDate?.value?.startDate || !eventDate?.value?.endDate;
    setDisableSubmitButton(isAnyValueMissing || isInvalidDate);
  }, [eventNameTemp, eventClientNameTemp, eventBrandNameTemp, eventAccountManagerTemp, eventDatesTemp, eventAttachmentTemp, eventNotesTemp, eventSearchRadiusTemp, eventStatusTemp, eventLocationTemp, eventPrimaryContactTemp])
  
  
  const onChangeDatesHandler = (id, { start, end }, name) => {
    const local_start_at = `${start}T${eventShiftStart}`;
    const local_end_at = `${end}T${eventShiftEnd}`;
    eventDate.value = {
      startDate: local_start_at,
      endDate: local_end_at
    };
    triggerUpdateEventDatesTemplate({ eventDetails: eventDate })
  }

  const onChangeEventName = (id, value) => {
    setEventName(value);
    eventName.value = value;
    if (!showEditIcon) { triggerEventNameChange({ eventDetails: eventName }); }
  }

  const onChangeNotesHandler = (event) => {
    notesTemplate.value = event?.target?.value;
    if (!showEditIcon) { triggerUpdateEventNotesTemplate({ eventDetails: notesTemplate }); }
  }

  const onSaveEventDetails = (actualPayload) => {
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        success('Successfully updated')
      },
      onError: () => {
      }
    }
    triggerUpdateEventCoreAttr(payload);
  }

  const onChangeSearchRadius = (searchRadiusTemplate) => {
    if (!showEditIcon) { triggerEventSearchRadiusUpdate({ eventDetails: searchRadiusTemplate }); }
  }

  const onSaveSearchRadius = (selectedValue, searchRadiusTemplate) => {
    const actualPayload = {
      'uuid': eventId,
      'search_radius': parseFloat(selectedValue)
    }
    onSaveEventDetails(actualPayload)
    triggerEventSearchRadiusUpdate({ eventDetails: searchRadiusTemplate });
  }

  const onSaveEventName = (id, value) => {
    const actualPayload = {
      'uuid': eventId,
      'name': value
    }
    onSaveEventDetails(actualPayload)
    setEventName(value);
    eventName.value = value;
    triggerEventNameChange({ eventDetails: eventName });
  }

  const onSaveClientName = (id, value) => {
    const actualPayload = {
      'uuid': eventId,
      'client_organization_uuid': value?.value
    }
    onSaveEventDetails(actualPayload)
    updateClientUserInput(value);
  }

  const onSaveBrandName = (id, value) => {
    const actualPayload = {
      'uuid': eventId,
      'obo_organization_uuid': value?.value
    }
    onSaveEventDetails(actualPayload)
    updateBrandUserInput(value);
  }

  const onSaveNotes = (id, value) => {
    const actualPayload = {
      'uuid': eventId,
      'desc': value
    }
    onSaveEventDetails(actualPayload)
    notesTemplate.value = value;
    triggerUpdateEventNotesTemplate({ eventDetails: notesTemplate });
  }

  const getClientAndBrandOptions = async(payload) => {
    try {
      const response = await getClientSuggestion(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const orgSuggestions = suggestions.map(({uuid: value, name: label}) => ({value, label}))
      const pageNumber = parseInt(response?.headers['response-page-number'])
      const totalPageCount = parseInt(response?.headers['total-page-count'])
      const hasMoreSuggestions = totalPageCount !== pageNumber;
      const responsePayload = {
        suggestions: orgSuggestions,
        hasMoreSuggestions
      }
      return responsePayload;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getOptions = (suggestions, search, hasMoreSuggestions, prevOptions, page, selectedOption = {}) => {
    let filteredOptions = [];
    const hasMore = hasMoreSuggestions;
    if (!suggestions.length) return { options: [] }
    if(suggestions?.length && selectedOption && selectedOption?.value) { // to make selected option as first one
      suggestions = suggestions?.filter(option => option?.value !== selectedOption?.value);
      if (page === 1) {
        suggestions.unshift(selectedOption);
      }
    }
    if (!search) {
      return {
        options: suggestions,
        hasMore,
        additional: {
          page: page + 1
        }
      };
    }
    const searchLower = search.toLowerCase();
    filteredOptions = suggestions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
    const slicedOptions = filteredOptions.slice( prevOptions.length, prevOptions.length + 10 );
    return {
      options: slicedOptions,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  }

  const getClientOptions = async(search, prevOptions, { page }, selectedOption) => {
    try {
      const payload = {
        'number': page,
        'userInput': search,
        'size': 10
      }
      const response = await getClientAndBrandOptions(payload);
      const { suggestions, hasMoreSuggestions } = response;
      const options = getOptions(suggestions, search, hasMoreSuggestions, prevOptions, page, selectedOption);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const getAccountManagerOptions = async(search, prevOptions, { page }) => {
    try {
      let payload = {
        number: page,
        size: 10,
        actualPayload: {
          event_contact_category_uuids: [ ACCOUNT_MANAGER_ATTRIBUTE_UUID ],
          search_field: search
        }
      }
      const response = await callAccountManagerOptionsAPI(payload);
      triggerCheckApiStatus(response);
      const suggestions = response?.data;
      const amSuggestions = suggestions.map(({uuid: value, dropdown_menu_name: label}) => ({value, label}));
      if(!amSuggestions.length) return { options: [] };
      const pageNumber = parseInt(response?.headers['response-page-number']);
      const totalPageCount = parseInt(response?.headers['total-page-count']);
      const hasMore = totalPageCount !== pageNumber;
      const options = getOptions(amSuggestions, search, hasMore, prevOptions, page, accountManagerName?.selectedOption);
      return options;
    } catch (error) {
      triggerCheckApiStatus(error?.response);
    }
  }

  const updateClientUserInput = (option) => {
    clientName.value = option?.value || '';
    clientName.selectedOption = option || [];
    triggerClientNameChange({ eventDetails: clientName });
  }

  const updateBrandUserInput = (option) => {
    brandName.value = option?.value || '';
    brandName.selectedOption = option || [];
    triggerBrandNameChange({ eventDetails: brandName });
  }

  const updateAccountManagerUserInput = (option) => {
    accountManagerName.value = option?.value || "";
    accountManagerName.selectedOption = option || [];
    triggerManagerNameChange({ eventDetails: accountManagerName });
  }

  const saveEditAccountManager = (id, option, name, personRecordUUID) => { // update account manager while edit event
    if(personRecordUUID && option && option.value) {
      const payload = {
        actualPayload : {
          uuid: personRecordUUID,
          person_uuid: option?.value
        },
        onSuccess: () => {
          updateAccountManagerUserInput(option);
          success('Successfully updated')
        },
        onError: () => {}
      }
      triggerUpdateEventPerson(payload);
    }
  }
  
  const addOrgPayload = (option) => {
    if (!option) return;
    const actualPayload = {
      "organization_category_uuid": ORGANIZATION_CATEGORY_UUID,
      "name": option,
      "status": "active"
    }
    return actualPayload;
  }

  const addToClientOptions = (option) => {
    const payload = {
      actualPayload: addOrgPayload(option),
      onSuccess: (selectedOption) => {
        updateClientUserInput(selectedOption)
        success(createdSuccessMessage('Client'))
        if (showEditIcon) {
          onSaveClientName('clientName', selectedOption)
        }
      },
      onError: (msg) => {
        error(msg || createdErrorMessage('Client'))
      },
    };
    triggerCreateClient(payload)
  }

  const addToBrandOptions = (option) => {
    const payload = {
      actualPayload: addOrgPayload(option),
      onSuccess: async (selectedOption) => {
        updateBrandUserInput(selectedOption)
        success(createdSuccessMessage('Brand'))
        if (showEditIcon) {
          onSaveBrandName('brandName', selectedOption)
        }
      },
      onError: (msg) => {
        error(msg || createdErrorMessage('Brand'))
      },
    };
    triggerCreateClient(payload)
  }

  const Menu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className='w-full text-sm font-medium leading-[17px] tracking-normal text-left overflow-auto'>
            {props.children}
          </div>
        </components.Menu>
      </Fragment>
    );
  }

  const attachmentsToUpload = (attachments) => {
    triggerAddFilesToUpload(attachments);
  }

  const submit = () => {
    setLoading(true);
    const payload = {
      onSuccess: (response) => {
        success(createdSuccessMessage("Event"));
        setLoading(false);
        const { data } = response;
        const eventId = data?.uuid;
        triggerGetEvent({ id: eventId });
        navigate(`${RoutingConstants.events.event}/${eventId}`);
      },
      onError: (msg) => {
        setLoading(false);
      },
    };
    triggerCreateEvent(payload)
  }

  const deleteEvent = async () => {
    setLoading(true);
    triggerClearEventDetailsForm();
    navigate(RoutingConstants.events.list);
  }

  const saveEditEventDate = ({ start, end }) => {
    const local_start_at = `${start}T${eventShiftStart}`;
    const local_end_at = `${end}T${eventShiftEnd}`;
    eventDate.value = {
      startDate: local_start_at,
      endDate: local_end_at
    };
    const actualPayload = {
      uuid: eventId,
      local_start_at,
      local_end_at
    }
    onSaveEventDetails(actualPayload)
    triggerUpdateEventDatesTemplate({ eventDetails: eventDate })
  }

  const onSaveShiftLocation = (location) => {
    const actualPayload = {
      'uuid': eventId,
      'primary_venue_address_space_uuid': location?.value
    }
    triggerUpdateShiftVenue({ id: eventId, venueId: location?.value });
    onSaveEventDetails(actualPayload);
  }

  const onChangeEventStatus = (value, options) => {
    let label = ''
    if (value) {
      label = options[0]?.options[value]?.label
    }
    statusTemp.optionSelection = label;
    if (!showEditIcon) { triggerEventStatusUpdate({ eventDetails: statusTemp }); }
  }

  const onSaveEventStatus = (value, optionList) => {
    let label = ''
    if (value) {
      label = optionList[0]?.options[value]?.label
    }
    let selectedValue = label;
    selectedValue = getKeyByValue(statusTemp?.optionsList, label);
    statusTemp.optionSelection = value;

    const actualPayload = {
      'uuid': eventId,
      'status': selectedValue
    }
    onSaveEventDetails(actualPayload)
    triggerEventStatusUpdate({ eventDetails: statusTemp });
  }

  const statusOptions = [{
      label: "Event status",
      options: Object.keys(statusTemp?.options).map(key => ({value: key, label: statusTemp?.options[key]}))
    }]

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full">
      <div className='gap-4 grid grid-cols-6 w-[900px] md:w-[620px] lg:w-[720px] xl:w-[820px]'>
        <fieldset className="w-full m-auto text-lg col-span-4">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={eventName?.id}>{eventName?.label}</label>{eventName?.required && squareRounded()}</div>
            <InputField name={eventName?.id} id={eventName?.id} onChange={onChangeEventName} placeholder={eventName?.placeholder} defaultValue={eventName?.value} type={eventName?.type}
              maxAllowed={eventName.maxAllowed} minAllowed={eventName.minAllowed} required={eventName.required} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveEventName} isEventPage={true} />
          </div>
        </fieldset>
        <fieldset className="w-full m-auto text-lg col-span-2">
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase pt-2 px-0 pb-0 gap-1 text-xs relative">
            <div className="flex flex-row">
              <label
                className="font-semibold font-inter text-sm text-[#393636] pl-2"
                htmlFor={statusTemp?.id}>{statusTemp?.label}
              </label>
                {statusTemp?.required && squareRounded()}
            </div>
            <SelectDropdown 
              options={statusOptions} 
              placeholder={statusTemp?.placeholder} 
              onChange={onChangeEventStatus} 
              selectedValue={statusTemp?.optionSelection} 
              showEditIcon={showEditIcon}
              saveEdit={onSaveEventStatus}
              isDisabled={!showEditIcon}
              optionsClass="event-details"
            />
          </div>
        </fieldset>
        <fieldset className="w-full m-auto text-lg col-span-3">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full p-2 pl-0 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={clientName?.id}>{clientName?.label}</label>{clientName?.required && squareRounded()}</div>
            <ReactSelectPaginate
              placeholder={eventClientNameTemp?.placeholder}
              loadMoreOptions={(...props) => getClientOptions(...props, eventClientNameTemp?.selectedOption)}
              onCreateOption={addToClientOptions}
              onChange={updateClientUserInput}
              value={eventClientNameTemp?.selectedOption}
              Menu={Menu}
              id={eventClientNameTemp?.id}
              name={eventClientNameTemp?.name}
              showEditIcon={showEditIcon}
              required={eventClientNameTemp?.required}
              saveEdit={onSaveClientName}
              createLabel="CREATE NEW CLIENT"
              minInputToCreate={3}
            />
          </div>
        </fieldset>
        <fieldset className="w-full m-auto text-lg col-span-3">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full p-2 pl-0 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={brandName?.id}>{brandName?.label}</label>{brandName?.required && squareRounded()}</div>
            <ReactSelectPaginate
              placeholder={eventBrandNameTemp?.placeholder}
              loadMoreOptions={(...props) => getClientOptions(...props, eventBrandNameTemp?.selectedOption)}
              onCreateOption={addToBrandOptions}
              onChange={updateBrandUserInput}
              value={eventBrandNameTemp?.selectedOption}
              Menu={Menu}
              id={eventBrandNameTemp?.id}
              name={eventBrandNameTemp?.name}
              showEditIcon={showEditIcon}
              required={eventBrandNameTemp?.required}
              saveEdit={onSaveBrandName}
              createLabel="CREATE NEW BRAND"
              minInputToCreate={3}
            />
          </div>
        </fieldset>
        <fieldset className="w-full m-auto text-lg col-span-3">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full p-2 pt-[10px] pl-0 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase pl-2" htmlFor={accountManagerName?.id}>{accountManagerName?.label}</label>{accountManagerName?.required && squareRounded()}</div>
            <ReactSelectPaginate
              placeholder={accountManagerName?.placeholder}
              loadMoreOptions={getAccountManagerOptions}
              onChange={updateAccountManagerUserInput}
              value={accountManagerName?.selectedOption}
              Menu={Menu}
              isValidNewOption={false}
              debounce={300}
              showEditIcon={showEditIcon}
              id={accountManagerName?.id}
              name={accountManagerName?.name}
              saveEdit={(...props) => saveEditAccountManager(...props, accountManagerName?.personRecordUUID)}
              required={accountManagerName?.required}
            />
          </div>
        </fieldset>
        <fieldset title={`${!eventDate?.isDateChangeable ? eventStrings.disabledContent : "" }`} className="w-full m-auto text-lg col-span-3">
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs items-center">
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={eventDate?.id}>{eventDate?.label}</label>{eventDate?.required && squareRounded()}</div>
              <DateRangeField
                name={eventDate?.id}
                id={eventDate?.id}
                onChange={onChangeDatesHandler}
                placeholder={eventDate?.placeholder}
                defaultValue={eventDate?.value}
                maxAllowed={eventDate?.maxAllowed}
                minAllowed={eventDate?.minAllowed}
                required={eventDate?.required}
                isReadOnly={true}
                showEditIcon={showEditIcon}
                saveEdit={saveEditEventDate}
                isDateChangeable={eventDate?.isDateChangeable}
              />
          </div>
        </fieldset>
        <LocationDetails showEditIcon={showEditIcon} searchRadiusTemplate={searchRadiusTemp} onChangeSearchRadius={onChangeSearchRadius} onSaveSearchRadius={onSaveSearchRadius} onSaveShiftLocation={onSaveShiftLocation} isShift={false} />
        <OnSiteContact isEditEvent={showEditIcon} eventId={eventId} />
        <Attachments
          attachmentTemplate={attachmentTemplate}
          eventAttachments={eventAttachments}
          attachmentsToUpload={attachmentsToUpload}
          isEditEvent={showEditIcon}
          deleteServerAssets={triggerDeleteEventAsset}
          uploadServerAssets={triggerAddEventAsset}
          eventId={eventId}
        />
        <Notes
          notesTemplate={notesTemplate}
          showEditIcon={showEditIcon}
          onChangeNotes={onChangeNotesHandler}
          saveCoreEdit={onSaveNotes}     
        />
        {!showEditIcon &&
        <div className="flex items-center justify-end pb-6 pt-2 rounded-b ml-auto mr-0 col-span-6">
          <button
            onClick={deleteEvent}
            disabled={loading}
            type='button'
            className={`cursor-pointer float-right w-fit text-coralPink bg-transparent text-sm outline-none rounded-lg text-center px-4 py-2 border border-coralPink flex justify-center items-center mr-2 mt-4 mb-8 disabled:bg-gray`}
          >
            {loading ? (
              <div className="flex flex-row justify-center items-center">
                <span className="mx-2">{Strings.loading}</span>
                <Spinner color={"white"} loading={loading} size={15} />
              </div>
            ) : (
              `${eventBtnText.cancelRemoveEvent}`
            )}
          </button>
          <button
            onClick={() => {
              reset(
                {
                  keepErrors: false,
                  keepDirty: true,
                  keepIsSubmitted: false,
                  keepTouched: false,
                  keepIsValid: false,
                  keepSubmitCount: false,
                }
              );
            }}
            disabled={loading || disableSubmitButton}
            type="submit"
            className={`cursor-pointer float-right w-fit text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium rounded text-center px-4 py-2 flex justify-center items-center text-sm mt-4 mb-8 disabled:bg-gray`}
          >
            {loading ? (
              <div className="flex flex-row justify-center items-center">
                <span className="mx-2">{Strings.loading}</span>
                <Spinner color={"white"} loading={loading} size={15} />
              </div>
            ) : (
              `${eventBtnText.createEvent}`
            )}
          </button>
        </div>
        }
        {showEditIcon && <div className='mt-2 mb-2'></div>}
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    eventClientNameTemp: state.events.eventClientNameTemp,
    eventBrandNameTemp: state.events.eventBrandNameTemp,
    eventAccountManagerTemp: state.events.eventManagerNameTemp,
    eventDatesTemp: state.events.eventDatesTemp,
    eventNameTemp: state.events.eventNameTemp,
    eventAttachmentTemp: state.events.eventAttachmentTemp,
    eventNotesTemp: state.events.eventNotesTemp,
    suggestionsUpdated: state.events.suggestionsUpdated,
    companyUUID: state.talent.companyUUID,
    eventAttachments: state.events.attachedDocuments,
    filesToUpload: state.events.filesToUpload,
    newEventId: state.events.newEventId,
    eventSearchRadiusTemp: state.events.eventSearchRadiusTemp,
    eventStatusTemp: state.events.eventStatusTemp,
    eventLocationTemp: state.events.eventLocationTemp,
    eventPrimaryContactTemp: state.events.eventPrimaryContactTemp,
  };
};

const mapDispatchToProps = {
  triggerEventNameChange: updateEventNameTemplate,
  triggerClientNameChange: updateEventClientNameTemplate,
  triggerBrandNameChange: updateEventBrandNameTemplate,
  triggerManagerNameChange: updateEventManagerNameTemplate,
  triggerUpdateEventNotesTemplate: updateEventNotesTemplate,
  triggerCreateClient: createOrganization,
  triggerCheckApiStatus: checkApiStatus,
  triggerAddAttachment: addAttachment,
  triggerAddFilesToUpload: addFilesToUpload,
  triggerUpdateEventPerson: updateEventPerson,
  triggerCreateEvent: createEvent,
  triggerUpdateEventDatesTemplate: updateEventDatesTemplate,
  triggerDeleteEventAsset: deleteEventAsset,
  triggerClearEventDetailsForm: clearEventDetailsForm,
  triggerAddEventAsset: addEventAsset,
  triggerUpdateEventCoreAttr: updateEventCoreAttr,
  triggerEventSearchRadiusUpdate: updateSearchRadiusTemplate,
  triggerUpdateShiftVenue: updateEventShiftVenue,
  triggerEventStatusUpdate: updateEventStatusTemplate,
  triggerGetEvent: getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
