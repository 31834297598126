import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createContactTemplate, createdErrorMessage, createdSuccessMessage, eventBtnText, eventStrings, personPayload, squareRounded } from '../../../../constants/events';
import InputField from '../../components/inputField';
import _ from 'lodash';
import CloseIcon from "../../talents/library/assets/svg/close.svg";
import { success, error } from "../../../toast";
import { createOnsiteContact } from '../../../../store/actions/events';
import Spinner from '../../../Spinner';
import { Strings } from '../../../../constants';
import { eventFormAttributesName } from "../../../../constants/events";
import { v4 as uuidv4 } from 'uuid';
import { phoneNumberRegex } from '../../talents/talentListNew/helper';
import { checkPhoneNumberValidity } from '../../../../store/actions/talents';
import InputMask from "react-input-mask";


const AddContact = (props) => {
  const { setShowContactModal, triggerCreateContact, isPrimary, createContact, removeEditIcons, triggerCheckPhoneValid } = props;
  const [contactTemplate] = useState(_.cloneDeep(createContactTemplate));
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);

  const saveContact = () => {
    if(phoneError) {
      return ;
    }
    setLoading(true);
    let title = ''; let phoneNumber = ''; let firstName = ''; let lastName = '';
    const actualPayload = personPayload;
    actualPayload['uuid'] = uuidv4();
    contactTemplate && contactTemplate.forEach(contact => {
      contact && contact.forEach(fieldData => {
        const { id, name, value } = fieldData;
        if (id === 'firstName') { firstName = value; actualPayload[name] = value }
        if (id === 'lastName') { lastName = value; actualPayload[name] = value }
        if (id === 'phoneNumber') { phoneNumber = value; actualPayload["person_phones"][0][name] = value }
        if (id === 'title') { title = value }
      })
    })
    if(!phoneNumber || !actualPayload?.first_name || !actualPayload?.last_name) {
      setLoading(false);
      return;
    }
    let displayName = '';
    if (title) {
      displayName = `${firstName} ${lastName}, ${title}, ${phoneNumber}`
    } else {
      displayName = `${firstName} ${lastName}, ${phoneNumber}`
    }
    const payload = {
      actualPayload: actualPayload,
      title: title,
      isPrimary: isPrimary,
      phoneNumber: phoneNumber,
      displayName: displayName,
      onSuccess: (selectedOption) => {
        removeEditIcons(isPrimary);
        createContact(selectedOption, isPrimary);
        setLoading(false);
        setShowContactModal(false);
      },
      onError: (msg) => {
        removeEditIcons(isPrimary);
        setLoading(false);
        setShowContactModal(false);
      },
    }
    triggerCreateContact(payload)
  }

  const onChangeContactHandler = (id, value, isOptionUpdate = false) => {
    contactTemplate.forEach(contact => {
      contact.forEach(field => {
        if (field.id === id) {
          if (isOptionUpdate) {
            field.optionSelection = value;
          } else {
            field.value = value;
          }
        }
      })
    });
    const disableButton = contactTemplate?.flat()?.filter(form => form.required)?.some(form => !form.value);
    setDisableSubmitBtn(disableButton);
  }
  const onBlurContactHandler = (value) => {
    if(value) {
      validatePhoneNumber(value);
    }
    else {
      setPhoneError("");
    }
  }

  const validatePhoneNumber = async (value) => {
    if(!phoneNumberRegex.test(value)) {
      setPhoneError("Invalid Phone Number");
    }
    else {
      const payload = {
        userContactNumber: value,
        onSuccess: () => {
          setPhoneError("");
        },
        onError: (message) => {
          setPhoneError(message);
        }
      }
      triggerCheckPhoneValid(payload);
    }
  }
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-[714px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-medium">
                {eventStrings.createContactTitle}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={() => setShowContactModal(false)} />
            </div>
            <div className="relative px-6 flex-auto">
              {
                contactTemplate && contactTemplate.map((contact, index) => {
                return(
                  <div key={index} className='gap-4 grid grid-cols-2'>
                    {
                      contact && contact.map((fieldData, i) => {
                        if (fieldData?.id === 'title' && isPrimary) {
                          fieldData['value'] = 'Primary Contact';
                        }
                        return (
                          <fieldset key={i} title={`${fieldData['disabled'] && eventStrings.disabledContent}`} className={`w-full text-lg ${fieldData?.id === "title" && isPrimary && "bg-disabledGray"} ${index + 1 < contactTemplate.length ? "mb-4" : ""}`}>
                            <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
                              <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-[#393636]" htmlFor={fieldData?.id}>{fieldData?.label}</label>{fieldData?.required && squareRounded()}</div>
                              {fieldData.name === eventFormAttributesName?.createContact?.phoneNumber && (
                                <InputMask
                                  mask={"(999) 999-9999"}
                                  maskChar={"_"}
                                  onBlur={(e) =>
                                    onBlurContactHandler(
                                      e.target.value.replace(/[^\d]/g, "")
                                    )
                                  }
                                  onChange={(e) =>
                                    onChangeContactHandler(
                                      fieldData?.id,
                                      e.target.value.replace(/[^\d]/g, "")
                                    )
                                  }
                                  alwaysShowMask
                                  className={
                                    "text-sm disabled:text-grayNobel placeholder-grayNobel text-tileGray font-normal h-4 mt-2 border-none w-full outline-none"
                                  }
                                />
                              )}
                              {fieldData.name !== eventFormAttributesName?.createContact?.phoneNumber && (
                                <InputField
                                  name={fieldData?.id}
                                  id={fieldData?.id}
                                  onChange={onChangeContactHandler}
                                  placeholder={fieldData?.placeholder}
                                  defaultValue={fieldData.value}
                                  type={fieldData.type}
                                  maxAllowed={fieldData.maxAllowed}
                                  minAllowed={fieldData.minAllowed}
                                  required={fieldData.required}
                                  disabled={
                                    fieldData?.id === "title" && isPrimary
                                  }
                                />
                              )}
                            </div>
                            {fieldData.name === eventFormAttributesName?.createContact?.phoneNumber && phoneError && <span className="text-sm font-inter text-red-500">{phoneError}</span> }
                          </fieldset>
                        )
                      })
                    }
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-end py-4 pr-6 rounded-b">
              <button
                className="text-fontGray-500 background-transparent font-medium px-4 py-2 text-sm outline-none rounded-lg focus:outline-none mr-2 border border-cadetGray/50"
                type="button"
                onClick={() => setShowContactModal(false)}
              >
                {eventBtnText.dismiss}
              </button>
              <button
                onClick={saveContact}
                disabled={loading || disableSubmitBtn || phoneError}
                type="button"
                className="text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium text-sm px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer"
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <Spinner color={"white"} loading={loading} size={15} />
                  </div>
                ) : (
                  `${eventBtnText.createSave}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

const mapDispatchToProps = {
  triggerCreateContact: createOnsiteContact,
  triggerCheckPhoneValid: checkPhoneNumberValidity
}

export default connect(null, mapDispatchToProps)(AddContact);
