import actionTypes from "../types";

const {
  GET_TALENTS_LIST,
  GET_TALENTS_LIST_SUCCESS,
  GET_TALENTS_LIST_ERROR,
  GET_PROVINCES,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_ERROR,
  GET_EVENT_ROLES,
  GET_EVENT_ROLES_SUCCESS,
  GET_EVENT_ROLES_ERROR,
  GET_FILTERED_TALENTS_LIST,
  GET_FILTERED_TALENTS_LIST_SUCCESS,
  GET_FILTERED_TALENTS_LIST_ERROR,
  CLEAR_FILTERED_TALENTS_LIST,
  CLEAR_FILTERED_TALENTS_LIST_SUCCESS,
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_ERROR,
  GET_EMAIL_UUID,
  GET_EMAIL_UUID_SUCCESS,
  GET_EMAIL_UUID_ERROR,
  GET_ADDRESS_UUID,
  GET_ADDRESS_UUID_SUCCESS,
  GET_ADDRESS_UUID_ERROR,
  GET_PHONE_UUID,
  GET_PHONE_UUID_SUCCESS,
  GET_PHONE_UUID_ERROR,
  GET_TALENTS_CORE_ATTRIBUTES,
  GET_TALENTS_CORE_ATTRIBUTES_SUCCESS,
  GET_TALENTS_CORE_ATTRIBUTES_ERROR,
  PERSONAL_INFO_FORM_TEMPLATE,
  EXPERIENCE_FORM_TEMPLATE,
  CONTACT_FORM_TEMPLATE,
  EMERGENCY_CONTACT_FORM_TALENT,
  PERSONAL_INFO_UPDATED_FORM,
  EXPERIENCE_UPDATED_FORM,
  CONTACT_UPDATED_FORM,
  EMERGENCY_CONTACT_UPDATED_FORM,
  CLEAR_UPDATED_FORM_DETAILS,
  SKILLS_FORM_TEMPLATE,
  SKILLS_UPDATED_FORM,
  TALENT_DATA,
  TALENT_DATA_SUCCESS,
  TALENT_DATA_ERROR,
  PHOTO_USER_UPDATED,
  UPLOAD_NEW_USER_IMAGE,
  UPLOAD_NEW_USER_IMAGE_SUCCESS,
  UPLOAD_NEW_USER_IMAGE_ERROR,
  DELETE_NEW_USER_IMAGE,
  DELETE_NEW_USER_IMAGE_SUCCESS,
  DELETE_NEW_USER_IMAGE_ERROR,
  UPLOAD_EXISTING_USER_IMAGE,
  UPLOAD_EXISTING_USER_IMAGE_SUCCESS,
  UPLOAD_EXISTING_USER_IMAGE_ERROR, 
  DELETE_EXISTING_USER_IMAGE, 
  DELETE_EXISTING_USER_IMAGE_SUCCESS,
  DELETE_EXISTING_USER_IMAGE_ERROR,
  CREATE_NEW_TALENT,
  CREATE_NEW_TALENT_SUCCESS,
  CREATE_NEW_TALENT_ERROR,
  UPDATE_CORE_ATTRIBUTE,
  SET_NEW_TALENT_ID,
  CLEAR_NEW_TALENT_ID,
  UPDATE_EXISTING_TALENT_DATA,
  UPDATE_EXISTING_TALENT_DATA_SUCCESS,
  UPDATE_EXISTING_TALENT_DATA_ERROR,
  UPDATE_NEW_TALENT_DATA,
  UPDATE_NEW_TALENT_DATA_SUCCESS,
  UPDATE_NEW_TALENT_DATA_ERROR,
  CREATE_TALENT_PHONE_ATTR, UPDATE_TALENT_PHONE_ATTR,
  CREATE_TALENT_EMAIL_ATTR, UPDATE_TALENT_EMAIL_ATTR,
  UPDATE_TALENT_EVENT_ROLE, DELETE_TALENT_ATTR,
  CREATE_TALENT_ADDRESS_ATTR, UPDATE_TALENT_ADDRESS_ATTR,
  UPDATE_ASSET_PRIMARY, DELETE_TALENT_EVENT_ATTR, 
  GET_TALENT_NOTES_DATA, TALENT_NOTES_DATA, CREATE_TALENT_NOTES, DELETE_TALENT_NOTE,UPDATE_TALENT_NOTE,
  UPDATE_OTHER_ATTRIBUTES,
  UPDATE_OTHER_ATTRIBUTES_SUCCESS, CHECK_PHONE_NUMBER_VALIDITY, SET_IS_PHONE_NUMBER_VALID, SET_IS_EMERGENCY_PHONE_NUMBER_VALID, CHECK_CONTACT_NUMBER_VALIDITY,
  GET_GMAP_ADDRESS_PREDICTIONS, GET_ADDRESS_BY_PLACE_ID,
  UPDATE_DEFAULT_CONTACT_ADDRESS, ADDRESS_FIELD_TEMPLATE
} = actionTypes.talents;

export const getTalentsList = (payload) => {
  return {
    type: GET_TALENTS_LIST,
    payload
  };
};

export const getTalentsListSuccess = (response) => {
  return {
    type: GET_TALENTS_LIST_SUCCESS,
    response
  };
};

export const getTalentsListError = (error) => {
  return {
    type: GET_TALENTS_LIST_ERROR,
    error
  };
};

export const getProvinces = (payload) => {
  return {
    type: GET_PROVINCES,
    payload
  };
};

export const getProvincesSuccess = (response) => {
  return {
    type: GET_PROVINCES_SUCCESS,
    response
  };
};

export const getProvincesError = (error) => {
  return {
    type: GET_PROVINCES_ERROR,
    error
  };
};

export const getEventRoles = (payload) => {
  return {
    type: GET_EVENT_ROLES,
    payload
  };
};

export const getEventRolesSuccess = (response) => {
  return {
    type: GET_EVENT_ROLES_SUCCESS,
    response
  };
};

export const getEventRolesError = (error) => {
  return {
    type: GET_EVENT_ROLES_ERROR,
    error
  };
};

export const getFilteredTalentsList = (payload) => {
  return {
    type: GET_FILTERED_TALENTS_LIST,
    payload
  };
};

export const getFilteredTalentsListSuccess = (response) => {
  return {
    type: GET_FILTERED_TALENTS_LIST_SUCCESS,
    response
  };
};

export const getFilteredTalentsListError = (error) => {
  return {
    type: GET_FILTERED_TALENTS_LIST_ERROR,
    error
  };
};

export const clearFilteredTalentsList = (error) => {
  return {
    type: CLEAR_FILTERED_TALENTS_LIST,
    error
  };
};

export const clearFilteredTalentsListSuccess = (error) => {
  return {
    type: CLEAR_FILTERED_TALENTS_LIST_SUCCESS,
    error
  };
};

export const getAttributes = (payload) => {
  return {
    type: GET_ATTRIBUTES,
    payload
  };
};

export const getAttributesSuccess = (response) => {
  return {
    type: GET_ATTRIBUTES_SUCCESS,
    response
  };
};

export const getAttributesError = (error) => {
  return {
    type: GET_ATTRIBUTES_ERROR,
    error
  };
};

export const getEmailUUID = (payload) => {
  return {
    type: GET_EMAIL_UUID,
    payload
  };
};

export const getEmailUUIDSuccess = (response) => {
  return {
    type: GET_EMAIL_UUID_SUCCESS,
    response
  };
};

export const getEmailUUIDError = (error) => {
  return {
    type: GET_EMAIL_UUID_ERROR,
    error
  };
};

export const getAddressUUID = (payload) => {
  return {
    type: GET_ADDRESS_UUID,
    payload
  };
};

export const getAddressUUIDSuccess = (response) => {
  return {
    type: GET_ADDRESS_UUID_SUCCESS,
    response
  };
};

export const getAddressUUIDError = (error) => {
  return {
    type: GET_ADDRESS_UUID_ERROR,
    error
  };
};

export const getPhoneUUID = (payload) => {
  return {
    type: GET_PHONE_UUID,
    payload
  };
};

export const getPhoneUUIDSuccess = (response) => {
  return {
    type: GET_PHONE_UUID_SUCCESS,
    response
  };
};

export const getPhoneUUIDError = (error) => {
  return {
    type: GET_PHONE_UUID_ERROR,
    error
  };
};

export const getTalentCoreAttributes = (payload) => {
  return {
    type: GET_TALENTS_CORE_ATTRIBUTES,
    payload
  };
};

export const getTalentCoreAttributesSuccess = (response) => {
  return {
    type: GET_TALENTS_CORE_ATTRIBUTES_SUCCESS,
    response
  };
};

export const getTalentCoreAttributesError = (error) => {
  return {
    type: GET_TALENTS_CORE_ATTRIBUTES_ERROR,
    error
  };
};

export const personalInfoFormTemplate = (response) => {
  return {
    type: PERSONAL_INFO_FORM_TEMPLATE,
    response
  };
};

export const personalInfoUpdatedForm = (response) => {
  return {
    type: PERSONAL_INFO_UPDATED_FORM,
    response
  };
};

export const experienceFormTemplate = (response) => {
  return {
    type: EXPERIENCE_FORM_TEMPLATE,
    response
  };
};

export const experienceUpdatedForm = (response) => {
  return {
    type: EXPERIENCE_UPDATED_FORM,
    response
  };
};

export const skillsFormTemplate = (response) => {
  return {
    type: SKILLS_FORM_TEMPLATE,
    response
  };
};

export const skillsUpdatedForm = (response) => {
  return {
    type: SKILLS_UPDATED_FORM,
    response
  };
};

export const contactFormTemplate = (response) => {
  return {
    type: CONTACT_FORM_TEMPLATE,
    response
  };
};

export const contactUpdatedForm = (response) => {
  return {
    type: CONTACT_UPDATED_FORM,
    response
  };
};

export const emergencyContactFormTemplate = (response) => {
  return {
    type: EMERGENCY_CONTACT_FORM_TALENT,
    response
  };
};

export const emergencyContactUpdatedForm = (response) => {
  return {
    type: EMERGENCY_CONTACT_UPDATED_FORM,
    response
  };
};

export const clearUpdatedFormDetails = () => {
  return {
    type: CLEAR_UPDATED_FORM_DETAILS
  }
}

export const talentData = (payload) => {
  return {
    type: TALENT_DATA,
    payload
  };
};

export const talentDataSuccess = (response) => {
  return {
    type: TALENT_DATA_SUCCESS,
    response
  };
};

export const talentDataError = (error) => {
  return {
    type: TALENT_DATA_ERROR,
    error
  };
};

export const photoUserUpdated = (response) => {
  return {
    type: PHOTO_USER_UPDATED,
    response
  };
};

export const uploadNewUserImage = (payload) => {
  return {
    type: UPLOAD_NEW_USER_IMAGE,
    payload
  };
};

export const uploadNewUserImageSuccess = (response) => {
  return {
    type: UPLOAD_NEW_USER_IMAGE_SUCCESS,
    response
  };
};

export const uploadNewUserImageError = (error) => {
  return {
    type: UPLOAD_NEW_USER_IMAGE_ERROR,
    error
  };
};

export const deleteNewUserImage = (payload) => {
  return {
    type: DELETE_NEW_USER_IMAGE,
    payload
  };
};

export const deleteNewUserImageSuccess = (response) => {
  return {
    type: DELETE_NEW_USER_IMAGE_SUCCESS,
    response
  };
};

export const deleteNewUserImageError = (error) => {
  return {
    type: DELETE_NEW_USER_IMAGE_ERROR,
    error
  };
};

export const uploadExistingUserImage = (payload) => {
  return {
    type: UPLOAD_EXISTING_USER_IMAGE,
    payload
  };
};

export const uploadExistingUserImageSuccess = (response) => {
  return {
    type: UPLOAD_EXISTING_USER_IMAGE_SUCCESS,
    response
  };
};

export const uploadExistingUserImageError = (error) => {
  return {
    type: UPLOAD_EXISTING_USER_IMAGE_ERROR,
    error
  };
};

export const deleteExistingUserImage = (payload) => {
  return {
    type: DELETE_EXISTING_USER_IMAGE,
    payload
  };
};

export const deleteExistingUserImageSuccess = (response) => {
  return {
    type: DELETE_EXISTING_USER_IMAGE_SUCCESS,
    response
  };
};

export const deleteExistingUserImageError = (error) => {
  return {
    type: DELETE_EXISTING_USER_IMAGE_ERROR,
    error
  };
};

export const createNewTalent = (payload) => {
  return {
    type: CREATE_NEW_TALENT,
    payload
  };
};

export const createNewTalentSuccess = (response) => {
  return {
    type: CREATE_NEW_TALENT_SUCCESS,
    response
  };
};

export const createNewTalentError = (error) => {
  return {
    type: CREATE_NEW_TALENT_ERROR,
    error
  };
};

export const updateCoreAttribute = (payload) => {
  return {
    type: UPDATE_CORE_ATTRIBUTE,
    payload
  };
};

export const setNewTalentId = (response) => {
  return {
    type: SET_NEW_TALENT_ID,
    response
  }
}

export const clearNewTalentId = () => {
  return {
    type: CLEAR_NEW_TALENT_ID
  }
}

export const updateExistingTalentData = (payload) => {
  return {
    type: UPDATE_EXISTING_TALENT_DATA,
    payload
  }
}

export const updateExistingTalentDataSuccess = (response) => {
  return {
    type: UPDATE_EXISTING_TALENT_DATA_SUCCESS,
    response
  };
};

export const updateExistingTalentDataError = (error) => {
  return {
    type: UPDATE_EXISTING_TALENT_DATA_ERROR,
    error
  };
};

export const updateNewTalentData = (payload) => {
  return {
    type: UPDATE_NEW_TALENT_DATA,
    payload
  }
}

export const updateNewTalentDataSuccess = (response) => {
  return {
    type: UPDATE_NEW_TALENT_DATA_SUCCESS,
    response
  };
};

export const updateNewTalentDataError = (error) => {
  return {
    type: UPDATE_NEW_TALENT_DATA_ERROR,
    error
  };
};

export const createTalentPhoneAttr = (payload) => {
  return {
    type: CREATE_TALENT_PHONE_ATTR,
    payload
  }
}

export const updateTalentPhoneAttr = (payload) => {
  return {
    type: UPDATE_TALENT_PHONE_ATTR,
    payload
  }
}

export const createTalentEmailAttr = (payload) => {
  return {
    type: CREATE_TALENT_EMAIL_ATTR,
    payload
  }
}

export const updateTalentEmailAttr = (payload) => {
  return {
    type: UPDATE_TALENT_EMAIL_ATTR,
    payload
  }
}

export const updateTalentEventRole = (payload) => {
  return {
    type: UPDATE_TALENT_EVENT_ROLE,
    payload
  }
}

export const deleteTalentEventRole = (payload) => {
  return {
    type: DELETE_TALENT_EVENT_ATTR,
    payload
  }
}

export const createTalentAddressAttr = (payload) => {
  return {
    type: CREATE_TALENT_ADDRESS_ATTR,
    payload
  }
}

export const updateTalentAddressAttr = (payload) => {
  return {
    type: UPDATE_TALENT_ADDRESS_ATTR,
    payload
  }
}

export const updateAssetPrimary = (payload) => {
  return {
    type: UPDATE_ASSET_PRIMARY,
    payload
  }
}

export const deleteTalentAttr = (payload) => {
  return {
    type: DELETE_TALENT_ATTR,
    payload
  }
}

export const getTalentNotes = (payload) => {
  return {
    type: GET_TALENT_NOTES_DATA,
    payload
  }
} 
export const getTalentNotesDataTemplate = (response) => {
  return {
    type: TALENT_NOTES_DATA,
    response
  }
}

export const createTalentNotes = (payload) => {
  return {
    type: CREATE_TALENT_NOTES,
    payload
  }
}

export const deleteTalentNote = (payload) => {
  return {
    type: DELETE_TALENT_NOTE,
    payload
  }
}
export const updateOtherAttributes = (payload) => {
  return {
    type: UPDATE_OTHER_ATTRIBUTES,
    payload
  }
}

export const updateOtherAttributesSuccess = (response) => {
  return {
    type: UPDATE_OTHER_ATTRIBUTES_SUCCESS,
    response
  }
}

export const updateTalentNote = (payload) => {
  return {
    type: UPDATE_TALENT_NOTE,
    payload
  }
}

export const checkPhoneNumberValidity = (payload) => {
  return {
    type: CHECK_PHONE_NUMBER_VALIDITY,
    payload
  }
}

export const checkContactNumberVaidity = (payload) => {
  return {
    type: CHECK_CONTACT_NUMBER_VALIDITY,
    payload
  }
}

export const setIsPhoneNumberValid = (payload) => {
  return {
    type: SET_IS_PHONE_NUMBER_VALID,
    payload
  }
}

export const setIsEmergencyPhoneNumberValid = (payload) => {
  return {
    type: SET_IS_EMERGENCY_PHONE_NUMBER_VALID,
    payload
  }
}

export const getGmapAddressPredictions = (payload) => {
  return {
    type: GET_GMAP_ADDRESS_PREDICTIONS,
    payload
  }
};

export const getGmapAddressByPlaceId = (payload) => {
  return {
    type: GET_ADDRESS_BY_PLACE_ID,
    payload
  }
}

export const updateDefaultContactAddress = (payload) => {
  return {
    type: UPDATE_DEFAULT_CONTACT_ADDRESS,
    payload
  }
}

export const addressFieldTemplate = (response) => {
  return {
    type: ADDRESS_FIELD_TEMPLATE,
    response
  }
}
