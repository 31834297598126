import React from "react";
import LoginFrame from "../../../assets/svg/loginFrame.svg"

const LoginCoverImage = () => {
  return (
    <section className="w-1/4 h-screen absolute top-0 right-0 hidden sm:grid">
      <img className="w-full h-full object-cover" src={LoginFrame} alt="Login side frame" />
    </section>
  )
}

export default LoginCoverImage;
