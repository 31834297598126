import React from "react";

const BackArrow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8337 10H4.16699" stroke="#3239B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.0003 15.8332L4.16699 9.99984L10.0003 4.1665" stroke="#3239B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export default BackArrow;
