const isTokenExpired = (expireDateTime) => {
  if (!expireDateTime) {
    return false;
  }
  const expiryDate = new Date(expireDateTime);
  const currentDate = new Date();
  const difference = expiryDate?.getTime() - currentDate?.getTime();
  if (difference <= 0) {
    return false;
  }
  return true;
}

export default isTokenExpired;
