import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editContactTemplate, eventBtnText, eventStrings, squareRounded } from '../../../../constants/events';
import InputField from '../../components/inputField';
import _ from 'lodash';
import CloseIcon from "../../talents/library/assets/svg/close.svg";
import { editOnsiteContact } from '../../../../store/actions/events';
import Spinner from '../../../Spinner';
import { Strings } from '../../../../constants';
import { updateTalentPhoneAttr } from '../../../../store/actions/talents';
import { eventFormAttributesName } from "../../../../constants/events";
import { checkPhoneNumberValidity } from '../../../../store/actions/talents';
import InputMask from "react-input-mask";
import { phoneNumberRegex } from '../../talents/talentListNew/helper';

const EditContact = (props) => {
  const { editContact, setShowContactModal, isPrimary, editData, removeEditIcons, triggerCheckPhoneValid } = props;
  const { name='', phone='', email='', id='', title='' } = editData;
  const contactTemplate = _.cloneDeep(editContactTemplate);
  const [loading, setLoading] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [titleError, setTitleError] = useState("");

  useEffect(() => {
    let { value } = contactTemplate?.find(fieldData => fieldData?.name === eventFormAttributesName?.editContact?.phoneNumber);
    value = value.replace(/\s+/g, '').replace(/^(\+1)/, '');
    if(value) {
      validatePhoneNumber(value, true);
    }
  }, []);

  const saveContact = () => {
    if(phoneError) {
      return ;
    }
    setLoading(true);
    let updatedName = ''; let updatedPhone = ''; let updatedTitle = '';
    let titleMinLength = 0;
    contactTemplate.forEach(contact => {
      if (contact.id === 'name') {
        updatedName = contact.value
      } else if (contact.id === 'phone') {
        updatedPhone = contact.value
      } else if (contact.id === 'title') {
        updatedTitle = contact.value
        titleMinLength = contact.minAllowed
      }
    })
    if (updatedTitle.length < titleMinLength) {
      setTitleError('need minimum 2 characters');
      return;
    }
    let label = ''
    if (updatedTitle) {
      label = `${updatedName}, ${updatedTitle}, ${updatedPhone}, ${email}`
    } else {
      label = `${updatedName}, ${updatedPhone}, ${email}`
    }
    const updatedContact = {
      'value': id,
      'label': label
    }
    removeEditIcons(isPrimary);
    editContact(updatedContact, isPrimary, updatedTitle);
    setLoading(false);
    setShowContactModal(false);
  }

  const onChangeContactHandler = (id, value) => {
    contactTemplate.forEach(contact => {
      if (contact.id === id) {
        contact.value = value;
      }
      if (contact.id === id && contact.name === eventFormAttributesName?.editContact?.title && titleError && value.length >= contact?.minAllowed) {
        setTitleError('');
        setLoading(false);
      }
      if (contact.id === id && contact.required) {
        if (value) {
          setDisableSubmitBtn(false)
        } else {
          setDisableSubmitBtn(true)
        }
      }
    })
  }
  const onBlurContactHandler = (value) => {
    if(value) {
      validatePhoneNumber(value);
    }
    else {
      setPhoneError("");
    }
  }

  const validatePhoneNumber = async (value, checkWithAPI = false) => {
    if(!phoneNumberRegex.test(value) && !checkWithAPI) {
      setPhoneError("Invalid Phone Number");
    }
    else {
      const payload = {
        userContactNumber: value,
        onSuccess: () => {
          setPhoneError("");
        },
        onError: (message) => {
          setPhoneError(message);
        }
      }
      triggerCheckPhoneValid(payload);
    }
  }

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-[800px] my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-medium">
                {eventStrings.editContactTitle}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={() => setShowContactModal(false)} />
            </div>
            <div className="relative px-6 flex flex-row justify-between gap-2">
              {
                contactTemplate && contactTemplate.map((fieldData, index) => {
                  if (name && fieldData?.name === 'name') fieldData['value'] = name;
                  if (phone && fieldData?.name === 'phone_number') fieldData['value'] = phone;
                  if (title && fieldData?.name === 'title') fieldData['value'] = title;
                  if (fieldData?.name === 'title' && isPrimary) {
                    fieldData['value'] = eventStrings.primaryContact;
                    fieldData['disabled'] = true;
                  }
                  return(
                    <fieldset key={index} title={`${fieldData['disabled'] && eventStrings.disabledContent}`} className={`w-full text-lg pointer-events-auto ${fieldData?.disabled && "bg-disabledGray"} ${fieldData.name !== eventFormAttributesName?.editContact?.phoneNumber && "mb-4"} `}>
                      <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
                        <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-[#393636]" htmlFor={fieldData?.id}>{fieldData?.label}</label>{fieldData?.required && squareRounded()}</div>
                        {fieldData.name === eventFormAttributesName?.editContact?.phoneNumber && (
                          <InputMask
                            mask={"(999) 999-9999"}
                            maskChar={"_"}
                            onBlur={(e) =>
                              onBlurContactHandler(
                                e.target.value.replace(/[^\d]/g, "")
                              )
                            }
                            onChange={(e) =>
                              onChangeContactHandler(
                                fieldData?.id,
                                e.target.value.replace(/[^\d]/g, "")
                              )
                            }
                            alwaysShowMask
                            defaultValue={fieldData.value
                              .replace(/\s+/g, "")
                              .replace(/^(\+1)/, "")}
                            className={
                              "text-sm disabled:text-grayNobel placeholder-grayNobel text-tileGray font-normal h-4 mt-2 border-none w-full outline-none"
                            }
                          />
                        )}
                        {fieldData.name !== eventFormAttributesName?.editContact?.phoneNumber && (
                          <InputField
                            name={fieldData?.name}
                            id={fieldData?.id}
                            onChange={onChangeContactHandler}
                            placeholder={fieldData?.placeholder}
                            defaultValue={fieldData?.value}
                            type={fieldData.type}
                            maxAllowed={fieldData.maxAllowed}
                            minAllowed={fieldData.minAllowed}
                            required={fieldData.required}
                            disabled={fieldData.disabled}
                          />
                        )}
                      </div>
                      {fieldData.name === eventFormAttributesName?.editContact?.phoneNumber && phoneError && <span className="whitespace-nowrap text-sm font-inter text-red-500">{phoneError}</span>}
                      {fieldData.name === eventFormAttributesName?.editContact?.title && titleError && <span className="whitespace-nowrap text-sm font-inter text-red-500">{titleError}</span>}
                    </fieldset>
                  )
                }
              )}
            </div>
            <div className="flex items-center justify-end pb-6 pt-2 pr-6 rounded-b">
              <button
                className="text-fontGray-500 background-transparent font-medium px-4 py-2 text-sm outline-none rounded-lg focus:outline-none mr-2 border border-cadetGray/50"
                type="button"
                onClick={() => { setShowContactModal(false); }}
              >
                {eventBtnText.dismiss}
              </button>
              <button
                onClick={saveContact}
                disabled={loading || disableSubmitBtn || phoneError}
                type="button"
                className="text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium text-sm px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer"
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <Spinner color={"white"} loading={loading} size={15} />
                  </div>
                ) : (
                  `${eventBtnText.editSave}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

const mapDispatchToProps = {
  triggerEditContact: editOnsiteContact,
  triggerUpdatePhone: updateTalentPhoneAttr,
  triggerCheckPhoneValid: checkPhoneNumberValidity
}

export default connect(null, mapDispatchToProps)(EditContact);
