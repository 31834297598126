import React from 'react';
import { Strings } from '../../../constants';

const LoginFooter = ({ showSignUp=true }) => {
  return (
    <div className="mt-12 fixed bottom-0 mb-8 w-full p-4 flex flex-col text-sm sm:flex-row">
      <div className="flex flex-row text-cyanBlue w-full sm:w-[10%] mr-0 sm:mr-3 justify-around sm:justify-normal">
        <div className="flex items-start flex-row lg:ml-[51px] sm:ml-5 ml-2">
          <p className="mr-[20px]">{Strings.help}</p>
          <p className="mr-[20px]">{Strings.privacy}</p>
        </div>
      </div>
      {
        showSignUp &&
        <div className="flex justify-center mb-auto font-semibold items-center w-full sm:w-[50%]">
          <p>{Strings.noAccount}&nbsp;<b>{Strings.signUp}</b></p>
        </div>
      }
      
    </div>
  )
}

export default LoginFooter;
