import { fetchWrapper } from '../_helpers/fetch_wrapper';
import { apiEndpoints } from '../constants';
// const baseUrl = process.env.REACT_APP_API_URL;
// const googleUrl = process.env.GOOGLE_LOCATION;
// const googleKey = process.env.GOOGLE_KEY;

export const geoLocationService = {
    getLocationZipCodeCoradinates,
    getCoordinatesFromZipCode
};
function getLocationZipCodeCoradinates(data) {
    return fetchWrapper.postMaps('https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:' + data +'|country:US&key=AIzaSyCKmp1e8GXNxGq3saDeauDIpgWX405gqBo');
    // return fetchWrapper.postMaps('https://maps.googleapis.com/maps/api/geocode/json?address=' + data +'&key=AIzaSyCKmp1e8GXNxGq3saDeauDIpgWX405gqBo');
}

function getCoordinatesFromZipCode(zipcode) {
    return fetchWrapper.resolveZipToCoordinates(`${apiEndpoints.baseUrl}${apiEndpoints.getLatLngFromZip}${zipcode}`)
}
