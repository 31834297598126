import React, { useState, useRef, useEffect } from "react";
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";

const InputField = ({
  name,
  id,
  onChange,
  defaultValue,
  placeholder,
  type,
  maxAllowed,
  minAllowed,
  required,
  customStyle,
  isReadOnly=false,
  showEditIcon=false,
  saveEdit,
  fieldCustomStyle,
  editCustomStyle,
  editSaveCustomStyle,
  isLocationInput=false,
  focusChange,
  ref,
  onInputBlur,
  resetPhoneError=() => {},
  dataId="",
  disabled=false,
  isEventPage=false,
  customRegexValidation=""
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setUpdatedVal(defaultValue);
  }, [defaultValue])
  

  let notEditable = false;
  if (isReadOnly && !showEditIcon) {
    notEditable = true;
  }
  const onInputChange = (e) => {
    if(customRegexValidation) {
      const isValidInput = customRegexValidation.test(e.target.value);
      if(!isValidInput) {
        return ;
      }
    }
    setUpdatedVal(e.target.value)
    if (showEditIcon) {
      return
    }
    onChange(id, e.target.value)
  }

  const onSaveEdit = () => {
    if (defaultValue === updatedVal) {
      setIsEditable(false);
    } else {
      const isValueEdited = Boolean(defaultValue);
      saveEdit(id, updatedVal, isValueEdited, name)
      setIsEditable(false);
    }
  }

  const fieldType = () => {
    if (!type) {
      return 'string';
    } else if (type === 'float') {
      return 'number';
    } else {
      return type;
    }
  }

  const inputBlurCallback = (phoneError) => {
    setTimeout(() => {
      if(showEditIcon && phoneError) {
        setIsEditable(true);
      }
    }, 500);
  }

  return (
    <div
      className={`flex ${showEditIcon ? "justify-between" : "justify-start"}`}
    >
      {isLocationInput === false ? (
        <input
          ref={inputRef}
          type={fieldType()}
          id={id}
          className={`text-sm disabled:text-grayNobel placeholder-grayNobel text-tileGray font-normal h-4 mt-2 border-none w-full outline-none ${customStyle} ${fieldCustomStyle} ${isEventPage && showEditIcon && !isEditable && "cursor-pointer"}`}
          name={name}
          autoComplete={"off"}
          defaultValue={defaultValue}
          minLength={minAllowed}
          maxLength={maxAllowed}
          max={type === "float" && "999"}
          placeholder={placeholder}
          step={type === "float" && "0.01"}
          required={required}
          readOnly={notEditable || (showEditIcon && !isEditable)}
          onChange={(e) => {
            onInputChange(e);
          }}
          onBlur={onInputBlur ? (e) =>  onInputBlur(id, e.target.value, type, inputBlurCallback) : () => {}}
          disabled={disabled}
          onClick={() => isEventPage && showEditIcon && setIsEditable(true)}
          title={disabled ? 'Field cannot be edited' : ''}
          value={updatedVal}
        />
      ) : (
        <input
          ref={ref}
          onFocus={focusChange}
          type={fieldType()}
          id={id}
          className={`text-sm placeholder-grayNobel text-tileGray font-normal h-4 mt-2 border-none w-full outline-none ${customStyle} ${fieldCustomStyle}`}
          name={name}
          autoComplete={"new-password"}
          defaultValue={defaultValue}
          minLength={minAllowed}
          maxLength={maxAllowed}
          max={type === "float" && "999"}
          placeholder={placeholder}
          step={type === "float" && "0.01"}
          required={required}
          readOnly={notEditable || (showEditIcon && !isEditable)}
          onChange={(e) => {
            onInputChange(e);
          }}
          data-id={dataId}
          disabled={disabled}
          title={disabled ? 'Field cannot be edited' : ''}
          value={updatedVal}
        />
      )}
      {!isEventPage && showEditIcon && !isEditable && (
        <img
          src={editIcon}
          alt="edit"
          className={`h-4 w-4 cursor-pointer ml-ten -mt-1 ${editCustomStyle}`}
          onClick={() => {
            setIsEditable(true);
          }}
        />
      )}
      {isEditable && (
        <div className={`${isEventPage ? "flex -mt-[12px] -ml-[10px] z-[1]" : "grid -mt-5 ml-2"} gap-2 ${editSaveCustomStyle}`}>
          <img
            src={saveIcon}
            alt="save edit"
            className={`${isEventPage ? "w-6 h-6" : "w-4 h-4"} cursor-pointer`}
            onClick={() => {
              onSaveEdit();
            }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className={`${isEventPage ? "w-6 h-6" : "w-4 h-4"} cursor-pointer`}
            onClick={() => {
              setUpdatedVal(defaultValue);
              setIsEditable(false);
              if (inputRef.current) {
                inputRef.current.value = defaultValue;
              }
              resetPhoneError();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default InputField;
