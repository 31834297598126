import React, { useState } from 'react';
import ReactDateMask from '../../../globalFunctions/reactDateMask';
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import getDobFormat from '../../../globalFunctions/getDobFormat';
import getDateInYearFormat from '../../../globalFunctions/getDateInYearFormat';

const DobField = ({ defaultValue, id, placeholder, onChange, showEditIcon, saveEdit }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedVal, setUpdatedVal] = useState('');
  const value = defaultValue && String(getDobFormat(defaultValue));
  const onDateChange = (value) => {
    const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (date_regex.test(value)) {
      return value;
    }
  }
  const onInputChange = (value) => {
    const dobValue = onDateChange(value);
    if (dobValue) {
      if (showEditIcon) {
        setUpdatedVal(dobValue)
      }
      onChange(id, dobValue)
    }
  }

  const onSaveEdit = () => {
    let dobVal = getDateInYearFormat(updatedVal);
    if (defaultValue === updatedVal) {
      setIsEditable(false);
    } else {
      const isValueEdited = Boolean(defaultValue);
      saveEdit(id, dobVal, isValueEdited)
      setIsEditable(false);
    }
  }
  return (
    <div className={`flex ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
      <ReactDateMask
        mask="mm/dd/yyyy"
        value={value}
        className="uppercase border-none outline-none h-4 mt-2 text-sm placeholder-grayNobel text-tileGray font-normal"
        readOnly={showEditIcon && !isEditable}
        onChange={(value) => {
          onInputChange(value)
        }}
        required
      />
      {
        showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className="h-4 w-4 cursor-pointer ml-ten -mt-1"
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className="grid -mt-5 ml-2 gap-2"
        >
          <img
            src={saveIcon}
            alt="save edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => {setIsEditable(false)}}
          />
        </div>
      }
    </div>
  )
}

export default DobField;
