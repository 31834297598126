import React from "react";
import { shiftStrings } from "../../../../constants/shifts";
import AddShift from "./AddShift";
import CloseIcon from "../../talents/library/assets/svg/close.svg";

const ShiftPopup = (props) => {
  const {
    closePopup,
    isEditShift,
    setShowShiftPopup,
    setIsEditShift,
    shiftId,
    isAutoGeneratedAndUntouched
  } = props;
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-[775px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 px-8 rounded-t">
              <h3 className="text-2xl font-medium">
                {isEditShift ? shiftStrings.editShift : shiftStrings.addShift}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={() => closePopup()} />
            </div>
            <div className="relative px-8 flex-auto">
              <AddShift
                closePopup={closePopup}
                isPopup={true}
                isEditShift={isEditShift}
                setShowShiftPopup={setShowShiftPopup}
                setIsEditShift={setIsEditShift}
                shiftId={shiftId}
                isAutoGeneratedAndUntouched={isAutoGeneratedAndUntouched}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ShiftPopup;
