import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import location from "../../../../assets/images/locationNew02.png"
import mail from "../../../../assets/images/mailNew.png"
import phone from "../../../../assets/images/phoneNew.png"
import ProfilePicCropper from "../../components/profilePicCropper";
import { FormCategories, Strings, formBtnText, payrollField, starRate, tempProfileImageLink } from "../../../../constants";
import AddIcon from "../../../Add";
import getAge from "../../../../globalFunctions/getAge";
import _ from 'lodash'
import InputField from "../../components/inputField";
import { updateCoreAttribute, updateOtherAttributes } from "../../../../store/actions/talents";
import StarRating from "../../components/starRating";

const TalentCard = (props) => {
  const {
    isTalentView,
    photos,
    isPersonalInfoUpdated,
    personalInfoUserUpdated,
    isContactUpdated,
    contactUserUpdated,
    userId,
    userRating, userPayrollId,
    triggerUpdateCoreAttribute,
    triggerUpdateOtherAttributes
  } = props;
  const [profileImage, setProfileImage] = useState(tempProfileImageLink);
  const [name, setName] = useState('New Talent');
  const [age, setAge] = useState('(AGE)');
  const [address, setAddress] = useState('Address, City, Country');
  const [emailAddress, setEmailAddress] = useState('email@email.com');
  const [phoneNumber, setPhoneNumber] = useState('Phone Number');
  const [bioDescription, setBioDescription] = useState('Bio');
  const [companyRating, setCompanyRating] = useState(0);
  const [payrollId, setPayrollId] = useState('');
  const [reset, setReset] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setCompanyRating(userRating);
    setPayrollId(userPayrollId);
  }, [userRating, userPayrollId])
  
  useEffect(() => {
    if (!isPersonalInfoUpdated && !isContactUpdated) {
      setReset(true);
    } else {
      setReset(false);
    }
  }, [isPersonalInfoUpdated, isContactUpdated])

  useEffect(() => {
    let primaryImageObj = photos && photos.find(photo => photo.primary);
    if (!primaryImageObj) {
      primaryImageObj = photos[0]
    }
    if (primaryImageObj && primaryImageObj?.fileLink) {
      setProfileImage(`${baseUrl}${primaryImageObj?.fileLink}`);
    } else {
      setProfileImage(tempProfileImageLink);
    }
  }, [photos, baseUrl])

  useEffect(() => {
    if (isPersonalInfoUpdated) {
      const personalUserData = _.cloneDeep(personalInfoUserUpdated);
      const personalInfoCore = personalUserData[FormCategories.core];
      const personalInfoBio = personalUserData[FormCategories.bio];
      const personalInfoUncategorized = personalUserData[FormCategories.uncategorized];
      let firstName = '', lastName = '';
      personalInfoCore && personalInfoCore.forEach(coreVal => {
        if (coreVal.id === Strings.firstName && coreVal.value) {
          firstName = coreVal.value
        }
        if (coreVal.id === Strings.lastName && coreVal.value) {
          lastName = coreVal.value
        }
      })
      if (firstName || lastName) {
        setName(`${firstName} ${lastName}`);
      }
      if (personalInfoBio.value) {
        setBioDescription(personalInfoBio.value);
      }
      personalInfoUncategorized && personalInfoUncategorized.forEach(uncategorized => {
        uncategorized.forEach(field => {
          if (field.label === Strings.dob && field.value) {
            setAge(`(${getAge(field.value)}yo)`);
          }
        })
      })
    }
  }, [isPersonalInfoUpdated, personalInfoUserUpdated])
  
  useEffect(() => {
    if (isContactUpdated) {
      const contactData = _.cloneDeep(contactUserUpdated);
      const contactInfo = contactData[FormCategories.contact];
      const contactAddressInfo = contactData[FormCategories.residentialAddress];
      
      contactInfo && contactInfo.forEach(contact => {
        if (contact.label === Strings.contactNumber && contact.value) {
          setPhoneNumber(contact.value);
        }
        if (contact.label === Strings.email && contact.value) {
          setEmailAddress(contact.value);
        }
      });
      let streetAddress = '', city = '', state = '';
      contactAddressInfo && contactAddressInfo.addressFields.forEach(addressFields => {
        addressFields.forEach(field => {
          if (field.label === Strings.streetAddress && field.value) {
            streetAddress = field.value;
          }
          if (field.label === Strings.city && field.value) {
            city = field.value;
          }
          if (field.label === Strings.state && field.optionSelection) {
            state = field.optionSelection;
          }
        })
      })
      if (streetAddress || city || state) {
        setAddress(`${streetAddress}${city && ', '}${city}${state && ', '}${state}`);
      }
    }
  }, [isContactUpdated, contactUserUpdated])

  const onChangeStarRating = (id, value, isOptionUpdate=false) => {}

  const onSaveStarRating = (id, value, isValueEdited, name, isOptionUpdate=false, optionList=[]) => {
    const actualPayload = {
      'uuid': userId,
      'company_rating': parseFloat(value)
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        triggerUpdateOtherAttributes({ rating: parseFloat(value), payroll: payrollId })
      },
      onError: () => {
      }
    }
    triggerUpdateCoreAttribute(payload);
  }

  const onChangePayroll = (id, value) => {}

  const onSavePayroll = (id, value, isValueEdited, name) => {
    const actualPayload = {
      'uuid': userId,
      'payroll_reference': value.toString()
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        triggerUpdateOtherAttributes({ rating: companyRating, payroll: value.toString() })
      },
      onError: () => {
      }
    }
    triggerUpdateCoreAttribute(payload);
  }

  return (
    <div className="flex">
      {/* <ProfilePicture image={profileImage} /> */}
      <div className="inherit rounded-ten w-[256px] h-[217px] pl-[10px]">
        <img
          src={profileImage}
          alt="Talent"
          className="block h-full w-full rounded-[10px] mt-[10px]"
        />
      </div>
      <div className="p-3 pl-0 w-full">
        <div className="flex flex-row justify-between mb-2 ml-4">
          <div className={`${isTalentView ? 'flex' : 'hidden'} mt-3`}>
            <p className='text-sm pt-[6px] text-grayNeutral font-medium'>PRID #</p>
            {
              payrollField && isTalentView &&
              <InputField name={payrollField?.uuid} id={payrollField?.uuid} onChange={onChangePayroll} placeholder='Not assigned' defaultValue={payrollId} type='text'
                maxAllowed={payrollField.maxAllowed} minAllowed={payrollField.minAllowed} required={payrollField.required} isReadOnly={isTalentView} showEditIcon={isTalentView} saveEdit={onSavePayroll} fieldCustomStyle="!w-[88px] !text-grayNeutral !font-medium" editCustomStyle="!mt-1.5" editSaveCustomStyle="!flex !mt-2" />
            }
          </div>
          <div className={`${isTalentView ? 'flex' : 'hidden'} justify-end`}>
            <button
              onClick={() => {}}
              type="submit"
              className={`cursor-pointer float-right w-fit text-white font-medium rounded-lg text-center py-1 px-3 flex justify-center items-center text-base mt-2 bg-secondaryLight hover:bg-secondary active:bg-secondaryDark`}
            >
              <div className="flex flex-row justify-center font-inter items-center">
                <AddIcon />
                <span className="mx-2 font-inter font-medium text-base">{formBtnText.addToEvent}</span>
              </div>
            </button>
          </div>
        </div>
        <div className={`${isTalentView ? 'mt-2' : 'mt-12'} flex mb-2`}>
          <h3 className="text-header leading-8 font-inter font-semibold mr-2 ml-4 capitalize">
            {name}
          </h3>
          <h3 className="flex items-center font-inter text-2xl font-normal text-grayNeutral">
            {age}
          </h3>
          {isTalentView &&
            <div className="w-auto h-auto ml-4 flex">
              <StarRating options={starRate.options} optionList={starRate.optionsList} name={starRate?.uuid} id={starRate?.uuid} onChangeOption={onChangeStarRating} defaultValue={Boolean(companyRating) && companyRating}
                isReadOnly={isTalentView} showEditIcon={isTalentView} saveEdit={onSaveStarRating} required={starRate.required} defaultCustomStyle="mt-0" editCustomStyle="mt-[1px]" editSaveCustomStyle="!flex !mt-2" />
            </div>
          }
        </div>
        <div className="flex gap-8 pb-3 font-medium justify-between ml-2 mr-5 mt-5">
          <label className="text-grayNeutral/[0.6] font-inter text-xs ml-ten flex items-center">
            <img src={location} alt="location pin" className="h-6 w-6 cursor-pointer mr-2" />
            {address}
          </label>
          <label className="text-grayNeutral/[0.6] font-inter text-xs ml-ten flex items-center">
            <img src={mail} alt="email icon" className="h-6 w-6 cursor-pointer mr-2" />
            {emailAddress}
          </label>
          <label className="text-grayNeutral/[0.6] font-inter text-xs ml-ten flex items-center">
            <img src={phone} alt="phone icon" className="h-6 w-6 cursor-pointer mr-2" />
            {phoneNumber}
          </label>
        </div>
        <hr className="ml-4 mr-4 border-[0.5px] border-silver" />
        <textarea className="w-full text-grayNeutral font-inter font-medium text-xs m-ten ml-[18px] h-[60px] overflow-auto resize-none" value={bioDescription} rows={10} cols={10} readOnly />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isPersonalInfoUpdated: state.talent.isPersonalInfoUpdated,
    personalInfoUserUpdated: state.talent.personalInfoUserUpdated,
    contactUserUpdated: state.talent.contactUserUpdated,
    isContactUpdated: state.talent.isContactUpdated,
    photos: state.talent.photos,
    userRating: state.talent.companyRating,
    userPayrollId: state.talent.payrollId
  };
};

const mapDispatchToProps = {
  triggerUpdateCoreAttribute: updateCoreAttribute,
  triggerUpdateOtherAttributes: updateOtherAttributes
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentCard);


export const ProfilePicture = ({ image }) => {
  const [showModal, setShowModal] = useState(false);
  const [showUploadOverlay, setShowUploadOverlay] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [resizedImage, setResizedImage] = useState(image);

  const inputRef = useRef(null);

  const handleImgChange = (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  }

  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const imageHover = () => {
    setShowUploadOverlay(!showUploadOverlay)
  }

  return (
    <div>
      {/* {showModal && 
        <ProfilePicCropper
          imageSrc={imageSrc}
          setResizedImage={setResizedImage}
          closeModal={closeModal}
        />
      } */}
      <div
        className="inherit rounded-ten w-profile h-profile"
        // onMouseEnter={imageHover}
        // onMouseLeave={imageHover}
      >
        {/* <div className={`opacity-80 bg-black z-10 w-profile h-profile rounded-ten fixed ${showUploadOverlay ? 'block' : 'hidden'}`}>
          <a
            className="flex flex-col items-center h-full justify-center text-white" href="/"
            onClick={handleInputClick}>
            <i className="w-16 h-16 fa fa-solid fa-camera text-white text-6xl align-middle cursor-pointer block"></i>
          </a>
          <input
            className="hidden"
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImgChange}
          />
        </div> */}
        <img
          src={resizedImage}
          alt="Talent"
          className="block h-full w-full rounded-[10px] mt-[10px]"
        />
      </div>
    </div>
  )
}
