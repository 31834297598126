import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from '../../../Spinner';
import { Strings, experienceContent, formBtnText } from '../../../../constants';
import { createNewTalent, deleteTalentAttr, deleteTalentEventRole, experienceUpdatedForm, skillsUpdatedForm, updateNewTalentData, updateTalentEventRole } from '../../../../store/actions/talents';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import BackArrow from '../../../BackArrow';
import _ from 'lodash';
import { error, success } from '../../../toast';
import getRecaptchaKey from '../../../../globalFunctions/getRecaptchaKey';

const Experience = (props) => {
  const {
    isExperienceUpdated,
    experienceUserUpdated,
    experienceTemplate,
    triggerExperienceUpdate,
    isSkillsUpdated,
    skillsUserUpdated,
    skillsTemplate,
    triggerSkillsUpdate,
    triggerCreateTalent,
    setSelectedSection,
    showEditIcon,
    userId,
    triggerUpdateEventRole,
    triggerUpdateSkills,
    triggerDeleteEvent,
    triggerDeleteSkill, isRegister,
    personalInfoUserUpdated, contactUserUpdated, emergencyContactUserUpdated,
    photos
  } = props;
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });

  const [loading, setLoading] = useState(false);
  const [captchaVal, setCaptchaVal] = useState('');
  const expTemplate = _.cloneDeep(experienceTemplate);
  const skillTemplate = _.cloneDeep(skillsTemplate);
  const [experienceFields, setExperienceFields] = useState(expTemplate);
  const [skillFields, setSkillFields] = useState(skillTemplate);
  const [isPersonalFullyValidated, setIsPersonalFullyValidated] = useState(false);
  const [isContactFullyValidated, setIsContactFullyValidated] = useState(false);
  const [isEmergencyFullyValidated, setIsEmergencyFullyValidated] = useState(false);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);

  useEffect(() => {
    if (isExperienceUpdated) {
      const userUpdated = _.cloneDeep(experienceUserUpdated);
      setExperienceFields(userUpdated);
    }
    if (isSkillsUpdated) {
      const userUpdated = _.cloneDeep(skillsUserUpdated);
      setSkillFields(userUpdated);
    }
  }, [isExperienceUpdated, isSkillsUpdated, experienceUserUpdated, skillsUserUpdated])

  useEffect(() => {
    if (personalInfoUserUpdated?.allFieldsUpdated) {
      setIsPersonalFullyValidated(true)
    } else {
      setIsPersonalFullyValidated(false)
    }
  }, [personalInfoUserUpdated])

  useEffect(() => {
    if (contactUserUpdated?.requiredFieldsUpdated) {
      setIsContactFullyValidated(true)
    } else {
      setIsContactFullyValidated(false)
    }
  }, [contactUserUpdated])

  useEffect(() => {
    if (emergencyContactUserUpdated?.allFieldsUpdated) {
      setIsEmergencyFullyValidated(true)
    } else {
      setIsEmergencyFullyValidated(false)
    }
  }, [emergencyContactUserUpdated])

  useEffect(() => {
    if (photos && Boolean(photos.length)) {
      setIsPhotoUploaded(true);
    }
  }, [photos])
  
  useEffect(() => {
    if (isRegister && !Boolean(photos.length)) {
      error('Please don\'t forget to take the time to add at least one photo on the right side before submitting your profile.')
    }
  }, [isRegister, photos])
  

  const updateExperienceInfo = () => {
    triggerExperienceUpdate({ experience: experienceFields })
  }

  const updateSkillsInfo = () => {
    triggerSkillsUpdate({ skills: skillFields })
  }

  const submit = () => {
    setLoading(true);
    updateExperienceInfo();
    updateSkillsInfo();
    if (isRegister) {
      const payload = {
        isRegister: true,
        captchaVal: captchaVal,
        onSuccess: () => {
          setLoading(false);
        },
        onError: (message) => {
          setLoading(false);
          error(message);
        }
      }
      triggerCreateTalent(payload);
    } else {
      const payload = {
        onSuccess: () => {
          setLoading(false);
        },
        onError: (message) => {
          setLoading(false);
          error(message);
        }
      }
      triggerCreateTalent(payload)
    }
  }

  const onExperienceUpdate = (e, id) => {
    if (userId) {
      let eventRoleUUID = e[e.length - 1].uuid;
      experienceFields.forEach(field => { 
        if (field.id === id) {
          field.options.forEach(option => {
            if (option.name === e[e.length - 1].name) {
              eventRoleUUID = option.uuid;
            }
          })
        }
      })
      const actualPayload = {
        "person_uuid": userId,
        "event_role_uuid": eventRoleUUID
      }
      const payload = {
        actualPayload: actualPayload,
        onSuccess: (uuid) => {
          experienceFields.forEach(field => {
            if (field.id === id) {
              e[e.length - 1].uuid = uuid;
              field.optionSelectionList = e;
            }
          })
          updateExperienceInfo();
        },
        onError: () => {
        }
      }
      triggerUpdateEventRole(payload);
    } else {
      experienceFields.forEach(field => {
        if (field.id === id) {
          field.optionSelectionList = e;
        }
      })
      updateExperienceInfo();
    }
  }

  const onSkillsUpdate = (e, id) => {
    if (userId) {
      let personAttrUUID = e[e.length - 1].uuid;
      skillFields.forEach(field => { 
        if (field.id === id) {
          field.options.forEach(option => {
            if (option.name === e[e.length - 1].name) {
              personAttrUUID = option.uuid;
            }
          })
        }
      })
      const actualPayload = {
        "person_uuid": userId,
        "person_attribute_uuid": personAttrUUID,
        "value": true
      }
      const payload = {
        actualPayload: actualPayload,
        onSuccess: (uuid) => {
          skillFields.forEach(field => {
            if (field.id === id) {
              e[e.length - 1].uuid = uuid;
              field.optionSelectionList = e;
            }
          })
          updateSkillsInfo();
        },
        onError: () => {
        }
      }
      triggerUpdateSkills(payload);
    } else {
      skillFields.forEach(field => {
        if (field.id === id) {
          field.optionSelectionList = e;
        }
      })
      updateSkillsInfo();
    }
  }

  const onRemoveExperience = (e, selectedItem, id) => {
    const payload = {
      uuid: selectedItem.uuid,
      onSuccess: (msg) => {
        experienceFields.forEach(field => {
          if (field.id === id) {
            field.optionSelectionList = e;
          }
        })
        updateExperienceInfo();
        success(msg);
      },
      onError: (msg) => {
        error(msg);
      }
    }
    if (showEditIcon) {
      triggerDeleteEvent(payload)
    } else {
      experienceFields.forEach(field => {
        if (field.id === id) {
          field.optionSelectionList = e;
        }
      })
      updateExperienceInfo();
    }
  }

  const onRemoveSkills = (e, selectedItem, id) => {
    const payload = {
      uuid: selectedItem.uuid,
      onSuccess: (msg) => {
        skillFields.forEach(field => {
          if (field.id === id) {
            field.optionSelectionList = e;
          }
        })
        updateSkillsInfo();
        success(msg);
      },
      onError: (msg) => {
        error(msg);
      }
    }
    if (showEditIcon) {
      triggerDeleteSkill(payload);
    } else {
      skillFields.forEach(field => {
        if (field.id === id) {
          field.optionSelectionList = e;
        }
      })
      updateSkillsInfo();
    }
  }

  const handleRecaptchaChange = (val) => {
    setCaptchaVal(val);
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full">
      {
        isRegister &&
        <div className='bg-darkMintGreen/11 mb-4 rounded-lg'>
          <p className='font-inter font-semibold text-midnightBlack text-base p-2 italic'>{experienceContent}</p>
        </div>
      }
      <div className='gap-4 grid'>
        {
          experienceFields.map((field, index) => {
            return (
              <fieldset key={index} className="w-full m-auto text-lg mb-4">
                {
                  field.options &&
                  <MultiSelectDropdown label={field.label} id={field.id} options={field.options} onOptionUpdate={onExperienceUpdate} optionsSelected={field.optionSelectionList} placeholder={field.placeholder} onRemoveOption={onRemoveExperience} />
                }
              </fieldset>
            )
          })
        }
        {
          skillFields.map((field, index) => {
            return (
              <fieldset key={index} className="w-full m-auto text-lg mb-4">
                {
                  field.options &&
                  <MultiSelectDropdown label={field.label} id={field.id} options={field.options} onOptionUpdate={onSkillsUpdate} optionsSelected={field.optionSelectionList} placeholder={field.placeholder} onRemoveOption={onRemoveSkills} />
                }
              </fieldset>
            )
          })
        }
      </div>
      <div className={`${showEditIcon ? 'hidden' : 'flex'} float-right`}>
        <button
          onClick={() => {
            setSelectedSection(2)
          }}
          disabled={loading}
          type="submit"
          className={`cursor-pointer float-right w-fit text-secondayNeutral font-medium rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-8 bg-catskill mr-4`}
        >
          <div className="flex flex-row justify-center items-center">
            <BackArrow />
            <span className="mx-2">{formBtnText.back}</span>
          </div>
        </button>
        {
          isRegister && isPersonalFullyValidated && isContactFullyValidated && isEmergencyFullyValidated && isPhotoUploaded &&
          <div className='h-[40px] mr-4'>
            <ReCAPTCHA
              sitekey={getRecaptchaKey()}
              onChange={handleRecaptchaChange}
            />
          </div>
        }
        <button
          onClick={() => {
            reset(
              {
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
              }
            );
          }}
          disabled={loading || (isRegister && !captchaVal)}
          type="submit"
          className={`cursor-pointer float-right w-fit text-white font-medium rounded text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-8 bg-secondaryLight hover:bg-secondary active:bg-secondaryDark disabled:bg-gray`}
        >
          {loading ? (
            <div className="flex flex-row justify-center items-center">
              <span className="mx-2">{Strings.loading}</span>
              <Spinner color={"white"} loading={loading} size={15} />
            </div>
          ) : (
            `${formBtnText.finalField}`
          )}
        </button>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    isExperienceUpdated: state.talent.isExperienceUpdated,
    experienceUserUpdated: state.talent.experienceUserUpdated,
    experienceTemplate: state.talent.experienceTemplate,
    isSkillsUpdated: state.talent.isSkillsUpdated,
    skillsUserUpdated: state.talent.skillsUserUpdated,
    skillsTemplate: state.talent.skillsTemplate,
    personalInfoUserUpdated: state.talent.personalInfoUserUpdated,
    contactUserUpdated: state.talent.contactUserUpdated,
    emergencyContactUserUpdated: state.talent.emergencyContactUserUpdated,
    photos: state.talent.photos || [],
  };
};

const mapDispatchToProps = {
  triggerExperienceUpdate: experienceUpdatedForm,
  triggerSkillsUpdate: skillsUpdatedForm,
  triggerCreateTalent: createNewTalent,
  triggerUpdateEventRole: updateTalentEventRole,
  triggerUpdateSkills: updateNewTalentData,
  triggerDeleteEvent: deleteTalentEventRole,
  triggerDeleteSkill: deleteTalentAttr
};

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
