import React from "react";
import TalentList from "../../talents/talentListNew/talentList";
import { shiftStrings } from "../../../../constants/shifts";
import CloseIcon from "../../talents/library/assets/svg/close.svg";
import { eventBtnText } from "../../../../constants/events";

const AddTalent = (props) => {
  const { closeTalentPopup, addPerson = () => {}, selectedRoleUUID, selectedTalentForRole } = props;
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-[850px] my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 px-8 rounded-t">
              <h3 className="text-2xl font-medium">
                {shiftStrings.addTalentRole}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={closeTalentPopup} />
            </div>
            <div className="relative px-4 flex-auto h-[480px] overflow-auto mb-3 event-shift-talent-add">
            <TalentList isAddPersonToRole addPersonToRole={addPerson} eventShiftRoleUUID={selectedRoleUUID} eventShiftRoleSelectedTalent={selectedTalentForRole} />
            </div>
            <div className="flex items-center justify-end pb-6 pt-2 pr-6 rounded-b">
                <button
                  className="text-fontGray-500 text-coralPink bg-transparent text-sm outline-none rounded-lg text-center px-4 py-2 border border-coralPink font-medium focus:outline-none mr-2 disabled:bg-gray"
                  type="button"
                  onClick={closeTalentPopup}
                >
                  {eventBtnText.dismiss}
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
export default AddTalent;
