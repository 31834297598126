import actionTypes from "../types";

const {
  GET_TALENTS_LIST,
  GET_TALENTS_LIST_SUCCESS,
  GET_TALENTS_LIST_ERROR,
  GET_PROVINCES_SUCCESS,
  GET_EVENT_ROLES_SUCCESS,
  GET_FILTERED_TALENTS_LIST_SUCCESS,
  CLEAR_FILTERED_TALENTS_LIST_SUCCESS,
  GET_ATTRIBUTES_SUCCESS,
  GET_EMAIL_UUID_SUCCESS,
  GET_ADDRESS_UUID_SUCCESS,
  GET_PHONE_UUID_SUCCESS,
  GET_TALENTS_CORE_ATTRIBUTES_SUCCESS,
  PERSONAL_INFO_FORM_TEMPLATE,
  EXPERIENCE_FORM_TEMPLATE,
  CONTACT_FORM_TEMPLATE,
  EMERGENCY_CONTACT_FORM_TALENT,
  PERSONAL_INFO_UPDATED_FORM,
  EXPERIENCE_UPDATED_FORM,
  CONTACT_UPDATED_FORM,
  EMERGENCY_CONTACT_UPDATED_FORM,
  CLEAR_UPDATED_FORM_DETAILS,
  SKILLS_FORM_TEMPLATE,
  SKILLS_UPDATED_FORM,
  PHOTO_USER_UPDATED,
  UPDATE_CORE_ATTRIBUTE,
  CREATE_NEW_TALENT_SUCCESS, CREATE_NEW_TALENT_ERROR,
  SET_NEW_TALENT_ID,
  CLEAR_NEW_TALENT_ID,
  GET_TALENT_NOTES_DATA,
  TALENT_NOTES_DATA,
  UPDATE_OTHER_ATTRIBUTES_SUCCESS,
  SET_IS_EMERGENCY_PHONE_NUMBER_VALID,
  SET_IS_PHONE_NUMBER_VALID,
  UPDATE_DEFAULT_CONTACT_ADDRESS,
  ADDRESS_FIELD_TEMPLATE
} = actionTypes.talents;

const {
  LOGOUT_SUCCESS
} = actionTypes.auth;

const initialState = {
  talentsData: [],
  totalTalentsItemCount: 0,
  talentsPageSize: 0,
  totalTalentsPageCount: 0,
  talentsListpagenumber: 0,
  provinces: [{}],
  eventRoles: [],
  filteredTalentsData: [],
  totalFilteredTalentsItemCount: 0,
  filteredTalentsPageSize: 0,
  totalFilteredTalentsPageCount: 0,
  filteredTalentsListpagenumber: 0,
  personalInfoUncategorized: [],
  photoUUID: '',
  companyUUID: '',
  skillCategorized: [],
  languageCategorized: [],
  emailAttributes: [],
  addressAttributes: [],
  phoneAttributes: [],
  emergencyRelationship: [],
  personalInfoTemplate: {},
  experienceTemplate: {},
  contactTemplate: {},
  emergencyContactTemplate: {},
  skillsTemplate: {},
  personalInfoUserUpdated: {},
  experienceUserUpdated: {},
  contactUserUpdated: {},
  emergencyContactUserUpdated: {},
  skillsUserUpdated: {},
  isPersonalInfoUpdated: false,
  isExperienceUpdated: false,
  isContactUpdated: false,
  isEmergencyContactUpdated: false,
  isSkillsUpdated: false,
  photos: [],
  emergencyRelationOptions: {},
  showOverlayLoader: false,
  newTalentId: '',
  isPersonalTemplateUpdated: false,
  isExperienceTemplateUpdated: false,
  isContactTemplateUpdated: false,
  isEmergencyContactTemplateUpdated: false,
  isSkillsTemplateUpdated: false,
  notesData: [],
  companyRating: 0,
  payrollId: '',
  addressTemplate: []
}

const talentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TALENTS_LIST:
      return { ...state, loading: true };
    case GET_TALENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        talentsData: action?.response?.talentsListData,
        totalTalentsItemCount: action?.response?.totalItemCount,
        talentsPageSize: action?.response?.pageSize,
        totalTalentsPageCount: action?.response?.totalPageCount,
        talentsListpagenumber: action?.response?.responsePagenumber
      };
    case LOGOUT_SUCCESS: 
      return {
        ...initialState
      }
    case GET_TALENTS_LIST_ERROR:
      return {
        ...state,
        loading: false
      };
    case GET_PROVINCES_SUCCESS:
      return {
        ...state,
        provinces: action?.response?.provincesList
      }
    case GET_EVENT_ROLES_SUCCESS:
      return {
        ...state,
        eventRoles: action?.response?.eventRolesList
      }
    case GET_FILTERED_TALENTS_LIST_SUCCESS:
      return {
        ...state,
        filteredTalentsData: action?.response?.filteredTalentsList,
        totalFilteredTalentsItemCount: action?.response?.totalItemCount,
        filteredTalentsPageSize: action?.response?.pageSize,
        totalFilteredTalentsPageCount: action?.response?.totalPageCount,
        filteredTalentsListpagenumber: action?.response?.responsePagenumber
      }
    case CLEAR_FILTERED_TALENTS_LIST_SUCCESS:
      return {
        ...state,
        filteredTalentsData: [],
        totalFilteredTalentsItemCount: 0,
        filteredTalentsPageSize: 0,
        totalFilteredTalentsPageCount: 0,
        filteredTalentsListpagenumber: 0
      }
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        personalInfoUncategorized: action?.response?.personalInfoUncategorized,
        photoUUID: action?.response?.photoUUID,
        companyUUID: action?.response?.companyUUID,
        skillCategorized: action?.response?.skillCategorized,
        languageCategorized: action?.response?.languageCategorized
      }
    case GET_EMAIL_UUID_SUCCESS:
      return {
        ...state,
        emailAttributes: action?.response?.emailAttributeList
      }
    case GET_ADDRESS_UUID_SUCCESS:
      return {
        ...state,
        addressAttributes: action?.response?.addressAttributeList
      }
    case GET_PHONE_UUID_SUCCESS:
      return {
        ...state,
        phoneAttributes: action?.response?.phoneAttributeList
      }
    case GET_TALENTS_CORE_ATTRIBUTES_SUCCESS: 
      return {
        ...state,
        emergencyRelationship: action?.response?.emergencyRelationshipOptions,
        emergencyRelationOptions: action?.response?.emergencyRelationOptionList
      }
    case PERSONAL_INFO_FORM_TEMPLATE: 
      return {
        ...state,
        isPersonalTemplateUpdated: true,
        personalInfoTemplate: action?.response?.formTemplate
      }
    case EXPERIENCE_FORM_TEMPLATE: 
      return {
        ...state,
        isExperienceTemplateUpdated: true,
        experienceTemplate: action?.response?.formTemplate
      }
    case CONTACT_FORM_TEMPLATE: 
      return {
        ...state,
        isContactTemplateUpdated: true,
        contactTemplate: action?.response?.formTemplate
      }
    case EMERGENCY_CONTACT_FORM_TALENT: 
      return {
        ...state,
        isEmergencyContactTemplateUpdated: true,
        emergencyContactTemplate: action?.response?.formTemplate
      }
    case SKILLS_FORM_TEMPLATE:
      return {
        ...state,
        isSkillsTemplateUpdated: true,
        skillsTemplate: action?.response?.formTemplate
      }
    case PERSONAL_INFO_UPDATED_FORM: 
      return {
        ...state,
        isPersonalInfoUpdated: true,
        personalInfoUserUpdated: action?.response?.personalInfo,
        loading: false
      }
    case EXPERIENCE_UPDATED_FORM: 
      return {
        ...state,
        isExperienceUpdated: true,
        experienceUserUpdated: action?.response?.experience
      }
    case CONTACT_UPDATED_FORM: 
      return {
        ...state,
        isContactUpdated: true,
        contactUserUpdated: { ...state.contactUserUpdated, ...action?.response?.contact }
      }
    case EMERGENCY_CONTACT_UPDATED_FORM: 
      return {
        ...state,
        isEmergencyContactUpdated: true,
        emergencyContactUserUpdated: { ...state.emergencyContactUserUpdated, ...action?.response?.emergencyContact }
      }
    case UPDATE_DEFAULT_CONTACT_ADDRESS:
      return {
        ...state,
        contactUserUpdated: {...state.contactUserUpdated, ...action?.payload }
      }
    case SKILLS_UPDATED_FORM:
      return {
        ...state,
        isSkillsUpdated: true,
        skillsUserUpdated: action?.response?.skills
      }
    case CLEAR_UPDATED_FORM_DETAILS:
      return {
        ...state,
        isPersonalInfoUpdated: false,
        isExperienceUpdated: false,
        isContactUpdated: false,
        isEmergencyContactUpdated: false,
        isSkillsUpdated: false,
        personalInfoUserUpdated: {},
        experienceUserUpdated: {},
        contactUserUpdated: {},
        emergencyContactUserUpdated: {},
        skillsUserUpdated: {},
        photos: [],
        companyRating: 0,
        payrollId: ''
      }
    case PHOTO_USER_UPDATED:
      return {
        ...state,
        photos: action?.response?.photo
      }
    case UPDATE_CORE_ATTRIBUTE:
      return {
        ...state,
        loading: true
      }
    case CREATE_NEW_TALENT_SUCCESS:
      return {
        ...state,
        showOverlayLoader: true
      }
    case CREATE_NEW_TALENT_ERROR:
      return {
        ...state,
        showOverlayLoader: true
      }
    case SET_NEW_TALENT_ID: 
      return {
        ...state,
        newTalentId: action?.response?.id
      }
    case CLEAR_NEW_TALENT_ID: 
      return {
        ...state,
        newTalentId: ''
      }
    case TALENT_NOTES_DATA: 
      return {
        ...state,
        notesData: action?.response
      }
    case UPDATE_OTHER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        companyRating: action?.response?.starRating,
        payrollId: action?.response?.payrollId
      }
    case SET_IS_PHONE_NUMBER_VALID:
      return {
        ...state,
        contactUserUpdated: { ...state.contactUserUpdated, isPhoneNumberValid: action?.payload }
      }
    case SET_IS_EMERGENCY_PHONE_NUMBER_VALID:
      return {
        ...state,
        emergencyContactUserUpdated: { ...state.emergencyContactUserUpdated, isEmergencyPhoneNumberValid: action?.payload }
      }
    case ADDRESS_FIELD_TEMPLATE:
      return {
        ...state,
        addressTemplate: action?.response?.addressTemplate
      }
    default:
      return { ...state };
  }
}

export default talentReducer;
