/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  eventBtnText,
  eventStrings,
  squareRounded,
} from "../../../../constants/events";
import CloseIcon from "../../talents/library/assets/svg/close.svg";
import Spinner from "../../../Spinner";
import { Strings } from "../../../../constants";
import InputField from "../../components/inputField";
import {
  updateShiftRoleTemplate,
  updateShiftNoOfStaffTemplate,
  updateMinRatingTemplate,
  updateRateTemplate,
  updateShiftSkillsTemplate,
  updateRoleCompositionTemplate,
  updateSubLocationTemplate,
  updateShiftTypeTemplate,
  updateShiftNotesTemplate,
  updateShiftAttireTemplate,
  updateShiftDutiesTemplate,
  resetRoleModalTemp,
  createShiftRole,
  updateShiftRole,
  getVenueEnvironment
} from "../../../../store/actions/shifts";
import TextAreaField from "../../talents/talentAdd/textAreaField";
import SelectDropdown from "../../components/selectDropdown";
import _ from "lodash";
import RoleComposition from "../../components/roleComposition";
import { useForm } from "react-hook-form";
import { success as toastSuccess, error as toastError } from "../../../toast";

const AddRole = (props) => {
  const {
    closeRolePopup,
    setLoadingShift,

    shiftUUIDForRole,
    roleUUIDForEdit,
    showEditIcon,
    isViewRole,
    eventUUID,

    storeShiftRoleTemp,
    storeNumberOfStaffTemp,
    storeShiftSkillsTemp,
    storeMinRatingTemp,
    storeRateTemp,
    storeShiftRoleCompositionTemp,
    storeSubLocationTemp,
    storeShiftTypeTemp,
    storeShiftTypeTempOptions,
    storeShiftTypeTempOptionsList,
    storeShiftAttireTemp,
    storeShiftDutiesTemp,
    storeShiftNotesTemp,
    eventRoles,
    skillCategorized,
    languageCategorized,

    triggerUpdateShiftRole,
    triggerUpdateShiftStaff,
    triggerUpdateShiftRating,
    triggerUpdateShiftRate,
    triggerUpdateShiftSkills,
    triggerUpdateShiftSubLocation,
    triggerUpdateShiftType,
    triggerUpdateShiftNotes,
    triggerUpdateShiftAttire,
    triggerUpdateShiftDuties,
    triggerResetModalRoleTemp,
    triggerCreateShiftRole,
    triggerUpdateRole,
    triggerGetVenueEnvironment
  } = props;
  const [loading, setLoading] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);

  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });

  let roleTemp = _.cloneDeep(storeShiftRoleTemp);
  let staffTemp = _.cloneDeep(storeNumberOfStaffTemp);
  let skillsTemp = _.cloneDeep(storeShiftSkillsTemp);
  let ratingTemp = _.cloneDeep(storeMinRatingTemp);
  let rateTemp = _.cloneDeep(storeRateTemp);
  let subLocationTemp = _.cloneDeep(storeSubLocationTemp);
  let venueTypeTemp = _.cloneDeep(storeShiftTypeTemp);
  let attireTemp = _.cloneDeep(storeShiftAttireTemp);
  let dutiesTemp = _.cloneDeep(storeShiftDutiesTemp);
  let notesTemp = _.cloneDeep(storeShiftNotesTemp);

  const minRatingRegex = /^([1-5](\.\d{0,2})?)?$/;


  useEffect(() => {
    if(!showEditIcon) {
      triggerGetVenueEnvironment();
    }
    return () => {
      triggerResetModalRoleTemp();
    }
  }, []);

  useEffect(() => {
    roleTemp = _.cloneDeep(storeShiftRoleTemp);
    staffTemp = _.cloneDeep(storeNumberOfStaffTemp);
    skillsTemp = _.cloneDeep(storeShiftSkillsTemp);
    ratingTemp = _.cloneDeep(storeMinRatingTemp);
    rateTemp = _.cloneDeep(storeRateTemp);
    subLocationTemp = _.cloneDeep(storeSubLocationTemp);
    venueTypeTemp = _.cloneDeep(storeShiftTypeTemp);
    attireTemp = _.cloneDeep(storeShiftAttireTemp);
    dutiesTemp = _.cloneDeep(storeShiftDutiesTemp);
    notesTemp = _.cloneDeep(storeShiftNotesTemp);
    checkForValidation();
  }, [
    storeShiftRoleTemp,
    storeNumberOfStaffTemp,
    storeShiftSkillsTemp,
    storeMinRatingTemp,
    storeRateTemp,
    storeSubLocationTemp,
    storeShiftTypeTemp,
    storeShiftTypeTempOptions,
    storeShiftTypeTempOptionsList,
    storeShiftAttireTemp,
    storeShiftDutiesTemp,
    storeShiftNotesTemp,
  ]);

  useEffect(() => {
    const rolesList = [
      {
        label: "Experiental",
        options: eventRoles?.experiential?.event_roles.map(val => ({ label: val.name, value: val.uuid }))
      },
      {
        label: "Hospitality",
        options: eventRoles?.hospitality?.event_roles.map(val => ({ label: val.name, value: val.uuid }))
      },
      {
        label: "Security",
        options: eventRoles?.security?.event_roles.map(val => ({ label: val.name, value: val.uuid }))
      }
    ];
    setRolesList(rolesList);
  }, [eventRoles]);

  useEffect(() => {
    const skillsList = [
      {
        label: "Skills",
        options: skillCategorized.map(val => ({ label: Object.keys(val)[0], value: Object.values(val)[0] }))
      },
      {
        label: "Languages Spoken",
        options: languageCategorized.map(val => ({ label: Object.keys(val)[0], value: Object.values(val)[0] }))
      }
    ];
    setSkillsList(skillsList)
  }, [skillCategorized, languageCategorized]);

  const checkForValidation = () => {
    const templateArr = [
      storeShiftRoleTemp,
      storeNumberOfStaffTemp,
      storeShiftSkillsTemp,
      storeMinRatingTemp,
      storeRateTemp,
      storeSubLocationTemp,
      storeShiftTypeTemp,
      storeShiftAttireTemp,
      storeShiftDutiesTemp,
      storeShiftNotesTemp,
    ];
    const isDisable = templateArr.filter(temp => temp?.required)?.some(temp => !temp.value.length);
    setDisableSubmitBtn(isDisable);
  }

  const onChangeShiftRoleHandler = (value) => {
    roleTemp["value"] = value;
    triggerUpdateShiftRole({ roleDetails: roleTemp });
  };

  const onChangeShiftStaffHandler = (id, value) => {
    staffTemp["value"] = value;
    triggerUpdateShiftStaff({ shiftDetails: staffTemp });
  };

  const onChangeShiftRatingHandler = (id, value) => {
    ratingTemp["value"] = value;
    triggerUpdateShiftRating({ shiftDetails: ratingTemp });
  };

  const onChangeShiftRateHandler = (id, value) => {
    rateTemp["value"] = value;
    triggerUpdateShiftRate({ shiftDetails: rateTemp });
  };

  const onChangeShiftSkillsHandler = (value) => {
    skillsTemp["value"] = value;
    triggerUpdateShiftSkills({ skillsDetails: skillsTemp });
  };

  const onChangeShiftSubLocationHandler = (id, value) => {
    subLocationTemp["value"] = value;
    triggerUpdateShiftSubLocation({ shiftDetails: subLocationTemp });
  };

  const onChangeShiftTypeHandler = (value) => {
    venueTypeTemp["value"] = value;
    triggerUpdateShiftType({ shiftDetails: venueTypeTemp });
  };

  const onChangeShiftNotesHandler = (e) => {
    notesTemp["value"] = e.target.value;
    triggerUpdateShiftNotes({ shiftDetails: notesTemp });
  };

  const onChangeShiftAttireHandler = (e) => {
    attireTemp["value"] = e.target.value;
    triggerUpdateShiftAttire({ shiftDetails: attireTemp });
  };

  const onChangeShiftDutiesHandler = (e) => {
    dutiesTemp["value"] = e.target.value;
    triggerUpdateShiftDuties({ shiftDetails: dutiesTemp });
  };

  const submit = () => {
    setLoading(true);
    setLoadingShift(shiftUUIDForRole);
    const payload = {
      shiftUUID: shiftUUIDForRole,
      eventUUID,
      onSuccess: () => {
        setLoadingShift(null);
        setLoading(false);
        toastSuccess("Role created successfully!");
        closeRolePopup();
      },
      onError: (error) => {
        console.log(error);
        setLoadingShift(null);
        setLoading(false);
        toastError("Error in role creation");
      }
    };
    triggerCreateShiftRole(payload);
  };

  const updateRoleOnEdit = (actualPayload) => {
    actualPayload["uuid"] = roleUUIDForEdit;
    const payload = {
      actualPayload,
      eventUUID,
      onSuccess: () => {
        toastSuccess("Role updated successfully!");
      },
      onError: (error) => {
        toastError("Error in role updation");
        console.log("Error", error);
      }
    }

    triggerUpdateRole(payload);
  }

  const saveEditDropdown = (selectedOption, options, id) => {
    const tempPayload = {};
    if(id === "role") {
      roleTemp["value"] = selectedOption;
      triggerUpdateShiftRole({ roleDetails: roleTemp });
      tempPayload["event_role_uuid"] = selectedOption;
    }
    if(id === "skills") {
      skillsTemp["value"] = selectedOption;
      triggerUpdateShiftSkills({ skillsDetails: skillsTemp });
      tempPayload["person_search_obj"]= {
        replace_items: true
      };
      tempPayload["person_search_obj"]["skills"] = {
        has_all_in_list: true,
        list: selectedOption
      }
    }
    if(id === "venueType" || id === "shiftType") {
      venueTypeTemp["value"] = selectedOption;
      triggerUpdateShiftType({ shiftDetails: venueTypeTemp });
      tempPayload["venue_environment"] = selectedOption;
    }
    if(Object.keys(tempPayload).length) {
      updateRoleOnEdit(tempPayload);
    }
  }

  const saveEditInput = (id, updatedVal, isValueEdited, name) => {
    const tempPayload = {};
    if(id === "numberOfStaff") {
      staffTemp["value"] = updatedVal;
      triggerUpdateShiftStaff({ shiftDetails: staffTemp });
      tempPayload["talent_needed"] = Number(updatedVal);
    }
    if(id === "minRating") {
      ratingTemp["value"] = updatedVal;
      triggerUpdateShiftRating({ shiftDetails: ratingTemp });
      tempPayload["minimum_rating"] = Number(updatedVal);
    }
    if(id === "rate") {
      rateTemp["value"] = updatedVal;
      triggerUpdateShiftRate({ shiftDetails: rateTemp });
      tempPayload["hourly_cost"] = Number(updatedVal);
    }
    if(id === "subLocation") {
      subLocationTemp["value"] = updatedVal;
      triggerUpdateShiftSubLocation({ shiftDetails: subLocationTemp });
      tempPayload["sub_location"] = updatedVal;
    }
    if(Object.keys(tempPayload).length) {
      updateRoleOnEdit(tempPayload);
    }
  }

  const saveEditForTextArea = (id, updatedVal) => {
    const tempPayload = {};
    if(id === "attire") {
      attireTemp["value"] = updatedVal;
      triggerUpdateShiftAttire({ shiftDetails: attireTemp });
      tempPayload["attire_reqs"] = updatedVal;
    }
    if(id === "duties") {
      dutiesTemp["value"] = updatedVal;
      triggerUpdateShiftDuties({ shiftDetails: dutiesTemp });
      tempPayload["duties"] = updatedVal;
    }
    if(id === "notes") {
      notesTemp["value"] = updatedVal;
      triggerUpdateShiftNotes({ shiftDetails: notesTemp });
      tempPayload["desc"] = updatedVal;
    }
    if(Object.keys(tempPayload).length) {
      updateRoleOnEdit(tempPayload);
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full">
      <div className="justify-center items-center flex overflow-x-hidden h-full my-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none event-shift-role-modal">
        <div className="relative w-[517px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-medium">{isViewRole ? eventStrings.viewRole : showEditIcon ? eventStrings.editRole : eventStrings.addRole}</h3>
              <img
                className="w-5 h-5 my-auto cursor-pointer"
                src={CloseIcon}
                alt="close modal"
                onClick={() => !loading && closeRolePopup()}
              />
            </div>
            <div className="relative px-6 flex-auto h-[480px] overflow-auto mb-3">
              <div className="flex flex-row mb-4">
                <fieldset className="text-lg w-[280px] mr-4">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase pt-2 px-0 pb-0 gap-1 text-xs relative">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636] pl-2"
                        htmlFor={roleTemp?.id}
                      >
                        {roleTemp?.label}
                      </label>
                      {roleTemp?.required && squareRounded()}
                    </div>
                    <SelectDropdown
                      options={rolesList}
                      placeholder={roleTemp?.placeholder}
                      onChange={onChangeShiftRoleHandler}
                      selectedValue={roleTemp?.value}
                      optionsClass="event-shift-role-modal-options-single"
                      showEditIcon={showEditIcon}
                      id={roleTemp?.id || "role"}
                      saveEdit={saveEditDropdown}
                    />
                  </div>
                </fieldset>
                <fieldset className="text-lg w-[172px]">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={staffTemp?.id}
                      >
                        {staffTemp?.label}
                      </label>
                      {staffTemp?.required && squareRounded()}
                    </div>
                    <InputField
                      name={staffTemp?.id}
                      id={staffTemp?.id}
                      onChange={onChangeShiftStaffHandler}
                      placeholder={staffTemp?.placeholder}
                      defaultValue={staffTemp?.value}
                      type={staffTemp?.type}
                      maxAllowed={staffTemp?.maxAllowed}
                      minAllowed={staffTemp?.minAllowed}
                      required={staffTemp?.required}
                      disabled={staffTemp?.disabled}
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      editSaveCustomStyle="-mt-[1px] -ml-[50px]"
                      saveEdit={saveEditInput}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="gap-4 grid grid-cols-5">
                <fieldset className="w-full text-lg col-span-5">
                  <div className="border focus-within:border-richBlue pt-2 pl-2 border-grayLight rounded w-full uppercase gap-1 text-xs relative">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={skillsTemp?.id}
                      >
                        {skillsTemp?.label}
                      </label>
                      {skillsTemp?.required && squareRounded()}
                    </div>
                    <SelectDropdown
                      options={skillsList}
                      placeholder={skillsTemp?.placeholder}
                      onChange={onChangeShiftSkillsHandler}
                      selectedValue={skillsTemp?.value}
                      mode="multiple"
                      optionsClass="event-shift-role-modal-options-multiple"
                      showEditIcon={showEditIcon}
                      id={skillsTemp?.id || "skills"}
                      saveEdit={saveEditDropdown}
                      required={skillsTemp.required}
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-3">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={ratingTemp?.id}
                      >
                        {ratingTemp?.label}
                      </label>
                      {ratingTemp?.required && squareRounded()}
                    </div>
                    <InputField
                      name={ratingTemp?.id}
                      id={ratingTemp?.id}
                      onChange={onChangeShiftRatingHandler}
                      placeholder={ratingTemp?.placeholder}
                      defaultValue={ratingTemp?.value}
                      type={ratingTemp?.type}
                      maxAllowed={ratingTemp?.maxAllowed}
                      minAllowed={ratingTemp?.minAllowed}
                      required={ratingTemp?.required}
                      disabled={ratingTemp?.disabled}
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      editSaveCustomStyle="-mt-[1px] -ml-[50px]"
                      saveEdit={saveEditInput}
                      customRegexValidation={minRatingRegex}
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-2">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={rateTemp?.id}
                      >
                        {rateTemp?.label}
                      </label>
                      {rateTemp?.required && squareRounded()}
                    </div>
                    <InputField
                      name={rateTemp?.id}
                      id={rateTemp?.id}
                      onChange={onChangeShiftRateHandler}
                      placeholder={rateTemp?.placeholder}
                      defaultValue={rateTemp?.value}
                      type={rateTemp?.type}
                      maxAllowed={rateTemp?.maxAllowed}
                      minAllowed={rateTemp?.minAllowed}
                      required={rateTemp?.required}
                      disabled={rateTemp?.disabled}
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      saveEdit={saveEditInput}
                      editSaveCustomStyle="-mt-[8px] -ml-[50px]"
                    />
                  </div>
                </fieldset>
                <RoleComposition shiftRoleCompositionTemp={storeShiftRoleCompositionTemp} showEditIcon={showEditIcon} updateRoleOnEdit={updateRoleOnEdit} />
                <fieldset className="w-full text-lg col-span-5">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={subLocationTemp?.id}
                      >
                        {subLocationTemp?.label}
                      </label>
                      {subLocationTemp?.required && squareRounded()}
                    </div>
                    <InputField
                      name={subLocationTemp?.id}
                      id={subLocationTemp?.id}
                      onChange={onChangeShiftSubLocationHandler}
                      placeholder={subLocationTemp?.placeholder}
                      defaultValue={subLocationTemp?.value}
                      type={subLocationTemp?.type}
                      maxAllowed={subLocationTemp?.maxAllowed}
                      minAllowed={subLocationTemp?.minAllowed}
                      required={subLocationTemp?.required}
                      disabled={subLocationTemp?.disabled}
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      saveEdit={saveEditInput}
                      editSaveCustomStyle="-mt-[8px]"
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-3">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase pt-2 px-0 pb-0  gap-1 text-xs relative">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636] px-2"
                        htmlFor={venueTypeTemp?.id}
                      >
                        {venueTypeTemp?.label}
                      </label>
                      {venueTypeTemp?.required && squareRounded()}
                    </div>
                    <SelectDropdown
                      options={venueTypeTemp?.options}
                      placeholder={venueTypeTemp?.placeholder}
                      onChange={onChangeShiftTypeHandler}
                      selectedValue={venueTypeTemp?.value}
                      optionsClass="event-shift-role-modal-options-single"
                      showEditIcon={showEditIcon}
                      editSaveCustomStyle="!bottom-[5px]"
                      id={venueTypeTemp?.id || "venueType"}
                      saveEdit={saveEditDropdown}
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-5">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={attireTemp?.id}
                      >
                        {attireTemp?.label}
                      </label>
                      {attireTemp?.required && squareRounded()}
                    </div>
                    <TextAreaField
                      name={attireTemp?.name}
                      id={attireTemp?.id}
                      placeholder={attireTemp?.placeholder}
                      defaultValue={attireTemp?.value}
                      maxAllowed={attireTemp?.maxAllowed}
                      minAllowed={attireTemp?.minAllowed}
                      onChange={onChangeShiftAttireHandler}
                      className="resize-none"
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      editSaveCustomStyle="absolute right-0"
                      editCustomStyle="relative"
                      saveEdit={saveEditForTextArea}
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-5">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={dutiesTemp?.id}
                      >
                        {dutiesTemp?.label}
                      </label>
                      {dutiesTemp?.required && squareRounded()}
                    </div>
                    <TextAreaField
                      name={dutiesTemp?.name}
                      id={dutiesTemp?.id}
                      placeholder={dutiesTemp?.placeholder}
                      defaultValue={dutiesTemp?.value}
                      maxAllowed={dutiesTemp?.maxAllowed}
                      minAllowed={dutiesTemp?.minAllowed}
                      onChange={onChangeShiftDutiesHandler}
                      className="resize-none"
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      editSaveCustomStyle="absolute right-0"
                      editCustomStyle="relative"
                      saveEdit={saveEditForTextArea}
                    />
                  </div>
                </fieldset>
                <fieldset className="w-full text-lg col-span-5">
                  <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
                    <div className="flex flex-row">
                      <label
                        className="font-semibold font-inter text-sm text-[#393636]"
                        htmlFor={notesTemp?.id}
                      >
                        {notesTemp?.label}
                      </label>
                      {notesTemp?.required && squareRounded()}
                    </div>
                    <TextAreaField
                      name={notesTemp?.name}
                      id={notesTemp?.id}
                      placeholder={notesTemp?.placeholder}
                      defaultValue={notesTemp?.value}
                      maxAllowed={notesTemp?.maxAllowed}
                      minAllowed={notesTemp?.minAllowed}
                      onChange={onChangeShiftNotesHandler}
                      className="resize-none"
                      showEditIcon={showEditIcon}
                      isEventPage={true}
                      editSaveCustomStyle="absolute right-0"
                      editCustomStyle="relative"
                      saveEdit={saveEditForTextArea}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="flex items-center justify-end pb-6 pt-2 pr-6 rounded-b">
              <button
                className="text-fontGray-500 text-coralPink bg-transparent text-sm outline-none rounded-lg text-center px-4 py-2 border border-coralPink font-medium focus:outline-none mr-2 disabled:bg-gray"
                type="button"
                onClick={closeRolePopup}
                disabled={loading}
              >
                {eventBtnText.dismiss}
              </button>
                {!showEditIcon && (
                  <button
                      onClick={() => {
                      reset(
                        {
                          keepErrors: false,
                          keepDirty: true,
                          keepIsSubmitted: false,
                          keepTouched: false,
                          keepIsValid: false,
                          keepSubmitCount: false,
                        }
                      );
                    }}
                    disabled={loading || disableSubmitBtn}
                    type="submit"
                    className="bg-secondaryLight hover:bg-secondary active:bg-secondaryDark text-white font-medium text-sm px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer"
                  >
                    {loading ? (
                      <div className="flex flex-row justify-center items-center">
                        <span className="mx-2">{Strings.loading}</span>
                        <Spinner color={"white"} loading={loading} size={15} />
                      </div>
                    ) : (
                      `${eventBtnText.createSave}`
                    )}
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    storeShiftRoleTemp: state.shifts.shiftRoleTemp,
    storeNumberOfStaffTemp: state.shifts.numberOfStaffTemp,
    storeMinRatingTemp: state.shifts.minRatingTemp,
    storeRateTemp: state.shifts.rateTemp,
    storeShiftSkillsTemp: state.shifts.shiftSkillsTemp,
    storeShiftRoleCompositionTemp: state.shifts.shiftRoleCompositionTemp,
    storeSubLocationTemp: state.shifts.subLocationTemp,
    storeShiftTypeTemp: state.shifts.shiftTypeTemp,
    storeShiftTypeTempOptions: state.shifts.shiftTypeTemp.options,
    storeShiftTypeTempOptionsList: state.shifts.shiftTypeTemp.optionsList,
    storeShiftNotesTemp: state.shifts.shiftNotesTemp,
    storeShiftAttireTemp: state.shifts.shiftAttireTemp,
    storeShiftDutiesTemp: state.shifts.shiftDutiesTemp,
    eventRoles: state.talent.eventRoles,
    skillCategorized: state.talent.skillCategorized,
    languageCategorized: state.talent.languageCategorized,
  };
};

const mapDispatchToProps = {
  triggerUpdateShiftRole: updateShiftRoleTemplate,
  triggerUpdateShiftStaff: updateShiftNoOfStaffTemplate,
  triggerUpdateShiftRating: updateMinRatingTemplate,
  triggerUpdateShiftRate: updateRateTemplate,
  triggerUpdateShiftSkills: updateShiftSkillsTemplate,
  triggerUpdateShiftComp: updateRoleCompositionTemplate,
  triggerUpdateShiftSubLocation: updateSubLocationTemplate,
  triggerUpdateShiftType: updateShiftTypeTemplate,
  triggerUpdateShiftNotes: updateShiftNotesTemplate,
  triggerUpdateShiftAttire: updateShiftAttireTemplate,
  triggerUpdateShiftDuties: updateShiftDutiesTemplate,
  triggerResetModalRoleTemp: resetRoleModalTemp,
  triggerCreateShiftRole: createShiftRole,
  triggerUpdateRole: updateShiftRole,
  triggerGetVenueEnvironment: getVenueEnvironment
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
