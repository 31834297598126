import { takeLatest, call, put, all, select } from "redux-saga/effects";
import actionTypes from "../types";
import { getTimezonesSuccess, updateShiftDateTimeTemplateSuccess, updateShiftLocationTemplateSuccess, updateShiftNameTemplateSuccess, updateShiftTimezoneTemplateSuccess, getEventShiftsSuccess,
  updateShiftRoleTemplateSuccess, updateShiftNoOfStaffTemplateSuccess, updateMinRatingTemplateSuccess,
  updateRateTemplateSuccess, updateShiftSkillsTemplateSuccess, updateRoleCompositionTemplateSuccess,
  updateSubLocationTemplateSuccess, updateShiftTypeTemplateSuccess, updateShiftNotesTemplateSuccess,
  updateShiftAttireTemplateSuccess, updateShiftDutiesTemplateSuccess, updateShiftNameTemplate, updateShiftTimezoneTemplate, updateShiftDateTimeTemplate, updateShiftLocationTemplate,
  clearShiftDetailsSuccess, getTimezones, getVenueEnvironmentSuccess, getEventShifts
} from "../actions/shifts";
import { createEventShift, timezones, getShifts, cloneShiftRole, deleteShiftRole, deleteShift, updateEventShiftDetails, getShift, venueAddress, createRole, updateRole, addPerson, updatePerson, deletePerson } from "../../api/shifts";
import { verifyApiStatus } from "./talent";
import { objToArrayOfVal } from "../../globalFunctions/convertObjToArrayOfVal";
import getKeyByValue from "../../globalFunctions/getKeyByValue";
import _ from "lodash";
import moment from "moment";
import { convertObjToSelectOptions } from "../../components/pages/talents/talentListNew/helper";
import { resetLocationTemp } from "../actions/events";

const {
  GET_TIMEZONES, GET_VENUE_ENVIRONMENT, UPDATE_SHIFT_NAME_TEMPLATE, UPDATE_SHIFT_TIMEZONE_TEMPLATE, UPDATE_SHIFT_DATE_TIME_TEMPLATE,
  UPDATE_SHIFT_LOCATION_TEMPLATE, CREATE_SHIFT, GET_EVENT_SHIFTS,
  CLONE_ROLE,
  DELETE_ROLE,
  DELETE_SHIFT, UPDATE_SHIFT_ROLE_TEMPLATE, UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE, UPDATE_MIN_RATING_TEMPLATE, UPDATE_RATE_TEMPLATE, UPDATE_SHIFT_SKILLS_TEMPLATE,
  UPDATE_ROLE_COMPOSITION_TEMPLATE, UPDATE_SUB_LOCATION_TEMPLATE, UPDATE_SHIFT_TYPE_TEMPLATE, UPDATE_SHIFT_NOTES_TEMPLATE,
  UPDATE_SHIFT_ATTIRE_TEMPLATE, UPDATE_SHIFT_DUTIES_TEMPLATE, GET_EVENT_SHIFT_DETAILS, CLEAR_SHIFT_DETAILS, UPDATE_SHIFT_DETAILS,
  CREATE_SHIFT_ROLE, UPDATE_SHIFT_ROLE, UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT,
  ADD_PERSON_TO_ROLE, UPDATE_PERSON_TO_ROLE, DELETE_PERSON_FROM_ROLE
} = actionTypes.shifts;

export const stateShiftTimezoneTemp = (state) => state.shifts.shiftTimezoneTemp;
export const stateShiftDateTimeTemp = (state) => state.shifts.shiftDateTimeTemp;
export const stateShiftLocationTemp = (state) => state.shifts.shiftLocationTemp;
export const stateShiftNameTemp = (state) => state.shifts.shiftNameTemp;
export const stateEventShifts = (state) => state.shifts.shiftList;
export const stateEventLocationTemp = (state) => state.events.eventLocationTemp;
export const stateShiftRoleTemp = (state) => state.shifts.shiftRoleTemp;
export const stateNumberOfStaffTemp = (state) => state.shifts.numberOfStaffTemp;
export const stateMinRatingTemp = (state) => state.shifts.minRatingTemp;
export const stateRateTemp = (state) => state.shifts.rateTemp;
export const stateShiftSkillsTemp = (state) => state.shifts.shiftSkillsTemp;
export const stateShiftRoleCompositionTemp = (state) => state.shifts.shiftRoleCompositionTemp;
export const stateSubLocationTemp = (state) => state.shifts.subLocationTemp;
export const stateShiftTypeTemp = (state) => state.shifts.shiftTypeTemp;
export const stateShiftNotesTemp = (state) => state.shifts.shiftNotesTemp;
export const stateShiftAttireTemp = (state) => state.shifts.shiftAttireTemp;
export const stateShiftDutiesTemp = (state) => state.shifts.shiftDutiesTemp;

export function* fetchTimezones() {
  try {
    const response = yield call(timezones);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const timezoneTemp = yield select(stateShiftTimezoneTemp);
    timezoneTemp['optionsList'] = data;
    const timezoneOptions = objToArrayOfVal(data)
    timezoneTemp['options'] = timezoneOptions;
    yield put(getTimezonesSuccess({ timezone: data, timezoneTemp: timezoneTemp }));
  } catch (error) {
    console.log('error occured on fetching time zones', error);
  }
}

export function* fetchVenueEnvironments() {
  try {
    const response = yield call(venueAddress);
    yield call(verifyApiStatus, response);
    const data = response?.data;
    const venueEnvironmentTemp = yield select(stateShiftTypeTemp);
    venueEnvironmentTemp["optionsList"] = data;
    venueEnvironmentTemp["options"] = convertObjToSelectOptions(data);
    yield put(getVenueEnvironmentSuccess({ venueEnvironmentTemp }))
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateShiftNameTemp(action) {
  const { payload } = action;
  yield put(updateShiftNameTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftTimezoneTemp(action) {
  const { payload } = action;
  yield put(updateShiftTimezoneTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftDateTimeTemp(action) {
  const { payload } = action;
  yield put(updateShiftDateTimeTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftLocationTemp(action) {
  const { payload } = action;
  yield put(updateShiftLocationTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* createShift(action) {
  const { payload } = action;
  const { id, isAutoGeneratedAndUntouched=false, shiftId, onSuccess, onError } = payload;
  try {
    const actualPayload = {}
    const shiftName = yield select(stateShiftNameTemp)
    const shiftLocation = yield select(stateShiftLocationTemp)
    const shiftDateTime = yield select(stateShiftDateTimeTemp)
    const shiftTimezone = yield select(stateShiftTimezoneTemp)
    const selectedTimezone = getKeyByValue(shiftTimezone?.optionsList, shiftTimezone?.optionSelection);
    actualPayload['name'] = shiftName?.value
    actualPayload['tz_name'] = selectedTimezone
    actualPayload['primary_venue_address_space_uuid'] = shiftLocation?.value
    actualPayload['local_start_at'] = shiftDateTime?.formattedStart
    actualPayload['local_end_at'] = shiftDateTime?.formattedEnd
    if (isAutoGeneratedAndUntouched) {
      actualPayload['uuid'] = shiftId;
      actualPayload['is_touched'] = true;
      const response = yield call(updateEventShiftDetails, actualPayload);
      yield call(verifyApiStatus, response);
      onSuccess()
    } else {
      actualPayload['event_uuid'] = id
      const response = yield call(createEventShift, actualPayload);
      yield call(verifyApiStatus, response);
      onSuccess()
    }
  } catch (error) {
    console.log('error occured on creating a shift', error);
    onError()
  }
}

export function* fetchEventShifts(action) {
  const { payload } = action;
  const { onSuccess, onError, id } = payload;
  try {
    const response = yield call(getShifts, id);
    yield call(verifyApiStatus, response);
    if(response?.data) yield call(updateEventShifts, response?.data);
    if(onSuccess) onSuccess(response?.data);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    if(onError) onError(error?.response);
  }
}

export function* updateEventShifts(shiftData) {
  try {
    let eventShifts = [];
    if(shiftData.length) {
      shiftData.forEach(shift => {
        const formattedShift = {
          UUID: shift?.uuid,
          name: shift?.name,
          startAt: shift?.local_start_at,
          startTZ: shift?.local_start_tz_abbr,
          endAt: shift?.local_end_at,
          endTZ: shift?.local_end_tz_abbr,
          primaryVenueAddressSpace: shift?.primary_venue_address_space,
          isAutoGeneratedAndUntouched: shift?.is_auto_generated_and_untouched,
          isVirtual: shift?.is_virtual,
          people: shift?.people,
          personSearchObj: shift?.person_search_obj,
          venue: shift?.venue_environment,
          roles: shift?.roles,
          desc: shift?.desc,
          eventUUID: shift?.event_uuid,
          stats: shift?.stats
        }
        eventShifts.push(formattedShift);
      })  
    }
    yield put(getEventShiftsSuccess({ eventShifts }))
  } catch (error) {
    console.log(error);
  }
}

export function* cloneRole(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  const { roleUUID, eventUUID } = actualPayload;
  try {
    const response = yield call(cloneShiftRole, roleUUID);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
      onSuccess(response?.data);
    };
    onSuccess(response?.data);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    onError(error?.response);
    console.log(error?.response);
  }
}

export function* addRoleToShift(roleData) {
  const { newRoleData, shiftUUID } = roleData;
  const stateShifts = yield select(stateEventShifts);
  try {
    const updatedRole = {
      ...newRoleData,
      event_shift_uuid: newRoleData?.event_shift?.uuid,
    }
    delete updatedRole.event_shift;
    const existingShifts = _.cloneDeep(stateShifts);
    const updatedShifts = existingShifts.map(shift => {
      if(shift?.UUID === shiftUUID) {
        shift?.roles.push(updatedRole);
      }
      return shift;
    });
    yield put(getEventShiftsSuccess({ eventShifts: updatedShifts }))
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deleteRole(action) {
  const { payload } = action;
  const { onSuccess, onError, actualPayload } = payload;
  const { roleUUID, eventUUID } = actualPayload;
  try {
    const response = yield call(deleteShiftRole, roleUUID);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
      onSuccess(response?.data);
    };
  } catch (error) {
    onError(error?.response);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* deleteEventShift(action) {
  const { payload } = action;
  const { onSuccess, onError, shiftUUID } = payload;
  const stateShifts = yield select(stateEventShifts);
  try {
    const response = yield call(deleteShift, shiftUUID);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      const existingShifts = _.cloneDeep(stateShifts);
      const updatedShiftList = existingShifts.filter((shift) => shift?.UUID !== shiftUUID);
      yield put(getEventShiftsSuccess({ eventShifts: updatedShiftList }))
      onSuccess(response?.data);
    };
  } catch (error) {
    onError(error?.response);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateShiftRoleTemp(action) {
  const { payload } = action;
  yield put(updateShiftRoleTemplateSuccess({ roleDetails: payload?.roleDetails }))
}

export function* updateShiftNoOfStaffTemp(action) {
  const { payload } = action;
  yield put(updateShiftNoOfStaffTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftMinRatingTemp(action) {
  const { payload } = action;
  yield put(updateMinRatingTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftRateTemp(action) {
  const { payload } = action;
  yield put(updateRateTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftSkillsTemp(action) {
  const { payload } = action;
  yield put(updateShiftSkillsTemplateSuccess({ skillsDetails: payload?.skillsDetails }))
}

export function* updateShiftRoleCompTemp(action) {
  const { payload } = action;
  yield put(updateRoleCompositionTemplateSuccess({ roleCompDetails: payload?.roleCompDetails }))
}

export function* updateShiftSubLocationTemp(action) {
  const { payload } = action;
  yield put(updateSubLocationTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftTypeTemp(action) {
  const { payload } = action;
  yield put(updateShiftTypeTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftNotesTemp(action) {
  const { payload } = action;
  yield put(updateShiftNotesTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftAttireTemp(action) {
  const { payload } = action;
  yield put(updateShiftAttireTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* updateShiftDutiesTemp(action) {
  const { payload } = action;
  yield put(updateShiftDutiesTemplateSuccess({ shiftDetails: payload?.shiftDetails }))
}

export function* getShiftDetails(action) {
  const { payload } = action;
  const { onSuccess, onError, id } = payload;
  try {
    const response = yield call(getShift, id);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield call(updateEventShift, response?.data);
    }
    onSuccess(response?.data);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    onError(error?.response);
  }
}

export function* updateShiftDates(shiftDetails) {
  const shiftDateTime = yield select(stateShiftDateTimeTemp);
  const { local_start_at, local_end_at } = shiftDetails;
  const formattedStart = moment(local_start_at).format("YYYY-MM-DD hh:mm A");
  const formattedEnd = moment(local_end_at).format("YYYY-MM-DD hh:mm A");
  shiftDateTime.value.startDate = formattedStart;
  shiftDateTime.value.endDate = formattedEnd;
  shiftDateTime.formattedStart = formattedStart;
  shiftDateTime.formattedEnd = formattedEnd;
  yield put(updateShiftDateTimeTemplate({ shiftDetails: shiftDateTime }));
}

export function* updateEventShift(shiftData) {
  try {
    yield call(updateShiftDates, shiftData);
    const { name, tz_name, primary_venue_address_space } = shiftData;
    const shiftName = yield select(stateShiftNameTemp)
    const shiftLocation = yield select(stateShiftLocationTemp)
    const shiftTimezone = yield select(stateShiftTimezoneTemp)
    const { venue_address = {} } = primary_venue_address_space
    const { venue = {} } = venue_address
    const { display_name = '', uuid = '' } = venue
    if (name) {
      shiftName.value = name
      yield put(updateShiftNameTemplate({ shiftDetails: shiftName }))
    }
    if (tz_name) {
      const options = shiftTimezone.optionsList
      const selectedTimezone = options[tz_name]
      shiftTimezone.optionSelection = selectedTimezone
      yield put(updateShiftTimezoneTemplate({ shiftDetails: shiftTimezone }))
    }
    if (display_name) {
      const selectedVenue = {};
      selectedVenue['value'] = uuid;
      selectedVenue['label'] = display_name;
      shiftLocation.value = uuid
      shiftLocation.selectedOption = selectedVenue
      yield put(updateShiftLocationTemplate({ shiftDetails: shiftLocation }))
    }
  } catch (error) {
    console.log('shift data error', error)
  }
}

export function* deleteShiftDetails(action) {
  const { payload={} } = action;
  const { onSuccess=()=>{} } = payload;
  yield put(resetLocationTemp());
  const shiftName = yield select(stateShiftNameTemp)
  const shiftLocation = yield select(stateShiftLocationTemp)
  const shiftDateTime = yield select(stateShiftDateTimeTemp)
  const shiftTimezone = yield select(stateShiftTimezoneTemp)
  const eventLocationTemp = yield select(stateEventLocationTemp)
  if (shiftName) {
    shiftName.value = ''
  }
  if (shiftLocation) {
    shiftLocation.selectedOption = eventLocationTemp?.selectedOption
    shiftLocation.value = eventLocationTemp?.value
  }
  if (shiftTimezone) {
    shiftTimezone.value = ''
    shiftTimezone.optionSelection = ''
  }
  if (shiftDateTime) {
    shiftDateTime.value.startDate = ''
    shiftDateTime.value.endDate = ''
    shiftDateTime.formattedStart = ''
    shiftDateTime.formattedEnd = ''
  }
  yield put(clearShiftDetailsSuccess({ shiftName: shiftName, shiftLocation: shiftLocation, shiftTimezone: shiftTimezone, shiftDateTime: shiftDateTime }));
  yield put(getTimezones());
  onSuccess();
}

export function* setShiftDetails(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError } = payload;
  try {
    const response = yield call(updateEventShiftDetails, actualPayload);
    yield call(verifyApiStatus, response);
    onSuccess(response?.data);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    onError(error?.response);
  }
}

export function* getPayloadForRole({ shiftUUID }) {
  const roleTemp = _.cloneDeep(yield select(stateShiftRoleTemp));
  const staffTemp = _.cloneDeep(yield select(stateNumberOfStaffTemp));
  const skillsTemp = _.cloneDeep(yield select(stateShiftSkillsTemp));
  const ratingTemp = _.cloneDeep(yield select(stateMinRatingTemp));
  const rateTemp = _.cloneDeep(yield select(stateRateTemp));
  const roleCompositionTemp = _.cloneDeep(
    yield select(stateShiftRoleCompositionTemp)
  );
  const subLocationTemp = _.cloneDeep(yield select(stateSubLocationTemp));
  const typeTemp = _.cloneDeep(yield select(stateShiftTypeTemp));
  const notesTemp = _.cloneDeep(yield select(stateShiftNotesTemp));
  const attireTemp = _.cloneDeep(yield select(stateShiftAttireTemp));
  const dutiesTemp = _.cloneDeep(yield select(stateShiftDutiesTemp));
  const rolePayload = {
    event_shift_uuid: shiftUUID,
    person_search_obj: {},
  };
  if (roleTemp?.value) rolePayload["event_role_uuid"] = roleTemp.value;
  if (staffTemp?.value) rolePayload["talent_needed"] = Number(staffTemp.value);
  if (skillsTemp?.value?.length) {
    rolePayload["person_search_obj"]["skills"] = {
      has_all_in_list: true,
      list: skillsTemp?.value,
    };
  }
  if (ratingTemp?.value)
    rolePayload["minimum_rating"] = Number(ratingTemp.value);
  if (rateTemp?.value) rolePayload["hourly_cost"] = Number(rateTemp.value);
  if (roleCompositionTemp?.value?.length) {
    rolePayload["person_search_obj"]["person_attributes"] =
      roleCompositionTemp?.value;
  }
  if (subLocationTemp?.value)
    rolePayload["sub_location"] = subLocationTemp.value;
  if (typeTemp?.value) rolePayload["venue_environment"] = typeTemp.value;
  if (notesTemp?.value) rolePayload["desc"] = notesTemp.value;
  if (attireTemp?.value) rolePayload["attire_reqs"] = attireTemp.value;
  if (dutiesTemp?.value) rolePayload["duties"] = dutiesTemp.value;
  return rolePayload;
}

export function* createShiftRole(action) {
  const { payload } = action;
  const { shiftUUID, onSuccess, onError, eventUUID } = payload;
  try {
    const rolePayload = yield call(getPayloadForRole, { shiftUUID });
    const response = yield call(createRole, rolePayload);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
    }
    onSuccess(response);
  } catch (error) {
    console.log("error", error);
    yield call(verifyApiStatus, error?.response);
    onError(error?.response);
  }
}

export function* updateRoleToTemplateForEdit(action) {
  const { payload } = action;
  const { roleUUID, shiftUUID } = payload;
  const roleTemp = _.cloneDeep(yield select(stateShiftRoleTemp));
  const staffTemp = _.cloneDeep(yield select(stateNumberOfStaffTemp));
  const skillsTemp = _.cloneDeep(yield select(stateShiftSkillsTemp));
  const ratingTemp = _.cloneDeep(yield select(stateMinRatingTemp));
  const rateTemp = _.cloneDeep(yield select(stateRateTemp));
  const roleCompositionTemp = _.cloneDeep(
    yield select(stateShiftRoleCompositionTemp)
  );
  const subLocationTemp = _.cloneDeep(yield select(stateSubLocationTemp));
  const typeTemp = _.cloneDeep(yield select(stateShiftTypeTemp));
  const notesTemp = _.cloneDeep(yield select(stateShiftNotesTemp));
  const attireTemp = _.cloneDeep(yield select(stateShiftAttireTemp));
  const dutiesTemp = _.cloneDeep(yield select(stateShiftDutiesTemp));
  try {
    const shiftList = yield select(stateEventShifts);
    const roleShift = shiftList.find(shift => shift.UUID === shiftUUID);
    const role = roleShift?.roles?.find(role => role.uuid === roleUUID);
    if(role?.event_role?.uuid) {
      roleTemp.value = role.event_role.uuid;
      yield put(updateShiftRoleTemplateSuccess({ roleDetails: roleTemp }));
    }
    if(role?.talent_needed) {
      staffTemp.value = role.talent_needed;
      yield put(updateShiftNoOfStaffTemplateSuccess({ shiftDetails: staffTemp }))
    }
    if(role?.person_search_obj?.skills?.list?.length) {
      skillsTemp.value = role.person_search_obj.skills.list;
      yield put(updateShiftSkillsTemplateSuccess({ skillsDetails: skillsTemp }));
    }
    if(role?.minimum_rating) {
      ratingTemp.value = role.minimum_rating;
      yield put(updateMinRatingTemplateSuccess({ shiftDetails: ratingTemp }));
    }
    if(role?.hourly_cost) {
      rateTemp.value = role.hourly_cost;
      yield put(updateRateTemplateSuccess({ shiftDetails: rateTemp }));
    }
    if(role?.person_search_obj?.person_attributes?.length) {
      roleCompositionTemp.value = role.person_search_obj.person_attributes;
      yield put(updateRoleCompositionTemplateSuccess({ roleCompDetails: roleCompositionTemp }));
    }
    if(role?.sub_location) {
      subLocationTemp.value = role.sub_location;
      yield put(updateSubLocationTemplateSuccess({ shiftDetails: subLocationTemp }));
    }
    try {
      const response = yield call(venueAddress);
      yield call(verifyApiStatus, response);
      const data = response?.data;
      typeTemp["optionsList"] = data;
      typeTemp["options"] = convertObjToSelectOptions(data);
    } catch (error) {
      yield call(verifyApiStatus, error?.response);
    }
    if(role?.venue_environment) {
      typeTemp["value"] = role.venue_environment;
    }
    yield put(updateShiftTypeTemplateSuccess({ shiftDetails: typeTemp }));

    if(role?.desc) {
      notesTemp.value = role.desc;
      yield put(updateShiftNotesTemplateSuccess({ shiftDetails: notesTemp }));
    }
    if(role?.attire_reqs) {
      attireTemp.value = role.attire_reqs;
      yield put(updateShiftAttireTemplateSuccess({ shiftDetails: attireTemp }));
    }
    if(role?.duties) {
      dutiesTemp.value = role.duties;
      yield put(updateShiftDutiesTemplateSuccess({ shiftDetails: dutiesTemp }));
    }
  } catch (error) {
    console.log(error);
  }
}


export function* updateParticularRoleToStore(roleData) {
  const { uuid } = roleData;
  const stateShifts = yield select(stateEventShifts);
  try {
    const updatedRole = {
      ...roleData,
      event_shift_uuid: roleData?.event_shift?.uuid,
    }
    delete updatedRole.event_shift;
    const existingShifts = _.cloneDeep(stateShifts);
    const updatedShifts = existingShifts.map(shift => {
      if(shift?.UUID === updatedRole.event_shift_uuid) {
        shift.roles = shift?.roles?.map(role => role.uuid === uuid ? updatedRole : role);
      }
      return shift;
    });
    yield put(getEventShiftsSuccess({ eventShifts: updatedShifts }))
  } catch(error) {
    console.log(error);
    yield call(verifyApiStatus, error?.response);
  }
}

export function* updateShiftRole(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError, eventUUID } = payload;
  try {
    const response = yield call(updateRole, actualPayload);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
    };
    onSuccess(response);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error);
    onError(error?.response)
  }
}

export function* addPersonToRole(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError, eventUUID } = payload;
  try {
    const response = yield call(addPerson, actualPayload);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
    }
    onSuccess(response?.data);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error);
    onError(error?.response)
  }
}

export function* updatePersonToRole(action) {
  const { payload } = action;
  const { actualPayload, onSuccess, onError, eventUUID } = payload;
  try {
    const response = yield call(updatePerson, actualPayload);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
    }
    onSuccess(response?.data);
  }
  catch (error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error);
    onError(error?.response)
  }
}

export function* deletePersonFromRole(action) {
  const { payload } = action;
  const { eventShiftRolePersonUUID, onSuccess, onError, eventUUID } = payload;
  try {
    const response = yield call(deletePerson, eventShiftRolePersonUUID);
    yield call(verifyApiStatus, response);
    if(response?.data) {
      yield put(getEventShifts({ id: eventUUID }));
    }
    onSuccess(response);
  } catch (error) {
    yield call(verifyApiStatus, error?.response);
    console.log(error);
    onError(error?.response)
  }
}

export function* saga() {
  yield takeLatest(GET_TIMEZONES, fetchTimezones);
  yield takeLatest(GET_VENUE_ENVIRONMENT, fetchVenueEnvironments);
  yield takeLatest(UPDATE_SHIFT_NAME_TEMPLATE, updateShiftNameTemp);
  yield takeLatest(UPDATE_SHIFT_TIMEZONE_TEMPLATE, updateShiftTimezoneTemp);
  yield takeLatest(UPDATE_SHIFT_DATE_TIME_TEMPLATE, updateShiftDateTimeTemp);
  yield takeLatest(UPDATE_SHIFT_LOCATION_TEMPLATE, updateShiftLocationTemp);
  yield takeLatest(CREATE_SHIFT, createShift);
  yield takeLatest(GET_EVENT_SHIFTS, fetchEventShifts);
  yield takeLatest(CLONE_ROLE, cloneRole);
  yield takeLatest(DELETE_ROLE, deleteRole);
  yield takeLatest(DELETE_SHIFT, deleteEventShift);
  yield takeLatest(UPDATE_SHIFT_ROLE_TEMPLATE, updateShiftRoleTemp);
  yield takeLatest(UPDATE_SHIFT_NO_OF_STAFF_TEMPLATE, updateShiftNoOfStaffTemp);
  yield takeLatest(UPDATE_MIN_RATING_TEMPLATE, updateShiftMinRatingTemp);
  yield takeLatest(UPDATE_RATE_TEMPLATE, updateShiftRateTemp);
  yield takeLatest(UPDATE_SHIFT_SKILLS_TEMPLATE, updateShiftSkillsTemp);
  yield takeLatest(UPDATE_ROLE_COMPOSITION_TEMPLATE, updateShiftRoleCompTemp);
  yield takeLatest(UPDATE_SUB_LOCATION_TEMPLATE, updateShiftSubLocationTemp);
  yield takeLatest(UPDATE_SHIFT_TYPE_TEMPLATE, updateShiftTypeTemp);
  yield takeLatest(UPDATE_SHIFT_NOTES_TEMPLATE, updateShiftNotesTemp);
  yield takeLatest(UPDATE_SHIFT_ATTIRE_TEMPLATE, updateShiftAttireTemp);
  yield takeLatest(UPDATE_SHIFT_DUTIES_TEMPLATE, updateShiftDutiesTemp);
  yield takeLatest(GET_EVENT_SHIFT_DETAILS, getShiftDetails);
  yield takeLatest(CLEAR_SHIFT_DETAILS, deleteShiftDetails);
  yield takeLatest(UPDATE_SHIFT_DETAILS, setShiftDetails);
  yield takeLatest(CREATE_SHIFT_ROLE, createShiftRole);
  yield takeLatest(UPDATE_ROLE_TO_TEMPLATE_FOR_EDIT, updateRoleToTemplateForEdit);
  yield takeLatest(UPDATE_SHIFT_ROLE, updateShiftRole);
  yield takeLatest(ADD_PERSON_TO_ROLE, addPersonToRole);
  yield takeLatest(UPDATE_PERSON_TO_ROLE, updatePersonToRole);
  yield takeLatest(DELETE_PERSON_FROM_ROLE, deletePersonFromRole);
}

export function* shiftSagas() {
  yield all([call(saga)]);
}
