import React, { useState } from "react";
import { connect } from "react-redux";
import ImageUploader from "react-image-upload";
import 'react-image-upload/dist/index.css'
import { deleteExistingUserImage, updateAssetPrimary, uploadNewUserImage } from "../../../../store/actions/talents";
import { personTypeUUID } from "../../../../constants";

const UserImageUploader = (props) => {
  const {
    photos,
    triggerAddImage,
    isTalentView,
    newTalentId,
    companyUUID,
    id,
    triggerDeleteExistingAsset,
    triggerAssetPrimary,
    isTalentRegister
  } = props;
  const baseUrl = process.env.REACT_APP_API_URL;
  const [imageIndex, setImageIndex] = useState([])

  const setImageFileObject = (image, i) => {
    let personId = newTalentId;
    if (isTalentView) {
      personId = id
    }
    if (Boolean(image.dataUrl)) {
      const actualPayload = {
        'companyId': companyUUID,
        'personId': personId,
        'asset': image.file,
        'isTalentView': isTalentView,
        'isRegister': isTalentRegister
      };
      const payload = {
        actualPayload: actualPayload,
        onSuccess: () => {
          const index = [...imageIndex, i];
          setImageIndex(index);
        },
        onError: () => {}
      }
      triggerAddImage(payload)
    }
  }

  const updateToPrimary = (photoData) => {
    const payload = {
      actualPayload: {
        'uuid': id,
        'person_type_uuid': personTypeUUID,
        'primary_photo_attribute_uuid': photoData?.attributeUUID
      },
      onSuccess: () => {},
      onError: () => {}
    }
    triggerAssetPrimary(payload)
  }

  const deleteExistingImageFileObject = (photoData) => {
    const actualPayload = {
      'companyId': companyUUID,
      'assetUUID': photoData?.assetUUID
    }
    if (photoData?.attributeUUID) {
      actualPayload['attributeUUID'] = photoData?.attributeUUID
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {},
      onError: () => {}
    }
    triggerDeleteExistingAsset(payload)
  }

  return (
    <div>
      {(() => {
        let td = [];
        photos.forEach((photo, i) => {
          const element = baseUrl + photo?.fileLink;
          td.push(<div key={i} className="relative">
            <img src={element} alt="" className="w-[350px] h-[350px] object-contain" />
            <div className="flex justify-end absolute bg-white w-[350px] z-[999] right-0">
              {photo?.primary ?
                <>
                  <svg className="mr-1.5" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <g clip-path="url(#clip0_1752_2043)">
                      <path d="M11.5 6.03993V6.49993C11.4994 7.57814 11.1503 8.62727 10.5047 9.49084C9.85908 10.3544 8.95164 10.9862 7.91768 11.2919C6.88372 11.5976 5.77863 11.5609 4.76724 11.1872C3.75584 10.8136 2.89233 10.123 2.30548 9.21847C1.71863 8.31395 1.4399 7.24396 1.51084 6.16809C1.58178 5.09221 1.9986 4.0681 2.69914 3.24847C3.39968 2.42884 4.34639 1.85762 5.39809 1.62001C6.4498 1.38239 7.55013 1.4911 8.535 1.92993" stroke="#2400FF" stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.5 2.5L6.5 7.505L5 6.005" stroke="#2400FF" stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1752_2043">
                        <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='primary-photo'  data-tooltip-content="Cannot delete primary picture" style={{ cursor: 'pointer' }}>
                    <path d="M1.5 3.5H2.5H10.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.5 3.5V10.5C9.5 10.7652 9.39464 11.0196 9.20711 11.2071C9.01957 11.3946 8.76522 11.5 8.5 11.5H3.5C3.23478 11.5 2.98043 11.3946 2.79289 11.2071C2.60536 11.0196 2.5 10.7652 2.5 10.5V3.5M4 3.5V2.5C4 2.23478 4.10536 1.98043 4.29289 1.79289C4.48043 1.60536 4.73478 1.5 5 1.5H7C7.26522 1.5 7.51957 1.60536 7.70711 1.79289C7.89464 1.98043 8 2.23478 8 2.5V3.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 6V9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7 6V9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </>
                :
                <>
                  <svg className={`mr-1.5 primary-photo cursor-pointer ${isTalentView ? 'block' : 'hidden'}`} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" data-tooltip-content="Set as profile photo" onClick={() => updateToPrimary(photo)}>
                    <path d="M6.5 11.5C9.26142 11.5 11.5 9.26142 11.5 6.5C11.5 3.73858 9.26142 1.5 6.5 1.5C3.73858 1.5 1.5 3.73858 1.5 6.5C1.5 9.26142 3.73858 11.5 6.5 11.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='primary-photo cursor-pointer' data-tooltip-content="Delete picture" onClick={() => deleteExistingImageFileObject(photo)}>
                    <path d="M1.5 3.5H2.5H10.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.5 3.5V10.5C9.5 10.7652 9.39464 11.0196 9.20711 11.2071C9.01957 11.3946 8.76522 11.5 8.5 11.5H3.5C3.23478 11.5 2.98043 11.3946 2.79289 11.2071C2.60536 11.0196 2.5 10.7652 2.5 10.5V3.5M4 3.5V2.5C4 2.23478 4.10536 1.98043 4.29289 1.79289C4.48043 1.60536 4.73478 1.5 5 1.5H7C7.26522 1.5 7.51957 1.60536 7.70711 1.79289C7.89464 1.98043 8 2.23478 8 2.5V3.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 6V9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7 6V9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </>
              }
            </div>
          </div>);
        })
        return td;
      })()}
      {(() => {
        let td = [];
        for (let i = 0; i < (5 + imageIndex.length) - photos.length; i++) {
          td.push(<div className={`mb-1 ${(imageIndex && imageIndex.includes(i)) ? 'hidden' : 'flex'}`} key={i}>
            <ImageUploader
              onFileAdded={(img) => setImageFileObject(img, i)}
              style={{ height: 350, width: 350, background: "#E9E9E9" }}
              deleteIcon={
                <img
                  src="https://img.icons8.com/ios/50/null/delete--v1.png"
                  alt=""
                  className="h-4 w-4 mr-1 mt-80 appearance-none"
                />
              }
              uploadIcon={
                <svg
                  className="svg-circleplus h-7 -ml-5 stroke-black"
                  viewBox="0 0 100 100"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    strokeWidth="7.5"
                  ></circle>
                  <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5"></line>
                  <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5"></line>
                </svg>
              }
            />
          </div>);
        }
        return td;
      })()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    photos: state.talent.photos || [],
    newTalentId: state.talent.newTalentId,
    companyUUID: state.talent.companyUUID
  };
};

const mapDispatchToProps = {
  triggerAddImage: uploadNewUserImage,
  triggerDeleteExistingAsset: deleteExistingUserImage,
  triggerAssetPrimary: updateAssetPrimary
}

export default connect(mapStateToProps, mapDispatchToProps)(UserImageUploader);
