import { editContactTemplate, eventAccountManagerTemplate, eventAttachmentsTemplate, eventBrandNameTemplate, eventClientNameTemplate, eventDatesTemplate,
  eventLocationTemplate, eventNameTemplate, eventNotesTemplate, eventPrimaryContactTemplate, eventSearchRadius, eventStatusTemplate } from "../../constants/events";
import actionTypes from "../types";
import _ from 'lodash';

const {
  GET_EVENTS_LIST, GET_EVENTS_LIST_SUCCESS, GET_EVENTS_LIST_ERROR,
  GET_EVENTS_CORE_DEF, GET_EVENTS_CORE_DEF_SUCCESS, GET_EVENTS_CORE_DEF_ERROR,
  GET_FILTERED_EVENTS_LIST, GET_FILTERED_EVENTS_LIST_SUCCESS, GET_FILTERED_EVENTS_LIST_ERROR, CLEAR_EVENT_LIST_FILTER,
  UPDATE_EVENT_NAME_TEMPLATE_SUCCESS, UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS, UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS, UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS,
  GET_CLIENT_SUGGESTIONS, GET_CLIENT_SUGGESTIONS_SUCCESS, UPDATE_CLIENT_SUGGESTIONS_SUCCESS, UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS,
  CREATE_VENUE_SUCCESS, CREATE_ONSITE_CONTACT_SUCCESS, GET_VENUE_SUGGESTIONS_SUCCESS, UPDATE_EVENT_ID_SUCCESS,
  SET_FILES_TO_UPLOAD, ADD_ATTACHMENT_SUCCESS, ALTERNATE_CONTACT_TEMP_SUCCESS, DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS, PRIMARY_CONTACT_SUCCESS,
  SET_NEW_EVENT_ID, UPDATE_EVENT_DATES_TEMPLATE_SUCCESS, UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS, CLEAR_EVENT_DETAILS_FORM, GET_EVENT, GET_EVENT_SUCCESS,
  RESET_LOCATION_TEMP, UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS,
  GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS, GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS, UPDATE_EVENT_FILTERS, SET_EVENT_SEARCH_TEXT
} = actionTypes.events;

const { LOGOUT_SUCCESS } = actionTypes.auth;

const initialState = {
  loading: false,
  eventsList: [],
  eventFieldsLoading: false,
  eventNameTemp: eventNameTemplate,
  eventClientNameTemp: eventClientNameTemplate,
  eventBrandNameTemp: eventBrandNameTemplate,
  eventManagerNameTemp: eventAccountManagerTemplate,
  eventDatesTemp: eventDatesTemplate,
  eventLocationTemp: eventLocationTemplate,
  eventPrimaryContactTemp: eventPrimaryContactTemplate,
  eventAttachmentTemp: eventAttachmentsTemplate,
  eventNotesTemp: eventNotesTemplate,
  alternateContactTemp: [],
  eventSearchRadiusTemp: eventSearchRadius,
  editContactTemp: editContactTemplate,
  onSiteContact: [],
  filesToUpload:[],
  attachedDocuments: [],
  totalEventsItemCount: 0,
  eventsPageSize: 0,
  totalEventsPageCount: 0,
  eventsListPageNumber: 0,
  filteredEventsList: [],
  totalFilteredEventsItemCount: 0,
  filteredEventsPageSize: 0,
  totalFilteredEventsPageCount: 0,
  filteredEventsListPageNumber: 0,
  clientSuggestions: [],
  clientSuggestionsPageNumber: 1,
  hasMoreClientSuggestions: false,
  venueSuggestions: [],
  venueSuggestionsPageNumber: 1,
  hasMoreVenueSuggestions: false,
  suggestionsUpdated: false,
  newEventId:"",
  eventDataLoading: false,
  altIds: [],
  eventShortId: "",
  eventStatusTemp: eventStatusTemplate,
  filterObj: {
    dateRangeSlug: "",
    sortOrderSlug: "",
    includePastEvents: ""
  },
  eventSearchText: "",
  eventDateRangeDropdown: {},
  eventSortOrderDropdown: {}
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS: 
      return {
        ...initialState
      }
    case GET_EVENTS_LIST:
      return { ...state, loading: true };
    case GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsList: action?.response?.eventsListData || [],
        totalEventsItemCount: action?.response?.totalItemCount,
        eventsPageSize: action?.response?.pageSize,
        totalEventsPageCount: action?.response?.totalPageCount,
        eventsListPageNumber: action?.response?.responsePagenumber
      };
    case GET_EVENTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        eventsList: []
      };
    case GET_EVENTS_CORE_DEF:
      return { ...state, eventFieldsLoading: true };
    case GET_EVENTS_CORE_DEF_SUCCESS:
      return {
        ...state,
        eventFieldsLoading: false,
      };
    case GET_EVENTS_CORE_DEF_ERROR:
      return {
        ...state,
        eventFieldsLoading: false,
      };
    case GET_FILTERED_EVENTS_LIST:
      return { ...state, loading: true };
    case GET_FILTERED_EVENTS_LIST_SUCCESS: 
      return {
        ...state,
        loading: false,
        filteredEventsList: action?.response?.eventsListData || [],
        totalFilteredEventsItemCount: action?.response?.totalItemCount,
        filteredEventsPageSize: action?.response?.pageSize,
        totalFilteredEventsPageCount: action?.response?.totalPageCount,
        filteredEventsListPageNumber: action?.response?.responsePagenumber
      }
    case GET_FILTERED_EVENTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        filteredEventsList: [],
        totalFilteredEventsItemCount: 0,
        filteredEventsPageSize: 0,
        totalFilteredEventsPageCount: 0,
        filteredEventsListPageNumber: 0,
      }
    case CLEAR_EVENT_LIST_FILTER:
      return {
        ...state,
        filteredEventsList: [],
        totalFilteredEventsItemCount: 0,
        filteredEventsPageSize: 0,
        totalFilteredEventsPageCount: 0,
        filteredEventsListPageNumber: 0,
      }
    case UPDATE_EVENT_NAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventNameTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventClientNameTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventBrandNameTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventManagerNameTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_DATES_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventDatesTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventNotesTemp: action?.response?.eventDetails
      }
    case UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventSearchRadiusTemp: action?.response?.eventDetails
      }
    case UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventStatusTemp: action?.response?.eventDetails
      }
    case GET_CLIENT_SUGGESTIONS: 
      return {
        ...state,
        suggestionsUpdated: false
      }
    case GET_CLIENT_SUGGESTIONS_SUCCESS: 
      return {
        ...state,
        clientSuggestions: action?.response?.clientSuggestions,
        clientSuggestionsPageNumber: action?.response?.pageNumber,
        hasMoreClientSuggestions: action?.response?.hasMoreSuggestions,
        suggestionsUpdated: true
      }
    case UPDATE_CLIENT_SUGGESTIONS_SUCCESS: 
      return {
        ...state,
        clientSuggestions: action?.response?.clientSuggestions,
        suggestionsUpdated: true
      }
    case GET_VENUE_SUGGESTIONS_SUCCESS: 
      return {
        ...state,
        venueSuggestions: action?.response?.suggestions,
        venueSuggestionsPageNumber: action?.response?.pageNumber,
        hasMoreVenueSuggestions: action?.response?.hasMoreSuggestions,
        suggestionsUpdated: true
      }
    case CREATE_VENUE_SUCCESS:
      return {
        ...state,
        eventLocationTemp: action?.response?.eventDetails
      }
    case CREATE_ONSITE_CONTACT_SUCCESS: 
      return {
        ...state,
        onSiteContact: action?.response?.contact
      }
    case SET_FILES_TO_UPLOAD:
      return {
        ...state,
        filesToUpload: action?.payload
      }
    case ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachedDocuments: action?.response?.attachments
      }
    case ALTERNATE_CONTACT_TEMP_SUCCESS: 
      return {
        ...state,
        alternateContactTemp: [...state.alternateContactTemp, action?.response?.altContact]
      }
    case DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS: 
      return {
        ...state,
        alternateContactTemp: action?.response?.altContact,
        altIds: action?.response?.altIdList
      }
    case PRIMARY_CONTACT_SUCCESS: 
      return {
        ...state,
        eventPrimaryContactTemp: action?.response?.contact
      }
    case SET_NEW_EVENT_ID:
      return {
        ...state,
        newEventId: action?.response?.id
      }
    case GET_EVENT:
      return {
        ...state,
        eventDataLoading: true
      }
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        eventDataLoading: false
      }
    case UPDATE_EVENT_ID_SUCCESS:
      return {
        ...state,
        eventShortId: action?.response?.id
      }
    case RESET_LOCATION_TEMP:
      return {
        ...state,
        eventLocationTemp: _.cloneDeep(eventLocationTemplate)
      }
    case CLEAR_EVENT_DETAILS_FORM:
      return {
        ...state,
        eventNameTemp: _.cloneDeep(eventNameTemplate),
        eventClientNameTemp: _.cloneDeep(eventClientNameTemplate),
        eventBrandNameTemp: _.cloneDeep(eventBrandNameTemplate),
        eventManagerNameTemp: _.cloneDeep(eventAccountManagerTemplate),
        eventDatesTemp: _.cloneDeep(eventDatesTemplate),
        eventLocationTemp: _.cloneDeep(eventLocationTemplate),
        eventSearchRadiusTemp: _.cloneDeep(eventSearchRadius),
        eventPrimaryContactTemp: _.cloneDeep(eventPrimaryContactTemplate),
        eventAttachmentTemp: _.cloneDeep(eventAttachmentsTemplate),
        eventNotesTemp: _.cloneDeep(eventNotesTemplate),
        eventStatusTemp: _.cloneDeep(eventStatusTemplate),
        filesToUpload:[],
        attachedDocuments: [],
        alternateContactTemp: [],
        onSiteContact: [],
        eventShortId: '',
        altIdList: []
      }
    case GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        eventDateRangeDropdown: action?.response?.dropdownResponse
      }
    case GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        eventSortOrderDropdown: action?.response?.dropdownResponse
      }
    case UPDATE_EVENT_FILTERS:
      return {
        ...state,
        filterObj: action?.response?.filterObj
      }
    case SET_EVENT_SEARCH_TEXT:
      return {
        ...state,
        eventSearchText: action?.response?.value
      }
    default:
      return { ...state };
  }
}

export default eventReducer;
