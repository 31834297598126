import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TalentForm from "./talentForm";
import { clearUpdatedFormDetails, getAttributes, getEventRoles, getPhoneUUID, getTalentCoreAttributes } from "../../../../store/actions/talents";
import Spinner from "../../../Spinner";

const TalentRegister = (props) => {
  const {
    triggerAttributes,
    triggerCoreAttributes,
    triggerPhoneAttributes,
    triggerEventRoles,
    isPersonalTemplateUpdated,
    isExperienceTemplateUpdated,
    isContactTemplateUpdated,
    isEmergencyContactTemplateUpdated,
    isSkillsTemplateUpdated,
    triggerClearFormTemplate
  } = props;

  const [showForm, setShowForm] = useState(false)

  const isRegister = true;

  useEffect(() => {
    triggerClearFormTemplate();
    const payload = {
      isRegister,
      onSuccess: () => {},
      onError: () => {}
    }
    triggerAttributes(payload);
    triggerCoreAttributes(payload);
    triggerPhoneAttributes(payload);
    triggerEventRoles(payload);
  }, []);

  useEffect(() => {
    setShowForm(false);
    if (isPersonalTemplateUpdated && isExperienceTemplateUpdated && isContactTemplateUpdated && isEmergencyContactTemplateUpdated && isSkillsTemplateUpdated) {
      setShowForm(true);
    }
  }, [isPersonalTemplateUpdated, isExperienceTemplateUpdated, isContactTemplateUpdated, isEmergencyContactTemplateUpdated, isSkillsTemplateUpdated])
  

  return (
    <>
      {!showForm && (
          <>
            <div className="bg-black opacity-30 z-10 absolute top-0 left-0 w-full h-full" />
            <div className="m-auto absolute top-0 left-0 w-full h-full">
              <Spinner color={"#3B44D4"} loading={showForm} size={45} styles="m-auto top-2/4 left-2/4 relative" />
            </div>
          </>
      )}
      <TalentForm isTalentRegister />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isPersonalTemplateUpdated: state.talent.isPersonalTemplateUpdated,
    isExperienceTemplateUpdated: state.talent.isExperienceTemplateUpdated,
    isContactTemplateUpdated: state.talent.isContactTemplateUpdated,
    isEmergencyContactTemplateUpdated: state.talent.isEmergencyContactTemplateUpdated,
    isSkillsTemplateUpdated: state.talent.isSkillsTemplateUpdated
  }
}

const mapDispatchToProps = {
  triggerAttributes: getAttributes,
  triggerPhoneAttributes: getPhoneUUID,
  triggerCoreAttributes: getTalentCoreAttributes,
  triggerEventRoles: getEventRoles,
  triggerClearFormTemplate: clearUpdatedFormDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentRegister);
