import React, { useState } from "react";
import { connect } from "react-redux";
import AddShift from "./AddShift";
import ShiftList from "./shiftList";
import { useParams } from "react-router-dom";
import { getEventShifts } from "../../../../store/actions/shifts";

const Shifts = (props) => {
  const {
    shiftList,
    triggerGetEventShifts,
  } = props;
  const [showAddShift, setShowAddShift] = useState(false);
  const { id } = useParams();
  let isAutoGeneratedAndUntouched = false;
  let UUID = ''
  if (shiftList && shiftList.length) {
    const firstShift = shiftList[0]
    isAutoGeneratedAndUntouched = firstShift?.isAutoGeneratedAndUntouched
    UUID = firstShift?.UUID
  }

 const getUpdatedShiftList = () => {
  const payload = {
    id,
    onSuccess: (response) => {},
    onError: (error) => {}
  };
  triggerGetEventShifts(payload);
 }

  if (showAddShift || (shiftList.length === 1 && isAutoGeneratedAndUntouched) || (!shiftList.length)) {
    return (
      <AddShift setShowAddShift={setShowAddShift} isEditShift={false} shiftId={UUID} isAutoGeneratedAndUntouched={isAutoGeneratedAndUntouched} getUpdatedShiftList={getUpdatedShiftList} isFirstShift={true} />
    )
  }

  return (
    <ShiftList shiftList={shiftList} setShowAddShift={setShowAddShift} getUpdatedShiftList={getUpdatedShiftList} eventUUID={id} />
  )
}

const mapStateToProps = (state) => {
  return {
    timezones: state.shifts.timezones,
    shiftNameTemp: state.shifts.shiftNameTemp,
    shiftDateTimeTemp: state.shifts.shiftDateTimeTemp,
    shiftLocationTemp: state.shifts.shiftLocationTemp,
    shiftList: state.shifts.shiftList,
  };
};

const mapDispatchToProps = {
  triggerGetEventShifts: getEventShifts
  // triggerFetchTimezones: getTimezones
};

export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
