import React from 'react';
import { eventBtnText, eventStrings } from '../../../../constants/events';
import CloseIcon from "../../talents/library/assets/svg/close.svg";

const UpdateVenue = (props) => {
  const { setShowVenueUpdatePopup, saveVenue, cancelVenue } = props;

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-[714px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-medium">
                {eventStrings.eventVenueUpdate}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={() => { cancelVenue(); setShowVenueUpdatePopup(false); }} />
            </div>
            <div className="relative px-6 flex-auto">
              <p>All the related shift location will be updated. Do you wish to proceed?</p>
            </div>
            <div className="flex items-center justify-end pb-6 pt-6 pr-6 rounded-b">
              <button
                className="text-fontGray-500 bg-transparent font-medium px-4 py-2 text-sm outline-none rounded-lg focus:outline-none mr-2 border border-cadetGray/50"
                type="button"
                onClick={() => { cancelVenue(); setShowVenueUpdatePopup(false); }}
              >
                {eventBtnText.dismiss}
              </button>
              <button
                onClick={() => { saveVenue(); setShowVenueUpdatePopup(false) }}
                type="button"
                className="text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium text-sm px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer"
              >
                {eventBtnText.proceed}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default UpdateVenue;
