import React, { useState, useEffect } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { DatePicker, ConfigProvider } from "antd";
import updateLocale from "dayjs/plugin/updateLocale";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import dayjs from "dayjs";
import "../styles/dateTimeRangeField.css";
import calendarIcon from "../../../assets/svg/calendarIcon.svg";
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";

const { RangePicker } = DatePicker;
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat)
dayjs.updateLocale("en", {
  weekStart: 1, // first day as monday
});

const DateTimeRangeField = ({
  name,
  id,
  onChange,
  defaultValue,
  placeholder,
  showEditIcon = false,
  required,
  eventDates,
  saveEdit,
  editSaveCustomStyle,
  dateStart,
  dateEnd
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [formattedStart, setFormattedStart] = useState(null);
  const [formattedEnd, setFormattedEnd] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (dateStart && dateEnd) {
      setSelectedDate([ dayjs(dateStart), dayjs(dateEnd) ])
    }
  }, [dateStart, dateEnd])
  

  const onDateChange = (start, end, formattedStart, formattedEnd, startDate, endDate) => {
    setTimeout(() => {
      setIsCalendarOpen(false);
    }, 200);
    if (!isEditable) {
      onChange(id, { start: formattedStart, end: formattedEnd }, start, end, name);
      setSelectedDate([
        dayjs(startDate, "YYYY-MM-DD hh:mm A"),
        dayjs(endDate, "YYYY-MM-DD hh:mm A")
      ])
    } else {
      console.log('in here', start, end, formattedStart, formattedEnd)
      setFormattedStart(formattedStart)
      setFormattedEnd(formattedEnd)
      setStart(start)
      setEnd(end)
      setIsUpdated(true);
      setSelectedDate([
        dayjs(startDate, "YYYY-MM-DD hh:mm A"),
        dayjs(endDate, "YYYY-MM-DD hh:mm A"),
      ])
    }
  };

  return (
    <div
      className={`flex ${
        showEditIcon ? "justify-between" : "justify-start"
      } mt-0.5 cursor-pointer`}
    >
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              cellActiveWithRangeBg: "#3b44d41a",
              cellHoverWithRangeBg: "#3b44d41a",
              cellHoverBg: "#3b44d41a",
              colorPrimary: "#2400ffcc",
            },
          },
        }}
      >
        <div
          className="relative w-full"
          onClick={() => { setIsCalendarOpen(true); showEditIcon && setIsEditable(true); }}
        >
          <RangePicker
            placeholder={["Shift start date time", "Shift end date time"]}
            superPrevIcon={<></>}
            superNextIcon={<></>}
            suffixIcon={<></>}
            style={{ width: 'auto' }}
            format="MM-DD-YYYY hh:mm A"
            showTime={{
              format: 'hh:mm A',
              use12Hours:true
            }}
            className="event-datepicker font-inter"
            open={isCalendarOpen}
            status="error"
            onChange={(dates, datesStrings) => {
              console.log('in dstes', dates)
              onDateChange(
                dates[0].format("YYYY-MM-DDTHH:mm:ss"),
                dates[1].format("YYYY-MM-DDTHH:mm:ss"),
                dates[0].format("YYYY-MM-DD hh:mm A"),
                dates[1].format("YYYY-MM-DD hh:mm A"),
                dates[0],
                dates[1]
              );
            }}
            onOpenChange={(openState) => {
              if(!showEditIcon) {
                setTimeout(() => {
                  setIsCalendarOpen(openState);
                }, 200);
              }
            }}
            inputReadOnly={true}
            allowClear={false}
            value={selectedDate}
            defaultValue={defaultDate}
            required={required}
          />
        </div>
        {!isEditable && <div className="flex">
          <div className={`-mt-[12px] calendar-icon min-w-[22px] ${!isEditable ? 'visible' : 'invisible'}`}>
            <img
              src={calendarIcon}
              alt="calendar-icon"
              onClick={() => !showEditIcon && setIsCalendarOpen(true)}
              className="w-6 h-6"
            />
          </div>
        </div>}
        {isEditable && (
          <div className={`flex flex-row gap-2 mr-2 -mt-[12px] ${isEditable ? 'visible' : 'invisible'} ${isUpdated ? 'pointer-events-auto' : 'pointer-events-none'} ${editSaveCustomStyle}`}>
            <img
              src={saveIcon}
              alt="save edit"
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                saveEdit(start, end, formattedStart, formattedEnd);
                setIsEditable(false);
              }}
            />
            <img
              src={clearIcon}
              alt="clear edit"
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setIsEditable(false);
                setSelectedDate([
                  defaultValue?.startDate
                    ? dayjs(defaultValue?.startDate, "YYYY-MM-DD hh:mm A")
                    : "",
                  defaultValue?.endDate
                    ? dayjs(defaultValue?.endDate, "YYYY-MM-DD hh:mm A")
                    : "",
                ])
              }}
            />
          </div>
        )}
      </ConfigProvider>
    </div>
  );
};

export default DateTimeRangeField;
