import React from 'react';
import pageNotFound from '../../../assets/images/404.jpg';

const PageNotFound = () => {
  return (
    <>
      <section className="container mx-auto text-center my-16 px-10">
        <div className="sm:max-w-lg mx-auto">
          <img src={pageNotFound} alt="" className="mx-auto mb-10 rounded-20 border border-gray w-11/12" />
          <p className="font-medium mb-4">Sorry, the page you are looking for does not exist.</p>
          <a href="/" className="mx-2 text-primary hover:text-secondary font-bold underline">Go to Main Page</a>
        </div>
      </section>
    </>
  )
}

export default PageNotFound
