import actionTypes from "../types";

const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} = actionTypes.auth;

export const logIn = (payload) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    response,
  };
};

export const loginError = (error) => {
  return {
    type: LOGIN_ERROR,
    error,
  };
};

export const logOut = () => {
  return {
    type: LOGOUT
  };
};

export const logOutSuccess = (response) => {
  return {
    type: LOGOUT_SUCCESS,
    response,
  };
};

export const logOutError = (error) => {
  return {
    type: LOGOUT_ERROR,
    error,
  };
};
