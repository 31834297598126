import { Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const FilterMultiSelectbox = (props) => {
  return (
    <div className="p-[12px] multiSelect">
        <h4>{props.title}</h4>
          <div className="border border-[#D9D9D9] rounded py-1 px-2 filter-select">
            <div className="text-[11px]">{props.label}</div>

            <Select
            className="w-[250px]"
            placeholder={props.placeholder || ""}
            onChange={(value) => props.onChange(value)}
            options={props.list}
            value={props.selectedValue}
            mode="tags"
            maxTagCount="responsive"
          />
          </div>
          <div className="text-right">
            <Button
              disabled={props?.selectedValue?.length < 1}
              className="mt-[10px]"
              onClick={() => props.onAddFilter(props.type)}
              icon={<PlusOutlined className="align-baseline" />}
            >
              {props.btnContent || "Add Filter"}
            </Button>
          </div>
        </div>
  );
};
export default FilterMultiSelectbox;
