import actionTypes from "../types";

const {
  GET_EVENTS_LIST, GET_EVENTS_LIST_SUCCESS, GET_EVENTS_LIST_ERROR,
  GET_EVENTS_CORE_DEF, GET_EVENTS_CORE_DEF_SUCCESS, GET_EVENTS_CORE_DEF_ERROR,
  SET_EVENT_NAME_TEMPLATE,
  UPDATE_EVENT_NAME_TEMPLATE, UPDATE_EVENT_NAME_TEMPLATE_SUCCESS,
  SET_EVENT_CLIENT_NAME_TEMPLATE,
  UPDATE_EVENT_CLIENT_NAME_TEMPLATE, UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS,
  SET_EVENT_BRAND_NAME_TEMPLATE,
  UPDATE_EVENT_BRAND_NAME_TEMPLATE, UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS,
  SET_EVENT_MANAGER_NAME_TEMPLATE,
  UPDATE_EVENT_MANAGER_NAME_TEMPLATE, UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS,
  SET_EVENT_DATES_TEMPLATE, UPDATE_EVENT_DATES_TEMPLATE, UPDATE_EVENT_DATES_TEMPLATE_SUCCESS,
  GET_FILTERED_EVENTS_LIST, GET_FILTERED_EVENTS_LIST_SUCCESS, GET_FILTERED_EVENTS_LIST_ERROR, CLEAR_EVENT_LIST_FILTER,
  GET_CLIENT_SUGGESTIONS, GET_CLIENT_SUGGESTIONS_SUCCESS,
  UPDATE_CLIENT_SUGGESTIONS,UPDATE_CLIENT_SUGGESTIONS_SUCCESS,
  CREATE_ORGANIZATION, UPDATE_SEARCH_RADIUS_TEMPLATE, UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS,
  CREATE_VENUE, CREATE_VENUE_SUCCESS, CREATE_VENUE_ERROR,
  GET_VENUE_SUGGESTIONS, GET_VENUE_SUGGESTIONS_SUCCESS,
  UPDATE_VENUE_SUGGESTIONS, UPDATE_VENUE_SUGGESTIONS_SUCCESS,
  CREATE_ONSITE_CONTACT, CREATE_ONSITE_CONTACT_SUCCESS,
  EDIT_ONSITE_CONTACT, EDIT_ONSITE_CONTACT_SUCCESS, SET_VENUE, SET_VENUE_SUCCESS,
  UPDATE_EVENT_VENUE_TEMPLATE, UPDATE_EVENT_VENUE_TEMPLATE_SUCCESS, CHECK_API_STATUS,
  SET_FILES_TO_UPLOAD, ADD_ATTACHMENT, ADD_ATTACHMENT_SUCCESS, ALTERNATE_CONTACT_TEMP, ALTERNATE_CONTACT_TEMP_SUCCESS,
  DELETE_ALTERNATE_CONTACT_TEMP, DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS, PRIMARY_CONTACT, PRIMARY_CONTACT_SUCCESS,
  UPDATE_EVENT_PERSON_RECORD, SET_NEW_EVENT_ID, CREATE_EVENT, CREATE_EVENT_SUCCESS, GET_EVENT,
  UPDATE_EVENT_NOTES_TEMPLATE, UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS, CLEAR_EVENT_DETAILS_FORM,
  DELETE_ASSET, ADD_NEW_ASSET, EVENT_STATE_RESET, GET_EVENT_SUCCESS,
  DELETE_EVENT, DELETE_EVENT_SUCCESS, EDIT_PHONE_ATTRIBUTE, EDIT_PHONE_ATTRIBUTE_SUCCESS,
  UPDATE_EVENT_CORE_ATTR, UPDATE_EVENT_CORE_ATTR_SUCCESS, CREATE_EVENT_PERSON_RECORD, UPDATE_EVENT_ID_SUCCESS, DELETE_EVENT_PERSON_RECORD,
  RESET_LOCATION_TEMP, UPDATE_EVENT_SHIFT_VENUE, UPDATE_EVENT_SHIFT_VENUE_SUCCESS,
  UPDATE_EVENT_STATUS_TEMPLATE, UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS, GET_EVENT_STATUS_OPTIONS,
  GET_EVENT_DATE_DROPDOWN_OPTIONS, GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS,
  GET_EVENT_SORT_DROPDOWN_OPTIONS, GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS,
  GET_DEFAULT_EVENT_FILTERS, UPDATE_EVENT_FILTERS, SET_EVENT_SEARCH_TEXT
} = actionTypes.events;

export const getEventsList = (payload) => {
  return {
    type: GET_EVENTS_LIST,
    payload
  };
};

export const getEventsListSuccess = (response) => {
  return {
    type: GET_EVENTS_LIST_SUCCESS,
    response
  };
};

export const getEventsListError = (error) => {
  return {
    type: GET_EVENTS_LIST_ERROR,
    error
  };
};

export const getEventsCoreDef = (error) => {
  return {
    type: GET_EVENTS_CORE_DEF,
    error
  };
};

export const getEventsCoreDefSuccess = (response) => {
  return {
    type: GET_EVENTS_CORE_DEF_SUCCESS,
    response
  };
};

export const getEventsCoreDefError = (error) => {
  return {
    type: GET_EVENTS_CORE_DEF_ERROR,
    error
  };
};

export const setEventNameTemplate = (response) => {
  return {
    type: SET_EVENT_NAME_TEMPLATE,
    response
  };
};

export const updateEventNameTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_NAME_TEMPLATE,
    payload
  };
};

export const updateEventNameTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_NAME_TEMPLATE_SUCCESS,
    response
  };
};

export const updateEventNotesTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_NOTES_TEMPLATE,
    payload
  };
};

export const uploadEventNotesTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS,
    response
  };
};

export const setEventClientNameTemplate = (response) => {
  return {
    type: SET_EVENT_CLIENT_NAME_TEMPLATE,
    response
  };
};

export const updateEventClientNameTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_CLIENT_NAME_TEMPLATE,
    payload
  };
};

export const updateEventClientNameTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS,
    response
  };
};

export const setEventBrandNameTemplate = (response) => {
  return {
    type: SET_EVENT_BRAND_NAME_TEMPLATE,
    response
  };
};

export const updateEventBrandNameTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_BRAND_NAME_TEMPLATE,
    payload
  };
};

export const updateEventBrandNameTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS,
    response
  };
};

export const setEventManagerNameTemplate = (response) => {
  return {
    type: SET_EVENT_MANAGER_NAME_TEMPLATE,
    response
  };
};

export const updateEventManagerNameTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_MANAGER_NAME_TEMPLATE,
    payload
  };
};

export const updateEventManagerNameTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS,
    response
  };
};

export const setEventDatesTemplate = (response) => {
  return {
    type: SET_EVENT_DATES_TEMPLATE,
    response
  };
};

export const updateEventDatesTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_DATES_TEMPLATE,
    payload
  };
};

export const updateEventDatesTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_DATES_TEMPLATE_SUCCESS,
    response
  };
};

export const getFilteredEventsList = (payload) => {
  return {
    type: GET_FILTERED_EVENTS_LIST,
    payload
  }
}

export const getFilteredEventsListSuccess = (response) => {
  return {
    type: GET_FILTERED_EVENTS_LIST_SUCCESS,
    response
  }
}

export const getFilteredEventsListError = (error) => {
  return {
    type: GET_FILTERED_EVENTS_LIST_ERROR,
    error
  }
}

export const clearEventListFilter = () => {
  return {
    type: CLEAR_EVENT_LIST_FILTER
  }
}

export const getClientSuggestions = (payload) => {
  return {
    type: GET_CLIENT_SUGGESTIONS,
    payload
  };
};

export const getClientSuggestionsSuccess = (response) => {
  return {
    type: GET_CLIENT_SUGGESTIONS_SUCCESS,
    response
  };
};

export const updateClientSuggestions = (payload) => {
  return {
    type: UPDATE_CLIENT_SUGGESTIONS,
    payload
  };
};

export const updateClientSuggestionsSuccess = (response) => {
  return {
    type: UPDATE_CLIENT_SUGGESTIONS_SUCCESS,
    response
  };
};

export const createVenue = (payload) => {
  return {
    type: CREATE_VENUE,
    payload
  };
};

export const createVenueSuccess = (response) => {
  return {
    type: CREATE_VENUE_SUCCESS,
    response
  };
};

export const createVenueError = (error) => {
  return {
    type: CREATE_VENUE_ERROR,
    error
  };
};

export const getVenueSuggestions = (payload) => {
  return {
    type: GET_VENUE_SUGGESTIONS,
    payload
  };
};

export const getVenueSuggestionsSuccess = (response) => {
  return {
    type: GET_VENUE_SUGGESTIONS_SUCCESS,
    response
  };
};

export const updateVenueSuggestions = (payload) => {
  return {
    type: UPDATE_VENUE_SUGGESTIONS,
    payload
  };
};

export const updateVenueSuggestionsSuccess = (response) => {
  return {
    type: UPDATE_VENUE_SUGGESTIONS_SUCCESS,
    response
  };
};

export const createOnsiteContact = (payload) => {
  return {
    type: CREATE_ONSITE_CONTACT,
    payload
  };
};

export const createOnsiteContactSuccess = (response) => {
  return {
    type: CREATE_ONSITE_CONTACT_SUCCESS,
    response
  };
};

export const editOnsiteContact = (payload) => {
  return {
    type: EDIT_ONSITE_CONTACT,
    payload
  };
};

export const editOnsiteContactSuccess = (response) => {
  return {
    type: EDIT_ONSITE_CONTACT_SUCCESS,
    response
  };
};

export const updateEventVenueTemplate = (payload) => {
  return {
    type: UPDATE_EVENT_VENUE_TEMPLATE,
    payload
  };
};

export const updateEventVenueTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_VENUE_TEMPLATE_SUCCESS,
    response
  };
};

export const setVenue = (payload) => {
  return {
    type: SET_VENUE,
    payload
  };
};

export const setVenueSuccess = (response) => {
  return {
    type: SET_VENUE_SUCCESS,
    response
  };
};

export const checkApiStatus = (payload) => {
  return {
    type: CHECK_API_STATUS,
    payload
  };
};

export const createOrganization = (payload) => {
  return {
    type: CREATE_ORGANIZATION,
    payload
  };
};
export const addFilesToUpload = (payload) => {
  return {
    type: SET_FILES_TO_UPLOAD,
    payload
  }
}

export const addAttachment = (payload) => {
  return {
    type : ADD_ATTACHMENT,
    payload
  }
}

export const addAttachmentSuccess = (response) => {
  return {
    type: ADD_ATTACHMENT_SUCCESS,
    response
  }
}

export const alternateContactTemp = (payload) => {
  return {
    type : ALTERNATE_CONTACT_TEMP,
    payload
  }
}

export const alternateContactTempSuccess = (response) => {
  return {
    type: ALTERNATE_CONTACT_TEMP_SUCCESS,
    response
  }
}

export const deleteAlternateContact = (payload) => {
  return {
    type : DELETE_ALTERNATE_CONTACT_TEMP,
    payload
  }
}

export const deleteAlternateContactSuccess = (response) => {
  return {
    type: DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS,
    response
  }
}

export const primaryContact = (payload) => {
  return {
    type : PRIMARY_CONTACT,
    payload
  }
}

export const updateEventPerson = (payload) => {
  return {
    type: UPDATE_EVENT_PERSON_RECORD,
    payload
  }
}

export const primaryContactSuccess = (response) => {
  return {
    type: PRIMARY_CONTACT_SUCCESS,
    response
  }
}

export const setNewEventId = (response) => {
  return {
    type: SET_NEW_EVENT_ID,
    response
  }
}

export const getEvent = (payload) => {
  return {
    type: GET_EVENT,
    payload
  }
}

export const getEventSuccess = (response) => {
  return {
    type: GET_EVENT_SUCCESS,
    response
  }
}

export const createEvent = (payload) => {
  return {
    type : CREATE_EVENT,
    payload
  }
}

export const createEventSuccess = (response) => {
  return {
    type: CREATE_EVENT_SUCCESS,
    response
  }
}

export const eventStateReset = (response) => {
  return {
    type: EVENT_STATE_RESET,
    response
  }
}

export const deleteEvent = (payload) => {
  return {
    type : DELETE_EVENT,
    payload
  }
}

export const deleteEventSuccess = (response) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    response
  }
}

export const clearEventDetailsForm = () => {
  return {
    type: CLEAR_EVENT_DETAILS_FORM
  }
}

export const deleteEventAsset = (payload) => {
  return {
    type: DELETE_ASSET,
    payload
  }
}

export const addEventAsset = (payload) => {
  return {
    type: ADD_NEW_ASSET,
    payload
  }
}

export const editPhoneAttribute = (payload) => {
  return {
    type : EDIT_PHONE_ATTRIBUTE,
    payload
  }
}

export const editPhoneAttributeSuccess = (response) => {
  return {
    type: EDIT_PHONE_ATTRIBUTE_SUCCESS,
    response
  }
}

export const updateEventCoreAttr = (payload) => {
  return {
    type : UPDATE_EVENT_CORE_ATTR,
    payload
  }
}

export const updateEventCoreAttrSuccess = (response) => {
  return {
    type: UPDATE_EVENT_CORE_ATTR_SUCCESS,
    response
  }
}

export const updateSearchRadiusTemplate = (payload) => {
  return {
    type : UPDATE_SEARCH_RADIUS_TEMPLATE,
    payload
  }
}

export const updateSearchRadiusTemplateSuccess = (response) => {
  return {
    type: UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS,
    response
  }
}

export const createEventPersonRecord = (payload) => {
  return {
    type : CREATE_EVENT_PERSON_RECORD,
    payload
  }
}

export const updateEventIdSuccess = (response) => {
  return {
    type: UPDATE_EVENT_ID_SUCCESS,
    response
  }
}

export const deleteEventPersonRecord = (payload) => {
  return {
    type : DELETE_EVENT_PERSON_RECORD,
    payload
  }
}

export const resetLocationTemp = () => {
  return {
    type: RESET_LOCATION_TEMP
  }
}

export const updateEventShiftVenue = (payload) => {
  return {
    type : UPDATE_EVENT_SHIFT_VENUE,
    payload
  }
}

export const updateEventShiftVenueSuccess = (response) => {
  return {
    type: UPDATE_EVENT_SHIFT_VENUE_SUCCESS,
    response
  }
}

export const updateEventStatusTemplate = (payload) => {
  return {
    type : UPDATE_EVENT_STATUS_TEMPLATE,
    payload
  }
}

export const updateEventStatusTemplateSuccess = (response) => {
  return {
    type: UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS,
    response
  }
}

export const getEventStatusOptions = (payload) => {
  return {
    type : GET_EVENT_STATUS_OPTIONS,
    payload
  }
}

export const getEventDateDropdownOptions = () => {
  return {
    type: GET_EVENT_DATE_DROPDOWN_OPTIONS
  }
}

export const getEventDateDropdownOptionsSuccess = (response) => {
  return {
    type: GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS,
    response
  }
}

export const getEventSortDropdownOptions = () => {
  return {
    type: GET_EVENT_SORT_DROPDOWN_OPTIONS
  }
}

export const getEventSortDropdownOptionsSuccess = (response) => {
  return {
    type: GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS,
    response
  }
}

export const getDefaultEventFilters = () => {
  return {
    type: GET_DEFAULT_EVENT_FILTERS
  }
}

export const updateEventFilters = (response) => {
  return {
    type: UPDATE_EVENT_FILTERS,
    response
  }
}

export const setEventSearchText = (response) => {
  return {
    type: SET_EVENT_SEARCH_TEXT,
    response
  }
}
