import React, { useState } from "react";
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import SelectField from "./selectField";
import InputField from "./inputField";

const SocialMediaField = ({ name, id, onChange, options, optionList, defaultValue, defaultOption, setSelectedOption, placeholder, maxAllowed, minAllowed, required, showEditIcon=false, saveEdit }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [selectedOpt, setSelectedOpt] = useState('');
  const isOptionUpdate = true;
  const onOptionSelect = (id, value, isOptionUpdate) => {
    if (showEditIcon) {
      setSelectedOpt(value)
    }
    onSelect(id, value, isOptionUpdate)
  }

  const onInputChange = (id, value) => {
    if (showEditIcon) {
      setInputVal(value)
    }
    onChange(id, value)
  }

  const onSelect = (id, value, isOptionUpdate) => {
    setSelectedOption(id, value, isOptionUpdate)
  }

  const onSaveEdit = () => {
    if ((defaultOption === selectedOpt) && (defaultValue === inputVal)) {
      setIsEditable(false);
    } else {
      let isValueEdited = false;
      if (defaultOption || defaultValue) {
        isValueEdited = ((defaultOption !== selectedOpt) || (defaultValue !== inputVal));
      }
      if (!inputVal) {
        setSelectedOpt('')
        setIsEditable(false);
        onOptionSelect(id, '', true);
        return
      }
      saveEdit(id, isValueEdited, selectedOpt, inputVal, optionList)
      setIsEditable(false);
    }
  }
  const disableInputField = () => {
    if (showEditIcon && !(defaultOption || selectedOpt)) {
      return true;
    } else if (!showEditIcon && !(defaultOption || selectedOpt)) {
      return true;
    }
    return false;
  }

  return (
    <div className={`flex ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
      <div className='flex flex-row gap-2'>
        <SelectField options={options} name={name} id={id} onChangeOption={onOptionSelect} defaultValue={defaultOption} customStyle="w-fit mt-1.5" isReadOnly={showEditIcon && !isEditable} required={required} />
        <InputField name={name} id={id} onChange={onInputChange} defaultValue={defaultValue} placeholder={placeholder} maxAllowed={maxAllowed} minAllowed={minAllowed} required={required} customStyle='mt-1.5' isReadOnly={disableInputField()} />
      </div>
      {
        showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className="h-4 w-4 cursor-pointer ml-ten -mt-1"
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className="grid -mt-4 ml-2 gap-2"
        >
          <img
            src={saveIcon}
            alt="save edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => {setInputVal(''); setSelectedOpt(''); setIsEditable(false)}}
          />
        </div>
      }
    </div>
  )
}

export default SocialMediaField;
