import React, { useEffect, useState } from "react";
import { eventStrings, eventApiEndPoints, squareRounded } from "../../../../constants/events";
import { useDropzone } from "react-dropzone";
import paperclip from "../../../../assets/svg/paperclip.svg";
import filePreviewBlue from "../../../../assets/svg/filePreviewBlue.svg";
import trash from "../../../../assets/svg/trash.svg";
import download from "../../../../assets/svg/download.svg";
import view from "../../../../assets/svg/view.svg";
import { Button } from "antd";
import "../events.css";
import { error as toastError, success as toastSuccess } from "../../../toast";
import { v4 as uuidv4 } from "uuid";
import localCacheStorage from "../../../../utils/localCacheStorage";

const Attachments = (props) => {
  const {
    attachmentTemplate,
    attachmentsToUpload = () => {},
    eventAttachments,
    isEditEvent = false,
    deleteServerAssets = () => {},
    uploadServerAssets = () => {},
    eventId
  } = props;
  const [uploadedFiles, setUploadedFiles] = useState([]); // for create
  const [userUploadedAttachments, setUserUploadedAttachments] = useState([]); // for edit
  const { baseUrl } = eventApiEndPoints;
  const viewableFormats = ["application/pdf", "image/png", "image/jpeg", "pdf", "png", "jpg"];

  useEffect(() => {
    setUserUploadedAttachments(eventAttachments);
  }, [eventAttachments]);

  const updateAttachmentsToStore = (files) => {
    attachmentsToUpload(files);
  };

  let { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "text/*": [".doc", ".docx", ".pdf"],
    },
    maxSize: 5000000, // 5MB

    // onDrop,
    onDropAccepted: (droppedFiles) => {
      if (droppedFiles.length > 0) {
        // eslint-disable-next-line array-callback-return
        droppedFiles.map((file) => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uuid: uuidv4(), // eventUUID
          });
        });
        updateAttachmentsToStore([
          ...userUploadedAttachments,
          ...droppedFiles,
        ]);
        if (isEditEvent) {
          const payload = {
            assets: droppedFiles,
            eventId,
            onSuccess: () => {}
          }
          uploadServerAssets(payload)
        }
        setUploadedFiles([...uploadedFiles, ...droppedFiles]);
        setUserUploadedAttachments([
          ...userUploadedAttachments,
          ...droppedFiles,
        ]);
      }
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        let rejectionErrors = [];
        rejectedFiles.forEach(({ file, errors }) => {
          errors.forEach((error) => {
            if (error.code === "file-too-large") {
              rejectionErrors.push(`The size of ${file.name} exceeds 5MB.`);
            } else if (error.code === "file-invalid-type") {
              rejectionErrors.push(`The file format of ${file.name} is invalid.`);
            } else {
              rejectionErrors.push(error.message);
            }
          });
        });
        rejectionErrors.forEach((error) => {
          toastError(error);
        });
      }
    },
  });

  const deleteAttachment = (index) => {
    const newFiles = [...userUploadedAttachments];
    if(isEditEvent) {
      const payload = {
        asset: newFiles[index],
        onSuccess: () => {
          newFiles.splice(index, 1);
          toastSuccess("Attachment Deleted");
        }
      }
      deleteServerAssets(payload);
    } else {
      newFiles.splice(index, 1);
      updateAttachmentsToStore(newFiles);
    }
    setUserUploadedAttachments(newFiles);
    setUploadedFiles(newFiles);
  };

  const isViewable = (type) => viewableFormats.includes(type);

  const downloadFile = (url, fileName, action="download", type) => {
    fetch(url, {
      method: "get",
      referrerPolicy: "no-referrer",
      headers: {
        "Authorization": `Bearer ${localCacheStorage.get("ACCESS_TOKEN")}`,
        "Content-type": "application/json"
      }
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        if (action === "download" || (action === "view" && !isViewable(type))) {
          aElement.setAttribute("download", fileName);
        }
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <fieldset class="relative flex items-center col-span-6 w-full m-auto ">
        <span class="flex-shrink mr-4 text-blackOlive font-bold">
          {eventStrings.attachmentSubtitle}
        </span>
        <div class="flex-grow border-t border-silver"></div>
      </fieldset>
      <fieldset className="w-full m-auto text-lg col-span-6">
        <div
          {...getRootProps({
            className: "dropzone cursor-pointer",
          })}
        >
          <input {...getInputProps()} />
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs flex items-center justify-between">
            <div>
              <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-[#393636] uppercase" htmlFor={attachmentTemplate?.id}>{attachmentTemplate?.label}</label>{attachmentTemplate?.required && squareRounded()}</div>
              <div className="label mt-1">
                <p className="text-grayNobel font-inter text-sm font-medium normal-case">
                  {attachmentTemplate?.placeholder}
                </p>
              </div>
            </div>
            <img src={paperclip} alt="Paper clip" className="w-6 h-6" />
          </div>
        </div>
      </fieldset>
      {userUploadedAttachments?.length > 0 && (
        <fieldset className="w-full m-auto text-lg col-span-6">
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
            <p className="text-tileGray text-xs font-medium uppercase">
              {eventStrings.attachedDocuments}
            </p>
            {userUploadedAttachments.map((file, index) => {
              return (
                <div
                  className={`download-attachments ${userUploadedAttachments.length > 1 && "border-b border-b-mercury"} pb-1 mt-2`}
                  key={index}
                >
                  <div>
                    <img src={filePreviewBlue} alt="file-preview" />
                  </div>
                  <div>
                    <p className="text-[15px] font-medium normal-case text-black">
                      {file.asset_filename || file.name || file.caption}
                    </p>
                  </div>
                  <div>
                    <Button
                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                      onClick={() =>
                        downloadFile(
                          file.preview
                            ? file.preview
                            : `${baseUrl}${file.value}`,
                          file.preview
                            ? file.name
                            : file.asset_filename || file.caption,
                          "view",
                          file?.type || file?.asset_mimetype
                        )
                      }
                    >
                      <img src={view} alt="view-attachment" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                      onClick={() =>
                        downloadFile(
                          file.preview
                            ? file.preview
                            : `${baseUrl}${file.value}`,
                          file.preview
                            ? file.name
                            : file.asset_filename || file.caption,
                          "download",
                          file?.type || file?.asset_mimetype
                        )
                      }
                    >
                      <img src={download} alt="download-attachment" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="px-2 border-[#98a2b380] rounded-[8px] shadow-attachment"
                      onClick={() => deleteAttachment(index)}
                    >
                      <img src={trash} alt="delete-attachment" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </fieldset>
      )}
    </>
  );
};

export default Attachments;
