import { apiEndpoints } from "../constants";
import { eventApiEndPoints, eventApiParams, date30DaysAgo } from "../constants/events";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";

export const eventCoreDef = async () => {
  const { baseUrl, eventCoreFields } = eventApiEndPoints;
  const eventCoreDefUrl = `${baseUrl}${eventCoreFields}`;
  try {
    const response = getRequest(eventCoreDefUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const eventsList = async (payload = {}) => {
  const { number, size, dateRangeSlug, sortOrderSlug, includePastEvents } = payload;
  const { baseUrl, events } = eventApiEndPoints;
  const { pageNumber, pageSize, listType, condensedType, includePastEventsQP, sortOrderSlugQP, dateRangeSlugQP } = eventApiParams;
  const eventsUrl = `${baseUrl}${events}?${listType}=${condensedType}&${pageNumber}=${number}&${pageSize}=${size}&${includePastEventsQP}=${includePastEvents}&${sortOrderSlugQP}=${sortOrderSlug}&${dateRangeSlugQP}=${dateRangeSlug}`;
  try {
    const response = getRequest(eventsUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const eventsFilter = async (payload = {}) => {
  const { number, size, dateRangeSlug, sortOrderSlug, includePastEvents } = payload;
  const filterPayload = { ...payload };
  delete filterPayload.number;
  delete filterPayload.size;
  delete filterPayload.dateRangeSlug;
  delete filterPayload.sortOrderSlug;
  delete filterPayload.includePastEvents;

  const { baseUrl, filterEvents } = eventApiEndPoints;
  const { pageNumber, pageSize, listType, condensedType, includePastEventsQP, sortOrderSlugQP, dateRangeSlugQP } = eventApiParams;
  const filterEventsUrl = `${baseUrl}${filterEvents}?${listType}=${condensedType}&${pageNumber}=${number}&${pageSize}=${size}&${includePastEventsQP}=${includePastEvents}&${sortOrderSlugQP}=${sortOrderSlug}&${dateRangeSlugQP}=${dateRangeSlug}`;
  try {
    const response = postRequest({
      url: filterEventsUrl,
      payload: filterPayload
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const createEvent = async (payload) => {
  const { baseUrl, event } = eventApiEndPoints;
  const url = `${baseUrl}${event}`
  try {
    const response = postRequest({
      url,
      payload
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getEventSuggestion =  async (payload = {}) => {
  const { number, actualPayload, size } = payload;
  const { baseUrl, filterEvents } = eventApiEndPoints;
  const { pageNumber, pageSize, listType, dropdownType, includePastEventsQP } = eventApiParams;
  const suggestionsURL = `${baseUrl}${filterEvents}?${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}&${includePastEventsQP}=true`;
  try {
    const response = postRequest({
      url: suggestionsURL,
      payload: actualPayload
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getClientSuggestion =  async (payload = {}) => {
  const { number, userInput, size } = payload;
  const { baseUrl, organizationSuggestion } = eventApiEndPoints;
  const { phrase, pageNumber, pageSize, listType, dropdownType } = eventApiParams;
  let suggestionsURL = `${baseUrl}${organizationSuggestion}?${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  if (userInput) {
    suggestionsURL = `${baseUrl}${organizationSuggestion}?${phrase}=${userInput}&${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  }
  try {
    if (!suggestionsURL) return;
    const response = getRequest(suggestionsURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getAccountManagerOptions = async(payload = {}) => {
  const { number, actualPayload, size } = payload;
  const { baseUrl, talentFilter } = apiEndpoints;
  const { pageNumber, pageSize, listType, dropdownType } = eventApiParams;
  const accountManagerURL = `${baseUrl}${talentFilter}?${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  try {
    const response = postRequest({
      url: accountManagerURL,
      payload: actualPayload
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getVenueSuggestion =  async (payload = {}) => {
  const { number, userInput, size } = payload;
  const { baseUrl, venueSuggestion } = eventApiEndPoints;
  const { phrase, pageNumber, pageSize, listType, dropdownType } = eventApiParams;
  const suggestionsURL = `${baseUrl}${venueSuggestion}?${userInput && phrase}${userInput && '='}${userInput && userInput}&${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  try {
    const response = getRequest(suggestionsURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getPrimaryContactSuggestion =  async (payload = {}) => {
  const { number, actualPayload, size } = payload;
  const { baseUrl, talentFilter } = apiEndpoints;
  const { pageNumber, pageSize, listType, dropdownType } = eventApiParams;
  const primaryContactURL = `${baseUrl}${talentFilter}?${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  try {
    const response = postRequest({
      url: primaryContactURL,
      payload: actualPayload
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getAlternateContactSuggestion =  async (payload = {}) => {
  const { number, userInput='', size } = payload;
  const { baseUrl, personSuggestion } = eventApiEndPoints;
  const { searchField, pageNumber, pageSize, listType, dropdownType } = eventApiParams;
  const suggestionsURL = `${baseUrl}${personSuggestion}?${listType}=${dropdownType}&${pageNumber}=${number}&${pageSize}=${size}`;
  const suggestionsPayload = {};
  suggestionsPayload[searchField] = userInput;
  try {
    const response = postRequest({ url: suggestionsURL, payload: suggestionsPayload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createVenue =  async (payload = {}) => {
  const { baseUrl, venue } = eventApiEndPoints;
  const venueURL = `${baseUrl}${venue}`;
  try {
    const response = postRequest({ url: venueURL, payload: payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createOrganization =  async (payload = {}) => {
  const { baseUrl, organization } = eventApiEndPoints;
  const organizationURL = `${baseUrl}${organization}`;
  try {
    const response = postRequest({ url: organizationURL, payload: payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const uploadAsset = async (payload) => {
  const { companyUUID, eventUUID, asset } = payload;
  const { baseUrl, assetService, addEvent, secure } = eventApiEndPoints;
  const assetUrl = `${baseUrl}${assetService}${secure}/${companyUUID}${addEvent}/${eventUUID}`;
  let form = new FormData();
  form.append('asset_file', asset);
  try {
    const response = postRequest({ url: assetUrl, payload: form });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEventPerson = async (payload) => {
  const { baseUrl, personRecord } = eventApiEndPoints;
  const updateUrl = `${baseUrl}${personRecord}`;
  try {
    const response = putRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const createEventPerson = async (payload) => {
  const { baseUrl, personRecord } = eventApiEndPoints;
  const updateUrl = `${baseUrl}${personRecord}`;
  try {
    const response = postRequest({ url: updateUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}


export const getEventData = async (id) => {
  const { baseUrl, event } = eventApiEndPoints;
  const eventUrl = `${baseUrl}${event}/${id}`;
  try {
    const response = getRequest(eventUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteAsset = async (payload) => {
  const { baseUrl, assetService } = eventApiEndPoints;
  const { company_uuid, asset_uuid } = payload;
  const url = `${baseUrl}${assetService}/${company_uuid}/${asset_uuid}`;
  try {
    const response = deleteRequest(url);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateAttribute = async (payload) => {
    const { baseUrl, event, attribute } = eventApiEndPoints;
    const url = `${baseUrl}${event}${attribute}`
    try {
      const response = postRequest({ url, payload });
      return response;
    } catch (error) {
      return error;
    }
}

export const deleteAttribute = async (id) => {
  const { baseUrl, event, attribute } = eventApiEndPoints;
  const url = `${baseUrl}${event}${attribute}/${id}`;
  try {
    const response = deleteRequest(url);
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteEvent = async (id) => {
  const { baseUrl, event } = eventApiEndPoints;
  const eventUrl = `${baseUrl}${event}/${id}`;
  try {
    const response = deleteRequest(eventUrl);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEvent = async (payload) => {
  const { baseUrl, event } = eventApiEndPoints;
  const updateEventUrl = `${baseUrl}${event}`;
  try {
    const response = putRequest({ url: updateEventUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteEventPerson = async (id) => {
  const { baseUrl, personRecord } = eventApiEndPoints;
  const url = `${baseUrl}${personRecord}/${id}`;
  try {
    const response = deleteRequest(url);
    return response;
  } catch (error) {
    return error;
  }
}

export const getAllEventShifts = async (id) => {
  const { baseUrl, event, shifts } = eventApiEndPoints;
  const { listType, dropdownObj } = eventApiParams;
  const url = `${baseUrl}${event}/${id}${shifts}?${listType}=${dropdownObj}`;
  try {
    const response = getRequest(url);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateShiftVenue = async (payload) => {
  const { baseUrl, eventShifts } = eventApiEndPoints;
  const updateEventShiftUrl = `${baseUrl}${eventShifts}`;
  try {
    const response = putRequest({ url: updateEventShiftUrl, payload });
    return response;
  } catch (error) {
    return error;
  }
}

export const eventStatuses = () => {
  const { baseUrl, eventStatuses } = eventApiEndPoints;
  const eventStatusURL = `${baseUrl}${eventStatuses}`;
  try {
    const response = getRequest(eventStatusURL);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEventSearchDefaults = () => {
  const { baseUrl, event, search, defaults } = eventApiEndPoints;
  const searchURL = `${baseUrl}${event}${search}${defaults}`;
  try {
    const response = getRequest(searchURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getEventSortOrderList = () => {
  const { baseUrl, event, search, sortOrder, list } = eventApiEndPoints;
  const listURL = `${baseUrl}${event}${search}${sortOrder}${list}`;
  try {
    const response = getRequest(listURL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getEventDateRangeList = () => {
  const { baseUrl, event, search, dateRange, list } = eventApiEndPoints;
  const listURL = `${baseUrl}${event}${search}${dateRange}${list}`;
  try {
    const response = getRequest(listURL);
    return response;
  } catch (error) {
    return error;
  }
}
