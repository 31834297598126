import { Button, Input, Radio, Select, Space, Divider } from "antd";
import NumericInput from "../numericInput";
import { PlusOutlined } from "@ant-design/icons";

const FilterLayout = (props) => {
  return (
    <div className="px-[8px] py-[12px] location">
      <div>Select either Zip or City and State</div>
      <div className="border border-solid border-slate-200 mt-[5px]">
        <div className=" p-[10px]">
          <h4 className="text-[11px] pb-[5px]">ZIP</h4>
          <NumericInput
            style={{
              width: 120,
              border: 0,
            }}
            maxLength={5}
            value={props.zipValue}
            onChange={props.onZipChange}
            placeholder="Enter zip"
          />
        </div>
        <div className="border-t border-slate-200">
          <div className=" p-[10px]">
            <h4 className="text-[11px] pb-[5px]">RADIUS (MILES)</h4>
            <Radio.Group
              onChange={props.onRadiusChange}
              value={props.radiusValue}
            >
              <Space direction="vertical">
                {props?.radiusList.map((val, i) => (
                  <Radio key={i} value={val.value}>
                    {val.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </div>
      </div>
      <Divider className="!text-xs">OR</Divider>
      <div className="border border-solid border-slate-200 mt-[10px] mb-[10px]">
        <div className="p-[10px] pb-[5px]">
          <h4 className="text-[11px] pb-[5px]">STATE</h4>
          <Select
            onChange={props.onStateChange}
            className="w-[180px]"
            placeholder="Select state"
            value={props.selectedState}
          >
            {props?.talentData?.provinces.map((val, i) => (
              <option value={val.uuid} key={i}>
                {val.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="border-t border-slate-200">
          <div className=" p-[10px]">
            <h4 className="text-[11px] pb-[5px]">CITY</h4>
            <Input
              style={{
                width: 120,
                border: 0,
              }}
              value={props.cityValue}
              onChange={props.onCityChange}
              placeholder="Enter city"
            />
          </div>
        </div>
      </div>
      <div className="text-right">
        <Button
          className=""
          disabled={
            (props.zipValue && props.radiusValue) ||
            (props.selectedState)
              ? false
              : true
          }
          onClick={() => props.onAddFilter("location")}
          icon={<PlusOutlined className="align-baseline" />}
        >
          Add Filter
        </Button>
      </div>
    </div>
  );
};
export default FilterLayout;
