const eventActions = {
  GET_EVENTS_LIST: "GET_EVENTS_LIST",
  GET_EVENTS_LIST_SUCCESS: "GET_EVENTS_LIST_SUCCESS",
  GET_EVENTS_LIST_ERROR: "GET_EVENTS_LIST_ERROR",
  GET_EVENTS_CORE_DEF: "GET_EVENTS_CORE_DEF",
  GET_EVENTS_CORE_DEF_SUCCESS: "GET_EVENTS_CORE_DEF_SUCCESS",
  GET_EVENTS_CORE_DEF_ERROR: "GET_EVENTS_CORE_DEF_ERROR",
  SET_EVENT_NAME_TEMPLATE: "SET_EVENT_NAME_TEMPLATE",
  UPDATE_EVENT_NAME_TEMPLATE: "UPDATE_EVENT_NAME_TEMPLATE",
  UPDATE_EVENT_NAME_TEMPLATE_SUCCESS: "UPDATE_EVENT_NAME_TEMPLATE_SUCCESS",
  SET_EVENT_CLIENT_NAME_TEMPLATE: "SET_EVENT_CLIENT_NAME_TEMPLATE",
  UPDATE_EVENT_CLIENT_NAME_TEMPLATE: "UPDATE_EVENT_CLIENT_NAME_TEMPLATE",
  UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS: "UPDATE_EVENT_CLIENT_NAME_TEMPLATE_SUCCESS",
  SET_EVENT_BRAND_NAME_TEMPLATE: "SET_EVENT_BRAND_NAME_TEMPLATE",
  UPDATE_EVENT_BRAND_NAME_TEMPLATE: "UPDATE_EVENT_BRAND_NAME_TEMPLATE",
  UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS: "UPDATE_EVENT_BRAND_NAME_TEMPLATE_SUCCESS",
  SET_EVENT_MANAGER_NAME_TEMPLATE: "SET_EVENT_MANAGER_NAME_TEMPLATE",
  UPDATE_EVENT_MANAGER_NAME_TEMPLATE: "UPDATE_EVENT_MANAGER_NAME_TEMPLATE",
  UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS: "UPDATE_EVENT_MANAGER_NAME_TEMPLATE_SUCCESS",
  SET_EVENT_DATES_TEMPLATE: "SET_EVENT_DATES_TEMPLATE",
  UPDATE_EVENT_DATES_TEMPLATE: "UPDATE_EVENT_DATES_TEMPLATE",
  UPDATE_EVENT_DATES_TEMPLATE_SUCCESS: "UPDATE_EVENT_DATES_TEMPLATE_SUCCESS",
  UPDATE_EVENT_NOTES_TEMPLATE: "UPDATE_EVENT_NOTES_TEMPLATE",
  UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS: "UPDATE_EVENT_NOTES_TEMPLATE_SUCCESS",
  GET_FILTERED_EVENTS_LIST: "GET_FILTERED_EVENTS_LIST",
  GET_FILTERED_EVENTS_LIST_SUCCESS: "GET_FILTERED_EVENTS_LIST_SUCCESS",
  GET_FILTERED_EVENTS_LIST_ERROR: "GET_FILTERED_EVENTS_LIST_ERROR",
  CLEAR_EVENT_LIST_FILTER: "CLEAR_EVENT_LIST_FILTER",
  GET_CLIENT_SUGGESTIONS: "GET_CLIENT_SUGGESTIONS",
  GET_CLIENT_SUGGESTIONS_SUCCESS: "GET_CLIENT_SUGGESTIONS_SUCCESS",
  UPDATE_CLIENT_SUGGESTIONS: "UPDATE_CLIENT_SUGGESTIONS",
  UPDATE_CLIENT_SUGGESTIONS_SUCCESS: "UPDATE_CLIENT_SUGGESTIONS_SUCCESS",
  CREATE_VENUE: "CREATE_VENUE",
  CREATE_VENUE_SUCCESS: "CREATE_VENUE_SUCCESS",
  CREATE_VENUE_ERROR: "CREATE_VENUE_ERROR",
  GET_VENUE_SUGGESTIONS: "GET_VENUE_SUGGESTIONS",
  GET_VENUE_SUGGESTIONS_SUCCESS: "GET_VENUE_SUGGESTIONS_SUCCESS",
  UPDATE_VENUE_SUGGESTIONS: "UPDATE_VENUE_SUGGESTIONS",
  UPDATE_VENUE_SUGGESTIONS_SUCCESS: "UPDATE_VENUE_SUGGESTIONS_SUCCESS",
  CREATE_ONSITE_CONTACT: "CREATE_ONSITE_CONTACT",
  CREATE_ONSITE_CONTACT_SUCCESS: "CREATE_ONSITE_CONTACT_SUCCESS",
  EDIT_ONSITE_CONTACT: "EDIT_ONSITE_CONTACT",
  EDIT_ONSITE_CONTACT_SUCCESS: "EDIT_ONSITE_CONTACT_SUCCESS",
  SET_VENUE: "SET_VENUE",
  SET_VENUE_SUCCESS: "SET_VENUE_SUCCESS",
  CHECK_API_STATUS: "CHECK_API_STATUS",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  SET_FILES_TO_UPLOAD: "SET_FILES_TO_UPLOAD",
  ADD_ATTACHMENT: "ADD_ATTACHMENT",
  ADD_ATTACHMENT_SUCCESS: "ADD_ATTACHMENT_SUCCESS",
  ALTERNATE_CONTACT_TEMP: "ALTERNATE_CONTACT_TEMP",
  ALTERNATE_CONTACT_TEMP_SUCCESS: "ALTERNATE_CONTACT_TEMP_SUCCESS",
  DELETE_ALTERNATE_CONTACT_TEMP: "DELETE_ALTERNATE_CONTACT_TEMP",
  DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS: "DELETE_ALTERNATE_CONTACT_TEMP_SUCCESS",
  PRIMARY_CONTACT: "PRIMARY_CONTACT",
  PRIMARY_CONTACT_SUCCESS: "PRIMARY_CONTACT_SUCCESS",
  UPDATE_EVENT_PERSON_RECORD: "UPDATE_EVENT_PERSON_RECORD",
  SET_NEW_EVENT_ID: "SET_NEW_EVENT_ID",
  GET_EVENT: "GET_EVENT",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  CREATE_EVENT: "CREATE_EVENT",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CLEAR_EVENT_DETAILS_FORM: "CLEAR_EVENT_DETAILS_FORM",
  DELETE_ASSET: "DELETE_ASSET",
  ADD_NEW_ASSET: "ADD_NEW_ASSET",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  EDIT_PHONE_ATTRIBUTE: "EDIT_PHONE_ATTRIBUTE",
  EDIT_PHONE_ATTRIBUTE_SUCCESS: "EDIT_PHONE_ATTRIBUTE_SUCCESS",
  UPDATE_EVENT_CORE_ATTR: "UPDATE_EVENT_CORE_ATTR",
  UPDATE_EVENT_CORE_ATTR_SUCCESS: "UPDATE_EVENT_CORE_ATTR_SUCCESS",
  CREATE_EVENT_PERSON_RECORD: "CREATE_EVENT_PERSON_RECORD",
  UPDATE_EVENT_ID_SUCCESS: "UPDATE_EVENT_ID_SUCCESS",
  DELETE_EVENT_PERSON_RECORD: "DELETE_EVENT_PERSON_RECORD",
  UPDATE_SEARCH_RADIUS_TEMPLATE: "UPDATE_SEARCH_RADIUS_TEMPLATE",
  UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS: "UPDATE_SEARCH_RADIUS_TEMPLATE_SUCCESS",
  RESET_LOCATION_TEMP: "RESET_LOCATION_TEMP",
  UPDATE_EVENT_SHIFT_VENUE: "UPDATE_EVENT_SHIFT_VENUE",
  UPDATE_EVENT_SHIFT_VENUE_SUCCESS: "UPDATE_EVENT_SHIFT_VENUE_SUCCESS",
  UPDATE_EVENT_STATUS_TEMPLATE: "UPDATE_EVENT_STATUS_TEMPLATE",
  UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS: "UPDATE_EVENT_STATUS_TEMPLATE_SUCCESS",
  GET_EVENT_STATUS_OPTIONS: "GET_EVENT_STATUS_OPTIONS",
  SET_EVENT_SEARCH_TEXT: "SET_EVENT_SEARCH_TEXT",
  GET_EVENT_DATE_DROPDOWN_OPTIONS: "GET_EVENT_DATE_DROPDOWN_OPTIONS",
  GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS: "GET_EVENT_DATE_DROPDOWN_OPTIONS_SUCCESS",
  GET_EVENT_SORT_DROPDOWN_OPTIONS: "GET_EVENT_SORT_DROPDOWN_OPTIONS",
  GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS: "GET_EVENT_SORT_DROPDOWN_OPTIONS_SUCCESS",
  GET_DEFAULT_EVENT_FILTERS: "GET_DEFAULT_EVENT_FILTERS",  
  UPDATE_EVENT_FILTERS: "UPDATE_EVENT_FILTERS"
}

export default eventActions;
