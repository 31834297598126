import React from "react";
import LoginHeader from "../../login/LoginHeader";
import LoginCoverImage from "../../login/LoginCoverImage";

const TalentRegisterThanks = () => {
  return (
    <div className="flex">
      <div className="flex flex-col w-full sm:w-3/4 h-100vh">
        <LoginHeader />
        <div className="flex text-center flex-col mt-16">
          <h2 className="text-header font-inter leading-header font-semibold text-black mb-8">Thank you!</h2>
          <p className="font-medium font-inter text-base mb-3"> Your Talent Profile has been successfully submitted.</p>
          <p className="font-medium font-inter text-base mb-3">We appreciate you taking the time to fill it out.</p>
        </div>
      </div>
      <LoginCoverImage />
    </div>
  )
}

export default TalentRegisterThanks;
