import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const success = (msg) => {
  toast.success(msg);
}

const error = (msg) => {
  toast.error(msg);
}

export { success, error }
