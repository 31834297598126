import { Button } from "antd";

const DeleteShiftModal = (props) => {
  const { onCancel, onConfirm } = props;
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-[550px] my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg p-6 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="border border-[#CBD1D9] rounded p-5">
              <h3 className="text-sm font-bold text-fontGray font-inter text-center">
                Are you sure you want to remove the shift?
              </h3>
              <p className="text-fontGray text-sm font-medium font-inter mt-3 text-center">
                The shift will not be available for the event, and roles associated with this shift will be permanently removed.
              </p>
            </div>
            <div className="flex items-center justify-end rounded-b mt-3">
              <Button
                className="text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium text-sm px-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer mr-4"
                type="button"
                onClick={onConfirm}
              >
                Delete Shift
              </Button>
              <Button
                onClick={onCancel}
                type="button"
                className="font-inter font-medium text-sm text-fontGray border rounded-[8px] border-[#98a2b380] shadow-attachment"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeleteShiftModal;
