// library imports
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// user imports
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import localCacheStorage from "../utils/localCacheStorage";

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;

const initialState = localCacheStorage.get("APP_STATE");

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), reduxDevTools)
);

store.subscribe(() => {
  localCacheStorage.set("APP_STATE", store.getState());
});

sagaMiddleware.run(rootSaga);

// exports
export default store;
