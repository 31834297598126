import React, { useState } from "react";
import editIcon from "../../../assets/images/editIcon02.png"
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";
import { heightInFt, heightInInch } from "../../../constants";

const HeightField = ({ defaultFt, defaultInch, name, id, onChangeFt, onChangeInch, showEditIcon=false, saveEdit, required }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [updatedFt, setUpdatedFt] = useState('');
  const [updatedInch, setUpdatedInch] = useState('');
  const onFtChange = (value) => {
    if (showEditIcon) {
      setUpdatedFt(value)
    }
    onChangeFt(value)
  }

  const onInchChange = (value) => {
    if (showEditIcon) {
      setUpdatedInch(value)
    }
    onChangeInch(value)
  }

  const onSaveEdit = () => {
    if ((defaultFt === parseInt(updatedFt)) && (defaultInch === parseInt(updatedInch))) {
      setIsEditable(false);
    } else {
      const ft = updatedFt ? updatedFt : defaultFt ? defaultFt : 0;
      const inch = updatedInch ? updatedInch : defaultInch ? defaultInch : 0;
      let isValueEdited = false;
      if (defaultFt || defaultInch) {
        isValueEdited = defaultFt !== parseInt(updatedFt) || defaultInch !== parseInt(updatedInch);
      }
      saveEdit(id, parseInt(ft), parseInt(inch), isValueEdited)
      setIsEditable(false);
    }
  }

  return (
    <div className={`flex ${showEditIcon ? 'justify-between' : 'justify-start'}`}>
      <div className='flex flex-row'>
        <select
          className="border-none w-auto outline-none text-sm text-left placeholder-grayNobel text-tileGray font-normal h-4 mt-2 mr-2 appearance-none"
          name={name}
          id={id}
          disabled={showEditIcon && !isEditable}
          required={required}
          onChange={(e) => onFtChange(e.target.value)}>
          <option key="0" value="">Feet</option>
          {
            heightInFt.map((ft, index) => {
              return (
                <option key={index + 1} value={ft} selected={defaultFt === ft}>{ft}'</option>
              )
            })
          }
        </select>
        <select
          className="border-none w-auto outline-none text-sm text-left placeholder-grayNobel text-tileGray font-normal h-4 mt-2 appearance-none"
          name={name}
          id={id}
          disabled={showEditIcon && !isEditable}
          required={required}
          onChange={(e) => onInchChange(e.target.value)}>
          <option key="0" value="">Inches</option>
          {
            heightInInch.map((inch, index) => {
              return (
                <option key={index + 1} value={index} selected={defaultInch === inch}>{inch}"</option>
              )
            })
          }
        </select>
      </div>
      {
        showEditIcon && !isEditable &&
        <img
          src={editIcon}
          alt="edit"
          className="h-4 w-4 cursor-pointer ml-ten -mt-1"
          onClick={() => {setIsEditable(true)}}
        />
      }
      {
        isEditable &&
        <div
          className="grid -mt-5 gap-2"
        >
          <img
            src={saveIcon}
            alt="save edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => { onSaveEdit() }}
          />
          <img
            src={clearIcon}
            alt="clear edit"
            className="w-4 h-4 cursor-pointer"
            onClick={() => {setUpdatedFt(''); setUpdatedInch(''); setIsEditable(false)}}
          />
        </div>
      }
    </div>
  )
}

export default HeightField;
