import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { COUNTRY_UUID, EMERGENCY_PHONE_UUID, FormCategories, Strings, personTypeUUID } from '../../../../constants';
import { createTalentPhoneAttr, emergencyContactUpdatedForm, updateCoreAttribute, updateTalentPhoneAttr, checkContactNumberVaidity, setIsEmergencyPhoneNumberValid } from '../../../../store/actions/talents';
import InputField from '../../components/inputField';
import SelectField from '../../components/selectField';
import _ from 'lodash';
import getKeyByValue from '../../../../globalFunctions/getKeyByValue';
import { phoneNumberRegex } from '../talentListNew/helper';

const EmergencyContact = (props) => {
  const {
    isEmergencyContactUpdated,
    emergencyContactUserUpdated,
    emergencyContactTemplate,
    triggerEmergencyContactUpdate,
    showEditIcon,
    triggerPhoneUpdated, triggerPhoneCreated,
    triggerUpdateCoreAttribute,
    userId, isRegister, triggerCheckPhoneValid, triggerSetIsEmergencyPhoneNumberValid
  } = props;

  const template = _.cloneDeep(emergencyContactTemplate);
  const [emergencyFields, setEmergencyFields] = useState(template?.contact);
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    if (isEmergencyContactUpdated) {
      const userUpdated = _.cloneDeep(emergencyContactUserUpdated);
      setEmergencyFields(userUpdated?.contact);
    }
  }, [isEmergencyContactUpdated, emergencyContactUserUpdated])

  useEffect(() => {
    if(isEmergencyContactUpdated && emergencyContactUserUpdated) {
      const userUpdated = _.cloneDeep(emergencyContactUserUpdated);
      userUpdated?.contact?.forEach(field => {
        if(field?.label === Strings.emergencyContactNumber && field.value) {
          const value = field.value;
          if(!phoneNumberRegex.test(value)) {
            setPhoneError("Invalid Phone Number");
            triggerSetIsEmergencyPhoneNumberValid(false);
          }
          else {
            const payload = {
              userEmergencyContactNumber: value,
              onEmergencySuccess: () => {
                setPhoneError("");
                triggerSetIsEmergencyPhoneNumberValid(true);
              },
              onEmergencyError: (message) => {
                setPhoneError(message)
                triggerSetIsEmergencyPhoneNumberValid(false);
              }
            }
            triggerCheckPhoneValid(payload);
          }
        }
      });
    }
  }, []);

  const updateEmergencyContactInfo = () => {
    const emergencyContactInfo = {}
    if (!showEditIcon) {
      let emergencyFieldVal = [];
      emergencyFields.forEach(field => emergencyFieldVal.push(Boolean(field?.value || field?.optionSelection)))
      const isEmptyEmergencyField = emergencyFieldVal.includes(false)
      if (!isEmptyEmergencyField) {
        emergencyContactInfo['allFieldsUpdated'] = true;
      }
    }
    emergencyContactInfo[FormCategories.contact] = emergencyFields;
    triggerEmergencyContactUpdate({ emergencyContact: emergencyContactInfo })
  }

  const onChangeHandler = (id, value) => {
    emergencyFields.forEach(field => { 
      if (field.id === id) { 
        field.value = value
      }
    })
    if (!showEditIcon) { updateEmergencyContactInfo() }
  }

  const onBlurHandler = (id, value, type, inputBlurCallback) => {
    if(type === "tel") {
      if(value) {
        validatePhoneNumber(value, inputBlurCallback);
      }
      else {
        if(showEditIcon) {
          setPhoneError("Enter Phone Number");
          triggerSetIsEmergencyPhoneNumberValid(false);
        } else {
          setPhoneError("");
          triggerSetIsEmergencyPhoneNumberValid(true);
        }
      }
    }
  }

  const resetPhoneError = () => {
    setPhoneError("");
    triggerSetIsEmergencyPhoneNumberValid(true);
  }


  const validatePhoneNumber = (value, callBack = () => {}) => {
    if(!phoneNumberRegex.test(value)) {
      setPhoneError("Invalid Phone Number");
      callBack(true);
      triggerSetIsEmergencyPhoneNumberValid(false);
    }
    else {
      const payload = {
        userEmergencyContactNumber: value,
        onEmergencySuccess: () => {
          setPhoneError("");
          callBack();
          triggerSetIsEmergencyPhoneNumberValid(true);
        },
        onEmergencyError: (message) => {
          setPhoneError(message);
          callBack(true);
          triggerSetIsEmergencyPhoneNumberValid(false);
        }
      }
      triggerCheckPhoneValid(payload);
    }
  }

  const onChangeOptionHandler = (id, value, isOptionUpdate=false) => {
    emergencyFields.forEach(field => { 
      if (field.id === id) { 
        field.optionSelection = value;
      }
    })
    if (!showEditIcon) { updateEmergencyContactInfo() }
  }

  const onRelationshipUpdate = (value, optionList) => {
    let selectedValue = value;
    const optionData = _.cloneDeep(optionList);
    selectedValue = getKeyByValue(optionData, value);
    const actualPayload = {
      'uuid': userId,
      'person_type_uuid': personTypeUUID,
      'status': 'available',
      'emergency_relationship': selectedValue
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        updateEmergencyContactInfo();
      },
      onError: () => {
      }
    }
    triggerUpdateCoreAttribute(payload);
  }

  const onNameUpdate = (id, value, isValueEdited, name) => {
    const actualPayload = {
      'uuid': userId,
      'emergency_name': value
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        updateEmergencyContactInfo();
      },
      onError: () => {
      }
    }
    triggerUpdateCoreAttribute(payload);
  }

  const onPhoneUpdate = (id, value, isValueEdited, name) => {
    const actualPayload = {
      'phone_number': value,
      'phone_type_uuid': EMERGENCY_PHONE_UUID,
      'country_uuid': COUNTRY_UUID,
      'emergency': true
    }
    if (isValueEdited) {
      actualPayload['uuid'] = id;
    } else {
      actualPayload['person_uuid'] = userId;
    }
    const payload = {
      actualPayload: actualPayload,
      onSuccess: (uuid) => {
        emergencyFields.forEach(field => { 
          if (field.id === id) { 
            field.id = uuid
          }
        })
        updateEmergencyContactInfo();
      },
      onError: () => {
      }
    }
    if (isValueEdited) {
      triggerPhoneUpdated(payload)
    } else {
      triggerPhoneCreated(payload)
    }
  }

  const onSaveEdit = (id, value, isValueEdited, name) => {
    emergencyFields.forEach(field => {
      if (field.id === id && (field.label === Strings.emergencyContactName)) { 
        return onNameUpdate(id, value, isValueEdited, name)
      }
      if (field.id === id && (field.label === Strings.emergencyContactNumber)) {
        return onPhoneUpdate(id, value, isValueEdited, name)
      }
    })
  }

  const onSaveOption = (id, value, isValueEdited, name, isOptionUpdate, optionList) => {
    emergencyFields.forEach(field => { 
      if (field.id === id && (field.id === Strings.relationship)) { 
        return onRelationshipUpdate(value, optionList)
      }
    })
  }
  return (
    <form className="w-full px-3">
      <div className='gap-4 grid grid-cols-3'>
        {
          emergencyFields.map((field, index) => {
            return (
              <div className='w-full'>
                <fieldset key={index} className="m-auto text-lg mb-4">
                  <div className={`border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs ${field.label === Strings.emergencyContactNumber && phoneError ? "border border-red-500" : ""}`}>
                    <label className="font-semibold text-sm text-[#393636]" htmlFor={field.id}>{field.label}{(field.required || isRegister) && <span className="text-red-500 ml-1">*</span>}</label>
                    {
                      !field.options &&
                      <InputField name={field?.id} id={field?.id} onChange={onChangeHandler} placeholder={field?.placeholder} defaultValue={field.value}
                        maxAllowed={field.maxAllowed} minAllowed={field.minAllowed} required={field.required || isRegister} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveEdit} onInputBlur={onBlurHandler} type={field.type} resetPhoneError={field.label === Strings.emergencyContactNumber ? resetPhoneError : () => {}} />
                    }
                    {
                      field.options &&
                      <SelectField options={field.options} optionList={field.optionsList} name={field?.id} id={field?.id} onChangeOption={onChangeOptionHandler}
                        defaultValue={field.optionSelection} isReadOnly={showEditIcon} showEditIcon={showEditIcon} saveEdit={onSaveOption} required={field.required || isRegister} />
                    }
                  </div>
                  {field.label === Strings.emergencyContactNumber && phoneError && <span className="text-sm font-inter text-red-500">{phoneError}</span>}
                </fieldset>
              </div>
            )
          })
        }
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    isEmergencyContactUpdated: state.talent.isEmergencyContactUpdated,
    emergencyContactUserUpdated: state.talent.emergencyContactUserUpdated,
    emergencyContactTemplate: state.talent.emergencyContactTemplate,
  };
};

const mapDispatchToProps = {
  triggerEmergencyContactUpdate: emergencyContactUpdatedForm,
  triggerPhoneUpdated: updateTalentPhoneAttr,
  triggerPhoneCreated: createTalentPhoneAttr,
  triggerUpdateCoreAttribute: updateCoreAttribute,
  triggerCheckPhoneValid: checkContactNumberVaidity,
  triggerSetIsEmergencyPhoneNumberValid: setIsEmergencyPhoneNumberValid
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContact);
