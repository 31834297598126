import React, { useState } from 'react';
import { connect } from 'react-redux';
import { VENUE_CATEGORY_UUID, createdErrorMessage, createdSuccessMessage, eventBtnText, eventStrings, eventVenueNameTemplate, squareRounded } from '../../../../constants/events';
import AddressField from '../../components/addressField';
import InputField from '../../components/inputField';
import _ from 'lodash';
import CloseIcon from "../../talents/library/assets/svg/close.svg";
import { ADDRESS_TYPE_UUID, Strings } from '../../../../constants';
import { success, error } from "../../../toast";
import { createVenue } from '../../../../store/actions/events';
import Spinner from '../../../Spinner';
import { updateShiftTimezoneTemplate } from '../../../../store/actions/shifts';
import UpdateVenue from './UpdateVenue';

const AddVenue = (props) => {
  const { addressTemplate, setShowVenueModal, provinces, triggerCreateVenue, isShift, timeZones, shiftTimezoneTemp, triggerUpdateShiftTimezone, getConfirmation=false, proceedToSaveNewVenue=() => {}, setRemoveEditBtn=() => {}, isCreate=false } = props;
  const address = _.cloneDeep(addressTemplate);
  const [venueName, setVenueName] = useState(null);
  const [venueAddress, setVenueAddress] = useState(address);
  const [loading, setLoading] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [showVenueUpdatePopup, setShowVenueUpdatePopup] = useState(false);

  const confirmNewVenueAdd = () => {
    setShowVenueUpdatePopup(true);
  }

  const saveVenue = () => {
    setLoading(true);
    let street1 = ''; let street2 = ''; let postalCode = ''; let city = '';
    let stateProvinceUuid = '';
    venueAddress.addressFields.forEach(address => {
      address.forEach(field => {
        if (field.value || field.optionSelection) {
          if (field.id === 'street_1') { street1 = field.value }
          if (field.id === 'street_2') { street2 = field.value }
          if (field.id === 'postal_code') { postalCode = field.value }
          if (field.id === 'city') { city = field.value }
          if (field.id === 'state') {
            const selectedProvince = provinces && provinces.find(province => province.name === field.optionSelection)
            stateProvinceUuid = selectedProvince && selectedProvince.uuid;
          }
        }
      })
    })
    const actualPayload = {
      "venue_category_uuid": VENUE_CATEGORY_UUID,
      "name": venueName,
      "venue_addresses": [{
        "address_type_uuid": ADDRESS_TYPE_UUID,
        "street_1": street1,
        "city": city,
        "state_province_uuid": stateProvinceUuid,
        "postal_code": postalCode,
        "street_2": street2
      }]
    }
    if (venueAddress['lat'] &&  venueAddress['long']) {
      actualPayload["venue_addresses"][0]["lat"] = venueAddress['lat']
      actualPayload["venue_addresses"][0]["long"] = venueAddress['long']
    }
    actualPayload["venue_addresses"][0]["tz_name"] = venueAddress['timezone'];
    actualPayload["venue_addresses"][0]["map_lookup_ref"] = venueAddress['map_lookup_ref'];
    const payload = {
      actualPayload: actualPayload,
      onSuccess: async (response) => {
        success(createdSuccessMessage('Venue'));
        const tz_name = response?.venue_addresses[0]?.tz_name;
        if(isShift && timeZones && Object.keys(timeZones).length && tz_name) {
          let timeZoneLabel = timeZones[tz_name];
          let shiftTimezone = _.cloneDeep(shiftTimezoneTemp);
          shiftTimezone.optionSelection = timeZoneLabel;
          triggerUpdateShiftTimezone({ shiftDetails: shiftTimezone });
        }
        setLoading(false);
        setShowVenueModal(false);
        if (!isCreate) {
          proceedToSaveNewVenue({value: response?.primary_address?.primary_venue_address_space?.uuid, label: response?.display_name});
        }
      },
      onError: (msg) => {
        error(createdErrorMessage('venue'))
        setLoading(false);
        setShowVenueModal(false);
      },
    };
    triggerCreateVenue(payload)
  }
  const onChangeVenueName = (id, value) => {
    setVenueName(value);
  }
  const onChangeVenueAddress = (fieldData, id, suggestionSelected, lat='', long='', placeId, timezone) => {
    if (address.id === id) {
      address.addressFields = fieldData
      if (lat !== undefined && long !== undefined) {
        address['lat'] = lat;
        address['long'] = long;
      }
      if(timezone !== undefined) {
        address["timezone"] = timezone;
      }
      if(placeId !== undefined) {
        address["map_lookup_ref"] = placeId;
      }
      const disableButton = address.addressFields.flat().filter(field => field.id !== Strings.apt).some(field => !field.value && !field.optionSelection);
      setDisableSubmitBtn(disableButton);
    }
    setVenueAddress(address);
  }

  const cancelSaveVenue = () => {
    setShowVenueUpdatePopup(false);
    setShowVenueModal(false);
    setRemoveEditBtn(true);
  }

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
      >
        <div className="relative w-[714px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-medium">
                {eventStrings.createVenueTitle}
              </h3>
              <img className='w-5 h-5 my-auto cursor-pointer' src={CloseIcon} alt="close modal" onClick={() => setShowVenueModal(false)} />
            </div>
            <div className="relative px-6 flex-auto">
              <fieldset className="w-full m-auto text-lg col-span-2 mb-4">
                <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
                  <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={eventVenueNameTemplate?.id}>{eventVenueNameTemplate?.label}</label>{eventVenueNameTemplate?.required && squareRounded()}</div>
                  <InputField name={eventVenueNameTemplate?.id} id={eventVenueNameTemplate?.id} onChange={onChangeVenueName} placeholder={eventVenueNameTemplate?.placeholder} defaultValue={venueName || eventVenueNameTemplate.value} type={eventVenueNameTemplate.type}
                    maxAllowed={eventVenueNameTemplate.maxAllowed} minAllowed={eventVenueNameTemplate.minAllowed} required={eventVenueNameTemplate.required} />
                </div>
              </fieldset>
              {
                <AddressField
                  title={address?.title} subTitle={address.subTitle} fieldData={venueAddress.addressFields} onChange={onChangeVenueAddress}
                  fieldId={address.id} fieldUUID={address.uuid} required={true} addressVal={addressTemplate.addressFields} fieldCustomStyle="normal-case placeholder-normal-case" isEventPage={true} suggestionClass={"normal-case"}
                />
              }
            </div>
            <div className="flex items-center justify-end pb-6 pt-2 pr-6 rounded-b">
              <button
                className="text-fontGray-500 bg-transparent font-medium px-4 py-2 text-sm outline-none rounded-lg focus:outline-none mr-2 border border-cadetGray/50"
                type="button"
                onClick={() => setShowVenueModal(false)}
              >
                {eventBtnText.dismiss}
              </button>
              <button
                onClick={getConfirmation ? confirmNewVenueAdd : saveVenue}
                disabled={loading || disableSubmitBtn}
                type="button"
                className="text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium text-sm px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-gray cursor-pointer"
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <Spinner color={"white"} loading={loading} size={15} />
                  </div>
                ) : (
                  `${eventBtnText.createSave}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
      {showVenueUpdatePopup && <UpdateVenue setShowVenueUpdatePopup={setShowVenueUpdatePopup} saveVenue={saveVenue} cancelVenue={cancelSaveVenue} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    addressTemplate: state.talent.addressTemplate,
    provinces: state.talent.provinces,
    timeZones: state.shifts.timezones,
    shiftTimezoneTemp: state.shifts.shiftTimezoneTemp,
  };
};

const mapDispatchToProps = {
  triggerCreateVenue: createVenue,
  triggerUpdateShiftTimezone: updateShiftTimezoneTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVenue);
