import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { error, success } from '../../../toast';
import { createdErrorMessage, createdSuccessMessage, eventBtnText, squareRounded, updatedSuccessMessage, updatedErrorMessage } from '../../../../constants/events';
import { Strings } from '../../../../constants';
import Spinner from '../../../Spinner';
import InputField from '../../components/inputField';
import DateTimeRangeField from '../../components/dateTimeRangeField';
import { createShift, updateShiftDateTimeTemplate, updateShiftDetails, updateShiftLocationTemplate, updateShiftNameTemplate, updateShiftTimezoneTemplate } from '../../../../store/actions/shifts';
import LocationDetails from '../event/LocationDetails';
import _ from 'lodash';
import getKeyByValue from '../../../../globalFunctions/getKeyByValue';
import SelectDropdown from '../../components/selectDropdown';

const AddShift = (props) => {
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all"
  });
  const { id } = useParams();

  const {
    setShowAddShift=()=>{}, isEditShift, shiftNameTemp, shiftDateTimeTemp, shiftTimezoneTemp,
    triggerUpdateShiftName, triggerUpdateShiftDetails, triggerUpdateShiftTimezone, triggerUpdateShiftDateTime,
    triggerUpdateShiftLocation, triggerCreateShift, eventLocationTemp, shiftLocationTemp, isAutoGeneratedAndUntouched=false,
    shiftId='', getUpdatedShiftList=()=>{}, closePopup=()=>{}, isPopup, eventDatesTemp, isFirstShift=false
  } = props;
  let shiftName = _.cloneDeep(shiftNameTemp);
  let shiftDateTime = _.cloneDeep(shiftDateTimeTemp);
  let shiftTimezone = _.cloneDeep(shiftTimezoneTemp);
  const [loading, setLoading] = useState(false);
  const [eventDates, setEventDates] = useState({});

  useEffect(() => {
    if (!shiftLocationTemp?.value) {
      triggerUpdateShiftLocation({ shiftDetails: eventLocationTemp })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(eventDatesTemp?.value)) {
      setEventDates(eventDatesTemp?.value)
    }
  }, [eventDatesTemp])
  

  const onSaveEventDetails = (actualPayload) => {
    const payload = {
      actualPayload: actualPayload,
      onSuccess: () => {
        success(updatedSuccessMessage())
      },
      onError: () => {
        error(updatedErrorMessage())
      }
    }
    triggerUpdateShiftDetails(payload);
  }

  const onChangeShiftName = (id, value) => {
    shiftName.value = value
    if (!isEditShift) { triggerUpdateShiftName({ shiftDetails: shiftName }) }
  }

  const onSaveShiftName = (id, value) => {
    const actualPayload = {
      'uuid': shiftId,
      'name': value
    }
    onSaveEventDetails(actualPayload);
    shiftName.value = value
    triggerUpdateShiftName({ shiftDetails: shiftName })
  }

  const onChangeShiftDatetime = (id, { start, end }, formattedStart, formattedEnd, name) => {
    const local_start_at = `${start}`;
    const local_end_at = `${end}`;
    shiftDateTime.value = {
      startDate: local_start_at,
      endDate: local_end_at
    };
    shiftDateTime['formattedStart'] = formattedStart;
    shiftDateTime['formattedEnd'] = formattedEnd;
    triggerUpdateShiftDateTime({ shiftDetails: shiftDateTime })
  }

  const onSaveShiftDatetime = (start, end, formattedStart, formattedEnd) => {
    const local_start_at = `${start}`;
    const local_end_at = `${end}`;
    shiftDateTime.value = {
      startDate: local_start_at,
      endDate: local_end_at
    };
    shiftDateTime['formattedStart'] = formattedStart;
    shiftDateTime['formattedEnd'] = formattedEnd;
    const actualPayload = {
      'uuid': shiftId,
      'local_start_at': start,
      'local_end_at': end
    }
    onSaveEventDetails(actualPayload);
    triggerUpdateShiftDateTime({ shiftDetails: shiftDateTime })
  }

  const onChangeShiftTimezone = (value, options) => {
    let label = ''
    if (value) {
      label = options[0]?.options[value]?.label
    }
    shiftTimezone.optionSelection = label;
    if (!isEditShift) { triggerUpdateShiftTimezone({ shiftDetails: shiftTimezone }); }
  }

  const onSaveShiftTimezone = (value, optionList) => {
    let label = ''
    if (value) {
      label = optionList[0]?.options[value]?.label
    }
    let selectedValue = label;
    selectedValue = getKeyByValue(shiftTimezone?.optionsList, label);
    const actualPayload = {
      'uuid': shiftId,
      'tz_name': selectedValue
    }
    onSaveEventDetails(actualPayload);
    shiftTimezone.optionSelection = value;
    triggerUpdateShiftTimezone({ shiftDetails: shiftTimezone });
  }

  const onChangeShiftLocation = (location) => {
    if (!isEditShift) { triggerUpdateShiftLocation({ shiftDetails: location }) }
  }

  const onSaveShiftLocation = (location) => {
    const actualPayload = {
      'uuid': shiftId,
      'primary_venue_address_space_uuid': location?.value
    }
    onSaveEventDetails(actualPayload);
    triggerUpdateShiftLocation({ shiftDetails: location })
  }

  const submit = () => {
    setLoading(true);
    const payload = {
      id: id,
      isAutoGeneratedAndUntouched: isAutoGeneratedAndUntouched,
      shiftId: shiftId,
      onSuccess: () => {
        success(createdSuccessMessage('Shift'));
        getUpdatedShiftList();
        setShowAddShift(false);
        setLoading(false);
        closePopup();
        if (isAutoGeneratedAndUntouched) {
          getUpdatedShiftList()
        }
      },
      onError: () => {
        error(createdErrorMessage('Shift'))
        setShowAddShift(false);
        setLoading(false);
        closePopup();
      }
    }
    triggerCreateShift(payload);
  }

  const timezoneOptions = [{
    label: "Time zones",
    options: Object.keys(shiftTimezone?.options).map(key => ({value: key, label: shiftTimezone?.options[key]}))
  }]

  return (
    <form onSubmit={handleSubmit(submit)} className="w-full event-shift-modal">
      <div className={`gap-4 grid grid-cols-3 w-[900px] md:w-[620px] lg:w-[720px] xl:w-[820px] ${isPopup && '!w-full'}`}>
        <fieldset className="w-full m-auto text-lg col-span-3">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={shiftName?.id}>{shiftName?.label}</label>{shiftName?.required && squareRounded()}</div>
            <InputField name={shiftName?.name} id={shiftName?.id} onChange={onChangeShiftName} placeholder={shiftName?.placeholder} defaultValue={shiftName?.value}
              maxAllowed={shiftName?.maxAllowed} minAllowed={shiftName?.minAllowed} required={shiftName?.required} isReadOnly={isEditShift} showEditIcon={isEditShift} saveEdit={onSaveShiftName} isEventPage={true} />
          </div>
        </fieldset>
        {/* date time field */}
        <fieldset className="w-full m-auto text-lg col-span-2">
          <div className='border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs'>
            <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={shiftDateTime?.id}>{shiftDateTime?.label}</label>{shiftDateTime?.required && squareRounded()}</div>
            <DateTimeRangeField name={shiftDateTime?.id} id={shiftDateTime?.id} onChange={onChangeShiftDatetime} placeholder={shiftDateTime?.placeholder} defaultValue={shiftDateTime?.value}
              type={shiftDateTime?.type} maxAllowed={shiftDateTime?.maxAllowed} minAllowed={shiftDateTime?.minAllowed} required={shiftDateTime?.required} eventDates={eventDates} showEditIcon={isEditShift} saveEdit={onSaveShiftDatetime}
              dateStart={shiftDateTime?.formattedStart} dateEnd={shiftDateTime?.formattedEnd}
              />
          </div>
        </fieldset>
        <fieldset className="w-full m-auto text-lg col-span-1">
          <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase pt-2 px-0 pb-0 gap-1 text-xs relative">
            <div className="flex flex-row">
              <label
                className="font-semibold font-inter text-sm text-[#393636] pl-2"
                htmlFor={shiftTimezone?.id}
              >
                {shiftTimezone?.label}
              </label>
              {shiftTimezone?.required && squareRounded()}
            </div>
            <SelectDropdown 
              options={timezoneOptions} 
              placeholder={shiftTimezone?.placeholder} 
              onChange={onChangeShiftTimezone} 
              selectedValue={shiftTimezone?.optionSelection} 
              showEditIcon={isEditShift}
              saveEdit={onSaveShiftTimezone}
              optionsClass="event-shift-options-single"
            />
          </div>
        </fieldset>
        <LocationDetails
          isShift={true}
          onChangeShiftLocation={onChangeShiftLocation}
          onSaveShiftLocation={onSaveShiftLocation}
          showEditIcon={isEditShift}
        />
        <div className="flex items-center justify-end pb-6 rounded-b ml-auto mr-0 col-span-3">
        {!isFirstShift && (
          <button
            className={`text-fontGray-500 bg-transparent font-medium px-4 py-2 text-sm outline-none rounded-lg focus:outline-none mr-2 mb-8 border border-cadetGray/50 text-coralPink border-coralPink ${
              isPopup && "!mb-0"
            }`}
            type="button"
            onClick={closePopup}
          >
            {eventBtnText.dismiss}
          </button>
        )}
          {!isEditShift && (
            <button
              onClick={() => {
                reset({
                  keepErrors: false,
                  keepDirty: true,
                  keepIsSubmitted: false,
                  keepTouched: false,
                  keepIsValid: false,
                  keepSubmitCount: false,
                });
              }}
              disabled={loading}
              type="submit"
              className={`cursor-pointer float-right w-fit text-white bg-secondaryLight hover:bg-secondary active:bg-secondaryDark font-medium rounded text-center px-4 py-2 flex justify-center items-center text-sm mb-8 disabled:bg-gray ${
                isPopup && "!mb-0"
              }`}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                  <span className="mx-2">{Strings.loading}</span>
                  <Spinner color={"white"} loading={loading} size={15} />
                </div>
              ) : (
                `${eventBtnText.createShift}`
              )}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    timezones: state.shifts.timezones,
    shiftNameTemp: state.shifts.shiftNameTemp,
    shiftDateTimeTemp: state.shifts.shiftDateTimeTemp,
    shiftTimezoneTemp: state.shifts.shiftTimezoneTemp,
    eventLocationTemp: state.events.eventLocationTemp,
    shiftLocationTemp: state.shifts.shiftLocationTemp,
    eventDatesTemp: state.events.eventDatesTemp,
  };
};

const mapDispatchToProps = {
  triggerUpdateShiftName: updateShiftNameTemplate,
  triggerUpdateShiftDetails: updateShiftDetails,
  triggerUpdateShiftTimezone: updateShiftTimezoneTemplate,
  triggerUpdateShiftDateTime: updateShiftDateTimeTemplate,
  triggerUpdateShiftLocation: updateShiftLocationTemplate,
  triggerCreateShift: createShift
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShift);
