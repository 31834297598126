import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import TalentCard from './talentCard';
import NavBar from '../../../layout/navbar';
import TalentSubHeaders from '../../../layout/talentSubHeaders';
import { Strings, imageContent, talentFormHeaders } from '../../../../constants';
import PersonalInformation from './personalInformation';
import Contact from './contact';
import Experience from './experience';
import UserImageUploader from './userImageUploader';
import { clearUpdatedFormDetails, setNewTalentId, talentData, getTalentNotesDataTemplate, getTalentNotes } from '../../../../store/actions/talents';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Notes from "./notes";

const TalentForm = (props) => {
  const {
    isTalentView,
    isPersonalInfoUpdated,
    triggerGetTalentData,
    triggerSetCurrentNewTalentId,
    triggerClearFormTemplate,
    isTalentRegister,
    isPersonalTemplateUpdated,
    isExperienceTemplateUpdated,
    isContactTemplateUpdated,
    isEmergencyContactTemplateUpdated,
    isSkillsTemplateUpdated,
    triggerTalentNotesList,
    triggerNotesData,
    triggerGetTalentNotesDataTemplate,
  } = props;
  const { id } = useParams();
  const [selectedSection, setSelectedSection] = useState(1);
  const [showOverlayLoader, setShowOverlayLoader] = useState(true);

  useEffect(() => {
    if (isTalentView) {
      if (isPersonalInfoUpdated) {
        triggerClearFormTemplate();
      }
      triggerGetTalentData({ id: id });
      // triggerTalentNotesList({id: id});
    } else {
      triggerSetCurrentNewTalentId({ id: uuidv4() });
    }
    triggerGetTalentNotesDataTemplate([]);
  }, []);

  useEffect(() => {
    if (isPersonalTemplateUpdated && isExperienceTemplateUpdated && isContactTemplateUpdated && isEmergencyContactTemplateUpdated && isSkillsTemplateUpdated) {
      setShowOverlayLoader(false);
    } else {
      setShowOverlayLoader(true);
    }
  }, [isPersonalTemplateUpdated, isExperienceTemplateUpdated, isContactTemplateUpdated, isEmergencyContactTemplateUpdated, isSkillsTemplateUpdated])

  const renderForm = () => {
    let selectedForm = "";
    Object.keys(talentFormHeaders).forEach((header) => {
      const headerNum = parseInt(header);
      const selectedSectionNum = parseInt(selectedSection);
      if (headerNum === selectedSectionNum) {
        selectedForm = talentFormHeaders[header];
      }
    });
    switch (selectedForm) {
      case Strings.personalInformation:
        return <PersonalInformation showEditIcon={isTalentView} setSelectedSection={updateSelectedSection} userId={id} isRegister={isTalentRegister} />
      case Strings.contact:
        return <Contact showEditIcon={isTalentView} setSelectedSection={updateSelectedSection} userId={id} isRegister={isTalentRegister} />
      case Strings.experience:
        return <Experience showEditIcon={isTalentView} setSelectedSection={updateSelectedSection} userId={id} isRegister={isTalentRegister} />
      case Strings.notes:
        return <Notes />;
      default:
        return <PersonalInformation showEditIcon={isTalentView} setSelectedSection={updateSelectedSection} userId={id} isRegister={isTalentRegister} />
    }
  };

  const updateSelectedSection = (sectionNumber) => {
    setSelectedSection(sectionNumber);
    renderForm();
  };

  return (
    <div className='flex'>
      {!isTalentRegister && <NavBar />}
      {showOverlayLoader && <div className="bg-black opacity-30 z-10 absolute top-0 left-0 w-full h-full" />}
      <div className='h-screen overflow-auto w-full mb-8'>
        <div className="flex h-screen">
          <div className="block w-3/4" onClick={(e) => e.stopPropagation()}>
            <div className="w-full p-8 bg-gradient-to-r from-silverSand to-bluewood">
              <div className="w-full h-64 p-ten border border-mercury rounded-lg text-left bg-white">
                <TalentCard isTalentView={isTalentView} userId={id} />
              </div>
            </div>
            <TalentSubHeaders
              setSelectedSection={updateSelectedSection} selectedSection={selectedSection}
              isContinueHighlight={!isTalentView} showSectionNumber={!isTalentView} isRegister={isTalentRegister}
            />
            {
              !showOverlayLoader &&
              <div className='p-6 w-full'>
                {renderForm()}
              </div>
            }
          </div>
          <div
            className="w-auto h-[150%] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <form noValidate>
              {
                isTalentRegister &&
                <div className='bg-coralPink mb-4 rounded-lg flex w-[350px] mt-2'>
                  <p className='font-inter font-semibold text-midnightBlack text-base p-2 italic'>{imageContent}</p>
                </div>
              }
              <UserImageUploader isTalentView={isTalentView} id={id} isTalentRegister={isTalentRegister} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    tokenExpires: state.auth.loginDetails?.token_expires || '',
    isPersonalInfoUpdated: state.talent.isPersonalInfoUpdated,
    showOverlayLoader: state.talent.showOverlayLoader,
    isPersonalTemplateUpdated: state.talent.isPersonalTemplateUpdated,
    isExperienceTemplateUpdated: state.talent.isExperienceTemplateUpdated,
    isContactTemplateUpdated: state.talent.isContactTemplateUpdated,
    isEmergencyContactTemplateUpdated: state.talent.isEmergencyContactTemplateUpdated,
    isSkillsTemplateUpdated: state.talent.isSkillsTemplateUpdated
  };
};

const mapDispatchToProps = {
  triggerGetTalentData: talentData,
  triggerSetCurrentNewTalentId: setNewTalentId,
  triggerClearFormTemplate: clearUpdatedFormDetails,
  triggerTalentNotesList: getTalentNotes,
  triggerGetTalentNotesDataTemplate: getTalentNotesDataTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentForm);
