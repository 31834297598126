import { combineReducers } from "redux";

import authReducer from "./auth";
import talentReducer from "./talents";
import eventReducer from "./events";
import shiftReducer from "./shifts";

const rootReducer = combineReducers({
  auth: authReducer,
  talent: talentReducer,
  events: eventReducer,
  shifts: shiftReducer
});

export default rootReducer;
