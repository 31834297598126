import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./forgotPassword.css";
import LoginHeader from '../login/LoginHeader';
import LoginFooter from '../login/LoginFooter';
import LoginCoverImage from '../login/LoginCoverImage';
import { RoutingConstants, Strings } from '../../../constants';
import isTokenExpired from '../../../utils/isTokenExpired';
import Spinner from '../../Spinner';

const ForgotPassword = (props) => {
  const {
    loading,
    loggedIn,
    tokenExpires
  } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  const navigate = useNavigate();
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [autofilled, setAutofilled] = useState(false)
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const isValidToken = isTokenExpired(tokenExpires);
    if (loggedIn && isValidToken) {
      navigate(RoutingConstants.root);
    }
    const emailField = document.getElementById("username");
    emailField?.focus();
  }, []);

  useEffect(() => {
    const username = document.querySelector('#username')
    setTimeout(() => {
      const emailAutofilled = getComputedStyle(username).backgroundColor === 'rgb(232, 240, 254)';
      if(!Object.keys(errors).length) {
        setAutofilled(emailAutofilled)
      } else {
        setAutofilled(false)
      }
    },1000);   
  }, [errors, autofilled])

  useEffect(() => {
    const firstField = document.getElementById("username");
    firstField.focus();
  }, []);

  const inputsValidated =
    userData.username?.length >= 6 &&
    userData.username?.length <= 100 &&
    !errors.username

  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("username");
    if (
      document.activeElement === inputTagElement_1
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (data) => {
    navigate(RoutingConstants.root);
  }
  return (
    <div className="flex">
      <div className="flex flex-col w-full sm:w-3/4 h-100vh">
        <LoginHeader />
        <section className="w-96 m-auto mt-12 font-inter p-4">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <h2 className="font-semibold text-black text-[28px] leading-[34px] mb-6">
              {Strings.forgotPwdHeader}
            </h2>
            <fieldset className="mb-4">
              <div className={`border rounded p-2.5 ${errors?.username ? "border-formWarning" : "border-grayLight"}`}>
                <div className="w-full font-semibold text-[11px] uppercase text-tileGray">
                  {Strings.loginEmailLabel}
                </div>
                <input
                  type="email"
                  className="placeholder-grayNobel placeholder:font-medium text-grayNobel w-full outline-none text-sm font-normal overflow-x-auto"
                  placeholder={Strings.forgotPwdPlaceholder}
                  id="username"
                  name="username"
                  {...register("username", {
                    required: {
                      value: true,
                      message: "EMAIL IS MANDATORY",
                    },
                    maxLength: {
                      value: 300,
                      message: "Enter valid email address",
                    },
                    minLength: {
                      value: 6,
                      message: "Enter valid email address",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Enter valid email address",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
              {errors.username && (
                <span className="text-[10px] text-formWarning uppercase font-normal">
                  {errors.username.message}
                </span>
              )}
            </fieldset>
            <button
              onClick={() => {
                const autosavedUsername =
                  document.getElementById("username").value;
                reset(
                  {
                    username: autosavedUsername
                  },
                  {
                    keepErrors: false,
                    keepDirty: true,
                    keepIsSubmitted: false,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                  }
                );
                setUserData({
                  username: autosavedUsername
                });
              }}
              disabled={errorFromApi || loading}
              type="submit"
              className={`cursor-pointer text-white font-semibold rounded w-full text-center py-2 px-3 flex justify-center items-center text-base mt-4 mb-4 ${
                errorFromApi || loading
                  ? `bg-gray pointer-events-none`
                  : inputsValidated && !errorFromApi
                  ? ` bg-secondaryLight hover:bg-secondary active:bg-secondaryDark`
                  : autofilled ? 'bg-secondaryLight hover:bg-secondary active:bg-secondaryDark' 
                  : `bg-gray`
              }`}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                  <span className="mx-2">{Strings.loading}</span>
                  <Spinner color={"white"} loading={loading} size={15} />
                </div>
              ) : (
                `${Strings.continue}`
              )}
            </button>
          </form>
          <div className="w-full flex flex-col items-center">
            {errorFromApi !== null ? (
              <div className="flex items-center mt-[20px] ">
                <div className="px-6 py-2 flex items-center">
                  <p className="text-[10px] text-formWarning uppercase font-normal">{`${errorFromApi}`}</p>
                </div>
              </div>
            ): ""}
          </div>
          <div className="w-full flex flex-col items-start">
            <Link
              to={RoutingConstants.root}
              className="text-cyanBlue font-medium text-sm mt-3 mb-12 text-start items-start"
            >
              <p>{Strings.noAccount}&nbsp;<b>{Strings.signUp}</b></p>
            </Link>
          </div>
        </section>
        <LoginFooter showSignUp={false} />
      </div>
      <LoginCoverImage />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    tokenExpires: state.auth.loginDetails?.token_expires || ''
  };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
