import React from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./calender.css";
// import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import events from "./events";
import Navbar from '../../layout/navbar';

const Calender = () => {
    let firstDay = 1;
  return (
    <div className="flex">
      <Navbar />
      <div className='calenderSection'>
          <h3>Events Calender</h3>
        <FullCalendar
          defaultView="dayGridMonth"
          firstDay={firstDay}
          locale="us"
          header={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay"
          }}
          themeSystem="Simplex"
          plugins={[dayGridPlugin]}
          events={events}
        />
      </div>
    </div>
  )
}

export default Calender