import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Strings, talentFormHeaders, talentRegisterHeaders } from '../../constants';

const TalentSubHeaders = (props) => {
  const {
    selectedSection,
    showSectionNumber,
    isContinueHighlight,
    setSelectedSection,
    personalInfoUserUpdated, contactUserUpdated, emergencyContactUserUpdated,
    isRegister,
    isPhoneNumberValid,
    isEmergencyPhoneNumberValid
  } = props;
  const [isPersonalFullyValidated, setIsPersonalFullyValidated] = useState(false);
  const [isContactFullyValidated, setIsContactFullyValidated] = useState(false);
  const formHeaders = isRegister ? talentRegisterHeaders : talentFormHeaders;
  useEffect(() => {
    if (personalInfoUserUpdated?.allFieldsUpdated) {
      setIsPersonalFullyValidated(true)
    } else {
      setIsPersonalFullyValidated(false)
    }
  }, [personalInfoUserUpdated])

  useEffect(() => {
    if (contactUserUpdated?.requiredFieldsUpdated && emergencyContactUserUpdated?.allFieldsUpdated) {
      setIsContactFullyValidated(true)
    } else {
      setIsContactFullyValidated(false)
    }
  }, [contactUserUpdated, emergencyContactUserUpdated])
  
  const getSectionColorStyle = (header) => {
    const partiallyValidated = 'text-sunshade border-sunshade border-b-3';
    const fullyValidated = 'text-algae border-algae border-b-3';
    if ((header === Strings.personalInformation && isPersonalFullyValidated) ||
    (header === Strings.contact && isContactFullyValidated)
    ) {
      return fullyValidated
    } else {
      return partiallyValidated;
    }
  }
  const headerCustomStyle = (header) => {
    if (isContinueHighlight) {
      return styleBasedOnCreate(header);
    } else {
      return styleBasedOnView(header);
    }
  }
  const styleBasedOnCreate = (header) => {
    let style = '';
    if (parseInt(header) <= parseInt(selectedSection)) {
      style = getSectionColorStyle(formHeaders[header]);
    } else if (parseInt(header) > parseInt(selectedSection)) {
      style = 'text-richBlue/80';
    }
    return style
  }
  const styleBasedOnView = (header) => {
    let style = 'text-black mb-[3px]';
    if (parseInt(header) === parseInt(selectedSection)) {
      style = 'text-richBlue/80 border-richBlue/80 border-b-3';
    }
    return style;
  }

  const handleHeaderClick = (header) => {
    switch (header) {
      case '2':
        if(personalInfoUserUpdated?.allFieldsUpdated){
          setSelectedSection(header);
        } else {
          setSelectedSection(1)
        }
        break;
      case '3':
        if(contactUserUpdated?.requiredFieldsUpdated && personalInfoUserUpdated?.allFieldsUpdated && emergencyContactUserUpdated?.allFieldsUpdated && isPhoneNumberValid && isEmergencyPhoneNumberValid) {
          setSelectedSection(header);
        } else if(personalInfoUserUpdated?.allFieldsUpdated && (!contactUserUpdated?.requiredFieldsUpdated || !emergencyContactUserUpdated?.allFieldsUpdated || (isPhoneNumberValid === false) || (isEmergencyPhoneNumberValid === false))) {
          setSelectedSection(2)
        } else {
          setSelectedSection(1)
        }
        break;
      default:
        setSelectedSection(header)
    }
     
  }

  const checkValidationForHeaderChange = (header) => {
    if(header >= '3') {
      if(isEmergencyPhoneNumberValid !== false && isPhoneNumberValid !== false) {
        setSelectedSection(header);
      }
    } else {
      setSelectedSection(header);
    }
  }

  const onHeaderClick = (header) => {
    return Boolean(isRegister) ? handleHeaderClick(header) : checkValidationForHeaderChange(header);
  }

  return (
    <div>
      <div className="flex border-b border-grayLight">
        {
          Object.keys(formHeaders).map((header) => {
            return (
              <div
                key={header}
                className={`cursor-pointer flex items-center px-2 pt-5 pb-4 justify-center ${headerCustomStyle(header)} ${isRegister ? 'w-1/3' : 'w-1/4'}`}
                onClick={() => onHeaderClick(header)}
              >
                <div className="text-lg font-medium flex items-center gap-2">
                  <span className={`w-5 h-6 border rounded-50 font-inter items-center justify-center p-1 ${showSectionNumber ? 'flex' : 'hidden'}`}>
                    {header}
                  </span>
                  {formHeaders[header]}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    personalInfoUserUpdated: state.talent.personalInfoUserUpdated,
    contactUserUpdated: state.talent.contactUserUpdated,
    emergencyContactUserUpdated: state.talent.emergencyContactUserUpdated,
    isPhoneNumberValid: state.talent.contactUserUpdated?.isPhoneNumberValid,
    isEmergencyPhoneNumberValid: state.talent.emergencyContactUserUpdated?.isEmergencyPhoneNumberValid
  };
};

export default connect(mapStateToProps, null)(TalentSubHeaders);