import React, { useState, useEffect } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { DatePicker, ConfigProvider } from "antd";
import updateLocale from "dayjs/plugin/updateLocale";
import moment from "moment";
import dayjs from "dayjs";
import "./dateRangeField.css";
import calendarIcon from "../../../assets/svg/calendarIcon.svg";
import saveIcon from "../../../assets/images/Check.svg";
import clearIcon from "../../../assets/images/X.svg";

const { RangePicker } = DatePicker;
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1, // first day as monday
});

const DateRangeField = ({
  name,
  id,
  onChange,
  defaultValue,
  placeholder,
  showEditIcon = false,
  required,
  isDateChangeable,
  saveEdit = () => {},
  editSaveCustomStyle,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDateValue, setSelectedDateValue] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setSelectedDateValue([
      defaultValue?.startDate
        ? dayjs(defaultValue?.startDate, "YYYY-MM-DD")
        : "",
      defaultValue?.endDate ? dayjs(defaultValue?.endDate, "YYYY-MM-DD") : "",
    ]);
    setDefaultDate([
      defaultValue?.startDate
        ? dayjs(defaultValue?.startDate, "YYYY-MM-DD")
        : "",
      defaultValue?.endDate ? dayjs(defaultValue?.endDate, "YYYY-MM-DD") : "",
    ]);
  }, [defaultValue]);

  const isDateSelected = Boolean(
    (selectedDateValue?.some(element => element !== "")) ||
      (defaultValue?.startDate && defaultValue?.endDate)
  );

  const onDateChange = (start, end, formattedStart, formattedEnd) => {
    setTimeout(() => {
      setIsCalendarOpen(false);
    }, 200);
    setSelectedDateValue([
      dayjs(formattedStart, "YYYY-MM-DD"),
      dayjs(formattedEnd, "YYYY-MM-DD"),
    ]);
    if (!showEditIcon) {
      onChange(id, { start: formattedStart, end: formattedEnd }, name);
    }
  };

  const onSaveEdit = () => {
    setIsCalendarOpen(false);
    const defaultStart = defaultDate[0].format("YYYY-MM-DD");
    const defaultEnd = defaultDate[1].format("YYYY-MM-DD");
    const selectedStart = selectedDateValue[0].format("YYYY-MM-DD");
    const selectedEnd = selectedDateValue[1].format("YYYY-MM-DD");
    setIsEditable(false);
    if (defaultStart !== selectedStart || defaultEnd !== selectedEnd) {
      saveEdit({
        start: selectedDateValue[0].format("YYYY-MM-DD"),
        end: selectedDateValue[1].format("YYYY-MM-DD"),
      })
    }
  };

  return (
    <div className="event-daterange-picker flex mt-0.5 cursor-pointer relative">
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              cellActiveWithRangeBg: "#3b44d41a",
              cellHoverWithRangeBg: "#3b44d41a",
              cellHoverBg: "#3b44d41a",
              colorPrimary: "#2400ffcc",
            },
          },
        }}
      >
        <div
          className="relative w-full"
          onClick={() => {
            if(!showEditIcon || isDateChangeable) {
              setIsCalendarOpen(true);
              if (showEditIcon) {
                setIsEditable(true);
              }
            }
          }}
        >
          <RangePicker
            placeholder={["", ""]}
            separator={`${isDateSelected ? "-" : ""}`}
            superPrevIcon={<></>}
            superNextIcon={<></>}
            suffixIcon={<></>}
            format={"MM/DD/YYYY"}
            className="event-datepicker font-inter"
            open={isCalendarOpen}
            status="error"
            onChange={(dates, datesStrings) => {
              onDateChange(
                datesStrings[0],
                datesStrings[1],
                dates[0].format("YYYY-MM-DD"),
                dates[1].format("YYYY-MM-DD")
              );
            }}
            onOpenChange={(openState) => {
              if (!showEditIcon) {
                setTimeout(() => {
                  setIsCalendarOpen(openState);
                }, 200);
              }
            }}
            inputReadOnly={true}
            size="small"
            allowClear={false}
            value={selectedDateValue}
            defaultValue={defaultDate}
            required={required}
          />
          {!isCalendarOpen && !isDateSelected && (
            <span className="datepicker-label absolute left-1/2 top-[60%] w-full text-grayNobel transform -translate-x-1/2 -translate-y-1/2 text-left normal-case text-sm">
              {placeholder}
            </span>
          )}
        </div>
        {(!showEditIcon || !isEditable) && (
          <div className="flex">
            <div className="-mt-[12px] calendar-icon min-w-[22px]">
              <img
                src={calendarIcon}
                alt="calendar-icon"
                onClick={() => {
                  if(!showEditIcon || isDateChangeable) {
                    setIsCalendarOpen(true);
                    if (showEditIcon) {
                      setIsEditable(true);
                    }
                  }
                }}
                className="w-6 h-6"
              />
            </div>
          </div>
        )}
        {isEditable && (
          <div
            className={`flex z-[1] gap-2 absolute bottom-[10px] right-[5px] ${editSaveCustomStyle}`}
          >
            <img
              src={saveIcon}
              alt="save edit"
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                onSaveEdit();
              }}
            />
            <img
              src={clearIcon}
              alt="clear edit"
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setSelectedDateValue(defaultDate);
                setIsCalendarOpen(false);
                setIsEditable(false);
              }}
            />
          </div>
        )}
      </ConfigProvider>
    </div>
  );
};

export default DateRangeField;
