import React from "react";

const AddIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill='none' xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4.16675V15.8334" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.16699 10H15.8337" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export default AddIcon;
