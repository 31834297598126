import authActions from "./auth";
import eventActions from "./events";
import shiftActions from "./shifts";
import talentActions from "./talents";

const actionTypes = {
  talents: talentActions,
  auth: authActions,
  events: eventActions,
  shifts: shiftActions
}

export default actionTypes;
