import actionTypes from "../types";

const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS
} = actionTypes.auth;

const initialState = {
  loggedIn: false,
  loading: false,
  loginDetails: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        loginDetails: action?.response?.loginData || {}
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        loginDetails: {}
      };
    case LOGOUT_SUCCESS: 
      return {
        ...initialState
      }
    default:
      return { ...state };
  }
}

export default authReducer;
