const talentActions = {
  GET_TALENTS_LIST: "GET_TALENTS_LIST",
  GET_TALENTS_LIST_SUCCESS: "GET_TALENTS_LIST_SUCCESS",
  GET_TALENTS_LIST_ERROR: "GET_TALENTS_LIST_ERROR",
  GET_PROVINCES: "GET_PROVINCES",
  GET_PROVINCES_SUCCESS: "GET_PROVINCES_SUCCESS",
  GET_PROVINCES_ERROR: "GET_PROVINCES_ERROR",
  GET_EVENT_ROLES: "GET_EVENT_ROLES",
  GET_EVENT_ROLES_SUCCESS: "GET_EVENT_ROLES_SUCCESS",
  GET_EVENT_ROLES_ERROR: "GET_EVENT_ROLES_ERROR",
  GET_FILTERED_TALENTS_LIST: "GET_FILTERED_TALENTS_LIST",
  GET_FILTERED_TALENTS_LIST_SUCCESS: "GET_FILTERED_TALENTS_LIST_SUCCESS",
  GET_FILTERED_TALENTS_LIST_ERROR: "GET_FILTERED_TALENTS_LIST_ERROR",
  CLEAR_FILTERED_TALENTS_LIST: "CLEAR_FILTERED_TALENTS_LIST",
  CLEAR_FILTERED_TALENTS_LIST_SUCCESS: "CLEAR_FILTERED_TALENTS_LIST_SUCCESS",
  GET_ATTRIBUTES: "GET_ATTRIBUTES",
  GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
  GET_ATTRIBUTES_ERROR: "GET_ATTRIBUTES_ERROR",
  GET_EMAIL_UUID: "GET_EMAIL_UUID",
  GET_EMAIL_UUID_SUCCESS: "GET_EMAIL_UUID_SUCCESS",
  GET_EMAIL_UUID_ERROR: "GET_EMAIL_UUID_ERROR",
  GET_ADDRESS_UUID: "GET_ADDRESS_UUID",
  GET_ADDRESS_UUID_SUCCESS: "GET_ADDRESS_UUID_SUCCESS",
  GET_ADDRESS_UUID_ERROR: "GET_ADDRESS_UUID_ERROR",
  GET_PHONE_UUID: "GET_PHONE_UUID",
  GET_PHONE_UUID_SUCCESS: "GET_PHONE_UUID_SUCCESS",
  GET_PHONE_UUID_ERROR: "GET_PHONE_UUID_ERROR",
  GET_TALENTS_CORE_ATTRIBUTES: "GET_TALENTS_CORE_ATTRIBUTES",
  GET_TALENTS_CORE_ATTRIBUTES_SUCCESS: "GET_TALENTS_CORE_ATTRIBUTES_SUCCESS",
  GET_TALENTS_CORE_ATTRIBUTES_ERROR: "GET_TALENTS_CORE_ATTRIBUTES_ERROR",
  PERSONAL_INFO_FORM_TEMPLATE: "PERSONAL_INFO_FORM_TEMPLATE",
  EXPERIENCE_FORM_TEMPLATE: "EXPERIENCE_FORM_TEMPLATE",
  CONTACT_FORM_TEMPLATE: "CONTACT_FORM_TEMPLATE",
  EMERGENCY_CONTACT_FORM_TALENT: "EMERGENCY_CONTACT_FORM_TALENT",
  PERSONAL_INFO_UPDATED_FORM: "PERSONAL_INFO_UPDATED_FORM",
  EXPERIENCE_UPDATED_FORM: "EXPERIENCE_UPDATED_FORM",
  CONTACT_UPDATED_FORM: "CONTACT_UPDATED_FORM",
  EMERGENCY_CONTACT_UPDATED_FORM: "EMERGENCY_CONTACT_UPDATED_FORM",
  CLEAR_UPDATED_FORM_DETAILS: "CLEAR_UPDATED_FORM_DETAILS",
  SKILLS_FORM_TEMPLATE: "SKILLS_FORM_TEMPLATE",
  SKILLS_UPDATED_FORM: "SKILLS_UPDATED_FORM",
  TALENT_DATA: "TALENT_DATA",
  TALENT_DATA_SUCCESS: "TALENT_DATA_SUCCESS",
  TALENT_DATA_ERROR: "TALENT_DATA_ERROR",
  PHOTO_USER_UPDATED: "PHOTO_USER_UPDATED",
  UPLOAD_NEW_USER_IMAGE: "UPLOAD_NEW_USER_IMAGE",
  UPLOAD_NEW_USER_IMAGE_SUCCESS: "UPLOAD_NEW_USER_IMAGE_SUCCESS",
  UPLOAD_NEW_USER_IMAGE_ERROR: "UPLOAD_NEW_USER_IMAGE_ERROR",
  DELETE_NEW_USER_IMAGE: "DELETE_NEW_USER_IMAGE",
  DELETE_NEW_USER_IMAGE_SUCCESS: "DELETE_NEW_USER_IMAGE_SUCCESS",
  DELETE_NEW_USER_IMAGE_ERROR: "DELETE_NEW_USER_IMAGE_ERROR",
  UPLOAD_EXISTING_USER_IMAGE: "UPLOAD_EXISTING_USER_IMAGE",
  UPLOAD_EXISTING_USER_IMAGE_SUCCESS: "UPLOAD_EXISTING_USER_IMAGE_SUCCESS",
  UPLOAD_EXISTING_USER_IMAGE_ERROR: "UPLOAD_EXISTING_USER_IMAGE_ERROR", 
  DELETE_EXISTING_USER_IMAGE: "DELETE_EXISTING_USER_IMAGE", 
  DELETE_EXISTING_USER_IMAGE_SUCCESS: "DELETE_EXISTING_USER_IMAGE_SUCCESS",
  DELETE_EXISTING_USER_IMAGE_ERROR: "DELETE_EXISTING_USER_IMAGE_ERROR",
  CREATE_NEW_TALENT: "CREATE_NEW_TALENT",
  CREATE_NEW_TALENT_SUCCESS: "CREATE_NEW_TALENT_SUCCESS",
  CREATE_NEW_TALENT_ERROR: "CREATE_NEW_TALENT_ERROR",
  UPDATE_CORE_ATTRIBUTE: "UPDATE_CORE_ATTRIBUTE",
  SET_NEW_TALENT_ID: "SET_NEW_TALENT_ID",
  CLEAR_NEW_TALENT_ID: "CLEAR_NEW_TALENT_ID",
  UPDATE_EXISTING_TALENT_DATA: "UPDATE_EXISTING_TALENT_DATA",
  UPDATE_EXISTING_TALENT_DATA_SUCCESS: "UPDATE_EXISTING_TALENT_DATA_SUCCESS",
  UPDATE_EXISTING_TALENT_DATA_ERROR: "UPDATE_EXISTING_TALENT_DATA_ERROR",
  UPDATE_NEW_TALENT_DATA: "UPDATE_NEW_TALENT_DATA",
  UPDATE_NEW_TALENT_DATA_SUCCESS: "UPDATE_NEW_TALENT_DATA_SUCCESS",
  UPDATE_NEW_TALENT_DATA_ERROR: "UPDATE_NEW_TALENT_DATA_ERROR",
  CREATE_TALENT_PHONE_ATTR: "CREATE_TALENT_PHONE_ATTR",
  UPDATE_TALENT_PHONE_ATTR: "UPDATE_TALENT_PHONE_ATTR",
  CREATE_TALENT_EMAIL_ATTR: "CREATE_TALENT_EMAIL_ATTR",
  UPDATE_TALENT_EMAIL_ATTR: "UPDATE_TALENT_EMAIL_ATTR",
  UPDATE_TALENT_EVENT_ROLE: "UPDATE_TALENT_EVENT_ROLE",
  DELETE_TALENT_EVENT_ATTR: "DELETE_TALENT_EVENT_ATTR",
  CREATE_TALENT_ADDRESS_ATTR: "CREATE_TALENT_ADDRESS_ATTR",
  UPDATE_TALENT_ADDRESS_ATTR: "UPDATE_TALENT_ADDRESS_ATTR",
  UPDATE_ASSET_PRIMARY: "UPDATE_ASSET_PRIMARY",
  DELETE_TALENT_ATTR: "DELETE_TALENT_ATTR",
  GET_TALENT_NOTES_DATA: "GET_TALENT_NOTES_DATA",
  TALENT_NOTES_DATA: "TALENT_NOTES_DATA",
  UPDATE_OTHER_ATTRIBUTES: "UPDATE_OTHER_ATTRIBUTES",
  UPDATE_OTHER_ATTRIBUTES_SUCCESS: "UPDATE_OTHER_ATTRIBUTES_SUCCESS",
  CREATE_TALENT_NOTES: "CREATE_TALENT_NOTES",
  DELETE_TALENT_NOTE: "DELETE_TALENT_NOTE",
  UPDATE_TALENT_NOTE: "UPDATE_TALENT_NOTE",
  CHECK_PHONE_NUMBER_VALIDITY: "CHECK_PHONE_NUMBER_VALIDITY",
  CHECK_CONTACT_NUMBER_VALIDITY: "CHECK_CONTACT_NUMBER_VALIDITY",
  SET_IS_PHONE_NUMBER_VALID: "SET_IS_PHONE_NUMBER_VALID",
  SET_IS_EMERGENCY_PHONE_NUMBER_VALID: "SET_IS_EMERGENCY_PHONE_NUMBER_VALID",
  GET_GMAP_ADDRESS_PREDICTIONS: "GET_GMAP_ADDRESS_PREDICTIONS",
  GET_ADDRESS_BY_PLACE_ID: "GET_ADDRESS_BY_PLACE_ID",
  UPDATE_DEFAULT_CONTACT_ADDRESS: "UPDATE_DEFAULT_CONTACT_ADDRESS",
  ADDRESS_FIELD_TEMPLATE: "ADDRESS_FIELD_TEMPLATE"
}

export default talentActions;
