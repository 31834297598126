import { squareRounded } from "../../../../constants/events";
import TextAreaField from "../../talents/talentAdd/textAreaField";

const Notes = (props) => {
  const {
    notesTemplate,
    showEditIcon = false,
    saveCoreEdit = () => {},
    onChangeNotes = () => {},
  } = props;

  return (
    <fieldset className="w-full m-auto text-lg col-span-6">
      <div className="border focus-within:border-richBlue border-grayLight rounded w-full uppercase p-2 gap-1 text-xs">
        <div className='flex flex-row'><label className="font-semibold font-inter text-sm text-tileGray uppercase" htmlFor={notesTemplate?.id}>{notesTemplate?.label}</label>{notesTemplate?.required && squareRounded()}</div>
        <TextAreaField
          name={notesTemplate?.name}
          id={notesTemplate?.id}
          placeholder={notesTemplate?.placeholder}
          defaultValue={notesTemplate?.value}
          maxAllowed={notesTemplate?.maxAllowed}
          minAllowed={notesTemplate?.minAllowed}
          onChange={onChangeNotes}
          showEditIcon={showEditIcon}
          saveEdit={saveCoreEdit}
          className="resize-none"
          isEventPage={true}
        />
      </div>
    </fieldset>
  );
};

export default Notes;
