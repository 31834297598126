// library imports
import { all, call } from "redux-saga/effects";

// user imports
import { authSagas } from "./auth";
import { talentSaga } from "./talent";
import { eventSagas } from "./events";
import { shiftSagas } from "./shifts";

// exports
export default function* rootSaga() {
  yield all([call(authSagas), call(talentSaga), call(eventSagas), call(shiftSagas)]);
}
