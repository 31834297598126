import React, { useState } from "react";
import expand from "../../../assets/images/expand.png"
import collapse from "../../../assets/images/collapse.png"
import MultipleSelectionDropdown from "../talents/library";

const MultiSelectDropdown = ({ label, id, options, onOptionUpdate, optionsSelected, placeholder, onRemoveOption }) => {
  const [showSearch, setShowSearch] = useState([])

  return (
    <div className="flex border rounded-lg border-grayLight">
      <div className="w-full">
        <div>
          <div className="flex flex-row justify-between cursor-pointer border-b border-grayLight py-2" onClick={() => setShowSearch(!showSearch)}>
            <div className='m-auto'>
              {showSearch ?
                <img src={expand} alt='chevron down' className="cursor-pointer ml-4" /> :
                <img src={collapse} alt='chevron right' className="cursor-pointer ml-4" />
              }
            </div>
            <div className="text-base font-medium ml-2">{label}</div>
            <div className="flex-1 height-px bg-white ml-8 mt-3" />
          </div>
          {
            showSearch &&
            <div className="mt-2">
              <MultipleSelectionDropdown
                options={options}
                selectedValues={optionsSelected}
                showCheckbox={true}
                style={{ border: 'none', outline: 'none', textAlign: 'center', fontSize: '14px' }}
                displayValue={"name"}
                id={id}
                placeholder={placeholder}
                onSelect={(e) => onOptionUpdate(e, id, optionsSelected)}
                onRemove={(e, item) => onRemoveOption(e, item, id, optionsSelected)}
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default MultiSelectDropdown;
