import { takeLatest, call, put, all } from "redux-saga/effects";

import {
  loginSuccess,
  loginError,
  logOutSuccess,
  logOutError
} from "../actions/auth";
import {
  login,
  logout
} from "../../api/auth";
import actionTypes from "../types";
import localCacheStorage from "../../utils/localCacheStorage";
import sessionCacheStorage from "../../utils/sessionCacheStorage";

const {
  LOGIN,
  LOGOUT
} = actionTypes.auth;

export function* fetchLogin(action) {
  const { payload } = action;
  const { onSuccess, onError, ...rest } = payload;
  try {
    const response = yield call(login, rest);
    if (response?.status !== 200) {
      const errorMsg = response?.response?.data?.msg;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    const loginData = response?.data;
    yield localCacheStorage.set("ACCESS_TOKEN", loginData?.access_token);
    localStorage.setItem('authToken', JSON.stringify(loginData?.access_token));
    
    yield put(loginSuccess({ loginData }));
    onSuccess();
  } catch (error) {
    onError(error.message);
    yield put(loginError(error));
    yield localCacheStorage.clear("ACCESS_TOKEN");
  }
}

export function* logoutUser() {
  try {
    yield localCacheStorage.clear("APP_STATE");
    yield localCacheStorage.clear("isFilter");
    yield sessionCacheStorage.clear("talentFilter");
    yield sessionCacheStorage.clear("talentFilterTags");
    localStorage.clear();
    sessionStorage.clear();
    yield localCacheStorage.clear();
    yield call(logout);
    yield put(logOutSuccess());
  } catch (error) {
    yield put(logOutError(error));
  }
}

export function* saga() {
  yield takeLatest(LOGIN, fetchLogin);
  yield takeLatest(LOGOUT, logoutUser);
}

export function* authSagas() {
  yield all([call(saga)]);
}
